import {useEffect} from "react";
import HeaderSideBar from "./../index"; 
import ThreeSixtyViewer from "../../../COMPONENTS/ThreeSixtyViewer/Viewr";
import { useLocation } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { setParentModuleActiveDispatch } from "../../../COMPONENTS/SideBarMenu/activeParentModule.slice";
import { setModuleActiveDispatch } from "../../../COMPONENTS/SideBarMenu/activeModule.slice";

export default ()=>{

  const dispatch = useDispatch(); 
  let {state}  = useLocation();

  useEffect(()=>{
    dispatch(setParentModuleActiveDispatch("threeSixtyTour"));
    dispatch(setModuleActiveDispatch(""));
  }, []);
    
    return (
        <HeaderSideBar> 
          <ThreeSixtyViewer {...state}/>
        </HeaderSideBar> 
    )
}