const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;
const REACT_APP_STRIP_PUBLISH_KEY = process.env.REACT_APP_STRIP_PUBLISH_KEY;
const REACT_APP_DOMAIN_URL = process.env.REACT_APP_DOMAIN_URL;
const REACT_APP_VIEWER_TOUR_BASE_URL = process.env.REACT_APP_VIEWER_TOUR_BASE_URL;
const REACT_APP_X_FUNCTION_KEY = process.env.REACT_APP_X_FUNCTION_KEY;
const REACT_APP_THREE_SIXY_IMG_BASE_URL_TO_BE_SHOWN = process.env.REACT_APP_THREE_SIXY_IMG_BASE_URL_TO_BE_SHOWN;


/**
 * 
 *  APP 
 * 
*/

// DEV
export const BASE_URL = REACT_APP_BASE_URL;


// THIS END POINTS RELATED TO AUTHENTICATIONS
export const LOGIN = "api/account/login";
export const REGISTRATION = "api/account/register";
export const FORGOT_PASSWORD = "api/account/forgotpassword";
export const RESET_PASSWORD = "api/account/resetpassword";
export const VERIFY_EMAIL = "api/account/VerifyEmail";
export const REFRESH_TOKEN = "api/account/refreshtoken";

export const LOG_OUT = "api/account/logout";
export const RESEND_EMAIN_VERIFY_USER = "api/account/resendemailverificationlink";

// PROFILE
export const GET_PROFILE_DETAILS = "api/UserProfile/getuserdetailbyuserid";
export const UPDATE_USER_PROFILE_DATA = "api/UserProfile/saveuserdetail";
export const GET_COUNTRY = "api/Country/getcountries";
export const GET_IMG_UL = "api/UserProfile/uploaduserprofilepic";
export const UPDATE_PASSWORD = "api/account/changepassword";
export const REMOVE_PROFILE_PIC = "api/UserProfile/removeprofilepic";

// VIRTUAL-TOUR
export const SAVE_VIRTUAL_TOUR = "api/VirtualTour/savevirtualtours";
export const GET_VIRTUAL_TOUR = "api/VirtualTour/getvirtualtours";
export const DELETE_VIRTUAL_TOUR_IMAGE = "api/VirtualTour/deletevirtualtourimage";
export const GET_VIRTUAL_TOUR_IMAGES = "api/VirtualTour/Getvirtualtourimages";
export const GET_DEFAULT_VIRTUAL_TOUR_IMAGES = "api/VirtualTour/GetDefaultvirtualtour";

// UPLOADING 
export const UPLOAD_360_IMAGES = "api/VirtualTour/savevirtualtourtimage";

// DASHBOARD
export const GET_TOURS_VIEWS_BY_USER = "api/Dashboard/gettourviewsbyuser";
export const GET_TOURS_VIEWS_BY_CATEGORY = "api/Dashboard/gettourviewsbycategory";
export const GET_TOURS_VIEWS_BY_DATE = "api/Dashboard/gettourviewsbydate";

// TAGGING
export const GET_VT_TAGGING = "/api/VirtualTour/GetTagsByExperienceID?ExperienceID=";
export const GET_USER_ASSETS_FOR_TAGGING = "/api/asset/GetUserAssetsForTagging?";
export const DELETE_VERTUAL_TOUR_TAGGING = "/api/VirtualTour/deletetags";
export const SAVE_VERTUAL_TOUR_TAGGING = "/api/VirtualTour/savetags";

// SUBSCRIPTION
export const GET_SUBSCRIPTION_DETAILS = "api/subscription/get-subscription-details";
export const RE_START_SUBSCRIPTION = "api/subscription/restart";
// export const CANCEL_SUBSCRIPTION = "api/subscription/cancel-at-periodend";
export const CANCEL_SUBSCRIPTION = "api/subscription/cancel";
export const VERIFY_CARD_PAYMENT = "api/subscription/VerifyRazorpayPayment";
export const CHECK_PAYMENT_STATUS = "api/subscription/CheckPaymentStatusAsync";

export const CREATE_SETUP_INTENT = "api/subscription/create-setup-intent";
export const CREATE_SETUP_INTENT_NEW = "api/subscription/create-setup-intent-new";
export const UPDATE_PAYMENT_METHOD = "api/subscription/update-subscription-withpayment";
export const CREATE_NEW_SUBSCRIPTION = "api/subscription/create-subscription";
export const GET_PORTAL_LINK = "/api/Subscription/get-portal-link";

// OTHERS
export const UPLOADED_TOUR_LOGO = "api/VirtualTour/uploadtourlogo";

// STRIP --> DEV
export const STRIP_PUBLISH_KEY = REACT_APP_STRIP_PUBLISH_KEY;


/**
 * 
 *  VIEWER 
 * 
*/

// MAIN DOMAIN URL --> DEV
export const DOMAIN_URL = REACT_APP_DOMAIN_URL;
export const LOCAL_DOMAIN_VIEER_BASE_URL = "http://localhost:3000/"
// VIEWER THREE SIXTY WORLD PAGE
export const VIEWER_THREE_SIXTY_WORLD_PAGE = "three-sixty-world";


// BASE URL --> DEV
export const VIEWER_TOUR_BASE_URL = REACT_APP_VIEWER_TOUR_BASE_URL;
// DEV
export const X_FUNCTION_KEY = REACT_APP_X_FUNCTION_KEY;
// TO GET TOURS 
export const VIEWER_TOUR_IMAGES = "api/GetTourData?"; 
// TO LOG
export const LOG_TOUR = "api/LogTourViews";
// GET TAG FOR TOURS
export const GET_TAG_FOR_VIEWER = "api/GetTags?"; 


// BASE URL FOR 360IMG TO BE SHOWN --> DEV
export const THREE_SIXY_IMG_BASE_URL_TO_BE_SHOWN =REACT_APP_THREE_SIXY_IMG_BASE_URL_TO_BE_SHOWN;






// export const BASE_URL = "https://recuerdodevapi.azurewebsites.net";
// // export const BASE_URL = "https://8ffc-2405-201-300b-61a8-e94a-98a1-e6ce-d19e.in.ngrok.io";


// // THIS END POINTS RELATED TO AUTHENTICATIONS
// export const LOGIN = "/api/account/login";
// export const REGISTRATION = "/api/account/register";
// export const FORGOT_PASSWORD = "/api/account/forgotpassword";
// export const RESET_PASSWORD = "/api/account/resetpassword";
// export const THANK_YOU = "/api/NoThankYou/savenothankyou";
// export const VERIFY_EMAIL = "/api/account/VerifyEmail";
// export const UPDATE_PASSWORD = "/api/account/changepassword";
// export const REFRESH_TOKEN = "/api/account/refreshtoken";
// export const LOG_OUT = "/api/account/logout";
// export const RESEND_EMAIN_VERIFY_USER = "/api/account/resendemailverificationlink";
// export const REMOVE_PROFILE_PIC = "/api/UserProfile/removeprofilepic";

// // THIS END POINTS RELATED TO PROFILE MANAGMENT
// export const GET_PROFILE_DETAILS = "/api/UserProfile/getuserdetailbyuserid";
// export const UPDATE_USER_PROFILE_DATA = "/api/UserProfile/saveuserdetail";

// // COMMON API
// export const GET_COUNTRY = "/api/Country/getcountries";
// export const GET_IMG_UL = "/api/UserProfile/uploaduserprofilepic";

// // UPLOADING 
export const UPLOAD_ASSESTS = "api/asset/saveuserassets";
// export const GET_SELF_UPLOAD_ASSESTS = "/api/asset/getuserassets";
// export const UPLOAD_360_IMAGES = "/api/VirtualTour/savevirtualtourtimage";

// // SHARING
// export const SHARE_WITH_ME_AND_OTHERS_USER_INFO = "/api/asset/getassetsharinguserlist";
// export const SHARE_ASSESTS = "/api/asset/shareassets";
// export const DELETE_ASSESTS = "/api/Asset/deleteassets";
// export const GET_SHARED_WITH_OTHERS = "/api/Asset/GetSharedWithOtherAssets";
// export const GET_SHARED_WITH_ME = "/api/Asset/GetSharedWithMeAssets";

// // VR-experience
// export const GET_VR_USERS = "/api/vrexperience/getmapedvrroomorrelationshiplist";
// export const GET_VR_ROOM_RELATIONSHIP = "/api/vrexperience/getvrroomandrelationshiplist";
// export const MAP_VR_WITH_RELATIONSHIP = "/api/vrexperience/mapvrroomwithrelationship";
// export const GET_VR_ASSEST_LIST = "/api/vrexperience/getvrassetslist";
// export const SHARE_ASSEST_IN_VR_ROOM = "/api/vrexperience/shareassetsinvrexperience";
// export const GENERATE_PIN = "/api/vrexperience/generatepinforvrroom";
// export const DELETE_SHARED_ASSESTS = "/api/vrexperience/deleteassetsinvrexperience";

// // VIRTUAL-TOUR
// export const SAVE_VIRTUAL_TOUR = "/api/VirtualTour/savevirtualtourt";
// export const GET_VIRTUAL_TOUR = "/api/VirtualTour/getvirtualtourt";
// export const DELETE_VIRTUAL_TOUR_IMAGE = "/api/VirtualTour/deletevirtualtourimage";
// export const GET_VIRTUAL_TOUR_IMAGES = "/api/VirtualTour/Getvirtualtourimages";
// export const GET_DEFAULT_VIRTUAL_TOUR_IMAGES = "/api/VirtualTour/GetDefaultvirtualtour";

// // TAGGING
// export const GET_VT_TAGGING = "/api/VirtualTour/GetVirtualTourImageTagging?VirtualTourImageID=";
// export const GET_USER_ASSETS_FOR_TAGGING = "/api/asset/GetUserAssetsForTagging?";
// export const DELETE_VERTUAL_TOUR_TAGGING = "/api/VirtualTour/deletevirtualtourimagetagging";
// export const SAVE_VERTUAL_TOUR_TAGGING = "/api/VirtualTour/savevirtualtourtimagetagging";

// // contributor
// export const INVITE_CONTRIBUTOR = "/api/InviteContributor/saveinvitecontributor";

// // blob storage 
// export const AZUR_SAS = "/api/Asset/getsaasurltouploadvideo";
// export const EXPIRE_AZUR_SAS = "/api/Asset/expiresaasurltouploadvideo";


// // Subscription 
// export const GET_SUBSCRIPTION_DETAILS = "/api/Subscription/GetSubscriptionDetails";
// export const GET_SETUP_INTENT = "/api/Subscription/GetClientSecret?";
// export const POST_PAYMENT_METHOD = "/api/Subscription/CreateSubscription";
// export const PAUSH_SUBSCRIPTION = "/api/Subscription/CancelSubscription";
// export const RESUME_SUBSCRIPTION = "/api/Subscription/ResumeSubscription";
// export const GET_PAYMENT_METHODS = "/api/Subscription/GetCustomerCard";
// export const SET_PAYMENT_METHOD_DEFAULT = "/api/Subscription/SetDefaultCard";
