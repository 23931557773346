import React, { useState, useEffect, useRef } from "react";
import Profile from ".";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import Arrow from './../../ASSETS/Images/icons/arrow_1.png';
import Upload from './../../ASSETS/Images/icons/upload.png';
import Delete from './../../ASSETS/Images/xr/delete.png';
import ProfileImage from './../../ASSETS/Images/defaultProfilePic.jpg';
import Edit from './../../ASSETS/Images/edit.png';
import Cancel from './../../ASSETS/Images/icons/Cross.png';
import classNames from 'classnames';
import {Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter,Breadcrumb, BreadcrumbItem, Form, FormGroup, Label, Input } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux'
import { getCountry, getImgURL } from "../../UTILITYS/endPoints";
import {deleteProfilePicDispatch, setDoNotShowExpiredModal, updateUserDetailsDispatch} from './slice.reducer.tsx'
import ImageCropper from "./../../COMPONENTS/CROP_IMAGE/ImageCropper";
import 'react-image-crop/dist/ReactCrop.css';
import { toast, ToastContainer } from 'react-toastify';
import validator from 'validator';
import { loaderDispatch } from "../../COMPONENTS/Loader/slice.reducer";
import { setModuleActiveDispatch } from "../../COMPONENTS/SideBarMenu/activeModule.slice";
import { setParentModuleActiveDispatch } from "../../COMPONENTS/SideBarMenu/activeParentModule.slice";
import { useContext } from "react";
import CreateTokenContextProvider from "../../STORE/App-wide-state/Token/createTokenContextProvider";
import { useAppSelector, useAppDispatch } from './../../UTILITYS/hook/typeVersionOfredux.tsx'
import {ContryInterface, getCountryInterface, } from './../../UTILITYS/Modals/comman.modal.tsx'
import userProfileModal from './../../UTILITYS/Modals/userProfile.modal.tsx'

export default ()=>{
    
    const navigate = useNavigate()
    const [modal, setModal] = useState(false);
    const [isRemovingProfilePicApply, setIsRemovingProfilePicApply] = useState(false);
    const [crpImgModal, setCrpImgModal] = useState(false);
    const [countries, setContries] = useState<ContryInterface[]>([])
    const toggle = () => {
        setIsRemovingProfilePicApply(false) 
        setModal(!modal)
    };
    const onConfirmRemoveProfilePic = () => {
        setIsRemovingProfilePicApply(!isRemovingProfilePicApply)
    };
    // const dispatch = useDispatch();
    const dispatch = useAppDispatch()
    // const userProfileDetails = useSelector(state => state.userProfileDetails)
    const userProfileDetails = useAppSelector(state => state.userProfileDetails)
    const [userDetails, setUserDetails] = useState<userProfileModal>(userProfileDetails)
    console.log('this is profile data dfdfdf', userDetails)
    const [firstName, setFirstName] = useState(userDetails.firstName)
    const [lastName, setLastName] = useState(userDetails.lastName)
    const [email, setEmail] = useState(userDetails.email)
    const [city, setCity] = useState(userDetails.city)
    const [mobileNumber, setMobileNumber] = useState(
        (userDetails.mobile !=undefined && userDetails.mobile !== "") ? userDetails.mobile.split(" ")[1]: ""
    )
    const [mobileNumberCode, setMobileNumberCode] = useState(
        (userDetails.mobile !=undefined && userDetails.mobile !== "") ? userDetails.mobile.split(" ")[0]: ""
    )
    const [selectCountyCode, setSelectCountyCode] = useState(userDetails.countryCode!=""?userDetails.countryCode: "")
    const tokenContext = useContext(CreateTokenContextProvider);
    const [imageToCrop, setImageToCrop] = useState<any>(undefined);
    const [imgURL, setImgURL] = useState<string| undefined>(userDetails.profilePicPath!=""?userDetails.profilePicPath: ProfileImage)
    const [imgURLOnGoingCropping, setImgURLOnGoingCropping] = useState<string| undefined>()
    const [choseFile ,setChoseFile] =useState<string| undefined>(userDetails.profilePicPath!=""?userDetails.profilePicPath: "");

    const [firstNameErr, setrFirstNameErr] = useState<boolean>(false)
    const [lastNameErr, setLastNameErr] = useState<boolean>(false)
    const [cityErr, setCityErr] = useState<boolean>(false)
    const [mobileNumberErr, setMobileNumberErr] = useState<boolean>(false)
    const [mobileNumberCodeErr, setMobileNumberCodeErr] = useState<boolean>(false);
    const [focused, setFocused] = useState(false);
    
    useEffect(()=>{
            dispatch(setParentModuleActiveDispatch("parentProfile"))
            dispatch(setModuleActiveDispatch("profile"))
        },
    []);

    useEffect(()=>{
        if(!userProfileDetails.isUserFetch){
            dispatch(setDoNotShowExpiredModal(true));
        }
    }, [userProfileDetails.isUserFetch]);

    useEffect(()=>{
        setUserDetails(userProfileDetails);
        setFirstName(userProfileDetails.firstName);
        setLastName(userProfileDetails.lastName);
        setCity(userProfileDetails.city);
        setEmail(userProfileDetails.email);
        setMobileNumber( (userProfileDetails.mobile !=undefined && userProfileDetails.mobile !== "") ? userProfileDetails.mobile.split(" ")[1]: "");
        setMobileNumberCode((userProfileDetails.mobile !=undefined && userProfileDetails.mobile !== "") ? userProfileDetails.mobile.split(" ")[0]: "");
        setSelectCountyCode(userProfileDetails.countryCode!=""?userProfileDetails.countryCode: "");
        setImgURL(userProfileDetails.profilePicPath!=""?userProfileDetails.profilePicPath: ProfileImage);
        setChoseFile(userProfileDetails.profilePicPath!=""?userProfileDetails.profilePicPath: "");
    }, [userProfileDetails]);

    useEffect(()=>{
        
        async function getContries(){
            try{
                const contry: getCountryInterface =await getCountry({}, dispatch) as getCountryInterface;
                setContries(contry.result.payload);
                localStorage.setItem("country", JSON.stringify({
                    ...contry.result.payload
                }))
            }catch (err){

            }
        }
        getContries()
    }, []);

    const onInputChange: React.ChangeEventHandler<HTMLInputElement> = (e)=>{
        
        switch(e.target.name){
          case "firstName":
            setFirstName(e.target.value.replace(/[^a-z]/gi, ''));
            //   setFirstName(e.target.value);
            setrFirstNameErr(false)
            break;
          case "lastName":
            setLastName(e.target.value.replace(/[^a-z]/gi, ''));
            // setLastName(e.target.value);
            setLastNameErr(false)
            break;
          case "city":
            setCity(e.target.value);
            setCityErr(false)
            break;
          case "mobileNumber":
            console.log('this is mobile number', /^\d{4,12}$/.test((e.target.value)))
            if(/^\d{0,12}$/.test(e.target.value)){
                setMobileNumberErr(false)
                setMobileNumber(e.target.value);
            }
            break;
        }
    }
    
    const getContyCodeView: () => JSX.Element[] = () =>{
       
        let options =[];

        options= countries.map(contry=>{
            if(mobileNumberCode !== "" && contry.phoneCode === mobileNumberCode.substring(1)){
                return <option value={'+'+contry.phoneCode} selected>
                    {/* {contry.name} (+{contry.phoneCode}) */}
                    {focused ? contry.name+" "+ ("+"+contry.phoneCode): ("+"+contry.phoneCode)}  
                </option>
            }else{
                return <option value={'+'+contry.phoneCode}>
                    {/* {contry.name} (+{contry.phoneCode}) */}
                    {focused ? contry.name+" "+ ("+"+contry.phoneCode): ("+"+contry.phoneCode)}
                </option>
            }
        })

        if(mobileNumberCode !== ""){
            return [<option value="">
            Select Country Code
        </option>, ...options];
        }
        return [<option value="">
            Select Country Code
        </option>, ...options];
    }
    
    const getContyView: () => JSX.Element[] = () =>{

        let options =null;

        options= countries.map(contry=>{
            console.log('this is coyuntryu', contry)
            if(selectCountyCode !== "" && selectCountyCode === contry.name){
                return <option value={contry.name} selected>
                    {contry.name}
                </option>
            }else{
                return <option value={contry.name}>
                    {contry.name}
                </option>
            }
        })

        if(selectCountyCode !== ""){
            return [<option value="">
             Select Country
        </option>, ...options];
        }
        return [<option value="">
             Select Country
        </option>, ...options];
    }
    
    const selectedValue: React.ChangeEventHandler<HTMLInputElement> = (e) =>{
        console.log('this is selected value', e.target.value, e.target.name)

        switch(e.target.name){
            case "select-code":
                setMobileNumberCodeErr(false)
                setMobileNumberCode(e.target.value);
                setTimeout(() => {
                    setFocused(false)
                }, 100);
              break;
            case "select-contry":
                setSelectCountyCode(e.target.value);
              break;
          }
    }

    const updateUserProfile= async(e: React.SyntheticEvent)=>{
       
        e.preventDefault();
        toast.dismiss()

        if(firstName!== undefined && validator.isEmpty(firstName.trim())){
            toast.error("Please Enter First Name", { });
            setrFirstNameErr(true)
            return;
        }else if(firstName!== undefined && !validator.isLength(firstName.trim(), {min: 1, max: 25})){
            toast.error("First Name length should be minimum 3 char and maximum 25 char", { });
            setrFirstNameErr(true)
            return;
        }
         
        if(lastName!== undefined && validator.isEmpty(lastName.trim())){
            toast.error("Please Enter Last Name", { });
            setLastNameErr(true)
            return;
        }else if(lastName!== undefined && !validator.isLength(lastName.trim(), {min: 1, max: 25})){
            toast.error("Last Name length should be minimum 3 char and maximum 25 char", { });
            setLastNameErr(true)
            return;
        }
        
        if(city!== undefined && !validator.isEmpty(city.trim()) && !validator.isLength(city.trim(), {min: 1, max: 25})){
            toast.error("City Name length should be minimum 3 char and maximum 25 char", { });
            setCityErr(true)
            return;
        }

        console.log('tii si selected contry code', mobileNumberCode, mobileNumber)

        if(!validator.isEmpty(mobileNumberCode) && validator.isEmpty(mobileNumber)){
            toast.error("Please Enter Phone Number", { });
            setMobileNumberErr(true)
            return;
        }else if(validator.isEmpty(mobileNumberCode) && !validator.isEmpty(mobileNumber)){
            toast.error("Please Select Country Code", { });
            setMobileNumberCodeErr(true)
            return;
        }
    
        
        let mobileNumberToUpdate = "";
        if(mobileNumberCode !== "" && mobileNumber !== ""){
            mobileNumberToUpdate = mobileNumberCode+" "+mobileNumber;
        }else{
            mobileNumberToUpdate = "";
        } 
       
        let imgURL: any= "";

        let profilePicPath: any={
            profilePicPath: null
        }
        
        if(choseFile !== userDetails.profilePicPath) {
            imgURL =await uploadProfilePic<any>();
            profilePicPath.profilePicPath = imgURL.result.payload.data.userProfilePic;
        }else{
            profilePicPath.profilePicPath = choseFile;
        }

        let updateProfileData = {
            countryCode: selectCountyCode,
            mobile: mobileNumberToUpdate,
            firstName: firstName,
            lastName: lastName,
            city: city,
            email: userDetails.email,
            ...profilePicPath
          }

          console.log('tjos os f f datra', updateProfileData)

          try{
            console.log('this is try registration', "try")
            await dispatch(updateUserDetailsDispatch(updateProfileData));
            toast("Profile Updated Successfully!");
            navigate('/viewProfile');
          }catch (err){
            
          }
        
    }

    async function uploadProfilePic<T>(){
        try{
            let data ={
                file: choseFile
            }
            console.log('this is fileURL', choseFile)
            const fileURL =await getImgURL(data, dispatch, tokenContext.accessToken) as T;
          
            return fileURL;
        }catch (err){

        }
    } 

    const onUploadFile: React.ChangeEventHandler<HTMLInputElement> = (event) => {
        if (event.target.files && event.target.files.length > 0) {
          const reader = new FileReader();
    
          reader.addEventListener("load", () => {
            const image = reader.result;
            setImageToCrop(image);
          });
    
          reader.readAsDataURL(event.target.files[0]);

          setModal(!modal)
          dispatch(loaderDispatch(true))
          setTimeout(() => {
            setCrpImgModal(!crpImgModal)
            dispatch(loaderDispatch(false))
          }, 500);
        }
    };

    const onCancelCropImg: () => void=()=>{
        setImgURL(userDetails.profilePicPath!=""?userDetails.profilePicPath: ProfileImage);
        setChoseFile(userDetails.profilePicPath!=""?userDetails.profilePicPath: "")
        setCrpImgModal(!crpImgModal);
    }

    const deleteProfilePic: () => void= () =>{
        console.log("deleteProfilePicdeleteProfilePic 1", ProfileImage)
        setImgURL(ProfileImage)
        dispatch(deleteProfilePicDispatch()) 
        toggle()
    }

    return (
        <React.Fragment>        
            <Profile 
                editProfile={{
                    edit: true,
                    onClick: toggle,
                    src: imgURL
                }} 
            >
                <div className="inner-wrap">
                    {/* {toggleCropIMGPopup && getPopupForCropImage()} */}
                    <Row>
                        <Col xl="8"> 
                            <div className="white-box">
                                <Row>
                                    <Col xs="12">
                                        <h5>Edit Profile</h5>
                                    </Col>
                                </Row>
                                {/* Profile content  */}
                                <div className="profile-cont">
                                    
                                    {/* edit profile Form  */}
                                    <Form className="edit_profile" onSubmit={updateUserProfile}>
                                        <div className="row pt-2 pt-md-4">
                                            <div className="col-sm-6 col-md-12 col-lg-6">
                                                <FormGroup>
                                                    <Label htmlFor="firstName">First Name</Label>
                                                    <Input onChange={onInputChange} value={firstName} id="firstName" placeholder="First Name" name="firstName" type="text" className={classNames({ "form-error": firstNameErr })}/>
                                                </FormGroup>
                                            </div>
                                            <div className="col-sm-6 col-md-12 col-lg-6">
                                                <FormGroup>
                                                    <Label htmlFor="lastName">Last Name</Label>
                                                    <Input value={lastName} onChange={onInputChange} id="lastName" placeholder="Last Name" name="lastName"
                                                    type="text" className={classNames({ "form-error": lastNameErr })} />
                                                </FormGroup>
                                            </div>
                                            <div className="col-sm-6 col-lg-6 pt-1">
                                                <FormGroup>
                                                    <Label  htmlFor="city">Email</Label>
                                                    <Input 
                                                        onChange={onInputChange} 
                                                        id="email" 
                                                        name="email" 
                                                        placeholder="Email" 
                                                        type="email" 
                                                        value={email} 
                                                        disabled
                                                    />
                                                </FormGroup>
                                            </div>
                                            <div className="col-md-12 col-lg-6 pt-1">
                                                <FormGroup>
                                                    <Label htmlFor="mobile">Mobile</Label>
                                                    <div className="d-flex">
                                                        <Input 
                                                            id="mobile-code" 
                                                            name="select-code" 
                                                            type="select" 
                                                            onChange={selectedValue}
                                                            className={classNames('w-50', { "form-error": mobileNumberCodeErr })}
                                                            onClick={() =>setFocused(true)}
                                                            onBlur={() => setFocused(false)}
                                                        >
                                                        {/* {selectedOption('MOBILE_CODE')} */}
                                                        {getContyCodeView()}
                                                        </Input>
                                                        <Input value={mobileNumber} onChange={onInputChange} id="mobile" placeholder="Mobile" name="mobileNumber" type="text" className={classNames('ms-4 w-60', { "form-error": mobileNumberErr })}
                                                        />
                                                    </div>
                                                </FormGroup>
                                            </div>
                                            <div className="col-sm-6 col-lg-6 pt-1">
                                                <FormGroup>
                                                    <Label value={city} htmlFor="city">City</Label>
                                                    <Input value={city} onChange={onInputChange} id="city" name="city" placeholder="City" type="text"  className={classNames({ "form-error": cityErr })}  />
                                                
                                                </FormGroup>
                                            </div>
                                            <div className="col-sm-6 col-lg-6 pt-1">
                                                <FormGroup>
                                                    <Label htmlFor="country">Country</Label>
                                                    <Input id="country" name="select-contry" type="select" onChange={selectedValue} >
                                                        {/* {selectedOption('COUNTRY')} */}
                                                        {getContyView()}
                                                    </Input>
                                                </FormGroup>
                                            </div>
                                            <div className="col-sm-12 col-lg-12 pt-3 mb-3">
                                                <Button className="mb-2" color="primary">
                                                    Save
                                                </Button>
                                                <NavLink to="/viewProfile" className="mb-2 btn btn-cancel ms-4">Cancel</NavLink>
                                    
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>

                {/* upload profile modal start  */}
                <Modal isOpen={modal} centered className="modal-md outside-close-modal" toggle={toggle} >
                    <button onClick={toggle}
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        ></button>
                    <ModalHeader className="text-center text-primary">
                        {!isRemovingProfilePicApply ? "Update profile picture": "Do you want to delete profile pic"}
                    </ModalHeader>
                 
                    {!isRemovingProfilePicApply && <ModalBody className="mb-2">
                        <div className="profile-large ms-auto me-auto">
                            <img src={imgURL} alt="profile" className="img-thumbnail" />
                        </div>
                    </ModalBody>}
                    
                    <ModalFooter className="justify-content-center">
                        <Button className="me-2" color="primary" outline onClick={()=>{!isRemovingProfilePicApply? onConfirmRemoveProfilePic(): setIsRemovingProfilePicApply(false)} }>
                            {!isRemovingProfilePicApply? <React.Fragment><img src={Delete} alt="Remove" />Remove</React.Fragment>: "Cancel"}
                        </Button>
                        {!isRemovingProfilePicApply? <Button className="ms-2 uploadBtn" color="primary" >
                            <img src={Upload} alt="Upload" />  
                            <input 
                                type="file" 
                                accept="image/*" 
                                onChange={onUploadFile} 
                            />
                            Upload Image
                        </Button>: <Button className="ms-2 " color="primary" onClick={deleteProfilePic}> 
                            {/* <img src={Delete} alt="Remove" />  */}
                            Remove 
                        </Button>}
                    </ModalFooter>
                </Modal>

                {/* upload profile modal end */}
                <Modal isOpen={crpImgModal} centered className="modal-lg medium outside-close-modal" toggle={onCancelCropImg} >
                <button onClick={onCancelCropImg}
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        ></button>
                    <ModalHeader className="text-center">Crop Image</ModalHeader>
                    <ModalBody className="mb-0 text-center">
                    <ImageCropper 
                        imageToCrop={imageToCrop}
                        onImageCropped={(croppedImageBlob: any, croppedImageBase64URL: any, stop: null=null) =>{
                            // console.log('thjis is croppedImageBase64URL', croppedImageBlob, croppedImageBase64URL)
                            setChoseFile(croppedImageBlob)
                            setImgURLOnGoingCropping(croppedImageBase64URL)
                            // setImgURL(croppedImageBase64URL)
                        }}
                        minWidthDataURL= {400}
                        minHeightDataURL= {400}
                        minWidthBlob= {400}
                        minHeightBlob= {400}
                    />
                    </ModalBody>
                    <label className="mb-1 upload-logo-restriction" style={{
                        width: "75%"
                    }}>
                        Zoom in/out and drag to reposition for a perfect fit (Recommended size - 400x400 pixels)
                    </label>
                    <ModalFooter className="justify-content-center">
                        <Button className="me-2 btn " color="primary" onClick={()=>{
                            setImgURL(imgURLOnGoingCropping)
                            setCrpImgModal(!crpImgModal)
                        }}>
                            <img src={Upload} alt="Upload" />Upload
                        </Button>
                        <Button className="ms-3 text-dark btn-me-cancel" color="light" onClick={onCancelCropImg}>
                            Cancel
                        </Button>
                        
                    </ModalFooter>
                </Modal>

            </Profile>
        </React.Fragment> as JSX.Element
    )

}