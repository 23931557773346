import React, { useState } from "react";
import Dropzone, {defaultClassNames} from '../dragFile/react-dropzone-uploader/dist/Dropzone.tsx';
import 'react-dropzone-uploader/dist/styles.css' 
import * as API from '../../../APIs'; 
import { useSelector, useDispatch } from 'react-redux'
import { isFileUploadingDispatch } from "../../../SCREENS/360virtualTour/Slices/VirtualTourImages.slice.js";
// import { setCurrentUploadedAssestsCountDispatch } from "./dragFileFolder.slice";
import convert from 'convert';
import { toast } from 'react-toastify';
import { generateVideoThumbnails, importFileandPreview } from "@rajesh896/video-thumbnails-generator";
import { FormGroup } from 'reactstrap';
import classNames from 'classnames';
import validator from 'validator';
import { setVirTourNameDispatch, setIsVirTourNameEmptyDispatch, resetThreeSixtyVirtualTourDispatch } from "../../../SCREENS/360virtualTour/Slices/threeSixtyVirtualImageOnUpload.slice.js"; 
import { useEffect } from "react";
import { is360Image, is360Image__ } from "../../../UTILITYS/endPoints.js"; 
import uploadProcessing from "./../../../ASSETS/Images/uploadProcessing.gif";
import { useContext } from "react";
import CreateTokenContextProvider from "../../../STORE/App-wide-state/Token/createTokenContextProvider.js";
import UpgradModal from "../../Subscription/UpgradModal.js";
import UploadImg from './../../../ASSETS/Images/xr/cloud-upload.png'
import { setDoNotShowExpiredModal } from "../../../SCREENS/Profile/slice.reducer.tsx";

const Layout = ({ input, previews, dropzoneProps, files, extra: { maxFiles } }) => {

  const [virtualTourName, setVirtualTourName] = useState('');
  // const [virtualTourNameErr, setvirtualTourNameErr] = useState(false);
  const threeSixtyVirtualImageOnUpload = useSelector(state => state.threeSixtyVirtualImageOnUpload);
  const dispatch = useDispatch();

  console.log("threeSixtyVirtualTourthreeSixtyVirtualTour", threeSixtyVirtualImageOnUpload)

  const onInputChange = (e)=>{
    switch(e.target.name){
      case "virtual-tour":
          setVirtualTourName(e.target.value.replace(/[^a-zA-Z0-9\s]/g, ''));
          // setvirtualTourNameErr(false);
          dispatch(setVirTourNameDispatch({virtualTourName: e.target.value.replace(/[^a-zA-Z0-9\s]/g, '')}));
          dispatch(setIsVirTourNameEmptyDispatch(false));
      break;
    }
  }

  return (
    <div>
      <div className="col-12">
        <div>Name</div>
        <FormGroup>
            <input value={virtualTourName} type="text" placeholder="Enter name"
            className={classNames("form-control", { "form-error": threeSixtyVirtualImageOnUpload.isVirtourNameEmpty })} name={'virtual-tour'} onChange={onInputChange}
            />
        </FormGroup>
      </div>

      <div {...dropzoneProps}>
        {files.length < maxFiles && input}
      </div>
      <div>
        {previews}
      </div>
  </div> 
  )

} 
  
const DragAndDropFiles = (props) => {

  const [isUpdatePreview, setIsUpdatePreview] = useState(false);
  const tokenContext = useContext(CreateTokenContextProvider);
  const dispatch = useDispatch();
  const userProfileDetails = useSelector(state => state.userProfileDetails)
  const threeSixtyVirtualImageOnUpload = useSelector(state => state.threeSixtyVirtualImageOnUpload); 
  const virtualTourImages = useSelector(state => state.virtualTourImages);
  const [disableDZ, setDisableDZ] = useState(false);
  const [openUpgrade, setOpenUpgrade] = useState(false);

  const getUploadParams =async ({ file, meta }) =>{

    let fileSizeInKB = 0;
    fileSizeInKB =convert(file.size, 'bytes').to('kB');

    if(204800<fileSizeInKB  ){
      toast.error(`${file.name} file is more than 200MB`, { });
      return  
    }

    const formData = new FormData(); 
    formData.append( "Image", file );
    formData.append( "VirtualTourID", props.tour.id );
    formData.append( "name", threeSixtyVirtualImageOnUpload.virtualTourName );
    
    props.setIsSaving !== undefined && props.setIsSaving(true)

    let headerConfig = {
      "Authorization": "Bearer " + tokenContext.accessToken
    }

    return { 
      url: API.BASE_URL+API.UPLOAD_360_IMAGES,  
      method: "POST",
      body: formData,
      headers: headerConfig
    } 
     
  } 

  const handleChangeStatus = (status) => { 
    
    if(status.meta.status === "rejected_file_type"){
      toast.error(`${status.meta.type.split("/")[1]} format is not supported`);
      return;
    }

    if(status.meta.status === "done"|| status.meta.status === "removed"){
      toast(`Image uploaded successfully`);
      props.toggle();
      setDisableDZ(false);
      dispatch(resetThreeSixtyVirtualTourDispatch());
      return;
    }else if(status.meta.status === "error_upload"){
      setTimeout(() => {
        console.log("DragAndDropFilesDragAndDropFiles status 1", JSON.parse(status.xhr.response));
        let parseErr= JSON.parse(status.xhr.response);
        if(parseErr.isError){
          toast(parseErr.responseException.exceptionMessage);
        }
      }, 500);

    }

    dispatch(isFileUploadingDispatch(true))
    setIsUpdatePreview(!isUpdatePreview)
  }

  const inputContent = () => {
    if(disableDZ)  <div className="inputinputUploaderLoader"><img className="files-upload-processing" src={uploadProcessing} /></div>
    return <div><div class="inputUploader"><img src={UploadImg} alt="Upload" /><div>Drop your 360° image here or <span>upload</span></div></div></div>
  }

  const isNameEmpty = () => { 

    if(validator.isEmpty(threeSixtyVirtualImageOnUpload.virtualTourName.trim())){
      toast.error("Please Enter 360° Experience Name First", { });
      dispatch(setIsVirTourNameEmptyDispatch(true));
      return true;
    }else if(!validator.isLength(threeSixtyVirtualImageOnUpload.virtualTourName.trim(), {min: 1, max: 25})){
      toast.error("Virtual Tour Name length should be minimum 3 char and maximum 25 char", { });
      dispatch(setIsVirTourNameEmptyDispatch(true));
      return true;
    }
    return false;
  }
  
  const isIMageNot360 = async (files) => {

    if (files[0]) {
      try {
        const imgUrl = await readFileAsDataURL(files[0]);
        const image = new Image();
        image.src = imgUrl;
        await new Promise((resolve, reject) => {
          image.onload = () => resolve();
          image.onerror = reject;
        });
        const width = image.width;
        const height = image.height;
        // Check if the image width is at least twice its height
        const is360 = width / height >= 2;
        if(!is360){
          toast.dismiss();
          toast.error("Oops! The uploaded image isn't a 360° photo. Please upload a valid 360° image", { });
        } 
        return Promise.resolve(is360)
      } catch (error) {
        console.error("Error processing image:", error);
      }
    }
    console.log("fileSizeInKBfileSizeInKB 2", "2nd")
  } 

  const readFileAsDataURL = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };
  

  const onDublicateImageHandler =  () => {
    toast("Duplicate name not allowed.", {});
  }

  const disabledDZ = () => { 
    setDisableDZ(true);
  }

  const isMoreThanOneImagesSelected = (files) => { 
    let images = 0; 
    files.forEach(file => {
      if(file.type.split("/")[0] === "image" || file.name.split(".")[1] === "heic"){
        images = images+1;
      }
    });
    toast.dismiss();
    console.log("isMoreThanOneImagesSelectedisMoreThanOneImagesSelected", files, images)
    
    if(images >1) toast.error("Uploading multiple images files is not allowed, upload one images file at a time", { });
    console.log("videosvideos", images);
    return images >1? true: false;
  }

  // const isPremium = () => {
    
  //   // if(!userProfileDetails.isPremium 
  //   //   && virtualTourImages.totalLength >0
  //   //   ){
  //   //   setOpenUpgrade(true);
  //   //   toast.error("Free users are allowed only 1 No. of 360° Rooms/Photos.", { });
  //   //   return false
  //   // }

  //   if( userProfileDetails.isExpired ){
  //     dispatch(setDoNotShowExpiredModal(false));
  //     // setOpenUpgrade(true);
  //     toast("Your subscription has been expired");
  //     return false;
  //   }

  //   return true;
  // } 

  const upgradModal = () => { 
    {/* Modal to be upgraded  */}
      return <UpgradModal
        isOpen= {openUpgrade} 
        setOpenUpgrade ={setOpenUpgrade}
      />
  }

  return ( 
    <React.Fragment>
      {upgradModal()}
      <Dropzone  
        disabled ={disableDZ}
        isMoreThanOneImagesSelected= {isMoreThanOneImagesSelected}
        // isPremium= {isPremium}
        isThreeSixtyImage= {props.isThreeSixtyImage}
        onDublicateImage= {onDublicateImageHandler}  
        virtualTourName= {threeSixtyVirtualImageOnUpload.virtualTourName}  
        virtualTourImages= {virtualTourImages.images}  
        isNameEmpty= {isNameEmpty}  
        isIMageNot360= {isIMageNot360}
        disabledDZ= {disabledDZ}  
        preventToUpload= {props.preventToUpload}
        getUploadParams={getUploadParams}
        LayoutComponent={Layout}
        classNames={{ inputLabelWithFiles: defaultClassNames.inputLabel }}
        onChangeStatus={handleChangeStatus}
        inputContent={inputContent}
        inputWithFilesContent={inputContent}
        styles={{
          dropzone: { width: 400, height: 180, padding: 0, overflow: "hidden" }
        }}
        accept= ".png, .jpeg, .jpg, .heic"
        multiple= {false}
      
      />
    </React.Fragment>
  )
}
  
export default DragAndDropFiles