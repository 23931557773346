import React, {useEffect, useState} from "react";
import HomeBlack from './../../ASSETS/Images/xr/home-black.png';
import lock from './../../ASSETS/Images/icons/lock.png';
import userIcon from './../../ASSETS/Images/icons/user_icon.png';
import activeUserIcon from './../../ASSETS/Images/icons/active-user.png';
import activeLock from './../../ASSETS/Images/icons/active-lock.png';
import { 
  useSelector, 
  useDispatch } from 'react-redux' 
import {
    Navbar,
    NavbarToggler,
    NavbarBrand,
    ModalHeader,
    Modal, ModalBody,
    Nav,
    NavItem,
    NavLink,Breadcrumb, BreadcrumbItem
  } from 'reactstrap';
import Parent from './index'
import ProfileImage from './../../ASSETS/Images/defaultProfilePic.jpg';
import Edit from './../../ASSETS/Images/xr/edit.png'
import Expired from './../../ASSETS/Images/xr/expired.png';
import {hamburgurDispatch} from './../../STORE/CommonSlices/hameBurgerOnOff';
import { useNavigate, useLocation } from 'react-router-dom'
import { getStatusClass } from "../../UTILITYS/Helper";

const HeaderComponent = (props) =>{
  
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { pathname, state } = useLocation();
  const userProfileDetails = useSelector(state => state.userProfileDetails);
  const [userDetails, setUserDetails] = useState({});
  const [activeItem] = useState(pathname.split("/")[1]);
  const [breadcrumbItems, setBreadcrumbItems] = useState(["Dashboard"]);
  const [currentRoute, setCurrentRoute] = useState(["home"]);

  useEffect(()=>{
      setUserDetails(userProfileDetails);
      getCurrentBreadcrumb();
  }, [userProfileDetails]);
    
  const getCurrentBreadcrumb= async()=>{
      let path = pathname.split("/")[1];
      let threeSixtyImgName= ""; 
      let parentBreadCrumOfvirtualTourWorld= ""; 
      setCurrentRoute(path);
      switch(path){
        case "home":
          setBreadcrumbItems([
            {breadcrumbName: "Dashboard", navigateTo: ()=> navigate("/home")}
          ]);
          break;
        case "viewProfile":
          setBreadcrumbItems([
            // {breadcrumbName: "Profile Management", navigateTo: ()=> navigate("/viewProfile")}, 
            {breadcrumbName: "Profile", navigateTo: ()=> navigate("/viewProfile")}]);
          break;
        case "editProfile":
          setBreadcrumbItems([
            {breadcrumbName: "Profile", navigateTo: ()=> navigate("/viewProfile")}, 
            {breadcrumbName: "Edit Profile", navigateTo: ()=> navigate("/editProfile")}]);
          break;
        case "changePassword":
          setBreadcrumbItems([
            {breadcrumbName: "Profile", navigateTo: ()=> navigate("/viewProfile")}, 
            {breadcrumbName: "Change Password", navigateTo: ()=> navigate("/changePassword")}]);
          break;
        case "virtual-tour":
          setBreadcrumbItems([ 
            // {breadcrumbName: "360° Tours Managment", navigateTo: ()=> navigate("/virtual-tour")}, 
            {breadcrumbName: "360° Tours", navigateTo: ()=> navigate("/virtual-tour")}]);
          break;
        case "virtual-tour-images":
          threeSixtyImgName= state && state.name;
          setBreadcrumbItems([
            // {breadcrumbName: "360° Tours Managment", navigateTo: ()=> navigate("/virtual-tour")}, 
            {breadcrumbName: "360° Tours", navigateTo: ()=> navigate("/virtual-tour")}, 
            {breadcrumbName: threeSixtyImgName || "Virtual Tour Images", navigateTo: ()=> navigate("/virtual-tour-images")}]); 
          break;
        case "virtual-tour-world":
          console.log("getVirtualImaggetVirtualImag 2", state)
          threeSixtyImgName= state && state.name;
          parentBreadCrumOfvirtualTourWorld= state && state.ParentBreadCrumbName;
          let virtualTour= JSON.parse((await localStorage.getItem("virtualTour")));
          setBreadcrumbItems([
            {breadcrumbName: "360° Tours", navigateTo: ()=> navigate("/virtual-tour")}, 
            {breadcrumbName: parentBreadCrumOfvirtualTourWorld || "Virtual Tour Images", navigateTo: ()=> navigate('/virtual-tour-images', {state: virtualTour})},
            {breadcrumbName: threeSixtyImgName || "Virtual Tour World", navigateTo: ()=> navigate("/virtual-tour-world")}]);
          break;

        case "analytics":
          setBreadcrumbItems([ 
            {breadcrumbName: "Analytics", navigateTo: ()=> navigate("/analytics")}]);
          break;
       
        case "subscription":
          setBreadcrumbItems([ 
            {breadcrumbName: "Subscription", navigateTo: ()=> navigate("/subscription")}]);
          break;
        
        case "checkout":
          setBreadcrumbItems([ 
            {breadcrumbName: "Subscription", navigateTo: ()=> navigate("/subscription")}
          ]);
          break;
      }
  }

  const shouldShowSimpleHeader =()=>{
    let path = pathname.split("/")[1];
    let show= false;
    switch(path){
      case "virtual-tour":
      case "three-sixy-vedio":
      case "home":
      case "analytics":
      case "subscription":
      case "checkout":
      case "payment-processing":
        show= true;
        break;
      case "virtual-tour-images":
        show= true;
        break;
      case "virtual-tour-world":
        show= true;
        break;
      default: 
        show= false;
    }
    return show;
  }

  // const getStatus =()=>{
  //   if(userDetails.isPremium && !userDetails.isExpired){
  //     return "Premium"
  //   }else if(userDetails.isExpired){
  //     return "Expired"
  //   }else if(!userDetails.isPremium){
  //     return "Free"
  //   }
  // }
 
  const getStatus =()=>{
    return userDetails.subscriptionStatus;
  }
  
  return  <React.Fragment>
    <div className={`${shouldShowSimpleHeader() ? 'simple-header': 'header-parent'}`}>
      <header className="header">
        <Navbar className={`navbar-expand-lg pt-0`}>
          {/* mobile drawer button  */}
          <NavbarToggler className="d-block d-md-none" onClick={()=>dispatch(hamburgurDispatch())}/>

          <div className="d-none d-md-block">
            <Breadcrumb className="mb-0">
              <BreadcrumbItem onClick={()=> navigate("/home")}><a href="#"><img src={HomeBlack} alt="home" /></a></BreadcrumbItem>
              {/* <BreadcrumbItem><a href="#">Profile Management</a></BreadcrumbItem>
              <BreadcrumbItem active>Profile</BreadcrumbItem> */}
              {breadcrumbItems.map((item)=>{
                return <BreadcrumbItem>
                  <a href="#" onClick={(e)=>{
                    e.preventDefault();
                    item.navigateTo();
                  }}>{item.breadcrumbName}</a>
                </BreadcrumbItem>
              })}
            </Breadcrumb>
          </div>
          
          <Nav className={`ml-auto d-none d-md-block ${!shouldShowSimpleHeader() && 'me-0 me-md-3'}`} navbar onClick={()=>navigate("/viewProfile")}>
              <NavItem className="header-profile">
                  <NavLink href="#" className={`pt-0 ${getStatusClass(userDetails)}`}>
                    <span className="userProfile">
                      <img src={userDetails.profilePicPath!=""?userDetails.profilePicPath: ProfileImage} alt="profile" className="profile-sm" />
                      {/* <img src={RankIcon} alt="profile" className="profile-rank" /> */}
                    </span>
                      <label>
                        <span className="userName">{userDetails.firstName} {userDetails.lastName}</span>
                        <span className="userPlan">{getStatus()}</span>
                      </label>
                  </NavLink>
              </NavItem>
          </Nav>
        </Navbar>
      </header>
      {!shouldShowSimpleHeader() &&
        <div className="px-2 px-md-0">
          <div className="container-fluid">
          <div class="headerbg"></div>
          <h2 className="header-title">Profile</h2>
          <div className="header-bottom">
              <div className="d-flex align-items-center">
                <span 
                  className="userProfile profile-md"
                  onClick={()=>{
                    if(currentRoute === "editProfile" && props.editProfile !== undefined && props.editProfile.edit){
                      props.editProfile.onClick();
                    }
                  }}>
                  <img 
                    src={props.editProfile!== undefined && props.editProfile.edit? props.editProfile.src: userDetails.profilePicPath!=""?userDetails.profilePicPath: ProfileImage} 
                    alt="profile" 
                    className="w-100" 
                  />
                  {currentRoute === "editProfile" && <img src={Edit} className="edit-profile" />}
                </span>
                <div className="ps-3 ps-md-4"><h5 className="m-0 text-capitalize">{userDetails.firstName} {userDetails.lastName}</h5></div>
                {<div className="ps-3 mt-3 mt-lg-0 ms-0 ms-md-auto me-0 header-innermenu">
                  <Nav className="flex-row flex-wrap text-center " navbar>
                    <NavItem className={activeItem === "viewProfile" && 'active'}>
                      <img src={activeItem === "viewProfile"? activeUserIcon: userIcon} alt="Upload" /> 
                      <NavLink 
                        onClick={()=>{
                          navigate("/viewProfile")
                        }}
                        style={{display: "inline", display: "inline",
                        marginLeft: "-16px", fontWeight: 600}}
                      >Profile</NavLink>
                    </NavItem>
                    <NavItem className={activeItem === "changePassword" && 'active'}>
                      <img src={activeItem === "changePassword"? activeLock: lock} alt="Upload" /> 
                      <NavLink onClick={()=>{
                        navigate("/changePassword")
                      }} style={{display: "inline", display: "inline",
                      marginLeft: "-16px", fontWeight: 600}}>
                        Change Password
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>}
              </div>
          </div>
        </div>
        </div>
      }
    </div>
    </React.Fragment>
}

export default (props)=>{
    return (
        <Parent>
          <React.Fragment>
              {HeaderComponent(props)}
          </React.Fragment>
        </Parent>
    )
}