import React from "react";
import { useSelector } from 'react-redux'

export default (props) => {
    const subscription = useSelector(state => state.subscription);
    // COMMON CLASS WILL GO HERE
    let loaderClass = "";
    if( props.loaderClass!= undefined){
        loaderClass = loaderClass+ ' ' + props.loaderClass;
    }
    return  <React.Fragment>
            <div className="loader">
                {/* <img src={Loader} alt="Loader" className={loaderClass} /> */}
                <div class="lds-spinner">
                    <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                </div>
                <div style={{marginTop: '15xp'}}>{subscription.isPaymentProcecing && 'Processing Payment, Please Wait...'}</div>
            </div>
        </React.Fragment>
}