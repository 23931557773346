export const RECUERO_INFO_TEXT = `"Love your parents. We are so busy growing up; we often forget that they are also growing old." - Unknown`;
export const COPY_RIGHT_TEXT = "Copyright 2024 © Recuerdo All Rights Reserved";
export const EMPTY_ASSEST_PRIMARY_TEXT = "No Asset Added";
export const EMPTY_ASSEST_DESCRIPTION_TEXT = "Once you add an asset, it will appear here!";
export const EMPTY_ASSET_WITH_ME_USER_PRIMARY_TEXT = "No Asset Shared with me";
export const EMPTY_ASSET_WITH_ME_DESCRIPTION_TEXT = "Once an asset is shared with you, it will appear here!";
export const EMPTY_ASSET_WITH_OTHERS_USER_PRIMARY_TEXT = "No Asset Shared with others";
export const EMPTY_ASSET_WITH_OTHERS_DESCRIPTION_TEXT = "Once you share an asset, it will appear here!";
export const NO_VR_USER_PRIMARY_TEXT = "No VR Experience Added";
export const NO_VR_USER_DESCRIPTION_TEXT = "Once you add a VR Experience, it will appear here";
export const EMPTY_AR_ASSEST_DES = "Once you select asset for this room, it will appear here!";

export const EMPTY_360_TOUR_PRIMARY_TEXT = "No Tours Added";
export const EMPTY_360_TOUR_DESCRIPTION_TEXT = "Once you add a tour, it will appear here!";
export const EMPTY_360_TOUR_IMAGES_PRIMARY_TEXT = "No Images Added";
export const EMPTY_360_TOUR_IMAGES_DESCRIPTION_TEXT = "Once you add a Image, it will appear here!";


export const UPLOADED_FILES_EXT = {
    images: ["png", "jpeg", "jpg", "heic", "Heif"],
    audios: ["mp3", "aac", "wav", "m4a",],
    videos: ["mp4", "3gp", "mov", "m4v", "Hevc"] 
}

// export const PIE_CHART_BODER_COLOR = [
//     "#0B1D78",
//     "#054FB9",
//     "#0461CF",
//     "#0073E6",
//     "#8BABF1",
//     "#3366D6",
//     "#4285F4",
//     "#71A3F7"
//   ];
// export const PIE_CHART_BACKGROUNG_COLOR =[  
//     "#054FB9",
//     "#0461CF",
//     "#0073E6",
//     "#8BABF1",
//     "#3366D6",
//     "#4285F4",
//     "#71A3F7"
// ]

export const PIE_CHART_BODER_COLOR = [
    "#6A1C4A",
    "#752554",
    "#872960",
    "#98356F",
    "#772B7C",
    "#5F1E68",
    "#6d2277",
    "#7f288a"
  ];
export const PIE_CHART_BACKGROUNG_COLOR = [
    "#6A1C4A",
    "#752554",
    "#872960",
    "#98356F",
    "#772B7C",
    "#5F1E68",
    "#6d2277",
    "#7f288a"
  ];

export const PIE_CHART_DATASETS_COMMAN_CONFIG = {
    label: 'Views',
    backgroundColor: PIE_CHART_BACKGROUNG_COLOR,
    borderColor: PIE_CHART_BODER_COLOR,
    borderWidth: 1,
}

export const LINE_CHART_COMMAN_OPTIONS = {
    interaction: {
        intersect: false
    },
    plugins: {
        legend: false
    },
    scales: {
        x: {
            border: {
                width: 1,
                color: "#9E379C"
            },
            grid: {
                display: false // Hide x-axis grid lines
            },
            ticks: {
                // Customize the style of the x-axis labels
                font: {
                //   size: 14, // Adjust the font size
                //   weight: 'bold', // Set the font weight
                },
                color: "#ebebeb"
            }
        },
        y: {
            border: {
                width: 1,
                color: "#9E379C"
            },
            grid: {
                display: false // Hide y-axis grid lines
            },
            beginAtZero: true, // Start y-axis at 0
            stepSize: 1, // Step size for y-axis
            ticks: {
                beginAtZero: true,
                callback: function(label, index, labels) {
                    // when the floored value is the same as the value we have a whole number
                    if (Math.floor(label) === label) {
                        return label;
                    }
                },
                color: "#ebebeb"
            }
        }
    }
}

export const LINE_CHART_DATASETS_COMMAN_CONFIG = {
   label: 'Views',
   fill: true,
   borderColor: '#780B78',
   tension: 0.4,
   backgroundColor: (context) => {
      const ctx = context.chart.ctx;
      const gradient = ctx.createLinearGradient(0, 0, 0, 200);
    //   gradient.addColorStop(0, "rgba(224, 229, 251, 1)");
    //   gradient.addColorStop(1, "rgba(224, 229, 251, 0)");
      gradient.addColorStop(0, "rgba(187, 2, 210, 0.30)");
      gradient.addColorStop(1, "rgba(255, 140, 0, 0)");
      return gradient;
    }
}

export const stripAppearance= {
    theme: 'night',
    variables: {
      fontFamily: 'Sohne, system-ui, sans-serif',
    //   fontWeightNormal: '500',
      borderRadius: '8px',
      colorBackground: '#0A2540',
      colorPrimary: '#EFC078',
      accessibleColorOnColorPrimary: '#1A1B25',
      colorText: 'white',
      colorTextSecondary: 'white',
      colorTextPlaceholder: '#727F96',
      tabIconColor: 'white',
      logoColor: 'dark'
    },
    rules: {
      '.Input, .Block': {
        backgroundColor: '#111736',
        border: '1.5px solid var(--colorPrimary)'
      }
    }
}

const RAZOR_PUBLICH_KEY= "rzp_test_X1T10XJvRiK2n6";


export const razorpayConfig= {
    v1_library: "https://checkout.razorpay.com/v1/checkout.js",
    options: {
        key: RAZOR_PUBLICH_KEY,
        name: "Sysmetis IT Solutions",
        description: "Monthly Test Plan",
        theme: {
            color: "#780B78"
        }
    }
}