import React, { useEffect, useRef, useState } from "react";
import Parent from '.'
import './ForgetResetPasswordStyle.module.css'
import {resetPassword} from './../../../UTILITYS/endPoints'
import { useNavigate , NavLink, useSearchParams} from 'react-router-dom'
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import validator from 'validator';
import Logo from './../../../COMPONENTS/Logo'
import Container from './../index.container'
import CopyRight from "../../../COMPONENTS/CopyRight";
import ButtonContainer from '../../../COMPONENTS/Button/buttonContainer'
import Button from '../../../COMPONENTS/Button/Button'
import Eye from './../../../ASSETS/Images/eye.png';
import EyeOff from './../../../ASSETS/Images/eye-off.png';
import { getUrlParams } from "../../../UTILITYS";
import { 
    useSelector, 
    useDispatch } from 'react-redux'
import classNames from 'classnames';
// THIS IS RESET PASSWORD SCREEN
export default (props) =>{

    const queryParameters = new URLSearchParams(window.location.search)
    const type = queryParameters.get("token")
    const dispatch = useDispatch()
    const navigate = useNavigate();
    let [searchParams, setSearchParams] = useSearchParams();

    const [token, setToken] = React.useState(
        searchParams.get("token")
    );
    const [email, setEmail] = React.useState(
        searchParams.get("email")
    );
    
    const [iscreatepassword, setIscreatepassword] = React.useState(
        searchParams.get("iscreatepassword")
    );

    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [isPasswordMaskedOPen, setIsPasswordMaskedOPen] = useState(true)
    const [isConPasswordMaskedOPen, setConIsPasswordMaskedOPen] = useState(true)
    const [passwordNameErr, setPasswordNameErr] = useState(false)
    const [consfirmfirstNameErr, setConsfirmfirstNameErr] = useState(false)

    useEffect(()=>{
        if(token === null || email === null) navigate(-1)
    }, []);

    const applyResetPassword =async (e) =>{
        
        e.preventDefault();
        toast.dismiss()
        // validation goes here
        if(!validator.isLength(password, {min: 8, max: 14})){
            if(password === "") toast.error("Please Enter New Password", { });
            else toast.error("Password length should be minimum 8 char and maximum 14 char", { });
            setPasswordNameErr(true)
            return;
        }
    
        if(validator.isEmpty(confirmPassword)){
        toast.error("Please Enter Confirm Password", { });
        setConsfirmfirstNameErr(true)
        return;
        }else if(password !== confirmPassword){
        toast.error("Password and confirmPassword should be same", { });
        setConsfirmfirstNameErr(true)
        return;
        } 

        let resetPasswordDATA = {
        password: password,
        confirmPassword: confirmPassword,
        email: email,
        token: token,
        }

        try{
            console.log('this is try applyResetPassword', "try")
            await resetPassword(resetPasswordDATA, dispatch);
            navigate('/ChangePasswordSuccessView', { state: {"iscreatepassword": iscreatepassword? true: false}})
            }catch (err){
            if(err!=undefined && err.isError!= undefined && err.isError) toast.error(err.responseException.exceptionMessage, { });
            console.log('this is catch applyResetPassword', err)
        }
    }

    const onInputChange = (e)=>{
    
        switch(e.target.name){
          case "password":
            setPassword(e.target.value);
            setPasswordNameErr(false)
            break;
          case "confirmPassword":
            setConfirmPassword(e.target.value);
            setConsfirmfirstNameErr(false)
            break;
        }
    }

    return (
        <Parent>
            <React.Fragment>
                <Container>      
                    <div className="text-center mb-4 mt-3">
                        <Logo logoClass="mb-4  pb-2" />
                        {iscreatepassword?<h4>Create Password</h4> :<h4>Reset Password</h4>}
                    </div>
                    <form className="pb-3" onSubmit={applyResetPassword}>
                        <div className="mb-3">
                            <label className="form-label">New Password</label>
                            <div className=" password-field">
                                <input value={password} onChange={onInputChange} type={isPasswordMaskedOPen ?'password': "Text"} name="password" className={classNames('form-control', { "form-error": passwordNameErr })} placeholder="New Password" />
                                <span onClick={()=>setIsPasswordMaskedOPen(!isPasswordMaskedOPen)}>
                                    <img src={isPasswordMaskedOPen ?Eye: EyeOff} alt="Eye" />
                                    {/* <img src={EyeOff} className="d-none" alt="Eye" /> */}
                                </span>
                            </div>
                        </div>
                        <div className="mb-3 ">
                            <label className="form-label">Confirm Password</label>
                            <div className="password-field">
                                <input value={confirmPassword} onChange={onInputChange} type={isConPasswordMaskedOPen ?'password': "Text"} name="confirmPassword" className={classNames('form-control', { "form-error": consfirmfirstNameErr })} placeholder="Confirm Password" />
                                <span onClick={()=>setConIsPasswordMaskedOPen(!isConPasswordMaskedOPen)}>
                                    <img src={isConPasswordMaskedOPen ?Eye: EyeOff} alt="Eye" />
                                    {/* <img src={EyeOff} className="d-none" alt="Eye" /> */}
                                </span>
                            </div>
                        </div>
                        <ButtonContainer 
                        containerClacess = {["mb-4", "mt-4"]}
                        render={() => <Button lable={iscreatepassword?"Create" :"Reset"} type="submit" buttonClassess = {["btn", "btn-primary", "w-100"]}/>}
                        />
                        <div className="text-center pt-2">
                            Already have an account? <NavLink to="/login"><u>Login</u></NavLink>
                        </div>
                    </form>
                    
                    {/* <CopyRight copyRightClassess = {["text-center", "copyright"]} /> */}
                </Container>
            </React.Fragment>
        </Parent>
    )
}