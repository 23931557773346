import {useEffect, useState, useContext} from "react";
import { useSelector, useDispatch } from 'react-redux';
import HeaderFooter from '.';
import Calandar from './../../ASSETS/Images/xr/calandar.png';
import Edit from './../../ASSETS/Images/xr/edit1.png';
import Share from './../../ASSETS/Images/xr/sahre.png';
import More from './../../ASSETS/Images/xr/more.png';
import Arrows from './../../ASSETS/Images/xr/arrows.png';
import { useNavigate } from "react-router-dom";
import Icon360 from './../../ASSETS/Images/xr/360-degrees.png';
import Icon360Item from './../../ASSETS/Images/xr/360Item.png';
import IconView from './../../ASSETS/Images/xr/view-primary.png';
import defaultThreeSixty from './../../ASSETS/Images/360Tours/defaultThreeSixty.jpg';
import Close from './../../ASSETS/Images/icons/cancel.png'
import { Row, Col, Input, Table } from 'reactstrap';
import { fetchTourViewsByCategoryDispatch, fetchTourViewsBydateDispatch, fetchTourViewsbyuser, fetchTourViewsbyuserDispatch, resetDashBoardData } from "./../Home/home.slice";
import moment from 'moment';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement, Title, Filler } from 'chart.js';
import { Pie, Line } from 'react-chartjs-2';
import { setParentModuleActiveDispatch } from "../../COMPONENTS/SideBarMenu/activeParentModule.slice";
import { setModuleActiveDispatch } from "../../COMPONENTS/SideBarMenu/activeModule.slice";
import EditShareDropDown from "../../COMPONENTS/DropDown/EditShare";
import { fetchVirtualTourDispatch, resetVirtualTour, updateVirtualTourDispatch } from "../360virtualTour/Slices/VirtualTour.slice";
import SharableModal from "../../COMPONENTS/SharableModal";
import validator from 'validator';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import { LINE_CHART_DATASETS_COMMAN_CONFIG, LINE_CHART_COMMAN_OPTIONS } from "../../UTILITYS/Constant";
import { getLineAnimation, getPercentage, pieChartDatasetsCommanConfig } from "../../UTILITYS/Helper";
import NoAssests from "../../COMPONENTS/NoAssests";
import DatePicker from 'react-date-picker';
import CreateTokenContextProvider from "../../STORE/App-wide-state/Token/createTokenContextProvider";

ChartJS.register(CategoryScale,  LinearScale, PointElement, LineElement, Title,ArcElement, Tooltip, Legend, Filler);
const pieOptions = {
  legend: {
    display: true,
    position: "right",
    labels: {
      boxWidth: 12,
      color: "#ebebeb",
    },
    onClick: (e) => e.stopPropagation()
  },
  elements: {
    arc: {
      borderWidth: 0
    }
  }
};

function Analytics() {
  
  const dispatch = useDispatch();
  const userProfileDetails = useSelector(state => state.userProfileDetails);
  const dashBoardData = useSelector(state => state.dashBoardData);
  const [selectedTour, setSelectedTour] = useState(0);
  const [specificTourViewsbyuser, setSpecificTourViewsbyuser] = useState(null);
  const tokenContext = useContext(CreateTokenContextProvider);
  const [fromDate, setFromDate] = useState(`${moment().format('YYYY')}-01-01`);
  const [toDate, setToDate] = useState(moment().format('YYYY-MM-DD'));
  const [minStartDate] = useState(moment().subtract(1, 'years').format('YYYY-MM-DD'));

  useEffect(()=>{
    dispatch(setParentModuleActiveDispatch("parentAnalytics"));
    dispatch(setModuleActiveDispatch(""));
  }, []);

  const getLineGraph =()=>{

    if(dashBoardData.tourViewsBydate.length === 1){
      
      let currentStartDate =dashBoardData.tourViewsBydate.map(date=> moment(date.viewdate).format('DD-MM-YYYY'))[0];
      const currentMoment = moment(currentStartDate, 'DD-MM-YYYY');
      const previousMoment = currentMoment.subtract(1, 'days');
      const previousDate = previousMoment.format('DD-MM-YYYY');

      return {
          labels: [...dashBoardData.tourViewsBydate.map(date=> moment(date.viewdate).format('DD-MM-YYYY')), previousDate].reverse(),
          datasets: [{
          ...LINE_CHART_DATASETS_COMMAN_CONFIG,
          data: [...dashBoardData.tourViewsBydate.map(date=> parseInt(date.totalviews)), 0].reverse(),
        }],
      }
    }

    return {
      labels: dashBoardData.tourViewsBydate.map(date=> moment(date.viewdate).format('DD-MM-YYYY')).reverse(),
      datasets: [{
        ...LINE_CHART_DATASETS_COMMAN_CONFIG,
        data: dashBoardData.tourViewsBydate.map(date=> parseInt(date.totalviews)).reverse(),
      }],
    }
  }

  // TO REQUIRED INFO ON APPLICATION LOAD
  useEffect(()=>{
    if(userProfileDetails.isUserFetch){
        dispatch(resetDashBoardData()); 
        let tour_id= 0;
        dispatch(fetchTourViewsbyuserDispatch(tour_id, fromDate, toDate, ()=>{
        dispatch(fetchTourViewsByCategoryDispatch(tour_id, fromDate, toDate))
        dispatch(fetchTourViewsBydateDispatch(tour_id, fromDate, toDate))
      })) 
    }
  }, [userProfileDetails.isUserFetch]);

  const getToursListView=()=>{
    let tours= [<option value="0">All</option>];
    if(dashBoardData.tourViewsByUser.tourViewsList.length>0)
      return [tours, ...dashBoardData.tourViewsByUser.tourViewsList.map(tous=><option value={tous.tour_id} >{tous.tour_name}</option>)]

    return tours
  }

  const selectedValue = (e) =>{
    switch(e.target.name){
      case "tours":
        setSelectedTour(e.target.value);
        break;
      }
  }
  
  const applyThisTour = async(toureId) =>{
    
    let tour_id= 0;
    if(selectedTour !== 0){
      tour_id= selectedTour;
    }

    if(toureId !== undefined && toureId ==="reset"){
      tour_id= 0;
      let from_date= `${moment().format('YYYY')}-01-01`;
      let to_date= moment().format('YYYY-MM-DD');
      setFromDate(from_date);
      setToDate(to_date);
      // dispatch(fetchTourViewsbyuserDispatch(0, fromDate, toDate)) 
      dispatch(fetchTourViewsByCategoryDispatch(tour_id, from_date, to_date));
      dispatch(fetchTourViewsBydateDispatch(tour_id, from_date, to_date));
      if(selectedTour === 0) return;
      setSelectedTour(0);
    }else{

      const getTourViewsbyuser =await fetchTourViewsbyuser(tokenContext.accessToken, dispatch, tour_id, fromDate, toDate);
      console.log("getTourViewsbyusersss", getTourViewsbyuser);
      setSpecificTourViewsbyuser(getTourViewsbyuser.result.payload);
      // dispatch(fetchTourViewsbyuserDispatch(0, fromDate, toDate)) 
      dispatch(fetchTourViewsByCategoryDispatch(tour_id, fromDate, toDate));
      dispatch(fetchTourViewsBydateDispatch(tour_id, fromDate, toDate));
    }

  }

  const getLIneChart=()=>{
    if(dashBoardData.tourViewsBydate.length>0)
      return <Line 
      options= {{
        ...LINE_CHART_COMMAN_OPTIONS,
        // animation: getLineAnimation(dashBoardData.tourViewsBydate.length, 550),
      }} 
      // data={lineData} 
      data={getLineGraph()} 
    />
    return <NoAssests />
  }

  // device CATEGORIES 
  const deviceData =()=> {
    let viewedInDevices= dashBoardData.tourViewsByCategory.filter(cat=> cat.category === "device");
    let getViewedInASCDorder= viewedInDevices.sort((a, b) => a.views - b.views);
    
    return {
      labels: getViewedInASCDorder.map(data=> data.name.replace(/^./, data.name[0].toUpperCase())),
      datasets: [
        {
          // ...PIE_CHART_DATASETS_COMMAN_CONFIG,
          ...pieChartDatasetsCommanConfig(getViewedInASCDorder),
          data: getViewedInASCDorder.map(data=> parseInt(data.views)),
        },
      ],
    };
  }

  const getDevicesPieChart=()=>{
    if(dashBoardData.tourViewsByCategory.filter(cat=> cat.category === "device").length> 0)
    return <div class="chart-container">
      <Pie
        data={deviceData()}
        options={{
          plugins: {...pieOptions}
        }}
      />
    </div>
    return <NoAssests />
  }

  // OS CATEGORIES 
  const osData = ()=>{
  
    let viewedInOs= dashBoardData.tourViewsByCategory.filter(cat=> cat.category === "os");
    let getViewedInOs= viewedInOs.sort((a, b) => a.views - b.views);
    
    return {
      labels: getViewedInOs.map(data=> data.name.replace(/^./, data.name[0].toUpperCase())),
      datasets: [
        {
          // ...PIE_CHART_DATASETS_COMMAN_CONFIG,
          ...pieChartDatasetsCommanConfig(getViewedInOs),
          data: getViewedInOs.map(data=> parseInt(data.views)),
        },
      ],
    };
  }

  const getOSPieChart=()=>{
    if(dashBoardData.tourViewsByCategory.filter(cat=> cat.category === "os").length> 0)
      return <div class="chart-container">
      <Pie
        data={osData()}
        options={{
          plugins: {...pieOptions}
        }}
      />
    </div>
    return <NoAssests />
  }

  // BROWSER CATEGORIES 
  const browserData =()=>{
    let viewedInBrowser= dashBoardData.tourViewsByCategory.filter(cat=> cat.category === "browser");
    let getViewedInBrowser= viewedInBrowser.sort((a, b) => a.views - b.views)
    return {
      labels: getViewedInBrowser.map(data=> data.name.replace(/^./, data.name[0].toUpperCase())),
      datasets: [
        {
          // ...PIE_CHART_DATASETS_COMMAN_CONFIG,
          ...pieChartDatasetsCommanConfig(getViewedInBrowser),
          data: getViewedInBrowser.map(data=> parseInt(data.views)),
        },
      ],
    };
  } 

  const getBrowserPieChart=()=>{
    if(dashBoardData.tourViewsByCategory.filter(cat=> cat.category === "browser").length> 0)
    return <div class="chart-container">
        <Pie 
          data={browserData()}
          options={{
            plugins: {...pieOptions}
          }}
          />
      </div>
    return <NoAssests />
  }
  
  const getAnalysisDeviseWise=()=>{
    let viewedInDevices= dashBoardData.tourViewsByCategory.filter(cat=> cat.category === "device");
    if(viewedInDevices.length> 0){
      let getViewedInASCDorder= viewedInDevices.sort((a, b) => a.views - b.views);
      let includePercentage= getPercentage(getViewedInASCDorder);
      return includePercentage.map(device=>{
        return <tr><td>{device.name}</td><td className="text-end">{device.views}</td><td className="text-end">{device.percentage}%</td></tr>
      })
    }
    return <td className="border-0" colSpan={3}><NoAssests /></td>
  }

  const getAnalysisOsWise=()=>{
    let viewedInOs= dashBoardData.tourViewsByCategory.filter(cat=> cat.category === "os");
    if(viewedInOs.length> 0){
      let getViewedInOs= viewedInOs.sort((a, b) => a.views - b.views);
      let includePercentage= getPercentage(getViewedInOs);
      return includePercentage.map(device=>{
        return <tr><td>{device.name}</td><td className="text-end">{device.views}</td><td className="text-end">{device.percentage}%</td></tr>
      })
    }
    return <td className="border-0" colSpan={3}><NoAssests /></td>
  }

  const getAnalysisBrowserWise=()=>{
    let viewedInBrowser= dashBoardData.tourViewsByCategory.filter(cat=> cat.category === "browser");
    if(viewedInBrowser.length> 0){
      let getViewedInBrowser= viewedInBrowser.sort((a, b) => a.views - b.views)
      let includePercentage= getPercentage(getViewedInBrowser);
      return includePercentage.map(device=>{
        return <tr><td>{device.name}</td><td className="text-end">{device.views}</td><td className="text-end">{device.percentage}%</td></tr>
      })
    }
    return <td className="border-0" colSpan={3}><NoAssests /></td>
  }
  
  return (
    <HeaderFooter>
      <div className="px-3">
        <div className="pt-0 pt-md-3 pb-2">
          <h4>Analytics</h4>
        </div>
        <div className="white-box">
          <Row className="align-items-end">
            <Col md={12} lg={5} xl={3} className="pb-3 pb-xl-0">
              <label className="mb-1">360° Tours</label>
              {/* SELECT TOUR SECTION START */}
              <Input 
                  style={{width: "100%"}}
                  id="tours" 
                  name="tours" 
                  type="select" 
                  onChange={selectedValue}
                  value={selectedTour}
              >
              {getToursListView()}
              </Input>
            {/* SELECT TOUR SECTION END */}
            </Col>
            <Col md={12} xl={9} className="pb-3 pb-xl-0">
              <label  className="mb-0">Date Range</label>
              <div className="custom-dp">
                <DatePicker 
                  format= "dd-MM-y"
                  onChange={(value) => {
                    setFromDate(moment(value, 'YYYY-MM-DD').format('YYYY-MM-DD'));
                  }} 
                  value= {fromDate}
                  minDate={new Date(minStartDate)} 
                  maxDate={new Date(moment(toDate).subtract(1, 'days'))} 
                  clearIcon={null}
                  // calender class
                  calendarClassName={" calendar"}
                  // picker class
                  className= {" form-control mt-1"}
                  // calendar Icon
                  // calendarIcon={"calendar Icon"}
                />
                <strong className="px-3"><img className="date-range" src={Arrows} alt="arrow" /></strong>
                <DatePicker 
                  format= "dd-MM-y"
                  onChange={(value) => {
                    setToDate(moment(value, 'YYYY-MM-DD').format('YYYY-MM-DD'));
                  }} 
                  value= {toDate}
                  minDate={new Date(moment(fromDate).add(1, 'days'))} 
                  maxDate={new Date()} 
                  clearIcon={null}
                  // calender class
                  calendarClassName={" calendar"}
                  // picker class
                  className= {" form-control mt-1"}
                  // calendar Icon
                  // calendarIcon={"calendar Icon"}
                />

               <div className="mt-3 mt-lg-0 ms-lg-4 w-100 w-lg-auto">
                <button className="btn btn-primary btn-md me-3 me-lg-4" onClick={applyThisTour}>Go</button>
                  <button className="btn btn-light btn-md me-3 me-lg-4" onClick={()=>{
                    applyThisTour("reset");
                  } }>Clear</button>
               </div>
              </div>
              <div >
              </div>
            {/* SELECT TOUR SECTION END */}
            <div>
            
            </div>
            </Col>
            
          </Row>
        </div>
          

          <Row>
            {/* TOUR VIEWS LINE CHART START */}
            <Col xl={8} className="mb-4"> 
              <div className="white-box h-100 pb-1">
                  <div class="chart-container analytics-line-chart h-100-50">
                    <h5>Tour Views</h5>
                    {getLIneChart()}
                  </div>
              </div>
            </Col>
            {/* TOUR VIEWS LINE CHART END */}

              <Col xl={4}> 
                <div className="white-box white-box-gradiant">
                  {/* SHOW INFO START */}
                  
                    <div className="d-flex align-items-center dashboard-card py-2">
                      <span ><img src={IconView} alt="360" /></span>  
                      <div>
                        <p className="mb-1"><strong className="text-disabled color-white">Total Tour Views</strong></p>
                        <h3 className="text-xxl mb-0" style={{"color": "#e6e6e6", "font-family": "system-ui", "font-weight": "100"}}>{
                        selectedTour !== 0? 
                          specificTourViewsbyuser ?specificTourViewsbyuser.total_tourviews: dashBoardData.tourViewsByUser.total_tourviews
                        :
                        dashBoardData.tourViewsByUser.total_tourviews
                        }</h3>
                      </div>
                    </div>
                    {/* SHOW INFO END */}
                </div>

                <div className="white-box white-box-gradiant">
                  {/* SHOW INFO START */}
                    <div className="d-flex align-items-center dashboard-card  py-2">
                      <span ><img src={Icon360Item} alt="360" /></span>
                      <div>
                        <p className="mb-1"><strong className="text-disabled color-white">360° Experiences</strong></p>
                        <h3 className="text-xxl mb-0" style={{"color": "#e6e6e6", "font-family": "system-ui", "font-weight": "100"}}>{
                        selectedTour !== 0? 
                          specificTourViewsbyuser? specificTourViewsbyuser.total_tourexperiences: dashBoardData.tourViewsByUser.totalTourexperiences
                        :
                        dashBoardData.tourViewsByUser.totalTourexperiences
                        }</h3>
                      </div>
                    </div>
                    {/* SHOW INFO END */}
                </div>
              </Col>

          </Row>
        
        <div className="white-box">
          <Row>
            {/* DEVICE PIE START */}
            <Col sm={6} md={12} lg={7}>
              <h5 className="mb-2">Devices</h5>
              <div className="border-box analytics-tbl text-center analytics-circle-chart py-0 h-100-50">
                {getDevicesPieChart()}
              </div>  
            </Col>
            
            <Col sm={6} md={12} lg={5}>
            <h5 className="mb-2 visibility-hidden d-none d-sm-block">Devices </h5>
              <div className="border-box analytics-tbl p-0">
                <Table className="w-100" bordered striped>
                  <thead>
                    <tr>
                      <th>Device</th>
                      <th className="text-center">View</th>
                      <th className="text-center">%</th>
                    </tr>
                  </thead>
                  <tbody>
                    {getAnalysisDeviseWise()}
                  </tbody>
                </Table>
              </div>  
            </Col>
            {/* DEVICE PIE START */}
          </Row>
        </div>
          
        <div className="white-box">
          <Row>
            {/* OS PIE START */}
            <Col sm={6} md={12} lg={7}>
              <h5 className="mb-2">OS</h5>
              <div className="border-box analytics-tbl text-center analytics-circle-chart py-0  h-100-50">
              {getOSPieChart()}
              </div>  
            </Col>
            
            <Col sm={6} md={12} lg={5}>
            <h5 className="mb-2 visibility-hidden d-none d-sm-block">Devices </h5>
              <div className="border-box analytics-tbl p-0">
                <Table className="w-100" bordered striped>
                  <thead>
                    <tr>
                      <th>OS</th>
                      <th className="text-center">View</th>
                      <th className="text-center">%</th>
                    </tr>
                  </thead>
                  <tbody>
                  {getAnalysisOsWise()}
                  </tbody>
                </Table>
              </div>  
            </Col>
            {/* OS PIE START */}
          </Row>
        </div>
          
          
        <div className="white-box">
          <Row>
            {/* BROWSER PIE START*/}
            <Col sm={6} md={12} lg={7}>
              <h5 className="mb-2">Browser</h5>
              <div className="border-box analytics-tbl text-center analytics-circle-chart py-0  h-100-50">
              {getBrowserPieChart()}
              </div>  
            </Col>
            
            <Col sm={6} md={12} lg={5}>
            <h5 className="mb-2 visibility-hidden d-none d-sm-block">Devices </h5>
              <div className="border-box analytics-tbl p-0">
                <Table className="w-100" bordered striped>
                  <thead>
                    <tr>
                      <th>Browser</th>
                      <th className="text-center">View</th>
                      <th className="text-center">%</th>
                    </tr>
                  </thead>
                  <tbody>
                  {getAnalysisBrowserWise()}
                  </tbody>
                </Table>
              </div>  
            </Col>
            {/* BROWSER PIE START */}
          </Row>
        </div>
      </div>
    </HeaderFooter>
  );
}
  
export default Analytics;