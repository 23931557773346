import Routes from './ROUTES'
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from "react-router-dom";
import { useSelector } from 'react-redux'
import { ToastContainer } from 'react-toastify';
import Loader from "./COMPONENTS/Loader";
import ProvideTokenContext from "./STORE/App-wide-state/Token/provideTokenContext";

function App() { 

  const loader = useSelector(state => state.loader);
  const LoaderForIndividualPage = useSelector(state => state.LoaderForIndividualPage);
  const subscription = useSelector(state => state.subscription);

  return (
    <Router>
      <div className="App">
        { ReactDOM.createPortal(
            <ToastContainer />,
            document.getElementById('toast-portal')
        )}
        {loader.open && <Loader />} 
        {(LoaderForIndividualPage.open || subscription.isPaymentProcecing) && <Loader />} 
        <ProvideTokenContext>
          <Routes />
        </ProvideTokenContext>
      </div>
    </Router>
  );
}

export default App;

/**
 * ip
 * osName
 * deviceType
 * deviceDetect
*/



// import React, { useState } from 'react';
// import {
//   Dropdown,
//   DropdownToggle,
//   DropdownMenu,
//   DropdownItem,
// } from 'reactstrap';
// import PropTypes from 'prop-types';

// function Example({ direction }) {
//   const [dropdownOpen, setDropdownOpen] = useState(false);

//   const toggle = () => {
//     // alert()
//     setDropdownOpen(!dropdownOpen);
//   }
//   return (
//     <div className="d-flex p-5">
//       <Dropdown isOpen={dropdownOpen} toggle={toggle} direction={direction}>
//         <DropdownToggle caret>Dropdown</DropdownToggle>
//         <DropdownMenu >
//           <DropdownItem header>Header</DropdownItem>
//           <DropdownItem>Some Action</DropdownItem>
//           <DropdownItem text>Dropdown Item Text</DropdownItem>
//           <DropdownItem disabled>Action (disabled)</DropdownItem>
//           <DropdownItem divider />
//           <DropdownItem>Foo Action</DropdownItem>
//           <DropdownItem>Bar Action</DropdownItem>
//           <DropdownItem>Quo Action</DropdownItem>
//         </DropdownMenu>
//       </Dropdown>
//     </div>
//   );
// }

// Example.propTypes = {
//   direction: PropTypes.string,
// };

// export default Example;