import { createSlice } from '@reduxjs/toolkit'
import { getData, postData } from '../../UTILITYS/index.tsx'
import { BASE_URL, GET_TOURS_VIEWS_BY_CATEGORY, GET_TOURS_VIEWS_BY_DATE, GET_TOURS_VIEWS_BY_USER } from '../../APIs/index.js'

const initialState = {
  tourViewsByUser:{
    total_tours: "",
    total_tourviews: "",
    tourViewsList: [],
    totalTourexperiences: 0,
  },
  tourViewsByCategory: [],
  tourViewsBydate: [],
  isDashBoardDataResGet: false
}  

export const home = createSlice({
  name: 'home',
  initialState: { ...initialState }, 
  reducers: { 
  
    setTourViewsbyuser: (state, action) => {
      return {
        ...state,
        tourViewsByUser:{
          total_tours: action.payload.total_tours,
          total_tourviews: action.payload.total_tourviews,
          tourViewsList: action.payload.tourViewsList,
          totalTourexperiences: action.payload.total_tourexperiences,
        },
      }
    },
    setTourViewsByCategory: (state, action) => {
      
      return {
        ...state,
        tourViewsByCategory: [...action.payload]
      }
    },
    setTourViewsBydate: (state, action) => {
      return {
        ...state,
        tourViewsBydate: [...action.payload]
      }
    },
    setResponseDashBoardData: (state, action) => {
      return {
        ...state,    
        [action.payload.key]: action.payload.value
      }
    },
    resetDashBoardData: ()=>{
      return {...initialState}
    },
  }
}) 

// Action creators are generated for each case reducer function
export const { setTourViewsbyuser,
    setTourViewsByCategory,
    setTourViewsBydate,
    setResponseDashBoardData,
    resetDashBoardData
  } = home.actions

// TO FETCH TOURS VIEWS BY USERS
export const fetchTourViewsbyuser =async (token, dispatch, tour_id, fromdate, todate) =>{

    try{

      let url= BASE_URL+ GET_TOURS_VIEWS_BY_USER+`?tour_id=${tour_id}&fromdate=${fromdate}&todate=${todate}`;
     
      let headerConfig = {
        "Content-Type": "application/json",
        "Authorization": "Bearer " +token
      }
    
      const getTourViewsbyuser =getData({url, headerConfig}, dispatch);
    
    return getTourViewsbyuser;
    
  }catch (err){

    throw (err)
  }

}

export const fetchTourViewsbyuserDispatch = (tour_id, fromdate, todate, cb) => {

  return async(dispatch, getState) => {
    
    if(getState().localSessionDetails.payload === "") return;

    try{
        let token = getState().localSessionDetails.payload.accessToken;

        const resTourViewsbyuser =await fetchTourViewsbyuser(token, dispatch, tour_id, fromdate, todate)
        
        dispatch(setTourViewsbyuser({...resTourViewsbyuser.result.payload, totalTourexperiences: resTourViewsbyuser.result.payload.total_tourexperiences}))

        cb();
       
      }catch (err){

        if(err!= undefined && err.isError){
           
        }
    }
  }
}

// TO FETCH TOURS VIEWS BY CATEGORY
const fetchTourViewsByCategory =async (token, dispatch, tour_id, fromdate, todate) =>{

  try{

      let url= BASE_URL+ GET_TOURS_VIEWS_BY_CATEGORY+`?tour_id=${tour_id}&fromdate=${fromdate}&todate=${todate}`;

      let headerConfig = {
        "Content-Type": "application/json",
        "Authorization": "Bearer " +token
      }

      const getTourViewsByCategory =await getData({url, headerConfig}, dispatch);
      return getTourViewsByCategory;
    
    }catch (err){

      throw (err)
    }
}

export const fetchTourViewsByCategoryDispatch = (tour_id, fromdate, todate) => {

  return async(dispatch, getState) => {
    
    if(getState().localSessionDetails.payload === "") return;

    try{
        let token = getState().localSessionDetails.payload.accessToken;

        const resTourViewsByCategory =await fetchTourViewsByCategory(token, dispatch, tour_id, fromdate, todate)
        
        dispatch(setTourViewsByCategory(resTourViewsByCategory.result.payload))
      
      }catch (err){

        if(err!= undefined && err.isError){
          
        }
    }
  }
}

// TO FETCH TOURS VIEWS BY DATE
const fetchTourViewsBydate =async (token, dispatch, tour_id, fromdate, todate) =>{

  try{

      let url= BASE_URL+ GET_TOURS_VIEWS_BY_DATE+`?tour_id=${tour_id}&fromdate=${fromdate}&todate=${todate}`;
      
      let headerConfig = {
        "Content-Type": "application/json",
        "Authorization": "Bearer " +token
      }
      
      const getTtourViewsBydate =await getData({url, headerConfig}, dispatch);
      return getTtourViewsBydate;
    
    }catch (err){

      throw (err)
    }
}

export const fetchTourViewsBydateDispatch = (tour_id, fromdate, todate) => {

  return async(dispatch, getState) => {
    
    if(getState().localSessionDetails.payload === "") return;

    try{
        let token = getState().localSessionDetails.payload.accessToken;

        const resTourViewsBydate =await fetchTourViewsBydate(token, dispatch, tour_id, fromdate, todate)
        
        dispatch(setTourViewsBydate(resTourViewsBydate.result.payload))
      
      }catch (err){

        if(err!= undefined && err.isError){
          
        }
    }
  }
}


export default home.reducer;