import React, { useEffect, useRef, useState, useContext } from "react";
import ReactDOM from 'react-dom';
import edit from './../../ASSETS/Images/icons/edit.png';
import Play from './../../ASSETS/Images/icons/play.svg';
// import Image from './../../ASSETS/Images/icons/Image.svg';
import Audio from './../../ASSETS/Images/icons/audio.svg';
// import Video from './../../ASSETS/Images/icons/Video.svg';
// import Location from './../../ASSETS/Images/icons/Location.svg';
// import Web from './../../ASSETS/Images/icons/web.svg';
import Image from './../../ASSETS/Images/icons/Image.png';
// import Audio from './../../ASSETS/Images/icons/audio.svg';
import Video from './../../ASSETS/Images/icons/Video_three_sixty.png';
import Location from './../../ASSETS/Images/icons/Lable.png';
import Web from './../../ASSETS/Images/icons/URL.png';
import HS from './../../ASSETS/Images/icons/location.png';
import deleteIcon from './../../ASSETS/Images/icons/delete.png';
import AddTag from './../../ASSETS/Images/icons/Cancel2x.png';
import Back from './../../ASSETS/Images/icons/Back.png';
import Collapse from './../../ASSETS/Images/icons/collapse.png';
import Expand from './../../ASSETS/Images/icons/Expand.png';
import CreateTokenContextProvider from "../../STORE/App-wide-state/Token/createTokenContextProvider.js";
import { useDispatch, useSelector } from 'react-redux'
// import { getData } from "../../UTILITYS/index.js";
import { deleteVTtag, getAssetsForTagging, getSASurlToUploadVideosFiles, getTagForViewer, getVTtag, logAgentData, postVTtag, putDataTo, saveAssetToS_3 } from "../../UTILITYS/endPoints.js";
import { toast } from 'react-toastify';
import { Modal, ModalBody, Button, Row, Col} from 'reactstrap'; 
import CancelIcon from './../../ASSETS/Images/icons/close.svg' 
import { useNavigate, useParams, useLocation, NavLink } from 'react-router-dom';
import validator from 'validator';
import MenuImg from './../../ASSETS/Images/xr/burger.png';
import Info from './../../ASSETS/Images/icons/Info.png';
import pauseAutorotate from './../../ASSETS/Images/icons/pause-autorotate.png';
import gyroscopeOpenIcon from './../../ASSETS/Images/icons/gyroscope-active.png';
import gyroscopeCloseIcon from './../../ASSETS/Images/icons/gyroscope-close.png';
import playAutorotate from './../../ASSETS/Images/icons/play-autorotate.png';
import MenuClose from './../../ASSETS/Images/xr/closeBurger.png';
import { THREE_SIXY_IMG_BASE_URL_TO_BE_SHOWN } from "../../APIs/index.js";
import { fetchViewerVirtualTourImagesDispatch } from "./viewerVirtualTourImages.slice.js";
import { deviceType, browserName, osName } from 'react-device-detect';
import Close from './../../ASSETS/Images/icons/cancel.png'
import ReactPlayer from 'react-player'
import Iframe from 'react-iframe';
import ExpiredView from "./ExpiredView.js";
import CarouselModal from "../../COMPONENTS/Galaries/CarouselModal.js";
import CustomVideoPlayer from "../../COMPONENTS/Galaries/CustomVideoPlayer/index.js";
import { LoaderForIndividualPageDispatch } from "../../COMPONENTS/Loader/LoaderForIndividualPage.slice.reducer.js";

// let isPanoramaLodedFirstTime= true;

function PenneliumReactOne(props) {
  
  const dispatch = useDispatch();
  const location = useLocation();
  const panoramaRef = useRef(null);
  const [viewer, setviewer] = useState(null);
  const [isParonamaIMGloaded, setIsParonamaIMGloaded] = useState(0);
  const tokenContext = useContext(CreateTokenContextProvider);
  const { virtualTourToken } = useParams();
  
  // ALL VIRTUAL TOUR EXPERIENCE
  const virtualTourExperience = useSelector(state => state.viewerVirtualTourImages);

  // DRAWER
  const [isEnableDrawer, setIsEnableDrawer] = useState(false);
  const [isActEnableDrawer, setIsActEnableDrawer] = useState(false);
  const [isEnableDrawerItem, setIsEnableDrawerItem] = useState(true);
  const [fetchedHotSpot, setFetchedHotSpot] = useState([]);
  const [activeTagID, setActiveTagID] = useState("");

  // CURRENT EXPERIENCE AND ITS LIST
  const [currentExperience, setCurrentExperience] = useState({ fileUrl: "", id: props.id, name: props.name }); 
  const [openLeftExperienceDrawer, setOpenLeftExperienceDrawer] = useState(false);
  
  // CURRENT EXPERIENCE'S ACTIVE HOT-SPOT
  const [currentTag, setCurrentTag] = useState({});
  const [currentTagType, setCurrentTagType] = useState("0");
  const [currentTagImage, setCurrentTagImage] = useState("");
  const [currentTagVideo, setCurrentTagVideo] = useState(null);
  const [currentTagUrl, setCurrentTagUrl] = useState("");
  

  // MODAL TO SHOW ACITVE TAG OF EXPERIENCE
  const [openWebInfoModalInIfram, setOpenWebInfoModalInIfram] = useState(false);
  const [isOpenCarousel, setIsOpenCarousel] = useState(false);

  // TOUR INFO
  const [isTourInfoModalShow, setIsTourInfoModalShow] = useState(false);

  const [isPanoramaLodedFirstTime, setIsPanoramaLodedFirstTime] = useState(true);
  const [isViewerAutoRotate, setIsViewerAutoRotate] = useState(true);
  const [shouldShowGSCallowButton, setShouldShowGSCallowButton] = useState(false);
  const [showGSCplayPaushButton, setShowGSCplayPaushButton] = useState(false);
  const [isOrientationStartByDefault, setIsOrientationStartByDefault] = useState(true);
  
  const closeCarousel = ()=>{
    setIsOpenCarousel(false);
  }

  function requestOrientation() {

    if (typeof DeviceMotionEvent.requestPermission === 'function'){
      DeviceMotionEvent.requestPermission()
      .then((permissionState) => {
        if(viewer!==null){
          if(permissionState === "granted"){
            viewer.startOrientation();
            setShouldShowGSCallowButton(false)
            setShowGSCplayPaushButton(true)

            viewer.stopAutoRotate();
            setIsViewerAutoRotate(false)
          }else{
            alert("You Denied Gyroscope Permission, Close the Application and come again to Allow the Gyroscope Permission")
            setShouldShowGSCallowButton(false)
          }
        }
      })
      .catch((err)=>{
        setShouldShowGSCallowButton(false)
      });
    }
  }

  // To get tour images
  useEffect(()=>{
    dispatch(LoaderForIndividualPageDispatch(true));
    dispatch(fetchViewerVirtualTourImagesDispatch(virtualTourToken));
    localStorage.removeItem('isEditableTagData');
  },[]);
  
  useEffect(()=>{
    dispatch(LoaderForIndividualPageDispatch(false));
  },[virtualTourExperience.isViewerVirtualTourImagesResGet]);

  useEffect(() => {
    if(viewer!==null){
      if(viewer.isLoaded() === undefined){
        let elem = document.getElementsByClassName('pnlm-load-box');
        let temp = "<div class='loader'><div class='lds-spinner'><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div>";
        elem[0].innerHTML = temp;
      }
    }
  })

  const getIp= async() =>{
    
    const getIp = await fetch("https://api.ipify.org/?format=json");
    const ip = await getIp.json();
    
    let dataObje= {
      tour_id: virtualTourExperience.tourId,
      tour_url: location.pathname,
      device_type: deviceType,
      browser_name: browserName,
      os_name: osName,
      ip_address: ip.ip
    }
    await logAgentData(dataObje, dispatch);
  }

  useEffect(() => {
    if(virtualTourExperience.tourImages.length >0 && virtualTourExperience.subscriptionStatus !== 4){
      
      let scenes = getListOfExperienceScenes(virtualTourExperience.tourImages); // load first and other 360 image in scene

      // if(viewer) viewer.destroy();
      setviewer(window.pannellum.viewer(panoramaRef.current, {
        "draggable": true,
        "disableKeyboardCtrl": true,
        "type": "equirectangular",
        "autoLoad": true, 
        // "panorama": state.fileUrl, 
        // "panorama": THREE_SIXY_IMG_BASE_URL_TO_BE_SHOWN+virtualTourExperience.tourImages[0].fileUrl,
        // "hotSpots": [],
        "firstScene": virtualTourExperience.tourImages[0].experienceID,
        "showControls": false,
        "compass": true,
        "orientationOnByDefault": deviceType === "mobile"? true: false,
        "scenes": scenes,
        "sceneFadeDuration": 2000,
        "autoRotate": -7,
      }));

      getIp();
      localStorage.removeItem('currentSceneId');
      localStorage.setItem("currentSceneId", String(virtualTourExperience.tourImages[0].experienceID));
      setCurrentExperience(virtualTourExperience.tourImages[0]);
      
    }
  }, [virtualTourExperience.tourImages.length]);
  
  const getListOfExperienceScenes= (experiencs)=>{

    let dummy = [];
    let mainObje= {};

    dummy= experiencs.map(element => {

      let obje= { 
          [String(element.experienceID)]: {
            // "title": element.roomName,
            // "hfov": 110,
            // "pitch": -3,
            // "yaw": 117,
            "type": "equirectangular",
            "panorama": THREE_SIXY_IMG_BASE_URL_TO_BE_SHOWN+element.fileUrl,
            "hotSpots": []
          }
        } 
        return obje;
      });
      
      dummy.forEach(element => {
        let abc = element;
        mainObje= {...mainObje, ...abc}
      });
      
      return mainObje;
      
  }

  useEffect(()=>{
    if(viewer!==null){
      viewer.on('touchstart', ()=>{
        setIsViewerAutoRotate(false)
        setIsOrientationStartByDefault(false)
      })
    }
  }, [viewer])
  
  // GET HOT-SPOT AND MAP WITH `pannellum viewer`
  useEffect(() => {

    if(viewer!==null){
      if(isPanoramaLodedFirstTime){
        fetchScenesArray(currentExperience); 
        viewer.on('load', ()=>{
          // view er.startOrientation();
          // alert()
          if(osName === "iOS" && deviceType === "mobile" && !viewer.isOrientationActive()){
            setShouldShowGSCallowButton(true);
          }else if(osName === "iOS" && deviceType === "mobile" && viewer.isOrientationActive()){
            setShowGSCplayPaushButton(true)
          }else if(osName === "Android" && deviceType === "mobile" && viewer.isOrientationActive()){
            viewer.stopAutoRotate();
            setIsViewerAutoRotate(false)
          }

          setIsParonamaIMGloaded(isParonamaIMGloaded+1);
          let elem = document.getElementsByClassName('pnlm-about-msg');
          if (elem.length > 0) {
            elem[0].remove();
          }
        })
      }
      
      setIsPanoramaLodedFirstTime(false);
  }}, [viewer, isPanoramaLodedFirstTime])

  useEffect(()=>{
    
    if(viewer!==null){

      viewer.on('scenechange', async(sceneId)=>{
        
        let currentSceneHS= await JSON.parse(localStorage.getItem("currentSceneHS")) || [];
        let currentSceneId= await JSON.parse(localStorage.getItem("currentSceneId"));
      
        if(sceneId != currentSceneId){    
          
          currentSceneHS.forEach(hs => {
            if(hs.id)
              viewer.removeHotSpot(hs.id, String(currentSceneId));
          });
          let currentExperience ={
            experienceID: sceneId
          }

          setTimeout(() => {
            
            resetTag();
            virtualTourExperience.tourImages.forEach(tour => {
              if(String(tour.experienceID) === String(sceneId))
                setCurrentExperience(tour);
            });
            fetchScenesArray(currentExperience);
            setIsViewerAutoRotate(false);
            setIsOrientationStartByDefault(true);
            viewer.stopAutoRotate();

          }, 700);
          
        }
        localStorage.removeItem('currentSceneId');
        localStorage.setItem("currentSceneId", sceneId);
        
      })

    }

  }, [viewer])

  // GET HOT-SPOT AND MAP WITH `pannellum viewer`

  const fetchScenesArray= async(currentExperience)=>{

    let data= {
      experienceID: currentExperience.experienceID
    }

    // let getScenesArrayPromiseJson= await getVTtag(data , dispatch, tokenContext.accessToken)
    let getScenesArrayPromiseJson= await getTagForViewer(data, dispatch, tokenContext.accessToken)
    let getScenesArray1= getScenesArrayPromiseJson.result;
      
    if(getScenesArray1.payload.length >0){

      setFetchedHotSpot([]);

      let allCurrentSceneHS= []
      
      getScenesArray1.payload.forEach(hotSpot =>{

        let createTooltipArgs= {
          pitch: hotSpot.pitchValue, yaw: hotSpot.pitchYaw, tagName: hotSpot.tagName, ...hotSpot, mainDBid: hotSpot.id, id: `edit-hotspot${hotSpot.uniqueTimestamp}`
        }
        
        setFetchedHotSpot((prev)=>{
          return [...prev, createTooltipArgs]
        });

        allCurrentSceneHS.push(createTooltipArgs);

        localStorage.removeItem('currentSceneHS');
        localStorage.setItem("currentSceneHS", JSON.stringify([...allCurrentSceneHS]));
        
        let newHotspot = {
            id: `edit-hotspot${hotSpot.uniqueTimestamp}`,
            pitch: hotSpot.pitchValue,
            yaw: hotSpot.pitchYaw,
            cssClass: getIcon(createTooltipArgs.tagType),
            createTooltipFunc: showHotspot,
            ...getSceneId(createTooltipArgs), // --> "sceneId": "circle",
            type: createTooltipArgs.tagType == 6? "scene": "info",
            // createTooltipArgs: "New Hotspot",
            createTooltipArgs,
            clickHandlerFunc: (e)=>{
              // e.stopPropagation();
              if(createTooltipArgs.tagType == 6){
                return;
              }
              
              setActiveTagID(hotSpot.id);
              editTag(createTooltipArgs);
              viewer.lookAt(parseInt(hotSpot.pitchValue), parseInt(hotSpot.pitchYaw));
            },
            isAddInfoEnable: false,
            draggable: true,
            "dragHandlerArgs": {id: 0}
          };
        // Add hotspot
        
        viewer.addHotSpot(newHotspot);
      });
    }
  }

  function getSceneId(createTooltipArgs) {

    if(createTooltipArgs.tagType == 6){
      return{
        "sceneId": String(createTooltipArgs.assetID)
      }
    }
  }

  function addShowHotspot(createTooltipArgs) {

    let newHotspot = {
      id: `edit-hotspot${createTooltipArgs.uniqueTimestamp}`,
      pitch: createTooltipArgs.pitch,
      yaw: createTooltipArgs.yaw,
      cssClass: getIcon(createTooltipArgs.tagType),
      createTooltipFunc: showHotspot,
      // createTooltipArgs: "New Hotspot",
      createTooltipArgs: {
        pitch: createTooltipArgs.pitch, yaw: createTooltipArgs.yaw, tagName: createTooltipArgs.tagName, ...createTooltipArgs, id: createTooltipArgs.uniqueTimestamp,
      },
      clickHandlerFunc: (e)=>{
        e.stopPropagation();
        setActiveTagID(createTooltipArgs.mainDBid);
        editTag({ pitch: createTooltipArgs.pitch, yaw: createTooltipArgs.yaw, tagName: createTooltipArgs.tagName, ...createTooltipArgs, mainDBid: createTooltipArgs.id, id: `edit-hotspot${createTooltipArgs.uniqueTimestamp}` });
        viewer.lookAt(parseInt(createTooltipArgs.pitch), parseInt(createTooltipArgs.yaw));
      },
      isAddInfoEnable: false,
      draggable: true,
      "dragHandlerArgs": {id: 0}
    };
    // Add hotspot
    console.log("cancelTagHandler 2", createTooltipArgs, newHotspot);
    viewer.addHotSpot(newHotspot);
  }

  const editTag= async(createTooltipArgs)=> {

    let isEditableTagData= await localStorage.getItem("isEditableTagData");
    const stringToObjIsEditableTagData = JSON.parse(isEditableTagData);
    
    if(stringToObjIsEditableTagData!== null && stringToObjIsEditableTagData.isEditableTagOpen){
      // REMOVE TEMPORARY EDIT HOT-SPOT
      viewer.removeHotSpot(stringToObjIsEditableTagData.previouseEditableTagID);
      // SHOW HOTSPOT IN REPLACE OF PREVIOUSE TEMPORARY EDIT HOT-SPOT
      addShowHotspot(stringToObjIsEditableTagData.pitchYaw);
    }
    viewer.removeHotSpot(createTooltipArgs.id);

    var newHotspot = {
      id: createTooltipArgs.id,
      tagName: createTooltipArgs.tagName,
      pitch: createTooltipArgs.pitch,
      yaw: createTooltipArgs.yaw,
      cssClass: getIcon(createTooltipArgs.tagType),
      
      
      clickHandlerFunc: (e)=>{

        e.stopPropagation();
        
        handlerToUploadAndEditTag({
          pitch: createTooltipArgs.pitch, 
          yaw: createTooltipArgs.yaw, 
          tagName: createTooltipArgs.tagName,
          isEdite: true,
          ...createTooltipArgs
        }); // same as --> addEditTagHotspot();
      },

      createTooltipFunc: showHotspot,
      createTooltipArgs: {
        pitch: createTooltipArgs.pitch, 
        yaw: createTooltipArgs.yaw, 
        tagName: createTooltipArgs.tagName,
        ...createTooltipArgs,
        addTooltipPermanent: true
      },
      isAddInfoEnable: true,
      draggable: true,
      "dragHandlerArgs": {id: 0}
    };

    viewer.addHotSpot(newHotspot);
    
    handlerToUploadAndEditTag({
      pitch: createTooltipArgs.pitch, 
      yaw: createTooltipArgs.yaw, 
      tagName: createTooltipArgs.tagName,
      isEdite: true,
      ...createTooltipArgs
    }); // same as --> addEditTagHotspot();

    // STORE THE EDIT HOTS-POT ID TO BE DELETED IF WE DO EDIT NEXT TIME
    let previouseEditableTagID= createTooltipArgs.id;
    localStorage.setItem("isEditableTagData", JSON.stringify({
      previouseEditableTagID: previouseEditableTagID,
      pitchYaw: createTooltipArgs,
      isEditableTagOpen: true
    }));
    setIsViewerAutoRotate(false);
    viewer.stopAutoRotate();
  }

  // HANDLER ADD HOTSPOT JUST AFTER ADDING
  function showHotspot(hotSpotDiv, createTooltipArgs) {


    let tagName= `
      <p>${createTooltipArgs.tagName}</p>
    `;

    if(createTooltipArgs.url && !validator.isEmpty(createTooltipArgs.url.trim()) && (validator.isURL(createTooltipArgs.url, {protocols: ['http','https'], require_protocol: true}))){
      if(createTooltipArgs.tagType === 4){
        tagName= `
        <p>${createTooltipArgs.tagName}: ${(new URL(createTooltipArgs.url)).hostname}</p>
      `;
      }
    }

    hotSpotDiv.classList.add('custom-tooltip');
    if(createTooltipArgs.addTooltipPermanent){
      hotSpotDiv.classList.add('permanent-tooltip-visible');
    }

    var span = document.createElement('span');
    span.setAttribute('id', 'show-tag-section'+createTooltipArgs.pitch);
    span.innerHTML = tagName;
    hotSpotDiv.appendChild(span);

    span.style.width = span.scrollWidth - 20 + 'px';
    span.style.marginLeft = -(span.scrollWidth - hotSpotDiv.offsetWidth) / 2 + 'px';
    span.style.marginTop = -span.scrollHeight - 12 + 'px';
  }

  // MODAL TO SHOW TAG INFO
  const showTagInfo= ()=>{
    return getTageBasedOnType()
  }

  const getWebInfoModal= ()=>{
      return <Modal isOpen={openWebInfoModalInIfram} centered className="drop-modal modal-lg" toggle={()=>{
      setOpenWebInfoModalInIfram(!openWebInfoModalInIfram);
    }} >
      <Button className="modalClose" onClick={()=>{
        setOpenWebInfoModalInIfram(!openWebInfoModalInIfram);
      }}> 
        <img src={Close} alt="Images" className="img-fluid" />
      </Button>
        <ModalBody>
          <Iframe url={currentTagUrl}
            width="100%"
            height="500px"
            id=""
            className=""
            display="block"
            position="relative"
            onError={()=>alert()}

          />
          <div className="" style={{"text-align": "center"}}>

          <label className="form-check-label" style={{"position": "relative", "top": "7px", "text-align": "center"}} htmlFor="check">
          Oops! The URL failed to load in the embedded window. Please click here to open it in a new tab and view the content <NavLink 
              to="#" 
              onClick={(e) => {
                e.preventDefault();
                window.open(currentTagUrl, '_blank');
              }} target="_blank"><u>Click here</u></NavLink>
            </label>

          </div>
          
          {/* <div className="text-center">
            If you want to open in new tab click here <NavLink to={currentTagUrl}><u>Click here</u></NavLink>
          </div> */}
        <div className=""></div>
        </ModalBody>
    </Modal>
  }

  const getTageBasedOnType = ()=>{
    if(currentTagType == 0){
      return;
    }else if(currentTagType == 3){
      return <CarouselModal 
          assests= {[{fIleUrl: currentTagImage}]}
          isOpenCarousel= {isOpenCarousel}
          closeCarousel ={closeCarousel}
          index ={0}
          hasLeftButton ={false}
          hasRightButton ={false}
      />
    }else if(currentTagType == 4){
      // return getWebInfoModal()
    }else if(currentTagType == 5){
      return <CustomVideoPlayer
        video= {{fIleUrl: currentTagVideo}}
        isOpenVidioPlayer= {isOpenCarousel}
        closeVideoPlayr ={closeCarousel}
      />
    }
  }

  const getHotSpot = ()=>{
    return (
      fetchedHotSpot.map(hs=>{
        return  <div key={hs.uniqueTimestamp} class={getActiveClass(hs)} onClick={async()=>{

            if(hs.tagType == 6){
              if(hs.assetID !== currentExperience.experienceID){
                virtualTourExperience.tourImages.forEach((img)=>{
                  if(img.experienceID === hs.assetID){
                    updateVierwer(img);
                    setIsPanoramaLodedFirstTime(false);
                    // isPanoramaLodedFirstTime= false;
                  }
                })
              }
              return;
            }

            viewer.lookAt(parseInt(hs.pitchValue), parseInt(hs.pitchYaw));
            setActiveTagID(hs.mainDBid);
            setTimeout(() => {
              editTag(hs);
            }, 700);
          }}
          >
          <img src={getRightDrawerIcon(hs.tagType)} class="tagImage" alt="back icon" />
          <p>{hs.tagName}</p>
        </div>
      })
    )
  }
  
  const getActiveClass = (hs)=>{
    if(hs.mainDBid === activeTagID){
      return "tagitem active"
    }else{
      return "tagitem"
    }
  }

  const getIcon = (tagType)=>{
    switch (tagType) {
      case 0:
        return "hotspot-location";
      case 1:
        return "hotspot-audio";
      case 2:
      case 5:
        return "hotspot-video";
      case 3:
        return "hotspot-image";
      case 4:
        return "hotspot-url";
      case 6:
        return "add-hot-spot";
    }
  }

  const getRightDrawerIcon = (tagType)=>{
    switch (tagType) {
      case 0:
        return Location;
      case 1:
        return Audio;
      case 2:
      case 5:
        return Video;
      case 3:
        return Image;
      case 4:
        return Web;
      case 6:
        return HS;
    }
  }

  const handlerIsEnableDrawer = ()=>{
    setIsEnableDrawer(!isEnableDrawer);
    setIsActEnableDrawer(!isActEnableDrawer);
  }
  
  const resetTag = ()=>{
    
    localStorage.removeItem('isEditableTagData');
    localStorage.removeItem('isNewAddedTagData');
    
    setCurrentTagType("0");
    setCurrentTagUrl("");
    setCurrentTagImage("")
    setCurrentTagVideo("")
    setCurrentTag({})
    
    setIsEnableDrawer(false);
    setIsActEnableDrawer(false);
    setFetchedHotSpot([]);
  }

  const handlerToUploadAndEditTag = (createTooltipArgs)=>{

    setCurrentTagType(createTooltipArgs.tagType);

    if(createTooltipArgs.tagType == 4){
      setCurrentTagUrl(createTooltipArgs.url);
      setOpenWebInfoModalInIfram(true);
      setCurrentTagImage("")
      setCurrentTagVideo("")
      window.open(createTooltipArgs.url, '_blank');
    }else if(createTooltipArgs.tagType == 3){
      setCurrentTagImage(createTooltipArgs.filePath)

      setCurrentTagUrl("");
      setCurrentTagVideo("")
    }else if(createTooltipArgs.tagType == 5){
      setCurrentTagVideo(createTooltipArgs.url)

      setCurrentTagUrl("");
      setCurrentTagImage("")
    }else if(createTooltipArgs.tagType == 0){
      setCurrentTagVideo("")
      setCurrentTagUrl("");
      setCurrentTagImage("")
    }
    
    setCurrentTag(createTooltipArgs);
    setIsEnableDrawer(false);
    setIsActEnableDrawer(true);
   
    setIsOpenCarousel(!isOpenCarousel);

  }

  const getTourImgListView= ()=>{   
    return virtualTourExperience.tourImages.map((img)=>{
      return <div className={`tour-item ${currentExperience.thumbnailUrl === img.thumbnailUrl && "active"}`} onClick={()=>{
        setOpenLeftExperienceDrawer(!openLeftExperienceDrawer);
        if(String(currentExperience.experienceID) !== String(img.experienceID)){
          updateVierwer(img);
          setIsPanoramaLodedFirstTime(false);
          // isPanoramaLodedFirstTime= false;
        }
      }}> 
        {img.roomName}
      </div>
    })
  }

  const updateVierwer= async (img)=>{

    viewer.loadScene(String(img.experienceID));
    setCurrentExperience(img);
    resetTag();
    viewer.stopAutoRotate();
    setIsViewerAutoRotate(false)
    setIsOrientationStartByDefault(true);
  }

  const getConfimViewToResumeAndCancel = ()=>{
      
    return <Modal isOpen={isTourInfoModalShow} centered className="drop-modal" toggle={()=>{
        setIsTourInfoModalShow(!isTourInfoModalShow);
        }} >
        <Button className="modalClose" onClick={()=>{
          setIsTourInfoModalShow(!isTourInfoModalShow);
        }}>
            <img src={Close} alt="Images" className="img-fluid" />
        </Button>
        <ModalBody className="px-0">
            <div className="share_people text-center">
                <div class="row">
                    <div className="col-12">
                      <h4 className="text-primary">{virtualTourExperience.tourName}</h4>
                      
                      {virtualTourExperience.tourDescription !== "" && <><hr/>
                      <div className="px-3">
                        <h6>Tour Description </h6>
                        <div style={{"font-size": "13px"}}>{virtualTourExperience.tourDescription}</div>
                      </div>
                      </>
                      }
                      <hr/>

                      <div className="px-3" style={{"position": "relative", "top": "-9px"}}>
                        <div>{virtualTourExperience.ownerFirstName} {virtualTourExperience.ownerLastName}</div>
                        <div style={{"font-size": "11px"}}>{virtualTourExperience.ownerEmail}</div>
                      </div>
                      {virtualTourExperience.tourLogo &&<img src={virtualTourExperience.tourLogo} alt="Upload" style={{ "height": "50px", "width": "aut0","object-fit": "cover", borderRadius: "10px"}} />}      
                    </div>
                </div>
            </div> 
        </ModalBody>
    </Modal>
  }

  if(!virtualTourExperience.subscriptionStatus){
  // if(true){
    return <ExpiredView />
  }

  const getGyroscopeButtonView = ()=>{
    if(shouldShowGSCallowButton){
      return<img 
          className="gyroscope"
          onClick={()=>{
            requestOrientation()
          }}
          src={gyroscopeCloseIcon}
        />
    }else if(showGSCplayPaushButton){
      return <img 
            className="gyroscope"
            onClick={()=>{
              if(isOrientationStartByDefault && viewer!==null){
                viewer.stopOrientation();
                setIsOrientationStartByDefault(false);
              }else{
                viewer.startOrientation();
                setIsOrientationStartByDefault(true);

                viewer.stopAutoRotate();
                setIsViewerAutoRotate(false)
              }
            }}
            src={!isOrientationStartByDefault ?gyroscopeCloseIcon: gyroscopeOpenIcon}
        />
    }

    if(
        osName === "Android" &&
        deviceType === "mobile"){
        return <img 
              className="gyroscope"
              onClick={()=>{
                if(isOrientationStartByDefault && viewer!==null){
                  viewer.stopOrientation()
                  setIsOrientationStartByDefault(false);
                }else{
                  viewer.startOrientation()
                  setIsOrientationStartByDefault(true);
                  viewer.stopAutoRotate();
                  setIsViewerAutoRotate(false)
                }
              }}
              src={!isOrientationStartByDefault ?gyroscopeCloseIcon: gyroscopeOpenIcon}
        />
    }
  }
  
  return (
    <React.Fragment>
      {showTagInfo()}
      {getConfimViewToResumeAndCancel()}
      <div className={`virtual-tour-wrap ${isActEnableDrawer && 'act'}`}>
        {isParonamaIMGloaded !=0 && <div className={`virtual-tour-drawer ${isEnableDrawer && 'actDrawer'}`}>
          <div className="collapseBtn">
              <button className="btn btn-link menu-btn" 
                onClick={()=>{
                  handlerIsEnableDrawer();
                }}
              > {!isEnableDrawer ?
                  <img src={Collapse} alt="collapse icon" />
                :
                  <img src={Expand} alt="collapse icon" />
                }
                </button>
          </div>

          <div class="tour-drawer-inner custom-scrollbar">
            <div class="accordion accordion-flush" id="accordionFlushExample">
              <div class="accordion-item">
                <h2 class="accordion-header" id="tags">
                  <button class={`accordion-button ${!isEnableDrawerItem && 'collapsed'}`} type="button" 
                  onClick={()=>{
                    setIsEnableDrawerItem(!isEnableDrawerItem);
                  }}
                  >
                    Tags
                  </button>
                </h2>
                <div id="flush-collapseOne" class={`accordion-collapse collapse ${isEnableDrawerItem && 'show'}`}>
                  <div class="accordion-body">
                    {fetchedHotSpot.length === 0 ?
                    <p class="no-cnt">No tags found</p> 
                    : 
                    <div class="taglist">
                      {getHotSpot()}
                    </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>}
        <div className="panorama-wrap">
          <div className="panorama-wrap">

            <nav class="upper-menu" style={{
                  "top": "20px"
              }}>         
              {virtualTourExperience.tourLogo && <li >
                <img src={virtualTourExperience.tourLogo} alt="menu icon"  />
              </li>}

              <li ><div >{virtualTourExperience.tourName}</div></li>

              <li onClick={()=>setIsTourInfoModalShow(!isTourInfoModalShow)} style={{
                "cursor": "pointer"
                // "left": "-9px"
              }}>
                <img src={Info} alt="menu icon" />
              </li>
            </nav>
            
            {!openLeftExperienceDrawer && <button className="btn btn-link menu-btn" style={{left: 0}}>
              <img src={MenuImg} alt="menu icon" onClick={() => setOpenLeftExperienceDrawer(!openLeftExperienceDrawer)} className="img-fluid" />
            </button>}
          
            <div
                className="panorama"
                ref={panoramaRef}
                style={{height: "calc(100vh - 0px)", width: "100%", minHeight: "230px"}}
                onClick={()=>setIsViewerAutoRotate(false)}
            >
            </div>
          </div>

          <div className={`tour-menu-list ${openLeftExperienceDrawer && 'open'}` }>
            <button className="btn btn-link menu-btn" style={{left: 0}}>
                <img src={MenuClose} alt="menu icon" onClick={() => setOpenLeftExperienceDrawer(false)} className="img-fluid" />
              </button>
              {/* FOR PAGINATION  START*/}
                {/* {getViewrVirtourImagesList()}  */}
              {/* FOR PAGINATION  END*/}
              <div className="" style={{
                  "height": "100%",
                  "display": "flex",
                  "align-items": "center"
              }}>
                <div className="tour-lists">
                  {getTourImgListView()}
                </div>
              </div>
          </div>
        
        </div>
        {/* <Button 
            className="btn btn-primary "
        > */}
          <div 
            style={{
             
            }}
            className="viewer-controller-container"   
          >
            <img 
              style={{
                  "height": "40px",
                }} 
              onClick={()=>{
                  if(viewer!==null){
                    if(isViewerAutoRotate){
                      viewer.stopAutoRotate();
                      setIsViewerAutoRotate(false)
                    }else{
                      viewer.startAutoRotate(-7);
                      setIsViewerAutoRotate(true)

                      viewer.stopOrientation();
                      setIsOrientationStartByDefault(false);
                    }
                  }
              }}
              src={!isViewerAutoRotate? playAutorotate: pauseAutorotate} 
            /><br/>
            {getGyroscopeButtonView()}
          </div>
      </div>

    </React.Fragment>
  );

}

export default PenneliumReactOne;