import { createSlice } from '@reduxjs/toolkit'
import { getData, postData } from '../../../UTILITYS/index.tsx';
import * as API from '../../../APIs'
import { toast } from 'react-toastify';
import { getUploadedLogo } from '../../../UTILITYS/endPoints.js';
import TourLogo from './../../../ASSETS/Images/icons/tour-logo.png';
import { base64toBlob } from '../../../UTILITYS/Helper.js';

const initialState = {
  tour: [],
  setHasMoreVirtualTour: true,
  isVirtualTourResGet: false,
}  

export const virtualTour = createSlice({
  name: 'virtualTour',
  initialState: { ...initialState }, 
  reducers: { 
  
    // REDUCERS FOR SET VIRTUAL-TOUR
    setVirtualtour: (state, action) => {
      console.log('this setVirtualtour userss', action)
      return {
        ...state,
        tour: [...state.tour, ...action.payload.pagedList]
      }
    },
    resetVirtualTour: ()=>{
        return {...initialState}
    },
    setHasMoreVirtualTour: (state, action)=>{
        return {
            ...state,
            setHasMoreVirtualTour: action.payload
        }
    },
    setUpdateVirtualTour: (state, action) => {

      return {
        ...state,
        tour: [...state.tour.map(image =>{
          if(image.id === action.payload.id)
            return {
              ...action.payload,
              name: action.payload.name,
              thumbnailUrl: image.thumbnailUrl,
              tourLogo: action.payload.logo
            }
            return image;
        })]
      }
    },
    setResponseForVirtualTour: (state, action) => {
      console.log('this is res of i sssf ff', action)
      return {
        ...state,    
        [action.payload.key]: action.payload.value
        }
    },
  }
}) 

// Action creators are generated for each case reducer function
export const { setVirtualtour,
    resetVirtualTour,
    setHasMoreVirtualTour,
    setUpdateVirtualTour,
    setResponseForVirtualTour
   } = virtualTour.actions

// TO FETCH VIRTUAL-TOUR
const fetchVirtualTours =async (token, dispatch, pageIndex, pageSize, requestForSelectBox =0) =>{

    try{

      if(requestForSelectBox != undefined && requestForSelectBox === "requestForSelectBox")
        requestForSelectBox= 1

      let url= `${API.BASE_URL+API.GET_VIRTUAL_TOUR}?pageindex=${pageIndex}&pagesize=${pageSize}&relationshipid=0&requestForSelectBox=${requestForSelectBox}`
     
      let headerConfig = {
        "Content-Type": "application/json",
        "Authorization": "Bearer " +token
      }

      const getVirtualTour =await getData({url, headerConfig}, dispatch)

      console.log("this is a getVirtualTour try", getVirtualTour)
    
    return getVirtualTour;
    
  }catch (err){

    throw (err)
  }

}

// ACTION-DISPATURE TO GET VIRTUAL-TOUR
export const fetchVirtualTourDispatch = (paegIndex, pageSize, requestForSelectBox) => {

  return async(dispatch, getState) => {
    
    if(getState().localSessionDetails.payload === "") return;

    try{
        let token = getState().localSessionDetails.payload.accessToken;

        const resVirtualTour =await fetchVirtualTours(token, dispatch, paegIndex, pageSize, requestForSelectBox)
        
        if(resVirtualTour.result.payload.pagedList.length === 0){
          dispatch(setHasMoreVirtualTour(false))
        }
        
        dispatch(setVirtualtour(resVirtualTour.result.payload))

        requestForSelectBox === undefined && dispatch(setResponseForVirtualTour({ key: "isVirtualTourResGet", value: true}));
       
      }catch (err){

        if(err!= undefined && err.isError){
           
        }
    }
  }
}

const updateVirtualTour =async (token, dispatch, data) =>{

  try{

    // let url= `${API.BASE_URL+API.GENERATE_PIN}`
    let url= `${API.BASE_URL+API.SAVE_VIRTUAL_TOUR}`
   
    let headerConfig = {
      "Content-Type": "application/json",
      "Authorization": "Bearer " +token
    }

    let dataObje= {
      ...data
    }

    const fetchUpdatedVirtualTour =await postData({url, headerConfig, dataObje}, dispatch)
  
  return fetchUpdatedVirtualTour;
  
  }catch (err){

    if(err!=undefined && err.isError!= undefined && err.isError) toast.error(err.responseException.exceptionMessage, { });
  }

}

export const updateVirtualTourDispatch = (data, logoData) => {

  return async(dispatch, getState) => {
    
    if(getState().localSessionDetails.payload === "") return;

    try{
        let token = getState().localSessionDetails.payload.accessToken;

        const resVirtualTour =await updateVirtualTour(token, dispatch, data);

        let logo= "";
        
        if(logoData.imgURLOnGoingCropping !== logoData.savedImgUrl){
          console.log("logo= logoData 3");
          logo= logoData.imgURLOnGoingCropping;
          await getUploadedLogo(logoData, dispatch, token);
        }else{
          console.log("logo= logoData 4");
          logo= logoData.savedImgUrl;
        }
        
        if(resVirtualTour.result != undefined && resVirtualTour.result.code === 200){

          dispatch(setUpdateVirtualTour({...resVirtualTour.result.payload[0], logo}))

          toast("Tour updated Successfully.");
        }
       
      }catch (err){

        if(err!= undefined && err.isError){
           
        }
    }
  }
}

export default virtualTour.reducer 