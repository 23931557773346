import { createSlice } from '@reduxjs/toolkit'
import { VIEWER_TOUR_BASE_URL, VIEWER_TOUR_IMAGES, X_FUNCTION_KEY } from '../../APIs';
import { getData } from '../../UTILITYS';

const initialState = {
  tourImages: [],
  setHasMoreViewerVirtualTourImages: true,
  isViewerVirtualTourImagesResGet: false,
  tourId: "",
  tourName: "",
  subscriptionStatus: 2,
  isResGet: false
}  

export const viewerVirtualTourImages = createSlice({
  name: 'viewerVirtualTourImages',
  initialState: { ...initialState }, 
  reducers: { 
  
    // REDUCERS FOR SET VIRTUAL-TOUR
    setViewerVirtualtourImages: (state, action) => {      
      return {
        ...state,
        tourImages: [...state.tourImages, ...action.payload.pagedList],
        tourId: action.payload.tourId,
        tourName: action.payload.tourName,
        subscriptionStatus: action.payload.subscriptionStatus,
        isResGet: true,
        ...action.payload,
      }
    },
    resetViewerVirtualTourImages: ()=>{
        return {...initialState}
    },
    setHasMoreViewerVirtualTourImages: (state, action)=>{
        return {
            ...state,
            setHasMoreViewerVirtualTourImages: action.payload
        }
    },
    setResponseForViewerVirtualTourImages: (state, action) => {
      return {
        ...state,    
        [action.payload.key]: action.payload.value
        }
    },
  }
}) 


export const { setViewerVirtualtourImages,
  resetViewerVirtualTourImages,
  setHasMoreViewerVirtualTourImages,
  setResponseForViewerVirtualTourImages
} = viewerVirtualTourImages.actions


const fetchViewerVirtualToursImages =async (dispatch, tourURLToken) =>{

    try{

      // https://xrtoursdevfunc.azurewebsites.net/api/GetTourData?TourURLToken=0b6c5e5e0f43450ca48d749b27ede26e0aea082d75f2488c986d19918d2afc5e
      let url= `${VIEWER_TOUR_BASE_URL+VIEWER_TOUR_IMAGES}TourURLToken=${tourURLToken}`
     
      let headerConfig = {
        "Content-Type": "application/json",
        "x-functions-key": X_FUNCTION_KEY
      }

      const getViewerVirtualTourImages =await getData({url, headerConfig}, dispatch)

      console.log("this is a getViewerVirtualTourImages try", getViewerVirtualTourImages)
    
    return getViewerVirtualTourImages;
    
  }catch (err){

    console.log("this is a getViewerVirtualTourImages catch");
    throw (err)
  }

}
 
export const fetchViewerVirtualTourImagesDispatch = (tourURLToken) => {
  return async(dispatch, getState) => {
    
    try{
      const resViewerVirtualTourImages =await fetchViewerVirtualToursImages(dispatch, tourURLToken)
      
      if(resViewerVirtualTourImages &&resViewerVirtualTourImages.result&& resViewerVirtualTourImages.result.payload&& resViewerVirtualTourImages.result.payload.pagedList && resViewerVirtualTourImages.result.payload.pagedList.length === 0){
        dispatch(setHasMoreViewerVirtualTourImages(false))
      }
      dispatch(setViewerVirtualtourImages(resViewerVirtualTourImages.result.payload))
      
      dispatch(setResponseForViewerVirtualTourImages({ key: "isViewerVirtualTourImagesResGet", value: true}));
      
    }catch (err){
      
        if(err!= undefined && err.isError){
           
        }
    }
  }
}

export default viewerVirtualTourImages.reducer 