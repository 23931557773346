import React, { useEffect, useState } from "react";
import Edit from './../../ASSETS/Images/xr/edit1.png';
import Share from './../../ASSETS/Images/xr/sahre.png';
import More from './../../ASSETS/Images/xr/more.png';
import EmbeddedIcon from './../../ASSETS/Images/icons/embedded-icon.png' 
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Col} from 'reactstrap';
import OutsideClickHandler from "../ClickOutsideHandler";

export default (props)=>{

    const [dropdownOpen, setDropdownOpen] = useState(false);
    
    const toggle = () =>{
        props.onClick()
        setDropdownOpen((prevState) => !prevState);
    } 

    useEffect(()=>{
        if(props.dropDownTour && (props.dropDownTour.id !== props.tour.id)){
            setDropdownOpen(false);
        }
    },[props.dropDownTour]);
    
    return (
        <React.Fragment>
                <Dropdown isOpen={dropdownOpen} direction='bottom' toggle={toggle}>
                    <DropdownToggle caret className="btn btn-link more-btn"  > 
                        <img src={More} alt="More" />
                    </DropdownToggle>
                        <DropdownMenu >
                            <DropdownItem onClick={(e)=>{
                                e.stopPropagation();
                                props.onEdit();
                            }}>
                            <img src={Edit}  className="me-2 me-md-3" alt="Images"  />
                            Edit             
                            </DropdownItem>
                    
                            {props.tour.thumbnailUrl && <DropdownItem onClick={(e)=>{
                                e.stopPropagation();
                                props.onShare();
                                
                            }}>
                            <img src={Share} className="me-2 me-md-3" alt="Images"  />
                            Share Tour         
                            </DropdownItem>}

                            <DropdownItem onClick={props.onEmbedCode}>
                            <img src={EmbeddedIcon}  className="me-2 me-md-3" alt="Images"  />
                            Embed 360 Tour             
                            </DropdownItem>
                            
                        </DropdownMenu>
                </Dropdown>
        </React.Fragment>
    )
}