import React, { useState, useEffect, useContext, useRef } from "react";
import { Button, Modal, ModalBody, FormGroup, Input, Row, Col, ModalFooter, ModalHeader, TabContent, TabPane, Nav, NavItem, NavLink } from'reactstrap';
import classnames from 'classnames';
import Upload from './../../ASSETS/Images/icons/upload-tour.png';
import UploadButton from './../../ASSETS/Images/icons/upload.png';
import TourLogo from './../../ASSETS/Images/icons/tour-logo.png';
import RemoveTour from './../../ASSETS/Images/icons/remove-tour.png';

import { useDispatch, useSelector } from 'react-redux'
import Close from './../../ASSETS/Images/icons/cancel.png'
import 'react-circular-progressbar/dist/styles.css'; 
import classNames from 'classnames';
import { addVirtualTour, fetchDefaultVTimages, getImgURL, getUploadedLogo } from "../../UTILITYS/endPoints";
import { toast } from 'react-toastify';
import AddAssets from './../../ASSETS/Images/xr/360-light.png'; 
import validator from 'validator';
import { fetchVirtualTourDispatch, resetVirtualTour } from "../../SCREENS/360virtualTour/Slices/VirtualTour.slice";
import CreateTokenContextProvider from "../../STORE/App-wide-state/Token/createTokenContextProvider";
import { default as ReactSelect, components } from "react-select";
import RadioButton from "../RadioButton";
import CarouselModal from "../Galaries/CarouselModal";
import ImagesCardList from "./../../COMPONENTS/AssestsAndList/Images/ImagesCardList";
import UpgradModal from "../Subscription/UpgradModal";
import { ExpiredModal } from "../Modal/Expired";
import { setDoNotShowExpiredModal } from "../../SCREENS/Profile/slice.reducer";
import { loaderDispatch } from "../Loader/slice.reducer";
import ImageCropper from "./../../COMPONENTS/CROP_IMAGE/ImageCropper";

export default (props)=>{
    
    const dispatch = useDispatch();
    const fileInputRef = useRef(null);
    const [modal, setModal] = useState(false);
    const [virtualTourName, setVirtualTourName] = useState('');
    const [virtualTourNameErr, setvirtualTourNameErr] = useState(false) 
    const [defaultVirtualTourName, setdefaultVirtualTourName] = useState('');
    const [defaultVirtualTourNameErr, setdefaultVirtualTourNameErr] = useState(false);

    const [vtDescription, setVTDescription] = useState(""); 
    const [vtDescriptionErr, setVtDescriptionErr] = useState(false); 

    const [defaultVtDescription, setDefaultVtDescription] = useState(""); 
    const [defaultVtDescriptionErr, setsetDefaultVtDescriptionErr] = useState(false); 

    const [vtLogo, setVtLogo] = useState("");
    const [defaultLogo, setDefaultLogo] = useState("");
    const [vtLogoErr, setVtLogoErr] = useState(false);
    const [defaultLogoErr, setDefaultLogoErr] = useState(false);

    const [defaulImgOptions, setDefaulImgOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState();
    const [isVTcreatedByOwn, setIsVTcreatedByOwn] = useState(true);
    const [isOpenCarousel, setIsOpenCarousel] = useState(false); 
    const tokenContext = useContext(CreateTokenContextProvider);
    const [activeTab, setactiveTab] = useState(1); 
    const [openUpgrade, setOpenUpgrade] = useState(false); 
    const userProfileDetails = useSelector(state => state.userProfileDetails)
    const virtualTours = useSelector(state => state.virtualTours);
    const [isDefaulOptionFetched, setIsDefaulOptionFetched] = useState(false);

    const [crpImgModal, setCrpImgModal] = useState(false);
    const [imageToCrop, setImageToCrop] = useState(undefined);
    const [imgURLOnGoingCropping, setImgURLOnGoingCropping] = useState(TourLogo);
    const [choseFile ,setChoseFile] =useState(TourLogo);
    const [key, setKey] = useState(1);

    useEffect(()=>{
        const base64String = TourLogo;
        const blob = base64toBlob(base64String);
        console.log("blobblob", blob);
        setChoseFile(blob);
    },[])

   const togglerr=(tab)=> {
        if (activeTab !== tab) {
        setactiveTab(tab)
        }
    }

    const cratedVTbyOwn = () => {
        setIsVTcreatedByOwn(true);
        if(!isVTcreatedByOwn){
            const base64String = TourLogo;
            const blob = base64toBlob(base64String);
            console.log("blobblob", blob);
            setChoseFile(blob);
            setImgURLOnGoingCropping(TourLogo);
        }
    }

    const cratedVTbyDefault = () => {
        setIsVTcreatedByOwn(false);
        if(isVTcreatedByOwn){
            const base64String = TourLogo;
            const blob = base64toBlob(base64String);
            console.log("blobblob", blob);
            setChoseFile(blob);
            setImgURLOnGoingCropping(TourLogo);
        }
    }

    const closeCarousel = ()=>{ setIsOpenCarousel(false)}

    const toggle = async() => {

        if(!modal && !isDefaulOptionFetched){
            let fetchDefaultVTimg=await fetchDefaultVTimages(tokenContext.accessToken, dispatch);
            setIsDefaulOptionFetched(true);
            if(fetchDefaultVTimg.result.payload.length >0){
                setDefaulImgOptions(fetchDefaultVTimg.result.payload);
                setSelectedOption({...fetchDefaultVTimg.result.payload[0], fIleUrl: fetchDefaultVTimg.result.payload[0].imagePath });
            }
        }

        resetState()
        setModal(!modal);
    }

    const resetState= ()=>{
        setVirtualTourName("");
        setVTDescription("");
        setdefaultVirtualTourName("");
        setDefaultVtDescription("");
        setdefaultVirtualTourNameErr(false);
        setsetDefaultVtDescriptionErr(false);
        setvirtualTourNameErr(false);
        setVtDescriptionErr(false);
        
        const base64String = TourLogo;
        const blob = base64toBlob(base64String);
        console.log("blobblob", blob);
        setChoseFile(blob);

        setImgURLOnGoingCropping(TourLogo);
    }

    const onInputChange = (e)=>{
        switch(e.target.name){
            case "virtual-tour-name":
                setVirtualTourName(e.target.value.replace(/[^a-zA-Z0-9\s]/g, ''));
                setvirtualTourNameErr(false)
            break;
            case "virtual-tour-vtDescription":
                setVTDescription(e.target.value);
                setVtDescriptionErr(false)
            break;
            case "default-virtual-tour-name":
                setdefaultVirtualTourName(e.target.value.replace(/[^a-zA-Z0-9\s]/g, ''));
                setdefaultVirtualTourNameErr(false)
            break;
            case "default-virtual-tour-vtDescription":
                setDefaultVtDescription(e.target.value);
                setsetDefaultVtDescriptionErr(false)
            break;
        }
    }

    const createdRoom= async()=>{
        
        toast.dismiss();
        
        let payload= {}
        
        if( userProfileDetails.isExpired ){
            dispatch(setDoNotShowExpiredModal(false));
            // setOpenUpgrade(true);
            toast("Your subscription has been expired");
            return;
        }

        // if(!userProfileDetails.isPremium && virtualTours.tour.length >0){
        //     setOpenUpgrade(true);
        //     toast.error("Free users are allowed only 1 Virtual Tour.", { });
        //     return;
        // }

        if(isVTcreatedByOwn){
            setdefaultVirtualTourNameErr(false);
            setdefaultVirtualTourName("");
            setDefaultVtDescription("");
            setsetDefaultVtDescriptionErr(false);
            if(validator.isEmpty(virtualTourName.trim())){
                toast.error("Please Enter Virtual Tour Name", { });
                setvirtualTourNameErr(true)
                return;
            }else if(!validator.isLength(virtualTourName.trim(), {min: 1, max: 25})){
                toast.error("Virtual Tour Name length should be minimum 3 char and maximum 25 char", { });
                setvirtualTourNameErr(true) 
                return;
            }
            payload.name= virtualTourName;
            payload.TourDescription= vtDescription;
        }else{
            setvirtualTourNameErr(false);
            setVirtualTourName("");
            setVTDescription("");
            setVtDescriptionErr(false);
            if(validator.isEmpty(defaultVirtualTourName.trim())){
                toast.error("Please Enter Virtual Tour Name", { });
                setdefaultVirtualTourNameErr(true)
                return;
            }else if(!validator.isLength(defaultVirtualTourName.trim(), {min: 1, max: 25})){
                toast.error("Virtual Tour Name length should be minimum 3 char and maximum 25 char", { });
                setdefaultVirtualTourNameErr(true) 
                return;
            }
            let splitImagePath= selectedOption.imagePath.split('/');
            payload.name = defaultVirtualTourName;
            payload.imageTitle = selectedOption.imageName;
            payload.TourDescription = defaultVtDescription;
            payload.Default360ImagNames = splitImagePath[splitImagePath.length-1];
        }

        let mappingData = payload;

        try{
            const resAddVirtualTour = await addVirtualTour(mappingData, dispatch, tokenContext.accessToken); 
            await uploadProfilePic(resAddVirtualTour.result.payload[0]);
           
            toast("Virtual Tour Created Successfully");
            dispatch(resetVirtualTour());
            dispatch(fetchVirtualTourDispatch(1, 25));
            toggle();
            setChoseFile(TourLogo);
            setImgURLOnGoingCropping(TourLogo);
        }catch (err){
            if(err!=undefined && err.isError!= undefined && err.isError) toast.error(err.responseException.exceptionMessage, { });
        }

    }

    const onUploadFile = async (event) => {
        console.log("crpImgModalcrpImgModal", event)
        if (event.target.files && event.target.files.length > 0) {
            
            console.log("crpImgModalcrpImgModal 2", crpImgModal)
            const reader = new FileReader();
    
            reader.addEventListener("load", () => {
                const image = reader.result;
                setImageToCrop(image);
            });
        
            reader.readAsDataURL(event.target.files[0]);

            dispatch(loaderDispatch(true))

            setTimeout(() => {
                setCrpImgModal(!crpImgModal)
                dispatch(loaderDispatch(false))
            }, 500);
        
        }
    };

    const onCancelCropImg =()=>{
        setChoseFile(TourLogo);
        setImgURLOnGoingCropping(TourLogo);
        setCrpImgModal(!crpImgModal);
        fileInputRef.current.value = null;
        setKey((prevKey) => prevKey + 1);

    }

    async function uploadProfilePic(resAddVirtualTour){

        // console.log("imgURLOnGoingCroppingimgURLOnGoingCropping", resAddVirtualTour);

        try{
            let data ={
                file: choseFile,
                tourId: resAddVirtualTour.id,
                // tourId: parseInt(150),
                // file: file
            }
        
            const fileURL =await getUploadedLogo(data, dispatch, tokenContext.accessToken);
          
            return fileURL;
        }catch (err){

        }
    } 

    const base64toBlob = (data) => {
        const base64WithoutPrefix = data.replace(/^data:image\/\w+;base64,/, "");
        const bytes = atob(base64WithoutPrefix);
        let length = bytes.length;
        let out = new Uint8Array(length);
        while (length--) {
          out[length] = bytes.charCodeAt(length);
        }
        return new Blob([out], { type: "image/jpeg" });
      };

    return (
        <React.Fragment>   
            {/* ADD VIRTUAL-TOUR */}
            <div className="addAssets">
                <button className="btn btn-primary" 
                
                onClick={()=>{
                    toast.dismiss()
                    if(userProfileDetails.isExpired ){
                        dispatch(setDoNotShowExpiredModal(false));
                        // setOpenUpgrade(true);
                        toast("Your subscription has been expired");
                        return;
                    }
                    // else if(!userProfileDetails.isPremium && virtualTours.tour.length >0
                    //     ){
                    //     setOpenUpgrade(true);
                    //     toast.error("Free users are allowed only 1 Virtual Tour.", { });
                    //     return;
                    // }
                    else{
                        toggle()
                    }
                }}

                ><img src={AddAssets} alt="add" className="me-2" 
                />Add 360° Tour</button>
            </div>

             {/* ADD VIRTUAL-TOUR MODAL */}
            <Modal isOpen={modal} centered className="drop-modal modal-md virtualMdl" toggle={()=>{
                toggle()
                }} {...props}>
                <h4 className="text-primary text-center mt-4 .margin-bottom_-05rem">Create 360 Tour</h4>
                <ModalBody>
                    <Nav tabs className="create-virtul-tour-nav">
                        <NavItem className="create-virtul-tour-nav-itme">
                            <NavLink
                            className={classnames({ active: activeTab === 1 })}
                            onClick={() => {
                                togglerr(1);
                                cratedVTbyOwn();
                            }}
                            >
                            Start Fresh
                            </NavLink>
                        </NavItem>
                        <NavItem className="create-virtul-tour-nav-itme">
                            <NavLink
                            className={classnames({ active: activeTab === 2 })}
                            onClick={() => {
                                togglerr(2);
                                cratedVTbyDefault();
                            }}
                            >
                            Start with Default 360 Tour
                            </NavLink>
                        </NavItem>
                    </Nav>

                    <TabContent activeTab={activeTab}>
                        <TabPane tabId={1}>
                            <div className="p-3">
                                {isVTcreatedByOwn && <div class="row">
                                    <div className="col-12">
                                        <label className="mb-1">Tour Name</label>
                                        <FormGroup>
                                            <input value={virtualTourName} type="text" placeholder="Enter tour name"
                                            className={classNames("form-control", { "form-error": virtualTourNameErr })} name={'virtual-tour-name'} onChange={onInputChange}
                                            />
                                        </FormGroup> 
                                    </div>
                                    
                                    <div className=" col-12">
                                        <label className="mb-1">Tour Description</label>
                                        <FormGroup>
                                            <textarea rows="3" value={vtDescription} type="text" placeholder="Enter tour description"
                                            className={classNames("form-control", { "form-error": vtDescriptionErr })} name={'virtual-tour-vtDescription'} onChange={onInputChange}
                                            />
                                        </FormGroup> 
                                    </div>

                                    <div className=" col-12">
                                        <label className="mb-1">Tour Logo</label>
                                        <FormGroup>
                                            
                                            <span className="ms-2" color="primary" >
                                                <img src={!isVTcreatedByOwn? TourLogo :imgURLOnGoingCropping} alt="Upload" style={{
                                                    "height": "60px", 
                                                    "width": "120px",
                                                    "margin": "2px",
                                                    "border-radius": "12px"
                                                }} 
                                                />  
                                            </span>

                                            <span className="ms-2 vrirual-tour-upload-logo-button" color="primary" >
                                                <input 
                                                    key={key}
                                                    type="file" 
                                                    accept="image/*" 
                                                    name="defaultVtLogo"
                                                    ref={fileInputRef}
                                                    onChange={(e)=>{
                                                        try{
                                                            e.stopPropagation()
                                                            onUploadFile(e)
                                                        }catch(err){
                                                        }
                                                    }} 
                                                />
                                                <img src={Upload} alt="Upload" />  
                                            </span>

                                            <span className="ms-2 vrirual-tour-upload-logo-button" color="primary" 
                                                onClick={()=>{
                                                    const base64String = TourLogo;
                                                    const blob = base64toBlob(base64String);
                                                    console.log("blobblob", blob);
                                                    setChoseFile(blob);
                                                    setImgURLOnGoingCropping(TourLogo);
                                                }}   
                                            >
                                                <img src={RemoveTour} alt="Upload" />  
                                            </span>
                                        </FormGroup>
                                    </div>
                                    <div className="small mt-1">
                                        <ul className="creative-list mb-0">
                                            <li>Create a 360 Tour with just a name now.</li>
                                            <li>Add 360 Experence later when you are ready.</li>
                                        </ul>
                                    </div>
                                </div>}
                                {/* <div className="row">
                                    <div className="col-12 mt-4">
                                        <button 
                                            className="btn btn-primary btn-long"
                                            onClick={createdRoom}
                                        >Create</button>
                                        <button 
                                            className="btn btn-primary btn-cancel btn-long ms-4"
                                            onClick={toggle}
                                        >Cancel</button>
                                    </div>
                                </div> */}
                            </div>
                        </TabPane>
                        <TabPane tabId={2}>
                            <div className="p-3">
                                {!isVTcreatedByOwn && <div className="x-hidden"><Row className="row">
                                    {/* <Col className="col-12">
                                        <label className="mb-1">Select a default Virtual Tour</label> 
                                    </Col> */}
                                </Row>
                                <Row>{<React.Fragment><div>
                                        {defaulImgOptions.map(defaulImges=>{
                                        return (

                                            <React.Fragment>
                                                <label className="mb-1">Tour Name</label>
                                                <FormGroup>
                                                    <input value={defaultVirtualTourName} type="text" placeholder="Enter tour name"
                                                    className={classNames("form-control", { "form-error": defaultVirtualTourNameErr })} name={'default-virtual-tour-name'} onChange={onInputChange}
                                                    />
                                                </FormGroup>

                                                <div className=" p-0">
                                                    <label className="mb-1">Tour Description</label>
                                                    <FormGroup>
                                                        <textarea rows="3" value={defaultVtDescription} type="text" placeholder="Enter tour description"
                                                        className={classNames("form-control", { "form-error": defaultVtDescriptionErr })} name={'default-virtual-tour-vtDescription'} onChange={onInputChange}
                                                        />
                                                    </FormGroup> 
                                                </div>

                                                <div className=" p-0">
                                                    <label className="mb-1">Tour Logo</label>
                                                    <FormGroup>
                                                        <span className="ms-2" color="primary" >
                                                            <img src={!isVTcreatedByOwn? imgURLOnGoingCropping :TourLogo} alt="Upload" style={{ "height": "60px", "width": "120px", 
                                                        "border-radius": "12px"}} />  
                                                        </span>

                                                        <span className="ms-2 vrirual-tour-upload-logo-button" color="primary" >
                                                            <input 
                                                                key={key}
                                                                type="file" 
                                                                accept="image/*" 
                                                                name="defaultVtLogo"
                                                                ref={fileInputRef}
                                                                onChange={(e)=>{
                                                                    try{
                                                                        e.stopPropagation()
                                                                        onUploadFile(e)
                                                                    }catch(err){
                                                                    }
                                                                }} 
                                                            />
                                                            <img src={Upload} alt="Upload" />  
                                                        </span>

                                                        <span className="ms-2 vrirual-tour-upload-logo-button" color="primary" 
                                                        onClick={()=>{
                                                        const base64String = TourLogo;
                                                        const blob = base64toBlob(base64String);
                                                        console.log("blobblob", blob);
                                                        setChoseFile(blob);
                                                        setImgURLOnGoingCropping(TourLogo);
                                                        }}   
                                                        >
                                                            <img src={RemoveTour} alt="Upload" />  
                                                        </span>
                                                    </FormGroup>
                                                </div>

                                                <div className="mb-2 curved-image-wrap">
                                                    <img src={defaulImges.imageThumbnailPath} alt="image" className="tour_image"               
                                                    onClick={()=>{
                                                        // setIsOpenCarousel(true)
                                                        // handleOptionChange(parseInt(defaulImges.id));    
                                                    }}/>
                                                </div>

                                            </React.Fragment>
                                        )
                                    })}
                                    </div>

                                    
                                    <div className="small mt-1">
                                        <p>Not having 360 Assets with you? Don't worry we have got you covered.</p>
                                        <ul className="creative-list mb-0">
                                        <li>Start with Default 360 Tour.</li>
                                        <li>Add Your customized 360 Experience later.</li>
                                        </ul>
                                    </div></React.Fragment>}
                                
                                </Row>
                                </div>}

                                
                            </div>
                        </TabPane>
                    </TabContent >
                   
                </ModalBody>

                <ModalFooter className="justify-content-center">
                    <button 
                        className="mb-2 btn btn-primary"
                        onClick={createdRoom}
                    >Create</button>
                    <button 
                        className="mb-2 btn btn-primary btn-cancel ms-4"
                        onClick={toggle}
                    >Cancel
                    </button>
                </ModalFooter>
            </Modal>

            {/* Modal to be upgraded  */}
            {/* <UpgradModal
                isOpen= {openUpgrade} 
                setOpenUpgrade ={setOpenUpgrade}
            /> */}

            <ExpiredModal />

            {selectedOption!==undefined && <CarouselModal 
                assests= {[selectedOption]}
                isOpenCarousel= {isOpenCarousel}
                closeCarousel ={closeCarousel}
                index ={0}
                hasLeftButton ={true}
                hasRightButton ={true}
            /> }
            
            <Modal isOpen={crpImgModal} centered className="modal-lg medium outside-close-modal" toggle={onCancelCropImg} >
                <button onClick={onCancelCropImg}
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                ></button>
                <ModalHeader className="text-center">Crop Image</ModalHeader>
                <ModalBody className="mb-0 text-center">
                <ImageCropper
                    imageToCrop={imageToCrop}
                    onImageCropped={(croppedImageBlob, croppedImageBase64URL, stop=null) =>{
                        setChoseFile(croppedImageBlob);
                        setImgURLOnGoingCropping(croppedImageBase64URL);
                    }}
                    // minWidth= {120}
                    // minHeight= {60}
                    minWidthDataURL= {120}
                    minHeightDataURL= {60}
                    minWidthBlob= {120}
                    minHeightBlob= {60}
                    initialAspectRatio= {2/1}
                    viewMode= {0}
                />
                </ModalBody>
                <label className="mb-1 upload-logo-restriction" style={{
                    // "font-size": "11px",
                    // "position": "absolute",
                    // "bottom": "70px",
                    // "left": "91px",
                }}>
                Kindly note that your Logo will appear within 120x60 canvas in the 360 viewer
                </label>
                <ModalFooter className="justify-content-center">
                    <Button className="me-2 btn " color="primary" onClick={()=>{
                        setCrpImgModal(!crpImgModal);
                        fileInputRef.current.value = null;
                        setKey((prevKey) => prevKey + 1);
                    }}>
                        <img src={UploadButton} alt="Upload" />Upload
                    </Button>
                    <Button className="ms-3 text-dark btn-me-cancel" color="light" onClick={onCancelCropImg}>
                        Cancel
                    </Button>
                    
                </ModalFooter>
            </Modal>
            
        </React.Fragment>
    )
}
