import React, { useEffect, useState } from "react";
import Edit from './../../ASSETS/Images/xr/edit1.png';
import Delete from './../../ASSETS/Images/xr/trash.png';
import More from './../../ASSETS/Images/xr/more.png';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Col} from 'reactstrap';

export default (props)=>{

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () =>{
        props.onClick()
        setDropdownOpen((prevState) => !prevState);
    } 

    useEffect(()=>{
        if(props.dropDownTourImages && (props.dropDownTourImages.id !== props.img.id)){
            setDropdownOpen(false);
        }
    },[props.dropDownTourImages]);
    
    return (
        <React.Fragment>
                <Dropdown isOpen={dropdownOpen} direction='bottom' toggle={toggle}>
                    <DropdownToggle caret className="btn btn-link more-btn" > 
                        <img src={More} alt="More" />
                    </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem onClick={()=>{props.onUpdate(props.img)}}>
                            <img src={Edit}  className="me-2 me-md-3" alt="Images"  />
                            Edit             
                        </DropdownItem>
                        {props.shouldShowDeleteButton && <DropdownItem className="text-danger" onClick={()=>{props.onDelete(props.img)}}>
                            <img src={Delete} className="me-2 me-md-3" alt="Images"  />
                            Delete           
                        </DropdownItem>}
                        
                    </DropdownMenu>
                </Dropdown>
        </React.Fragment>
    )
}