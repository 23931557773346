import React, { useEffect, useState } from "react";
import './index.css';
import { NavLink, useSearchParams } from "react-router-dom";
import Container from './../Authentications/index.container';
import Logo from './../../ASSETS/Images/xr/logo.png';
import loginBackground from './../../ASSETS/Images/xr/loginbg.jpg';
import verifiedEmail from './../../ASSETS/Images/xr/send.png';
import { verifyEmail } from "../../UTILITYS/endPoints";
import { useDispatch } from 'react-redux'

function Congratulation() {

  const dispatch = useDispatch()
  let [searchParams] = useSearchParams();

  const [token] = React.useState(
      searchParams.get("token")
  );
  const [email] = React.useState(
      searchParams.get("email")
  );

  useEffect(()=>{
    window.scrollTo(0, 0);
    async function verifyEmailHandler(){
      try{
        console.log('this is try verifyEmailHandler', "try")
        await verifyEmail({token, email}, dispatch);
      }catch (err){

      }
    }
    verifyEmailHandler()

  }, [])

    return (
      <React.Fragment>
        <Container>  
          <div className="text-center mb-3 mt-3">
            <img src={Logo} alt="Logo" className="mb-4 pb-2" />
            
            <div className="email-verification pb-3">
              <img src={verifiedEmail} alt="Logo" className="mb-3 pb-1" /> 
                <h5>Congratulations, your account has been verified.</h5>
                <div className="mt-4 pt-2">
                  <NavLink className="btn btn-primary w-100" to="/login">Login</NavLink>
                </div>
              </div>
            </div>
        </Container> 
      </React.Fragment>
    );
  }
  
  export default Congratulation;