import React, { useEffect } from "react";
import Dashboard from "../Dashboard";
import { useDispatch } from 'react-redux';
import { setDoNotShowExpiredModal } from "../Profile/slice.reducer";

export default (props)=>{
    
    const dispatch = useDispatch();
    useEffect(()=>{
        window.scrollTo(0, 0);
        dispatch(setDoNotShowExpiredModal(true));
    }, []);
    
    return (
        <React.Fragment>
            <Dashboard>
                {props.children}
            </Dashboard>
        </React.Fragment>
    )
}