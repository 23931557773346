import { useEffect, useState, useMemo } from "react";

const VideoPreview = ({ url, onCheckUrl }) => {

  // Function to determine the video platform
  const getVideoInfo = (url) => {
    if (url.includes('youtube.com')) {
      onCheckUrl(true);
      return {
        platform: 'YouTube',
        id: url.split('v=')[1],
      };
    } else if (url.includes('vimeo.com')) {
      onCheckUrl(true);
      return {
        platform: 'Vimeo',
        id: url.split('/').pop(),
      };
    } else {
      onCheckUrl(false);
      return null; // Unsupported platform
    }
  };

  // const videoInfo = getVideoInfo(url);
  const videoInfo = useMemo(() => getVideoInfo(url), [url]);

  if (!videoInfo) {
    return <div>Unsupported video platform</div>;
  }

  console.log("videoInfovideoInfo", videoInfo)

  return (
    <div>
      {videoInfo.platform === 'YouTube' && (
        <iframe width="250" height="200" src={`https://www.youtube.com/embed/${videoInfo.id}`} title="" frameBorder="0"allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"  allowFullScreen>   </iframe>
      )}
      {videoInfo.platform === 'Vimeo' && (
        <iframe
          src={`https://player.vimeo.com/video/${videoInfo.id}`}
          width="250" height="200"
          frameBorder="0"
          allowFullScreen
          title="Vimeo video player"
        ></iframe>
      )}
    </div>
  );
};

export default VideoPreview;
