import React from 'react';
import exclamation from './../../ASSETS/Images/xr/exclamation.png';

export default () =>{ 
    return (
        <React.Fragment>
            <div style={{    
                   "display": "flex",
                   "align-items": 'center',
                   "justify-content": "center",
                   "height": '100vh',
                   "flex-wrap": "wrap",
                   "flex-direction": "column"
                }}
            >
                <div>
                    <img src={exclamation} alt="no img" style={{
                        "height": "150px",
                        "width": "150px"}}
                    />
                </div>
                <h3 style={{
                        "font-weight": "600",
                        "margin": "15px",
                        "color": "var(--primary)"
                    }}>Oops!!</h3>
                <h5>Looks like the 360 Tour is no longer avaliable for viewing  </h5>
                <h5>Please contact the 360 Tour owner.</h5> 
            </div>
        </React.Fragment>
    )
}