import React, { useEffect, useState, useRef } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import "./Demo.css";
import zoomIn from './../../../ASSETS/Images/icons/zoom-in.png';
import zoomOut from './../../../ASSETS/Images/icons/zoom-out.png';

export default (props) => {

  const { imageToCrop, onImageCropped } = props;
  const [zoomLevel, setZoomLevel] = useState(1);

  const [image] = useState(imageToCrop);
  const cropperRef = useRef(null);

  const onCropEnd = ()=>{
     cropperRef.current.cropper.getCroppedCanvas({
      minWidth: props.minWidthBlob || 400,
      minHeight: props.minHeightBlob || 400,
      maxWidth: props.minWidthBlob || 400,
      maxHeight: props.minHeightBlob || 400
    }).toBlob((blob) => {
      
      onImageCropped(blob, cropperRef.current.cropper.getCroppedCanvas(
          {
            minWidth: props.minWidthDataURL || 800,
            minHeight: props.minHeightDataURL || 800,
            maxWidth: props.minWidthDataURL || 800,
            maxHeight: props.minHeightDataURL || 800,
          }
          ).toDataURL());
        })
  }
  
  // const onCrop =async ()=>{
  //   await cropperRef.current.cropper.getCroppedCanvas().toBlob((blob) => {
  //     onImageCropped(blob, cropperRef.current.cropper.getCroppedCanvas().toDataURL());})
  // }
    
  const zoomInHandler = ()=>{
    cropperRef.current.cropper.zoom(0.1)
  }

  const zoomOutHandler = ()=>{
    cropperRef.current.cropper.zoom(-0.1)
  }

  useEffect(()=>{
    setTimeout(() => {
        onCropEnd()
    }, 800);
  }, []);
  
  let viewMode= 1;
  
  if(props.viewMode === 0){
    viewMode= props.viewMode;
  }
  
  return (
    <React.Fragment>
        <div style={{
              "position": "relative",
              "top": "388px",
              "z-index": "10",
        }}>
          <img src={zoomOut} alt="Upload" onClick={zoomOutHandler} style={{
              "marginRight": "10px",
              "height": "29px", 
              "cursor": "pointer"
            }} 
          />  
          <img src={zoomIn} alt="Upload" onClick={zoomInHandler} style={{
              "height": "29px", 
              "cursor": "pointer"
            }} 
          />  
        </div>
        <Cropper
          style={{ height: 400, width: "100%" }}
          // zoom={0.5}
          initialAspectRatio={props.initialAspectRatio || 1}
          preview=".img-preview"
          src={image}
          viewMode={1}
          background={false}
          responsive={true}
          autoCropArea={0.7}
          checkOrientation={false}
          guides={true}
          cropBoxMovable= {false}
          cropBoxResizable= {false}
          dragMode= 'move'
          crop= {(e)=>{
            onCropEnd()
          }}
          // zoom={(event)=>{
          //   console.log("eventevent", event.detail.ratio, event.detail.oldRatio)
          // }}
          ref= {cropperRef}
          zoomOnWheel = {true}
          // crop={onCrop}
        />
      </React.Fragment>
  );
};