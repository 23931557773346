import React, { useEffect, useState } from "react";
import Profile from ".";
import Arrow from './../../ASSETS/Images/icons/arrow_1.png';
import Upload from './../../ASSETS/Images/icons/upload.png';
import Delete from './../../ASSETS/Images/icons/deleteWhite.png'; 
import { NavLink } from "react-router-dom";
import ProfileImage from './../../ASSETS/Images/defaultProfilePic.jpg';
import Edit from './../../ASSETS/Images/edit.png';
import {Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter,Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux'  
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { setModuleActiveDispatch } from "../../COMPONENTS/SideBarMenu/activeModule.slice";
import { setParentModuleActiveDispatch } from "../../COMPONENTS/SideBarMenu/activeParentModule.slice";
import { useAppSelector, useAppDispatch } from './../../UTILITYS/hook/typeVersionOfredux.tsx'
import userProfileModal from './../../UTILITYS/Modals/userProfile.modal.tsx'
import { fetchUserDetailsDispatch, resetUserData, setDoNotShowExpiredModal } from "./slice.reducer.tsx";

type allProps = {};  
type componentType= JSX.Element;

export default (props: allProps): componentType=>{

    const [modal, setModal] = useState<boolean>(false);
    // const userProfileDetails = useSelector(state: RootState => state.userProfileDetails)
    const userProfileDetails = useSelector(state => state.userProfileDetails);
    const [userDetails, setUserDetails] = useState<userProfileModal>();
    console.log("this is a usesrprofile useEffect", userProfileDetails, userDetails)
    
    const toggle: ()=> void = () => setModal(!modal);
    // const dispatch = useDispatch();
    const dispatch = useAppDispatch()

    useEffect(()=>{
        dispatch(setModuleActiveDispatch("profile"))
        dispatch(setParentModuleActiveDispatch("parentProfile"))
    }, []); 

    useEffect(()=>{
        setUserDetails(userProfileDetails) 
    }, [userProfileDetails]);

    // THIS IS FIRST PAGE AFTER LOGING, SO ITS GOOD TO GET USER DETAILS
    useEffect(()=>{
        if(!userProfileDetails.isUserFetch){
            console.log('oj mey profile iof ths 444',);
            dispatch(resetUserData())
            dispatch(fetchUserDetailsDispatch());
            dispatch(setDoNotShowExpiredModal(true));
        }
    }, [userProfileDetails.isUserFetch]);

    return (
        <React.Fragment>
            <Profile>
                <div className="inner-wrap">
                    <Row>
                        <Col xl="8">
                            <div className="white-box">
                                <Row>
                                    <Col xs="6">
                                        <h5>Profile</h5>
                                    </Col>
                                    <Col xs="6" className=" text-end">
                                        <NavLink to="/editProfile" className="btn btn-primary btn-sm btn-pills">Edit</NavLink>
                                    </Col>
                                </Row>
                                <div className=" profile-cont">
                                    <div className="row pt-2 pt-md-4 userInfo">
                                        <div className="col-md-6 col-lg-5 pt-3 mb-0 mb-md-3">
                                            <small>First Name</small>
                                            <h5>{userDetails?.firstName}</h5>
                                        </div>
                                        <div className="col-md-6 col-lg-5 pt-3 mb-0 mb-md-3">
                                            <small>Last Name</small>
                                            <h5>{userDetails?.lastName}</h5>
                                        </div>
                                        <div className="col-md-6 col-lg-5 pt-3 mb-0 mb-md-3">
                                            <small>Email</small>
                                            <h5 style={{textTransform: "lowercase"}}>{userDetails?.email}</h5>
                                        </div>
                                        <div className="col-md-6 col-lg-5 pt-3 mb-0 mb-md-3">
                                            <small>Mobile</small>
                                            <h5>{userDetails?.mobile}</h5>
                                        </div>
                                        <div className="col-md-6 col-lg-5 pt-3 mb-0 mb-md-3">
                                            <small>City</small>
                                            <h5>{userDetails?.city}</h5>
                                        </div>
                                        <div className="col-md-6 col-lg-5 pt-3 mb-0 mb-md-3">
                                            <small>Country</small>
                                            <h5>{userDetails?.countryCode}</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
               
                {/* upload profile modal start  */}
                <Modal isOpen={modal} centered className="modal-md outside-close-modal" toggle={toggle} {...props}>
                    <ModalHeader className="text-center" toggle={toggle}>Update profile picture</ModalHeader>
                    <ModalBody className="mb-2">
                        <div className="profile-large ms-auto me-auto">
                            <img src={ProfileImage} alt="profile" className="img-thumbnail" />
                        </div>
                    </ModalBody>
                    <ModalFooter className="justify-content-center">
                        <Button className="me-2 me-md-3"color="primary" outline  onClick={toggle}>
                            <img src={Delete} alt="Remove" /> Remove
                        </Button>
                        <Button className="ms-2 ms-md-3" color="primary" onClick={toggle}>
                            <img src={Upload} alt="Upload" /> Upload Image
                        </Button>
                    </ModalFooter>
                </Modal>
                {/* upload profile modal end */}

            </Profile>
        </React.Fragment> as JSX.Element
    )
}