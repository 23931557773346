import { createSlice } from '@reduxjs/toolkit'
import { getData, postData } from './../../UTILITYS/index.tsx';
import * as API from './../../APIs'
import userProfileModal, {DeleteProfilePIc, UpdateProfile, FetchUserDetails} from './../../UTILITYS/Modals/userProfile.modal.tsx'

const initialState: userProfileModal = {
  isUserFetchFaild: null,
  isUserFetch: false,
  imageFileCount: 0,
  audioFileCount: 0,
  videoFileCount: 0,
  doNotShowExpiredModal: false,
  subscriptionStatus: "",
  isExpired: false
}

export const userData = createSlice({
  name: 'userData',
  initialState: { ...initialState },
  reducers: {
    setUserData: (state, action) => {
      return {
        ...state,
        ...action.payload,
        isUserFetch: true
      }    
    },
    resetUserData: (state, action)=>{
      return {...initialState}
    },
    setProfilePic: (state, action)=>{
      return {
        ...state,
        profilePicPath: action.payload.profilePicPath
      }
    },
    setFetchUserDetailsFailed: (state, action)=>{
      state.isUserFetchFaild = action.payload
    },
    setDoNotShowExpiredModal: (state, action)=>{
      state.doNotShowExpiredModal = action.payload
    },
    setSubscriptionStatus: (state, action)=>{
      console.log("setUpdateRecuerdoStatussetUpdateRecuerdoStatus 1", action);
      // state.isPremium = action.payload.isPremium;
      // state.isExpired= action.payload.isExpired;
    }
  }
})

// Action creators are generated for each case reducer function
export const { setUserData, resetUserData, setFetchUserDetailsFailed, setProfilePic, setDoNotShowExpiredModal, setSubscriptionStatus } = userData.actions

async function userDetailsFetch<T>(token: any, dispatch: any){
    
  console.log("this is a userDetailsFetch try", token)

    try{
      let url= API.BASE_URL+API.GET_PROFILE_DETAILS
     
      let headerConfig = {
        "Content-Type": "application/json",
        "Authorization": "Bearer " +token
      }

      const fetchUserRes: T =await getData({url, headerConfig}, dispatch)as T

      console.log("this is a userDetailsFetch try", fetchUserRes)
    
    return fetchUserRes as T;
    
  }catch (err){

    console.log("this is a userDetailsFetch catch")
    throw (err)
  }

}

export const fetchUserDetailsDispatch = (cb) => {

  return async(dispatch: any, getState: any) => {
    console.log('this is our state data', getState().localSessionDetails.payload) 
    
    if(getState().localSessionDetails.payload === "") return;

    try{
        let token = getState().localSessionDetails.payload.accessToken;
        const resUserDetailsFetch =await userDetailsFetch<FetchUserDetails>(token, dispatch) 
        console.log('this is profile slice', resUserDetailsFetch)

        let isExpired= false;
        if(resUserDetailsFetch.result.payload.result.profileIconStatus === "Expired"){
          isExpired= true;
        }
        
        dispatch(setUserData({
          ...resUserDetailsFetch.result.payload.result.userDetail,
          // isPremium: resUserDetailsFetch.result.payload.result.isPremium,
          isExpired: isExpired,
          subscriptionStatus: resUserDetailsFetch.result.payload.result.profileIconStatus,
        }))
        dispatch(setFetchUserDetailsFailed(null))
        if(cb){
          cb()
        }
       
      }catch (err: any){
        console.log("this is a fetchUserDetailsDispatch catch pp", err)
        if(err!= undefined && err.isError){
            dispatch(setFetchUserDetailsFailed(err.responseException.exceptionMessage))
        }
    }
  }
}

async function postUserUpdata<T>(token: any, data: any, dispatch: any){

  console.log("this is a postUserUpdata try")

  try{
    let url= API.BASE_URL+API.UPDATE_USER_PROFILE_DATA
    let dataObje= {
      ...data
    }
    let headerConfig = {
      "Content-Type": "application/json",
      "Authorization": "Bearer " +token
    }
    const updataeRes: T =await postData({url, headerConfig, dataObje}, dispatch) as T;

    console.log("this is a postUserUpdata try", updataeRes)
  
  return updataeRes as T;
  
}catch (err){

  console.log("this is a postUserUpdata catch")
  throw (err)
}
}

export const updateUserDetailsDispatch = (data: any) => {

  return async(dispatch: any, getState: any) => {
  
    try{
      
        let token = getState().localSessionDetails.payload.accessToken;
        const resUserDetailsUpdate =await postUserUpdata<UpdateProfile>(token, data, dispatch) 
        
        dispatch(setUserData({...resUserDetailsUpdate.result.payload,
           profilePicPath: data.profilePicPath!= null? data.profilePicPath: ""
        }));
        dispatch(setFetchUserDetailsFailed(null))
       
      }catch (err){

    }
  }
}

async function deleteProfilePicPost<T>(token: any, dispatch: any){

  try{
    let url= API.BASE_URL+API.REMOVE_PROFILE_PIC
    
    let headerConfig = {
      "Content-Type": "application/json",
      "Authorization": "Bearer " +token
    }
    const deleteProfilePicRes: T =await postData({url, headerConfig}, dispatch) as T;
  
  return deleteProfilePicRes as T;
  
  }catch (err){

    console.log("this is a deleteProfilePicPost catch")
    throw (err)
  }
}


export const deleteProfilePicDispatch = () => {
  console.log('deleteProfilePicDispatch 1 ', "deleteProfilePicDispatch") 

  return async(dispatch: any, getState: any) => {
      try{
    
        let token = getState().localSessionDetails.payload.accessToken;
        const deleteProfilePicRes =await deleteProfilePicPost<DeleteProfilePIc>(token, dispatch)
        console.log('deleteProfilePicDispatch 3 ', deleteProfilePicRes)
        dispatch(setUserData(deleteProfilePicRes.result.payload))
        dispatch(setFetchUserDetailsFailed(null))
       
        }catch (err){
          
      }
    }

  }



export default userData.reducer