import React from "react";

type componentType= ()=> JSX.Element;

const DashboardNoItemsSection: componentType = (): JSX.Element=>{
    console.log('this is NoAssests component 1')
    return (
        <React.Fragment>
            <p className="text-center no-data">No data available</p>
        </React.Fragment>
    )
}

export default React.memo(DashboardNoItemsSection);