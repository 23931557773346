import React, {useState} from "react";
import Parent from '.'
import './ForgetResetPasswordStyle.module.css'
import {forgetPassword} from './../../../UTILITYS/endPoints'
import { useNavigate, NavLink } from 'react-router-dom'
import Logo from './../../../COMPONENTS/Logo'
import Container from './../index.container'
import CopyRight from "../../../COMPONENTS/CopyRight";
import {  
    useDispatch } from 'react-redux'
import ButtonContainer from '../../../COMPONENTS/Button/buttonContainer'
import Button from '../../../COMPONENTS/Button/Button'
import classNames from 'classnames';
import { toast } from 'react-toastify';
import validator from 'validator';
import "react-toastify/dist/ReactToastify.css";

// THIS IS FORGOT PASSWORD SCREEN
export default (props) =>{

    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [email, setEmail] = useState('')
    const [emailNameErr, setEmailNameErr] = useState(false)

    const applyForgotPassword = async (e) =>{
        e.preventDefault() 
        toast.dismiss()
        // validation goes here
        if(!validator.isEmail(email)){
            // setEmailErr(true)
            if(email === "") toast.error("Please Enter Email", { });
            else toast.error("Please Enter valid Email", { });
            setEmailNameErr(true)
            return
        }
       

        try{
            console.log('this is try applyForgotPassword', "try")
            await forgetPassword({"email": email}, dispatch);
            navigate('/SentEmailView', { state: {"email": email}})
          }catch (err){
            if(err!=undefined && err.isError!= undefined && err.isError) toast.error(err.responseException.exceptionMessage, { });
            console.log('this is catch applyForgotPassword', err)
        }
    }

    return (
        <Parent>
            <React.Fragment>
                <Container>      
                    <div className="text-center mb-4 mt-3">
                        <Logo logoClass="mb-1 pb-2" />
                        <h4>Forgot Password</h4>
                    </div>
                    <form className="pb-3" onSubmit={applyForgotPassword}>
                        <div className="mb-3">
                            <label className="form-label">Email</label>
                            <input type="text" className={classNames("form-control", { "form-error": emailNameErr })} name="email" placeholder="Email" value={email} onChange={(e)=>{
                                setEmailNameErr(false)
                                setEmail(e.target.value)
                            }} />
                        </div>
                        <ButtonContainer 
                        containerClacess = {["mb-4", "mt-4"]}
                        render={() => <Button lable="Next" type="submit" buttonClassess = {["btn", "btn-primary", "w-100"]}/>}
                        />
                        <div className="text-center pt-2">
                            Already have an account? <NavLink to="/login"><u>Login</u></NavLink>
                        </div>
                    </form>
                    
                    {/* <CopyRight copyRightClassess = {["text-center", "copyright"]} /> */}
                </Container>
            </React.Fragment>
        </Parent>
    )
}