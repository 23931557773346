import React from "react";
import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
  } from 'reactstrap';
import { PARENT_MODULE_ITEMS } from "../SideBarMenu/moduleList";
import { useSelector } from 'react-redux';

export default (props) =>{

    let isVrMenu =props.tabHeading === "VR Experience"? true: false
    const activeParentModuleSlice = useSelector(state => state.activeParentModule);

    const subMenu = ()=>{
        return props.subMen.map((item, index)=>{
            // console.log('JFKDF KJIFE EE', item)
            return (
                <DropdownItem 
                    key={index}
                    onClick={(e)=>{
                        e.stopPropagation();
                        item.onClick(item.subModule)
                    }} 
                    className={item.dropdownItemClasses ? `${item.dropdownItemClasses}`: "" }>
                        {item.subHeading}
                </DropdownItem>
            )
        })
    }

    return (<UncontrolledDropdown nav inNavbar className={props.dropdownClasses ? `${props.dropdownClasses}`: ""} 
    onClick= {()=>props.setThisModuleActive(props.currentModule)} 
    // disabled = {(props.currentModule== "parentAssestManagment" || props.currentModule == "parentFamilyFreind")? true: false}
    >
                <DropdownToggle nav caret className={isVrMenu ? "vr-exp": ""}>
                    <div className="menu-icon">
                        <img src={props.headingAvatar} alt="User" />
                    </div>
                    
                    <span
                         className= {PARENT_MODULE_ITEMS.secondMenuItems[0].module === activeParentModuleSlice.module? "active-menu-text-color": "default-color"}
                    >{props.tabHeading}</span>
                </DropdownToggle>
                <DropdownMenu end className={props.dropdownMenuClasses ? `${props.dropdownMenuClasses}`: ""}>
                   {subMenu()}
                </DropdownMenu>
            </UncontrolledDropdown>)
}
