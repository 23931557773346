import React, { useEffect,useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import HeaderFooter from '.';
import Image1 from './../../ASSETS/Images/xr/360.png';
import Edit from './../../ASSETS/Images/xr/edit1.png';
import Share from './../../ASSETS/Images/xr/sahre.png';
import More from './../../ASSETS/Images/xr/more.png';
import { useNavigate } from "react-router-dom";
import Icon360 from './../../ASSETS/Images/xr/360-degrees.png';
import Icon360Item from './../../ASSETS/Images/xr/360Item.png';
import IconView from './../../ASSETS/Images/xr/view-primary.png';
import eyeIconWithColor from './../../ASSETS/Images/xr/eye-icon-with-color.png';
import defaultThreeSixty from './../../ASSETS/Images/360Tours/defaultThreeSixty.jpg';
import Close from './../../ASSETS/Images/icons/cancel.png'
import { Row, Col, Card, CardBody, CardFooter, Modal, ModalBody, FormGroup, Button, ModalHeader} from 'reactstrap';
import { fetchTourViewsByCategoryDispatch, fetchTourViewsBydateDispatch, fetchTourViewsbyuserDispatch, resetDashBoardData } from "./home.slice";
import moment from 'moment';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement, Title, Filler } from 'chart.js';
import { Pie, Line } from 'react-chartjs-2';
import { setParentModuleActiveDispatch } from "../../COMPONENTS/SideBarMenu/activeParentModule.slice";
import { setModuleActiveDispatch } from "../../COMPONENTS/SideBarMenu/activeModule.slice";
import EditShareDropDown from "../../COMPONENTS/DropDown/EditShare";
import { fetchUserDetailsDispatch, resetUserData } from "../Profile/slice.reducer";
import { fetchVirtualTourDispatch, resetVirtualTour, updateVirtualTourDispatch } from "../360virtualTour/Slices/VirtualTour.slice";
import SharableModal from "../../COMPONENTS/SharableModal";
import validator from 'validator';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import { LINE_CHART_DATASETS_COMMAN_CONFIG, LINE_CHART_COMMAN_OPTIONS } from "../../UTILITYS/Constant";
import { getLineAnimation, pieChartDatasetsCommanConfig } from "../../UTILITYS/Helper";
import NoAssests from "../../COMPONENTS/NoAssests";
import Expired from './../../ASSETS/Images/xr/expired.png';
import { ExpiredModal } from "../../COMPONENTS/Modal/Expired";
import Animate_360 from './../../ASSETS/Images/icons/animate-360.gif' 
ChartJS.register(CategoryScale,  LinearScale, PointElement, LineElement, Title,ArcElement, Tooltip, Legend, Filler);
const pieOptions = {
  legend: {
    display: true,
    position: "bottom",
    labels: {
      boxWidth: 12,
      color: "#ebebeb",
    },
    onClick: (e) => e.stopPropagation()
  },
  elements: {
    arc: {
      borderWidth: 0
    }
  }
};

function HomeScreenLanddingPage(args) {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userProfileDetails = useSelector(state => state.userProfileDetails);
  const dashBoardData = useSelector(state => state.dashBoardData);
  const virtualTours = useSelector(state => state.virtualTours);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const [dropDownTour, setDropDownTour] = useState({});
  const [openSharModal, setOpenSharModal] = useState(false);
  const [modal, setModal] = useState(false);
  const [updatedVirtualTourNameDATA, setupdatedVirtualTourNameDATA] = useState({});
  const [updatedVirtualTourName, setupdatedVirtualTourName] = useState('');
  const [updatedVirtualTourNameErr, setupdatedvirtualTourNameErr] = useState(false);
  // const [isModalShowIfSubscriptionExpired, setIsModalShowIfSubscriptionExpired] = useState(false);
  
  // useEffect(()=>{
  //   setIsModalShowIfSubscriptionExpired(userProfileDetails.recuerdoStatus === 4 && !userProfileDetails.doNotShowExpiredModal)
  // }, [userProfileDetails.recuerdoStatus, userProfileDetails.doNotShowExpiredModal]);
  
  useEffect(()=>{
    dispatch(setParentModuleActiveDispatch("parentDashboard"));
    dispatch(setModuleActiveDispatch(""));
  }, []);

  // OS CATEGORIES 
  const osData = ()=>{
   
    let viewedInOs= dashBoardData.tourViewsByCategory.filter(cat=> cat.category === "os");
    let getViewedInOs= viewedInOs.sort((a, b) => a.views - b.views);
   
    return {
      labels: getViewedInOs.map(data=> data.name.replace(/^./, data.name[0].toUpperCase())),
      datasets: [
        {
          // ...PIE_CHART_DATASETS_COMMAN_CONFIG,
          ...pieChartDatasetsCommanConfig(getViewedInOs),
          data: getViewedInOs.map(data=> parseInt(data.views)),
        },
      ],
    };
  }

  // device CATEGORIES 
  const deviceData =()=> {
    let viewedInDevices= dashBoardData.tourViewsByCategory.filter(cat=> cat.category === "device");
    let getViewedInASCDorder= viewedInDevices.sort((a, b) => a.views - b.views);
    
    return {
      labels: getViewedInASCDorder.map(data=> data.name.replace(/^./, data.name[0].toUpperCase())),
      datasets: [
        {
          // ...PIE_CHART_DATASETS_COMMAN_CONFIG,
          ...pieChartDatasetsCommanConfig(getViewedInASCDorder),
          data: getViewedInASCDorder.map(data=> parseInt(data.views)),
        },
      ],
    };
  }
 
  // BROWSER CATEGORIES 
  const browserData =()=>{
    let viewedInBrowser= dashBoardData.tourViewsByCategory.filter(cat=> cat.category === "browser");
    let getViewedInBrowser= viewedInBrowser.sort((a, b) => a.views - b.views)
    return {
      labels: getViewedInBrowser.map(data=> data.name.replace(/^./, data.name[0].toUpperCase())),
      datasets: [
        {
          // ...PIE_CHART_DATASETS_COMMAN_CONFIG,
          ...pieChartDatasetsCommanConfig(getViewedInBrowser),
          data: getViewedInBrowser.map(data=> parseInt(data.views)),
        },
      ],
    };
  } 

  // // LINE CHART 
  // const lineData = {
  //   labels: dashBoardData.tourViewsBydate.map(date=> moment(date.viewdate).format('DD-MM-YYYY')).reverse(),
  //   datasets: [{
  //     ...LINE_CHART_DATASETS_COMMAN_CONFIG,
  //     data: dashBoardData.tourViewsBydate.map(date=> parseInt(date.totalviews)).reverse(),
  //   }],
  // };

  const getLineGraph =()=>{

    if(dashBoardData.tourViewsBydate.length === 1){
      
      let currentDate =dashBoardData.tourViewsBydate.map(date=> moment(date.viewdate).format('DD-MM-YYYY'))[0];
      const currentMoment = moment(currentDate, 'DD-MM-YYYY');
      const previousMoment = currentMoment.subtract(1, 'days');
      const previousDate = previousMoment.format('DD-MM-YYYY');

      return {
        labels: [...dashBoardData.tourViewsBydate.map(date=> moment(date.viewdate).format('DD-MM-YYYY')), previousDate].reverse(),
        datasets: [{
          ...LINE_CHART_DATASETS_COMMAN_CONFIG,
          data: [...dashBoardData.tourViewsBydate.map(date=> parseInt(date.totalviews)), 0].reverse(),
        }],
      }
    }

    return {
      labels: dashBoardData.tourViewsBydate.map(date=> moment(date.viewdate).format('DD-MM-YYYY')).reverse(),
      datasets: [{
        ...LINE_CHART_DATASETS_COMMAN_CONFIG,
        data: dashBoardData.tourViewsBydate.map(date=> parseInt(date.totalviews)).reverse(),
      }],
    }
  }

  useEffect(()=>{
    // dispatch(resetUserData())
    dispatch(fetchUserDetailsDispatch(()=>{
      // 2023-10-27
      let tour_id= 0;
      const currentYear = moment().format('YYYY');
      let fromDate = `${currentYear}-01-01`;
      let toDate = moment().format('YYYY-MM-DD');
      dispatch(resetDashBoardData()); 
      dispatch(resetVirtualTour());
      dispatch(fetchTourViewsbyuserDispatch(tour_id, fromDate, toDate, ()=>{
        dispatch(fetchTourViewsByCategoryDispatch(tour_id, fromDate, toDate))
        dispatch(fetchTourViewsBydateDispatch(tour_id, fromDate, toDate))
        dispatch(fetchVirtualTourDispatch(1, 2));
      })) 
    }));

    return ()=>{
      dispatch(resetVirtualTour());
    }
  }, [])

  // TO REQUIRED INFO ON APPLICATION LOAD
  // useEffect(()=>{
  //   setTimeout(() => {   
  //     if(userProfileDetails.isUserFetch){
        
  //       }
  //   }, 300);

  //   return ()=>{
  //     dispatch(resetVirtualTour());
  //   }
  // }, [userProfileDetails.isUserFetch]);

  const getVirtualTours = ()=>{ 
    return virtualTours.tour !=undefined && virtualTours.tour.length >0 && virtualTours.tour.map((tour, index)=>{     
        return getVirtualTour(tour)
    })
  }

  const onNavigate = (tour) => {
    navigate('/virtual-tour-images', {state: tour})
  } 

  const sharableModal = ()=>{

    return <SharableModal
      tour= {dropDownTour}
      openSharModal= {openSharModal}
      setOpenSharModal= {setOpenSharModal}
    />
  }

  const getUpdateTour = (tour)=>{ 
    setupdatedVirtualTourNameDATA(tour);
    setupdatedVirtualTourName(tour.name);
    setModal(!modal);
}

  const closeUpdatedModal = () => {
    setupdatedVirtualTourNameDATA({})
    setModal(!modal);
  }

  const toggleSharModal = (tour)=>{
    setOpenSharModal(!openSharModal);
  }

  const onInputChange = (e)=>{
    switch(e.target.name){
        case "virtual-tour":
            setupdatedVirtualTourName(e.target.value.replace(/[^a-zA-Z0-9\s]/g, ''));
            setupdatedvirtualTourNameErr(false)
        break;
    }
  }

  const updateTour= async()=>{
          
    toast.dismiss();

    if(validator.isEmpty(updatedVirtualTourName.trim())){
        toast.error("Please Enter Tour Name", { });
        setupdatedvirtualTourNameErr(true)
        return;
    }else if(!validator.isLength(updatedVirtualTourName.trim(), {min: 1, max: 25})){
        toast.error("Tour Name length should be minimum 3 char and maximum 25 char", { });
        setupdatedvirtualTourNameErr(true)
        return;
    }

    let mappingData = {
        name: updatedVirtualTourName,
        id: updatedVirtualTourNameDATA.id 
    }

    try{
        console.log('object', mappingData)
        dispatch(updateVirtualTourDispatch(mappingData));
        closeUpdatedModal()
      }catch (err){ 
        if(err!=undefined && err.isError!= undefined && err.isError) toast.error(err.responseException.exceptionMessage, { });
      }

  }

  const getVirtualTour = (tour)=>{ 
    return <Col lg="6" >
            <Card className="mb-4 cursor-pointer" >
                <CardBody className="p-2 pb-0 text-center" onClick={()=>onNavigate(tour)}>
                    <div className="curved-image-wrap">
                    <img src={tour.thumbnailUrl? tour.thumbnailUrl: defaultThreeSixty} alt="image" className="assets_image" />
                    </div>
                </CardBody>
                <CardFooter className="p-2 pt-0"> 
                  <div className="image-card-title">
                      <p className="mb-0 text-center" onClick={()=>onNavigate(tour)}>{tour.name}</p>
                      {/* <EditShareDropDown
                        dropDownTour= {dropDownTour}
                        tour= {tour}
                        onClick={()=>{
                            setDropDownTour(tour);
                        }}
                        onEdit={()=>{
                            getUpdateTour(tour);
                        }}
                        onShare={()=>{
                            toggleSharModal(tour);
                        }}
                      /> */}
                  </div>
                </CardFooter>
            </Card>
        </Col>
  }

  const getLIneChart=()=>{
    if(dashBoardData.tourViewsBydate.length>0)
      return <Line 
      options= {{
        ...LINE_CHART_COMMAN_OPTIONS,
        animation: getLineAnimation(dashBoardData.tourViewsBydate.length, 550),
      }} 
      // data={lineData} 
      data={getLineGraph()} 
    />
    return <NoAssests />
  }

  const getViewsByDateChart=()=>{
    if(dashBoardData.tourViewsByUser.tourViewsList.length>0)
      return [...dashBoardData.tourViewsByUser.tourViewsList].sort((a, b) => b.tour_views - a.tour_views).map(tous=>{
        return <div className="tour-list-item">
          <div className="d-flex align-items-center">
            <img src={eyeIconWithColor} className="small-tour" />
            <p className="mb-0">{tous.tour_name}</p>  
          </div>
          <div>{tous.tour_views}</div>
      </div>
      }).sort()
    return <NoAssests />
  }

  const getDevicesPieChart=()=>{
    if(dashBoardData.tourViewsByCategory.filter(cat=> cat.category === "device").length> 0)
    return <div class="chart-container" style={{height: "auto", width: "100%"}}>
      <Pie
        data={deviceData()}
        options={{
          plugins: {...pieOptions}
        }}
      />
    </div>
    return <NoAssests />
  }

  const getOSPieChart=()=>{
    if(dashBoardData.tourViewsByCategory.filter(cat=> cat.category === "os").length> 0)
      return <div class="chart-container" style={{height: "auto", width: "100%"}}>
      <Pie
        data={osData()}
        options={{
          plugins: {...pieOptions}
        }}
      />
    </div>
    return <NoAssests />
  }

  const getBrowserPieChart=()=>{
    if(dashBoardData.tourViewsByCategory.filter(cat=> cat.category === "browser").length> 0)
      return <div class="chart-container" style={{height: "auto", width: "100%"}}>
        <Pie 
          data={browserData()}
          options={{
            plugins: {...pieOptions}
          }}
        />
      </div>
    return <NoAssests />
  }

  return (
    <HeaderFooter>
      {sharableModal()}
      <div className="px-2 px-md-0 dashboard">
      <div className="container-fluid">
        <div className="pt-0 pt-md-3 pb-2">
          <h4>Dashboard</h4>
        </div>
        {/* INFOR START*/}
        <Row>
          <Col sm={6} md={12} lg={4}>
            <div className="white-box white-box-gradiant" style={{
              
            }}>
              <div className="d-flex align-items-center dashboard-card">
                <span ><img src={Icon360} alt="360" /></span>
                <div>
                  <p className="mb-1"><strong className="text-disabled color-white">360° Tours</strong></p>
                  <h3 className="text-xxl mb-0" style={{"color": "#e6e6e6", "font-family": "system-ui", "font-weight": "100"}}>{dashBoardData.tourViewsByUser.total_tours}</h3>
                </div>
              </div>
            </div>
          </Col>
          <Col sm={6} md={12} lg={4}>
            <div className="white-box white-box-gradiant">
              <div className="d-flex align-items-center dashboard-card">
                <span ><img src={IconView} alt="360" /></span>
                <div>
                  <p className="mb-1"><strong className="text-disabled color-white">360° Tour Views</strong></p>
                  <h3 className="text-xxl mb-0" style={{"color": "#e6e6e6", "font-family": "system-ui", "font-weight": "100"}}>{dashBoardData.tourViewsByUser.total_tourviews}</h3>
                </div>
              </div>
            </div>
          </Col>
          <Col sm={6} md={12} lg={4}>
            <div className="white-box white-box-gradiant">
              <div className="d-flex align-items-center dashboard-card">
                <span ><img src={Icon360Item} alt="360" /></span>
                <div>
                  <p className="mb-1"><strong className="text-disabled color-white">360° Experiences</strong></p>
                  <h3 className="text-xxl mb-0" style={{"color": "#e6e6e6", "font-family": "system-ui", "font-weight": "100"}}>{dashBoardData.tourViewsByUser.totalTourexperiences}</h3>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        {/* INFOR END*/}
        <Row>
            <Col xl={8} className="mb-4"> 
              <div className="white-box h-100">
                  {/* LINE CHART START */}
                  <div class="chart-container dashboard-line-chart" style={{height: "100%", width: "100%"}}>
                    <h5>Tour Views</h5>
                    {getLIneChart()}
                  </div>
                  {/* LINE CHART END */}
              </div>
            </Col>
            <Col xl={4} className="mb-4">
              <div className="white-box h-100 px-0">
                <div className="px-4">
                  <Row>
                    <Col xs={8}><h5>Views by Tours</h5></Col>
                    <Col xs={4}  className="text-end"><a href="#" onClick={()=>navigate("/virtual-tour")}>View All</a></Col>
                  </Row>
                </div>
                <div className="views-lists custom-scrollbar">
                    {/* VIEWS BY TOURS START*/}
                    {getViewsByDateChart()}
                    {/* VIEWS BY TOURS END*/}
                </div>
              </div>
            </Col>
        </Row>
        <div className="">
          <Row>
            {/* PIE CHART START*/}
            <Col sm={6} lg={4} className="mb-4" >
              <div className="white-box text-center circle-chart">
              <h5  className="mb-4">Devices</h5>
                {/* DEVICE CATEGORIES */}
                {getDevicesPieChart()}
              </div>
            </Col>

            <Col sm={6} lg={4} className="mb-4">
              <div className="white-box text-center circle-chart">
              <h5 className="mb-4">OS</h5>
                {/* OS CATEGORIES */}
                {getOSPieChart()}
              </div>
            </Col>

            <Col sm={6} lg={4} className="mb-4">
              <div className="white-box text-center circle-chart">
                <h5 className="mb-4">Browser</h5>
                {/* BROWSER CATEGORIES */}
                {getBrowserPieChart()}
              </div>
            </Col>
          </Row>
            {/* PIE CHART END*/}
        </div>
        <Row>
          <Col md={12} className="h-100">
            <div className="white-box h-100">
              <Row>
                <Col xs={8}><h5>Tours</h5></Col>
                <Col xs={4} className="text-end"><a href="#" onClick={()=>navigate("/virtual-tour")}>View All</a></Col>
              </Row>
              <div className="virtual-tour-and-img mt-2">
                <Row>
                  {getVirtualTours()}
                </Row>
                {virtualTours.tour.length===0 && <NoAssests />}
              </div>
            </div>
          </Col>
        </Row>
      </div>
      </div>
      {/* UPDATED VIRTUAL-TOUR MODAL */}
      <Modal isOpen={modal} centered className="drop-modal" toggle={()=>{
          closeUpdatedModal()
          }} >
          <Button className="modalClose" onClick={()=>{
              closeUpdatedModal()
          }}>
              <img src={Close} alt="Images" className="img-fluid" />
          </Button>
          <ModalBody>
              <div>
                  <h5 className="text-center text-primary mt-1">Update</h5>
              </div>
              <div className="share_people p-2">
                  <div class="row">
                      <div className="col-12">
                          <FormGroup>
                              <label className="form-label font-medium">Rename Tour</label>
                              <input value={updatedVirtualTourName} type="text" placeholder="Enter your virtual tour name"
                              className={classNames("form-control", { "form-error": updatedVirtualTourNameErr })} name={'virtual-tour'} onChange={onInputChange}
                              />
                          </FormGroup>
                      </div>
                  </div>
                  <div className="row">
                      <div className="col-12 mt-4">
                          <button 
                              className="btn btn-primary"
                              onClick={updateTour}
                          >Save</button>
                          <button 
                              className="btn btn-primary btn-cancel ms-4"
                              onClick={closeUpdatedModal}
                          >Cancel</button>
                      </div>
                  </div>
              </div> 
          </ModalBody>
      </Modal>

      {/* MODAL IF SUBSCRIPTION EXPIRED */} 
      <ExpiredModal />
      
    </HeaderFooter>
  );
}
  
export default HomeScreenLanddingPage;