import React, { useState, useEffect, useContext, useRef } from "react";
import Home from "..";
import { useNavigate } from "react-router-dom";
import 'react-circular-progressbar/dist/styles.css';
import { useDispatch, useSelector } from 'react-redux'
import InfiniteScroll from "react-infinite-scroll-component";
import { fetchVirtualTourDispatch, updateVirtualTourDispatch } from "../Slices/VirtualTour.slice";
import { Row, Col, Card, CardBody, CardFooter, ModalHeader, ModalFooter } from 'reactstrap';
import ImagesIcon from './../../../ASSETS/Images/defaultProfilePic.jpg';
import AddVirtualTourButton from "../../../COMPONENTS/360VirtualTour/AddVirtualTourButton"; 
import Edit from './../../../ASSETS/Images/xr/edit1.png';
import Share from './../../../ASSETS/Images/xr/sahre.png';
import More from './../../../ASSETS/Images/xr/more.png';
import UploadButton from './../../../ASSETS/Images/icons/upload.png';
import Upload from './../../../ASSETS/Images/icons/upload-tour.png';
import TourLogo from './../../../ASSETS/Images/icons/tour-logo.png';
import RemoveTour from './../../../ASSETS/Images/icons/remove-tour.png';
import _360OverlayIcon from './../../../ASSETS/Images/icons/_360OverlayIcon.gif';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import CreateTokenContextProvider from "./../../../STORE/App-wide-state/Token/createTokenContextProvider";
import ImageCropper from "./../../../COMPONENTS/CROP_IMAGE/ImageCropper";
import Animate_360 from './../../../ASSETS/Images/icons/animate-360.gif' 

import { toast } from 'react-toastify';
import validator from 'validator';
import Close from './../../../ASSETS/Images/icons/cancel.png'
import classNames from 'classnames';
import { Button, Modal, ModalBody, FormGroup } from 'reactstrap';
import defaultThreeSixty from './../../../ASSETS/Images/360Tours/defaultThreeSixty.jpg';
import EditShareDropDown from "../../../COMPONENTS/DropDown/EditShare";
import SharableModal from "../../../COMPONENTS/SharableModal";
import { loaderDispatch } from "../../../COMPONENTS/Loader/slice.reducer";
import { getImgURL } from "../../../UTILITYS/endPoints";
import { DOMAIN_URL, VIEWER_THREE_SIXTY_WORLD_PAGE } from "../../../APIs";
import { ExpiredModal } from "../../../COMPONENTS/Modal/Expired";
import { setDoNotShowExpiredModal } from "../../Profile/slice.reducer";

let visitPageFirstTime= true;

export default ()=>{
    
    const dispatch = useDispatch(); 
    const navigate = useNavigate();
    const fileInputRef = useRef(null);
    const userProfileDetails = useSelector(state => state.userProfileDetails);
    const virtualTours = useSelector(state => state.virtualTours);
    const [openSharModal, setOpenSharModal] = useState(false);
    const [loadVirtualTour, setLoadVirtualTour] = useState(1);
    const [modal, setModal] = useState(false);
    const [updatedVirtualTourName, setupdatedVirtualTourName] = useState('');
    const [updatedVirtualTourNameErr, setupdatedvirtualTourNameErr] = useState(false);
    const [updatedVirtualTourNameDATA, setupdatedVirtualTourNameDATA] = useState({});
    const [dropDownTour, setDropDownTour] = useState({});
    const [didCopied, setDidCopied] = useState(false);
    const [showEmbeddedModal, setShowEmbeddedModal] = useState({
        show: false,
        url: ""
    });

    const tokenContext = useContext(CreateTokenContextProvider);
    const [vtDescription, setVTDescription] = useState(""); 
    const [vtDescriptionErr, setVtDescriptionErr] = useState(false); 
    
    const [crpImgModal, setCrpImgModal] = useState(false);
    const [imageToCrop, setImageToCrop] = useState(undefined);
    const [imgURLOnGoingCropping, setImgURLOnGoingCropping] = useState("");
    const [choseFile ,setChoseFile] =useState("");
    const [savedImgUrl ,setSavedImgUrl] =useState("");
    const [key, setKey] = useState(1);
    
    const sharableModal = ()=>{
        
        return <SharableModal 
        tour= {dropDownTour}
        openSharModal= {openSharModal}
        setOpenSharModal= {setOpenSharModal}
        />
    }
    
    const closeUpdatedModal = () => {
        setupdatedVirtualTourNameDATA({})
        setModal(!modal);
        fileInputRef.current.value = null;
    }

    useEffect(()=>{
         visitPageFirstTime= true;
    }, [])
    
    useEffect(()=>{
        if(userProfileDetails.isUserFetch && !visitPageFirstTime){
            console.log("visitPageFirstTimevisitPageFirstTime", visitPageFirstTime)
            dispatch(fetchVirtualTourDispatch(loadVirtualTour, 25))
        }
        visitPageFirstTime= false;
    },[loadVirtualTour, userProfileDetails.isUserFetch]);
    
    const fetchMoreData = () => {
        setLoadVirtualTour(loadVirtualTour+1) 
    };
    
    const onNavigate = (tour) => {
        navigate('/virtual-tour-images', {state: tour});
    } 

    const getVirtualList = ()=>{
        
        return <InfiniteScroll
                    className="row"
                    dataLength={virtualTours.tour}
                    next={fetchMoreData}
                    hasMore={virtualTours.setHasMoreVirtualTour}
                    loader={virtualTours.tour.length > 25 ?<h4>Loading...</h4>: ""}
                    endMessage={
                    <p style={{ textAlign: "center" }}>
                    </p>
                    } 
                >
                {getVirtualTours()}
            </InfiniteScroll>
       
    }

    const getVirtualTours = ()=>{ 
        return virtualTours.tour !=undefined && virtualTours.tour.length >0 && virtualTours.tour.map((tour, index)=>{     
            return getVirtualTour(tour)
        })
            
    }

    const getVirtualTour = (tour)=>{ 
        
        return <Col lg="6" >
                <Card className="mb-4 cursor-pointer" >
                    <CardBody className="p-2 pb-0 text-center" onClick={()=>onNavigate(tour)}>
                        <div className="curved-image-wrap">
                            <img src={tour.thumbnailUrl? tour.thumbnailUrl: defaultThreeSixty} alt="image" className="assets_image" />
                        </div>
                    </CardBody>
                    <CardFooter className="p-2 pt-0"> 
                        <div className="image-card-title">
                            <p className="mb-0 text-center" onClick={()=>onNavigate(tour)}>{tour.name}</p>
                            <EditShareDropDown
                                dropDownTour= {dropDownTour}
                                tour= {tour}
                                onClick={()=>{
                                    setDropDownTour(tour);
                                }}
                                onEdit={()=>{
                                    if(userProfileDetails.isExpired ){
                                        toast.dismiss()
                                        dispatch(setDoNotShowExpiredModal(false));
                                        // setOpenUpgrade(true);
                                        toast("Your subscription has been expired");
                                        return;
                                    }        
                                    getUpdateTour(tour);
                                }}
                                onShare={()=>{
                                    if(userProfileDetails.isExpired ){
                                        toast.dismiss()
                                        dispatch(setDoNotShowExpiredModal(false));
                                        // setOpenUpgrade(true);
                                        toast("Your subscription has been expired");
                                        return;
                                    }        
                                    toggleSharModal(tour);
                                }}
                                onEmbedCode={()=>{
                                    if(userProfileDetails.isExpired ){
                                        toast.dismiss()
                                        dispatch(setDoNotShowExpiredModal(false));
                                        // setOpenUpgrade(true);
                                        toast("Your subscription has been expired");
                                        return;
                                    }        
                                    // setShowEmbeddedModal({...showEmbeddedModal, show: true})
                                    let publicLInk =`<iframe src="${DOMAIN_URL}${VIEWER_THREE_SIXTY_WORLD_PAGE}/${tour.tourUrlToken}" width="600" height="400" frameborder="0" allowfullscreen></iframe>`;
                                    // publicLInk =DOMAIN_URL+VIEWER_THREE_SIXTY_WORLD_PAGE+`/${tour.tourUrlToken}`;
                                    setShowEmbeddedModal((pre)=> ({...pre, url: publicLInk, show: true}));
                                } 
                                }
                            />
                        </div>
                    </CardFooter>
                </Card>
            </Col>
    }

    const toggleSharModal = (tour)=>{
        setOpenSharModal(!openSharModal);
    }

    const onInputChange = (e)=>{
        switch(e.target.name){
            case "virtual-tour":
                setupdatedVirtualTourName(e.target.value.replace(/[^a-zA-Z0-9\s]/g, ''));
                setupdatedvirtualTourNameErr(false)
            break;
            case "virtual-tour-vtDescription":
                setVTDescription(e.target.value);
                setVtDescriptionErr(false)
            break;
        }
    }
     
    const getUpdateTour = (tour)=>{ 
        setupdatedVirtualTourNameDATA(tour);
        setupdatedVirtualTourName(tour.name);
        setVTDescription(tour.tourDescription)
        setModal(!modal);

        if(tour.tourLogo){
            console.log("logo= logoData 1");
            setImgURLOnGoingCropping(tour.tourLogo);
            setChoseFile(tour.tourLogo);
            setSavedImgUrl(tour.tourLogo);
        }else{
            console.log("logo= logoData 2");
            setImgURLOnGoingCropping("");
            setChoseFile("");
            setSavedImgUrl("");
        }
    }

    const updateTour= async()=>{
        
        toast.dismiss();

        if(validator.isEmpty(updatedVirtualTourName.trim())){
            toast.error("Please Enter Tour Name", { });
            setupdatedvirtualTourNameErr(true)
            return;
        }else if(!validator.isLength(updatedVirtualTourName.trim(), {min: 1, max: 25})){
            toast.error("Tour Name length should be minimum 3 char and maximum 25 char", { });
            setupdatedvirtualTourNameErr(true)
            return;
        }

        let mappingData = {
            name: updatedVirtualTourName,
            id: updatedVirtualTourNameDATA.id,
            TourDescription: vtDescription
        }

        let logoData={
            imgURLOnGoingCropping: imgURLOnGoingCropping,
            savedImgUrl: savedImgUrl,
            file: choseFile,
            tourId: updatedVirtualTourNameDATA.id
        }

        try{
            console.log('object', mappingData)
            dispatch(updateVirtualTourDispatch(mappingData, logoData));
            closeUpdatedModal()
        }catch (err){ 
            if(err!=undefined && err.isError!= undefined && err.isError) toast.error(err.responseException.exceptionMessage, { });
        }
    }

    const onUploadFile = (event) => {
        if (event.target.files && event.target.files.length > 0) {
          const reader = new FileReader();
    
          reader.addEventListener("load", () => {
            const image = reader.result;
            setImageToCrop(image);
          });
    
          reader.readAsDataURL(event.target.files[0]);

          dispatch(loaderDispatch(true))
          setTimeout(() => {
            setCrpImgModal(!crpImgModal)
            dispatch(loaderDispatch(false))
          }, 500);
        }
    };

    const onCancelCropImg =()=>{
        setChoseFile(updatedVirtualTourNameDATA.tourLogo);
        setImgURLOnGoingCropping(updatedVirtualTourNameDATA.tourLogo);
        setCrpImgModal(!crpImgModal);
        fileInputRef.current.value = null;
        setKey((prevKey) => prevKey + 1);

    }

    const base64toBlob = (data) => {
        const base64WithoutPrefix = data.replace(/^data:image\/\w+;base64,/, "");
        const bytes = atob(base64WithoutPrefix);
        let length = bytes.length;
        let out = new Uint8Array(length);
        while (length--) {
          out[length] = bytes.charCodeAt(length);
        }
        return new Blob([out], { type: "image/jpeg" });
      };
    

    return (
        <React.Fragment>
            <Home>
            <ExpiredModal />
                {sharableModal()}
                <div className="ps-3 pe-3 mb-3">
                    {/* SHARED USERS INFORMATIONS  */}
                    <div className="white-box">
                        <div className="pageTitle pt-0">
                            <Row>
                                <Col xs="12">
                                    <h5>360° Tours</h5> 
                                </Col>
                            </Row>
                        </div>
                        <div className="userCard virtual-tour-and-img mt-2">
                        {getVirtualList()} 
                        </div>
                    </div>
                </div>

                {/* ADD VIRTUAL-TOUR BUTTON */}
                <AddVirtualTourButton /> 

                {/* UPDATED VIRTUAL-TOUR MODAL */}
                <Modal isOpen={modal} centered className="drop-modal" toggle={()=>{
                    closeUpdatedModal()
                    }} >
                    <Button className="modalClose" onClick={()=>{
                        closeUpdatedModal()
                    }}>
                        <img src={Close} alt="Images" className="img-fluid" />
                    </Button>
                    <ModalBody>
                        <div>
                            <h5 className="text-center text-primary mt-1 white-color">Update</h5>
                        </div>
                        <div className="share_people p-2">
                            <div class="row">
                                <div className="col-12">
                                    <FormGroup>
                                        <label className="form-label font-medium">Rename Tour</label>
                                        <input value={updatedVirtualTourName} type="text" placeholder="Enter tour name"
                                        className={classNames("form-control", { "form-error": updatedVirtualTourNameErr })} name={'virtual-tour'} onChange={onInputChange}
                                        />
                                    </FormGroup>

                                    <div className=" p-0">
                                        <label className="mb-1">Tour Description</label>
                                        <FormGroup>
                                            <textarea rows="5" value={vtDescription} type="text" placeholder="Enter tour description"
                                            className={classNames("form-control", { "form-error": vtDescriptionErr })} name={'virtual-tour-vtDescription'} onChange={onInputChange}
                                            />
                                        </FormGroup> 
                                    </div>

                                    <div className=" p-0">
                                        <label className="mb-1">Tour Logo</label>
                                        <FormGroup>
                                            <span className="ms-2" color="primary" >
                                                <img src={imgURLOnGoingCropping} alt="Upload" style={{ "height": "60px", "width": "120px", 
                                                    "border-radius": "12px"}} />  
                                            </span>

                                            <span className="ms-2 vrirual-tour-upload-logo-button" color="primary" >
                                                <input 
                                                    key={key}
                                                    type="file" 
                                                    accept="image/*"                                         
                                                    name="defaultVtLogo"
                                                    ref={fileInputRef}
                                                    onChange={onUploadFile} 
                                                />
                                                <img src={Upload} alt="Upload" />  
                                            </span>

                                            <span className="ms-2 vrirual-tour-upload-logo-button" color="primary" 
                                                onClick={()=>{

                                                    const base64String = TourLogo;
                                                    const blob = base64toBlob(base64String);
                                                    setChoseFile(blob);
                                                    setImgURLOnGoingCropping(TourLogo);
                                                }}                      
                                            >
                                                <img src={RemoveTour} alt="Upload" />  
                                            </span>
                                        </FormGroup>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="row">
                                <div className="col-12 mt-4">
                                    <button 
                                        className="btn btn-primary"
                                        onClick={updateTour}
                                    >Save</button>
                                    <button 
                                        className="btn btn-primary btn-cancel ms-4"
                                        onClick={closeUpdatedModal}
                                    >Cancel</button>
                                </div>
                            </div>
                        </div> 
                    </ModalBody>
                </Modal>

                <Modal isOpen={crpImgModal} centered className="modal-lg medium outside-close-modal" toggle={onCancelCropImg} >
                    <button onClick={onCancelCropImg}
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    ></button>
                    <ModalHeader className="text-center">Crop Image</ModalHeader>
                    <ModalBody className="mb-0 text-center">
                    <ImageCropper
                        imageToCrop={imageToCrop}
                        onImageCropped={(croppedImageBlob, croppedImageBase64URL, stop=null) =>{
                            setChoseFile(croppedImageBlob);
                            setImgURLOnGoingCropping(croppedImageBase64URL);
                        }}

                        // minWidth= {120}
                        // minHeight= {60}
                        initialAspectRatio= {2/1}
                        minWidthDataURL= {120}
                        minHeightDataURL= {60}
                        minWidthBlob= {120}
                        minHeightBlob= {60}
                        viewMode= {0}
                    />
                    </ModalBody>
                        <label className="mb-1 upload-logo-restriction" style={{
                            // "font-size": "11px",
                            // "position": "absolute",
                            // "bottom": "70px",
                            // "left": "91px",
                        }}>
                        Kindly note that your Logo will appear within 120x60 canvas in the 360 viewer
                        </label>
                    <ModalFooter className="justify-content-center">
                        <Button className="me-2 btn " color="primary" onClick={()=>{
                            setCrpImgModal(!crpImgModal)
                            setKey((prevKey) => prevKey + 1);
                        }}>
                            <img src={UploadButton} alt="Upload" />Upload
                        </Button>
                        <Button className="ms-3 text-dark btn-me-cancel" color="light" onClick={onCancelCropImg}>
                            Cancel
                        </Button>
                        
                    </ModalFooter>
                </Modal>

                 {/* EMBEDED CODE MODAL */}
                 <Modal isOpen={showEmbeddedModal.show} centered className="drop-modal" toggle= {()=>{
                    setShowEmbeddedModal({...showEmbeddedModal, show: false});
                    setDidCopied(false);
                }}>
                       <Button className="modalClose" onClick={()=>{
                             setShowEmbeddedModal({...showEmbeddedModal, show: false});
                             setDidCopied(false);
                        }}>
                            <img src={Close} alt="Images" className="img-fluid" />
                        </Button>
                        <ModalHeader className="mt-2 pb-1 px-0">Copy Embedded Code</ModalHeader>
                        
                        
                        <ModalBody className="pt-0">
                            <div className="share-field mx-2 mb-3">
                                <input value={showEmbeddedModal.url} disabled className="form-control text-truncate" />
                                <CopyToClipboard text={showEmbeddedModal.url} onCopy={() => {
                                    if(!didCopied){
                                        setDidCopied(true);
                                        toast.dismiss();
                                        toast("Copied Successfully", { })
                                    }
                                }}>
                                    <span >{didCopied? "Copied": "Copy"}</span>
                                </CopyToClipboard>
                            </div>
                        </ModalBody>
                        
                </Modal> 


            </Home>
        </React.Fragment>
    )
}