import React, {useState, useEffect } from "react";
import HeaderSideBar from "./../index";
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from "react-infinite-scroll-component";
import ImagesCardList from "./../../../COMPONENTS/360VirtualTour/Images/ImagesCardList";
import { useLocation, useNavigate} from "react-router-dom";
import { EMPTY_360_TOUR_DESCRIPTION_TEXT, EMPTY_360_TOUR_IMAGES_DESCRIPTION_TEXT, EMPTY_360_TOUR_IMAGES_PRIMARY_TEXT, EMPTY_ASSEST_DESCRIPTION_TEXT, EMPTY_ASSEST_PRIMARY_TEXT } from "./../../../UTILITYS/Constant";
import Asset from './../../../ASSETS/Images/xr/no-assets.png';
import NoContentWithOutUploadPage from "./../../../COMPONENTS/NoContentPage/NoContentWithOutUploadPage";
// import { VRfetchImagesDispatch, VRresetAssests } from "./../Slices/virtualTourImages.slice";
import Breadcrum from "./../../../COMPONENTS/Breadcrum";
// import BG from './../../../ASSETS/Images/threeSixtyBanner.jpg';
import BG from './../../../ASSETS/Images/threeSixtyBanner.jpg';
import MobileBG from './../../../ASSETS/Images/Mobile-BG.jpg';
import { setParentModuleActiveDispatch } from "./../../../COMPONENTS/SideBarMenu/activeParentModule.slice";
import { setModuleActiveDispatch } from "./../../../COMPONENTS/SideBarMenu/activeModule.slice";
import { deleteVirtualTourImageDispatch, fetchVirtualTourImagesDispatch, resetvirtualTourImages, updateVirtualTourImageDispatch } from "../Slices/VirtualTourImages.slice";
import NoContentWithAddVirtualImagesButton from "../../../COMPONENTS/NoContentPage/NoContentWithAddVirtualImagesButton";
import { isFileUploadingDispatch } from "../../360virtualTour/Slices/VirtualTourImages.slice";
import { LoaderForIndividualPageDispatch } from "../../../COMPONENTS/Loader/LoaderForIndividualPage.slice.reducer";
import ThreeSixtyImageUploader from "../../../COMPONENTS/DRAGE_FILE_FOLDER/ThreeSixtyImageUploader";
import ThreeSixtyImageUploderButton from "../../../COMPONENTS/UploadButton/ThreeSixtyImageUploderButton";
import { Button, Modal, ModalBody, FormGroup, Input, ModalFooter, Row, Col, ModalHeader } from 'reactstrap';
import { toast } from 'react-toastify';
import validator from 'validator';
import Close from './../../../ASSETS/Images/icons/cancel.png'
import classNames from 'classnames';
import Share from './../../../ASSETS/Images/xr/sahre.png'
import DeleteIcon from './../../../ASSETS/Images/xr/deleteIcon.png' 
import EmbeddedIcon from './../../../ASSETS/Images/icons/embedded-icon.png' 
import SharableModal from "../../../COMPONENTS/SharableModal";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { DOMAIN_URL, VIEWER_THREE_SIXTY_WORLD_PAGE } from "../../../APIs";
import { ExpiredModal } from "../../../COMPONENTS/Modal/Expired";
import { setDoNotShowExpiredModal } from "../../Profile/slice.reducer";

function ImageAssest(props){
    
    const dispatch = useDispatch(); 
    const navigate = useNavigate();
    const LoaderForIndividualPage = useSelector(state => state.LoaderForIndividualPage)
    const virtualTourImages = useSelector(state => state.virtualTourImages);
    const userProfileDetails = useSelector(state => state.userProfileDetails)
    let {state}  = useLocation();
    const [openSharModal, setOpenSharModal] = useState(false);
    const [loadVirtualImages, setLoadVirtualImages] = useState(1);
    const [isSaving, setIsSaving] = useState(false);
    const [isVirtualImageEmpty, setIsVirtualImageEmpty] = useState(true);
    const [tour] = useState(state)
    const [modal, setModal] = useState(false);
    const [deleteImgmodal, setdeleteImgmodal] = useState(false);
    const [updateName, setUpdateName] = useState('');
    const [updateNameErr, setUpdateNameErr] = useState(false)
    const [updatedImgOrDeletedImgDATA, setupdatedImgOrDeletedImgDATA] = useState({})
    const [dropDownTourImages, setDropDownTourImages] = useState({});
    const [didCopied, setDidCopied] = useState(false);
    const [showEmbeddedModal, setShowEmbeddedModal] = useState({
        show: false,
        url: ""
    });
    
    useEffect(()=>{
        dispatch(resetvirtualTourImages())
        dispatch(setParentModuleActiveDispatch("threeSixtyTour"));
        dispatch(setModuleActiveDispatch(""));
        dispatch(LoaderForIndividualPageDispatch(true))

        let publicLInk =`<iframe src="${DOMAIN_URL}${VIEWER_THREE_SIXTY_WORLD_PAGE}/${tour.tourUrlToken}" width="600" height="400" frameborder="0" allowfullscreen></iframe>`;
        // publicLInk =DOMAIN_URL+VIEWER_THREE_SIXTY_WORLD_PAGE+`/${tour.tourUrlToken}`;
        setShowEmbeddedModal((pre)=> ({...pre, url: publicLInk}));
    }, []);

    const closeUpdatedModal = () => {
        setupdatedImgOrDeletedImgDATA({})
        setModal(!modal);
    }

    const closeDeletedModal = () => {
        setupdatedImgOrDeletedImgDATA({})
        setdeleteImgmodal(!deleteImgmodal);
    }

    useEffect(()=>{
        if(virtualTourImages.isImgResGet){
            dispatch(LoaderForIndividualPageDispatch(false))
        }
    }, [virtualTourImages.isImgResGet]);

    useEffect(()=>{
        dispatch(fetchVirtualTourImagesDispatch(loadVirtualImages, 25, tour.id)); 
    },[loadVirtualImages]) 

    const fetchMoreData = () => {
        // LoaderForIndividualPage.open
        if(!isSaving){
            setLoadVirtualImages(loadVirtualImages+1)
        }
    };

    useEffect(()=>{
        if( virtualTourImages.totalLength === 0){
            setIsVirtualImageEmpty(true)
        }else{
            setIsVirtualImageEmpty(false)
        }
    }, [virtualTourImages.images])   
    
    
    const onUpdateHanler = (img)=>{
        if(userProfileDetails.isExpired ){
            toast.dismiss()
            dispatch(setDoNotShowExpiredModal(false));
            // setOpenUpgrade(true);
            toast("Your subscription has been expired");
            return;
        }
        setupdatedImgOrDeletedImgDATA(img);
        setUpdateName(img.name);
        setModal(!modal);
    }

    const onDeleteHanler = (img)=>{
        if(userProfileDetails.isExpired ){
            toast.dismiss()
            dispatch(setDoNotShowExpiredModal(false));
            // setOpenUpgrade(true);
            toast("Your subscription has been expired");
            return;
        }
        setupdatedImgOrDeletedImgDATA(img);
        setdeleteImgmodal(!deleteImgmodal);
    }

    const getVirtualImagesList = ()=>{
       return  <InfiniteScroll
                    className="row"
                    dataLength={virtualTourImages.images}
                    next={fetchMoreData}
                    hasMore={virtualTourImages.hasMoreVirtualTourImages}
                    loader={virtualTourImages.totalLength > 25 ?<h4>Loading...</h4>: ""}
                    endMessage={
                    <p style={{ textAlign: "center" }}>
                        {/* <b>Yay! You have seen it all</b> */}
                    </p>
                    }
                    // scrollThreshold= {0.9}
               >
               {getVirtualImag()}
           </InfiniteScroll>
      
    } 

    const goToTSworldHandler =async(img) =>{
        await localStorage.removeItem('virtualTourImages');
        await localStorage.setItem('virtualTourImages', JSON.stringify(virtualTourImages));
        await localStorage.setItem('virtualTour', JSON.stringify(tour));
        navigate('/virtual-tour-world', {state: {...img, ParentBreadCrumbName: tour.name}});
    }

    const getVirtualImag = ()=>{
        return virtualTourImages.images.map((img, index)=>{

            return <ImagesCardList   
                    img={img} 
                    index ={index} 
                    key ={index} 
                    isFF={true} 
                    assests= {virtualTourImages.images}
                    getCheckBoxClasses ={()=>{}}
                    onUpdate ={onUpdateHanler}
                    onDelete= {onDeleteHanler}
                    onGoToTSworld= {()=>goToTSworldHandler(img)}
                    dropDownTourImages= {dropDownTourImages}
                    setDropDownTourImages= {setDropDownTourImages}
                    shouldShowDeleteButton= {virtualTourImages.totalLength > 1}
                /> 
        })
    }

    const onFileUpload = ()=>{
        dispatch(resetvirtualTourImages());
        console.log("resetvirtualTourImagesresetvirtualTourImages 1", loadVirtualImages);
        if(loadVirtualImages !== 1){
            console.log("resetvirtualTourImagesresetvirtualTourImages 2", loadVirtualImages);
            setLoadVirtualImages(1);
        }else{
            console.log("resetvirtualTourImagesresetvirtualTourImages 3", loadVirtualImages);
            dispatch(fetchVirtualTourImagesDispatch(loadVirtualImages, 25, tour.id))
        }
        dispatch(LoaderForIndividualPageDispatch(true))
        dispatch(isFileUploadingDispatch(false))
        setIsSaving(false);
    } 

    const onInputChange = (e)=>{
        switch(e.target.name){
            case "update-name":
                setUpdateName(e.target.value.replace(/[^a-zA-Z0-9\s]/g, ''));
                setUpdateNameErr(false)
            break;
        }
    }

    const updateImage= async()=>{
        
        toast.dismiss();

        if(validator.isEmpty(updateName.trim())){
            toast.error("Please Enter Image Name", { });
            setUpdateNameErr(true)
            return;
        }else if(!validator.isLength(updateName.trim(), {min: 1, max: 25})){
            toast.error("Image Name length should be minimum 3 char and maximum 25 char", { });
            setUpdateNameErr(true)
            return;
        }

        let mappingData = {
            name: updateName,
            id: updatedImgOrDeletedImgDATA.id 
        }

        try{
            console.log('object', mappingData)
            dispatch(updateVirtualTourImageDispatch(mappingData));
            // toast("Image updatad Successfully.");
            // dispatch(resetVirtualTour());
            // dispatch(fetchVirtualTourDispatch(1, 25));
            closeUpdatedModal()
          }catch (err){
            if(err!=undefined && err.isError!= undefined && err.isError) toast.error(err.responseException.exceptionMessage, { });
          }

    }

    const deleteImage =async ()=>{
        let deleteAssestDATA = updatedImgOrDeletedImgDATA;
        try{
            console.log("deleteAssestDATAdeleteAssestDATA", deleteAssestDATA)
            dispatch(deleteVirtualTourImageDispatch({id: deleteAssestDATA.id}));
            closeDeletedModal()
        }catch (err){
            if(err!=undefined && err.isError!= undefined && err.isError) toast.error(err.responseException.exceptionMessage, { });
        }
    } 

    const sharableModal = ()=>{

        return <SharableModal
            tour= {tour}
            openSharModal= {openSharModal}
            setOpenSharModal= {setOpenSharModal}
        />
    }

    if(isVirtualImageEmpty){ 
    // if(true){ 
        return <HeaderSideBar> 
            <NoContentWithAddVirtualImagesButton 
                breadCrumbItems= {["VR Experience", "Images" ]}
                primaryText= {EMPTY_360_TOUR_IMAGES_PRIMARY_TEXT}
                description= {EMPTY_360_TOUR_IMAGES_DESCRIPTION_TEXT}
                noImgIcon= {Asset}
                onFileUpload ={onFileUpload}
                tour ={tour}
            />
        </HeaderSideBar>
    }
 
    return ( 
        <React.Fragment>
            <HeaderSideBar>
            <ExpiredModal />
                {sharableModal()}
                {/* <div className="innerHeader ps-3 pe-3 ps-md-4 pe-md-4">
                    <div className="row align-items-center">
                            <Breadcrum 
                                breadCrumbItems= {["VR Experience", "Images"]}
                            />
                    </div>
                </div> */}
            <div className="container-fluid">
               <div className="white-box">
                    <div className="pageTitle">
                            <div className="imageHeader">
                                <img src={BG} alt="banner" className="d-none d-md-block w-100" style={{height: "220px"}}/>
                                {/* <div class="headerbg"></div> */}
                                <img src={MobileBG} alt="banner" className="d-block d-md-none w-100" />
                                <h3>{tour.name}</h3>
                            </div>
                        </div>
                        
                        <div className="mt-3 mb-3">    
                            <Row className="align-items-center mb-2">
                                <Col xs={7} sm={6}><h5 className="mb-0">360° Experiences</h5></Col>
                                {
                                    virtualTourImages.totalLength !==0 && 
                                    <Col xs={5} sm={6} className="text-end"> 
                                        <button 
                                            className="btn btn-primary btn-small share-btn" style={{marginRight: "12px"}}
                                            onClick={()=>{
                                                if(userProfileDetails.isExpired ){
                                                    toast.dismiss()
                                                    dispatch(setDoNotShowExpiredModal(false));
                                                    // setOpenUpgrade(true);
                                                    toast("Your subscription has been expired");
                                                    return;
                                                }
                                                setShowEmbeddedModal({...showEmbeddedModal, show: true})
                                            }}
                                        ><img className="me-2" src={EmbeddedIcon} alt="Share"/>
                                            Embed 360 Tour
                                        </button>
                                        
                                        <button 
                                            className="btn btn-primary btn-small share-btn"
                                            onClick={()=> setOpenSharModal(true)}
                                        ><img className="me-2" src={Share} alt="Share"/>
                                            Share Tour
                                        </button>
                                    </Col>
                                }
                            </Row>        
                            <div className="assets_item mt-3 virtual-tour-and-img">
                                {getVirtualImagesList()}
                            </div>
                        </div>
                </div>
               </div>


                {/* UPLOAD 360 IMAGES */}
                <ThreeSixtyImageUploderButton   
                    onFileUpload ={onFileUpload}
                    tour ={tour}
                    setIsSaving ={setIsSaving}
                />

                {/* UPDATE IMAGE */}
                <Modal isOpen={modal} centered className="drop-modal" toggle={()=>{
                    closeUpdatedModal()
                    }} {...props}>
                    <Button className="modalClose" onClick={()=>{
                        closeUpdatedModal()
                    }}>
                        <img src={Close} alt="Images" className="img-fluid" />
                    </Button>
                    <ModalBody>
                        <div>
                            <h5 className="text-center text-primary mt-1 white-color">Update</h5>
                        </div>
                        <div className="share_people p-2">
                            <div class="row">
                                <div className="col-12">
                                    <FormGroup>
                                        <label className="form-label font-medium">Rename Tour</label>
                                        <input value={updateName} type="text" placeholder="Enter name"
                                        className={classNames("form-control", { "form-error": updateNameErr })} name={'update-name'} onChange={onInputChange}
                                        />
                                    </FormGroup>
                                </div>
                            </div>
                            
                            <div className="row">
                                <div className="col-12 text-center mt-4">
                                    <button 
                                        className="btn btn-primary"
                                        onClick={updateImage}
                                    >Save</button>
                                     <button 
                                        className="btn btn-primary btn-cancel ms-4"
                                        onClick={closeUpdatedModal}
                                    >Cancel</button>
                                </div>
                            </div>
                        </div> 
                    </ModalBody>
                </Modal>

                {/* DELETE IMAGE */}
                <Modal isOpen={deleteImgmodal} centered className="modal-medium inner-close" toggle= {closeDeletedModal} {...props}>
                        <ModalHeader toggle= {closeDeletedModal}>
                        </ModalHeader>
                        <div className="text-center mb-3">
                            <span className="delete-modal"><img src={DeleteIcon} alt="Images" className="img-fluid mb-4" /></span>
                        </div>
                        <ModalBody>
                            <div className="text-center mt-3">
                               
                                <h6>You are about to delete a 360<sup>o</sup> Experience</h6>
                                <p className="small">this 360<sup>o</sup> Experience will be deleted from the tour<br />Are you sure?</p>
                            </div>  
                        </ModalBody>
                        <ModalFooter className="justify-content-center pt-0">
                            <Button className="me-2 btn-long" color="danger" onClick={deleteImage}>Delete</Button>
                            <Button className="ms-2  btn-long" color="outline-secondary" onClick={closeDeletedModal}>Cancel</Button>
                        </ModalFooter>
                </Modal> 
                
                {/* EMBEDED CODE MODAL */}
                <Modal isOpen={showEmbeddedModal.show} centered className="drop-modal" toggle= {()=>{
                    setShowEmbeddedModal({...showEmbeddedModal, show: false});
                    setDidCopied(false);
                }} {...props}>
                       <Button className="modalClose" onClick={()=>{
                             setShowEmbeddedModal({...showEmbeddedModal, show: false});
                             setDidCopied(false);
                        }}>
                            <img src={Close} alt="Images" className="img-fluid" />
                        </Button>
                        <ModalHeader className="mt-2 pb-1 px-0">Copy Embedded Code</ModalHeader>
                        
                        
                        <ModalBody className="pt-0">
                            <div className="share-field mx-2 mb-3">
                                <input value={showEmbeddedModal.url} disabled className="form-control text-truncate" />
                                <CopyToClipboard text={showEmbeddedModal.url} onCopy={() => {
                                    if(!didCopied){
                                        setDidCopied(true);
                                        toast.dismiss();
                                        toast("Copied Successfully", { })
                                    }
                                }}>
                                    <span >{didCopied? "Copied": "Copy"}</span>
                                </CopyToClipboard>
                            </div>
                        </ModalBody>
                        
                </Modal> 
            </HeaderSideBar>
        </React.Fragment>
    )
}

export default (ImageAssest)