export const SIDE_MENU_ITEMS ={
    fistMenuItems: [
        {
            subModule: "profile"
        },
        {
            subModule: "changePassword"
        }
    ],

    forthMenuItems: [
        {
            subModule: "virtualTour"
        }
    ]
} 
 
export const PARENT_MODULE_ITEMS ={
    fistMenuItems: [
        {
            module: "parentDashboard"
        }
    ],
    secondMenuItems: [
        {
            module: "parentProfile"
        }
    ],
    thirdMenuItems: [
        {
            module: "threeSixtyTour"
        }
    ],
    fifthMenuItems: [
        {
            module: "parentAnalytics"
        }
    ],
    sevenMenuItems: [
        {
            module: "parentSubscription"
        }
    ],
    eightMenuItems: [
        {
            module: "threeSixtyVedio"
        }
    ]
} 

