import React, {useState} from "react";
import { Col, Card, CardBody, CardFooter, Row,Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import Delete from './../../../ASSETS/Images/xr/trash.png';
import Edit from './../../../ASSETS/Images/xr/edit1.png';
import defaultThreeSixty from './../../../ASSETS/Images/360Tours/defaultThreeSixty.jpg';
import More from './../../../ASSETS/Images/xr/more.png';
import EditDelete from "../../DropDown/EditDelete";
import Animate_360 from './../../../ASSETS/Images/icons/animate-360.gif'

export default (props)=>{
 
    const dispatch = useDispatch(); 
    const assestsData = useSelector(state => state.assestsData);
    const sharedAssestWithMeOrBy = useSelector(state => state.sharedAssestWithMeOrBy);
    const [isOpenCarousel, setIsOpenCarousel] = useState(false); 

    const closeCarousel = ()=>{
        setIsOpenCarousel(false)
    }

    return (
        <React.Fragment >
            <Col  lg="6" className="" key={props.img.id}>
                <Card className="mb-4 cursor-pointer" >
                    <CardBody className="p-2 pb-0 text-center" onClick={()=>props.onGoToTSworld()}>
                        <div className="curved-image-wrap_tour">
                            <div className="Three-sixty-icon-overlay" >
                                <img 
                                    style={{height: "65px"}}
                                    src={Animate_360} alt="image" 
                                />
                            </div>
                            <img src={props.img.thumbnailUrl? props.img.thumbnailUrl: defaultThreeSixty} alt="image" className="assets_image" />
                        </div>
                    </CardBody>
                    <CardFooter className="p-2 pt-0">
                        <div className="image-card-title">
                            <p className="mb-0 text-center ">{props.img.name}</p>
                            <EditDelete
                                    {...props}
                                    dropDownTourImages= {props.dropDownTourImages}
                                    tourImages= {props.img}
                                    onClick={()=>{
                                        props.setDropDownTourImages(props.img);
                                    }}
                                />
                        </div>
                        {/* <Row className="align-items-center"> 
                            <Col xs={11} md={11}>
                                <p className="mb-0 text-center ">{props.img.name}</p>
                            </Col>
                                <EditDelete
                                    {...props}
                                    dropDownTourImages= {props.dropDownTourImages}
                                    tourImages= {props.img}
                                    onClick={()=>{
                                        props.setDropDownTourImages(props.img);
                                    }}
                                />
                            <Col col={1} className="align-end" > 
                                <Dropdown isOpen={dropdownOpen} direction='bottom'>
                                    <DropdownToggle caret className="btn btn-link more-btn" onClick={toggle} > 
                                        <img src={More} alt="More" />
                                    </DropdownToggle>
                                    <DropdownMenu {...args}>
                                        <DropdownItem onClick={()=>{props.onUpdate(props.img)}}>
                                            <img src={Edit}  className="me-2 me-md-3" alt="Images"  />
                                            Edit             
                                        </DropdownItem>
                                        <DropdownItem className="text-danger" onClick={()=>{props.onDelete(props.img)}}>
                                            <img src={Delete} className="me-2 me-md-3" alt="Images"  />
                                            Delete           
                                        </DropdownItem>
                                        
                                    </DropdownMenu>
                                </Dropdown>
                            </Col>
                        </Row>  */}
                    </CardFooter>
                </Card>
            </Col> 

            {/* CAROUSAL MODAL */}
                {/* <CarouselModal 
                    assests= {props.assests}
                    isOpenCarousel= {isOpenCarousel}
                    closeCarousel ={closeCarousel}
                    index ={props.index}
                /> */}
        </React.Fragment>
    )
}