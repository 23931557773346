import React, {useEffect} from "react";
import Parent from '.'
import './ForgetResetPasswordStyle.module.css'
import Logo from './../../../COMPONENTS/Logo'
import Container from './../index.container'
import CopyRight from "../../../COMPONENTS/CopyRight";
import {useLocation, useNavigate} from "react-router-dom";
import sendEmail from './../../../ASSETS/Images/xr/email.png';

// THIS IS SEND EMAIL VIEW SCREEN 
export default (props) =>{

    let { state } = useLocation();
    const navigate = useNavigate()
  
    useEffect(()=>{
      console.log('this is confirmation props', state)
      if(state === undefined || state === null || state.email === undefined ){
        return navigate('/')
      }
    }, [])

    return (
        <Parent>
            <React.Fragment>
                <Container>      
                    <div className="text-center mb-4 mt-3">
                        <Logo logoClass="mb-1 pb-2" />
                        <div className="email-verification pb-3">
                            <img src={sendEmail} alt="Logo" className="mb-3 pb-1" /> 
                            <h5>Email Sent</h5>
                            <p>We sent an email to <a href={`mailto:${state!=undefined && state.email!= undefined && state.email}`} >{state!=undefined && state.email!= undefined && state.email}</a> with a link to reset your password.</p>
                        </div>
                    </div>
                    
                    {/* <CopyRight copyRightClassess = {["text-center", "copyright"]} /> */}
                </Container>
            </React.Fragment>
        </Parent>
    )
}