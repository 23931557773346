import React, { useState, useEffect } from "react";
import Dashboard from './../../ASSETS/Images/xr/home.png';
import User from './../../ASSETS/Images/xr/profile.png';
import Subscription from './../../ASSETS/Images/xr/subscription.png';
import ProfileImage from './../../ASSETS/Images/xr/profile-image.jpg';
import Tours from './../../ASSETS/Images/xr/360.png';
import Analytics from './../../ASSETS/Images/xr/growth.png';
import Parent from './index'
import { Nav, NavItem, NavLink, NavbarBrand} from 'reactstrap';
import NavBarTab from "../NavBarTab";
import LogoutCOM from '../Logout'
import {useLocation, useNavigate} from "react-router-dom";
import {SIDE_MENU_ITEMS as LIST_SUBMENU, PARENT_MODULE_ITEMS} from './moduleList'
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import {hamburgurDispatch} from './../../STORE/CommonSlices/hameBurgerOnOff';
import { setModuleActiveDispatch } from "./activeModule.slice";
import { setParentModuleActiveDispatch, setToggleForExpandAndColleps } from "./activeParentModule.slice";
import Logo from './../../ASSETS/Images/xr/logo.png';
import Border from './../../ASSETS/Images/xr/border.png';
import { resetVirtualTour } from "../../SCREENS/360virtualTour/Slices/VirtualTour.slice";

const SidebarComponent = (props) =>{ 
    
    const { pathname } = useLocation();
    const activeRout = pathname.split("/")[1];
    const navigate = useNavigate();
    const hamburgur = useSelector(state => state.hamburgur)
    const userProfileDetails = useSelector(state => state.userProfileDetails)
    const [userDetails, setUserDetails] = useState({});
    const dispatch = useDispatch();
    const activeModuleSlice = useSelector(state => state.activeModule);
    const activeParentModuleSlice = useSelector(state => state.activeParentModule);

    useEffect(()=>{
        setUserDetails(userProfileDetails)
    }, [userProfileDetails]);   
    const SIDE_MENU_ITEMS ={
        fistMenuItems: [
            {
                onClick: (subModule)=>{
                    dispatch(setModuleActiveDispatch(subModule))
                    dispatch(setParentModuleActiveDispatch("parentProfile"))
                    dispatch(hamburgurDispatch())
                    navigate('/viewProfile');
                }, 
                dropdownItemClasses: classNames({ "active": LIST_SUBMENU.fistMenuItems[0].subModule === activeModuleSlice.subModule }),
                subHeading: "Profile",
                subModule: "profile"
            },
            {
                onClick: (subModule)=>{
                    dispatch(setModuleActiveDispatch(subModule))
                    dispatch(setParentModuleActiveDispatch("parentProfile"))
                    dispatch(hamburgurDispatch())
                    navigate('/changePassword')
                },
                dropdownItemClasses: classNames({ "active": LIST_SUBMENU.fistMenuItems[1].subModule === activeModuleSlice.subModule }),
                subHeading: "Change Password",
                subModule: "changePassword"
            },
        ], 

        forthMenuItems: [
            {
                onClick: (subModule)=>{
                    dispatch(hamburgurDispatch());
                    navigate('/virtual-tour');
                },
                dropdownItemClasses: classNames({ "active": LIST_SUBMENU.forthMenuItems[0].subModule === activeModuleSlice.subModule }),
                subHeading: "360 Tours",
                subModule: "virtualTour"
            }
        ]
    } 

    const removeDrawer =() =>{
        dispatch(hamburgurDispatch())
    }

    const expandCollepsed = (bool)=>{
        if(bool && activeParentModuleSlice.toggleModul){  
            return true;
        }else{
            return false;
        }
    }

    return ( 
            <React.Fragment>
                <div 
                    className={classNames( "sideDrawer pb-0" ,{"active": hamburgur.isHamburgerOn })}
                >
                    <div className="p-2">
                        <div className="text-center p-2">
                            <NavbarBrand className="" href="#" onClick={()=>{
                                dispatch(setParentModuleActiveDispatch("parentDashboard"))
                                dispatch(hamburgurDispatch());
                                navigate("/home")
                            }}>
                                <img src={Logo} alt="Logo" className="img-fluid" />
                            </NavbarBrand>
                        </div>
                        <img src={Border} alt="border" className="w-100 px-3 opacity-05" />
                    </div>
                    
                    <div className="drawer-wrap custom-scrollbar">
                        <Nav vertical className="px-3">
                            <div className="d-inline-block d-md-none mobile-profile ps-2">
                                <NavLink href="#" className={userDetails.isPremium ? 'user-premium' : 'user-free'} onClick={()=> navigate('/viewProfile')}>
                                    <img src={userDetails.profilePicPath!=""?userDetails.profilePicPath: ProfileImage} className="profile-medium img-thumbnail mb-2  mt-3" alt="User" />
                                    <div className="mb-3">
                                    <span className="userPlan">{userDetails.isPremium ?"Premium": "Free" }</span>
                                    </div>
                                    <h4 className="mb-1 userName">{
                                userDetails.firstName !=undefined && userDetails.firstName.length >8 ? userDetails.firstName.substring(0, 8)+"...": userDetails.firstName
                                    } {
                                        userDetails.lastName !=undefined && userDetails.lastName.length >8 ? userDetails.lastName.substring(0, 8)+"...": userDetails.lastName
                                    }</h4>
                                    <p>{userDetails.email}</p>
                                </NavLink>
                            </div>

                            {/* DASHBOARD */}
                            <NavItem 
                            className={classNames({ "active": PARENT_MODULE_ITEMS.fistMenuItems[0].module === activeParentModuleSlice.module })}
                            >
                                <NavLink href="#" onClick={()=>{
                                    dispatch(hamburgurDispatch());
                                    navigate('/home');
                                }}>
                                    <div className="menu-icon">
                                        <img src={Dashboard} alt="Dashboard" />
                                    </div>
                                    <span
                                        className= {PARENT_MODULE_ITEMS.fistMenuItems[0].module === activeParentModuleSlice.module? "active-menu-text-color": "default-color"}
                                    >Dashboard</span>
                                </NavLink>
                            </NavItem>

                            {/* 360 VIRTUAL TOUR MODULE */}
                            <NavItem 
                                className={classNames({ "active": PARENT_MODULE_ITEMS.thirdMenuItems[0].module === activeParentModuleSlice.module })}
                            >
                                <NavLink href="#" onClick={()=>{
                                    dispatch(hamburgurDispatch());
                                    if(activeRout !== "virtual-tour"){
                                        dispatch(resetVirtualTour());
                                        navigate('/virtual-tour');
                                    }
                                }}>
                                    
                                    <div className="menu-icon">
                                        <img src={Tours} alt="360 tours" />
                                    </div>
                                    <span
                                        className= {PARENT_MODULE_ITEMS.thirdMenuItems[0].module === activeParentModuleSlice.module? "active-menu-text-color": "default-color"}
                                    >360<sup>o</sup> Tours</span>
                                </NavLink>
                            </NavItem>

                            {/* ANALYTICS */}
                            <NavItem 
                                className={classNames({ "active": PARENT_MODULE_ITEMS.fifthMenuItems[0].module === activeParentModuleSlice.module })}
                            >
                                <NavLink href="#" onClick={()=>{
                                    dispatch(hamburgurDispatch());
                                    navigate('/analytics');
                                }}>
                                    <div className="menu-icon">
                                        <img src={Analytics} alt="analytics" />
                                    </div>
                                    <span
                                        className= {PARENT_MODULE_ITEMS.fifthMenuItems[0].module === activeParentModuleSlice.module? "active-menu-text-color": "default-color"}
                                    >Analytics</span>
                                </NavLink>
                            </NavItem>

                            {/* SUBSCRIPTION */}
                            {/* <NavItem 
                                className={classNames({ "active": PARENT_MODULE_ITEMS.sevenMenuItems[0].module === activeParentModuleSlice.module })}
                            >
                                <NavLink href="#" onClick={()=>{
                                    dispatch(hamburgurDispatch());
                                    navigate('/subscription')
                                }}>
                                    <div className="menu-icon">
                                        <img src={Subscription} alt="parentSubscription" />
                                    </div>
                                    <span
                                         className= {PARENT_MODULE_ITEMS.sevenMenuItems[0].module === activeParentModuleSlice.module? "active-menu-text-color": "default-color"}
                                    >Subscription</span>
                                </NavLink>
                            </NavItem> */}
                            
                            {/* 360 VEDIO */}
                            <NavItem 
                                className={classNames({ "active": PARENT_MODULE_ITEMS.sevenMenuItems[0].module === activeParentModuleSlice.module })}
                            >
                                <NavLink href="#" onClick={()=>{
                                    dispatch(hamburgurDispatch());
                                    navigate('/subscription')
                                }}>
                                    <div className="menu-icon">
                                        <img src={Subscription} alt="parentSubscription" />
                                    </div>
                                    <span
                                        className= {PARENT_MODULE_ITEMS.sevenMenuItems[0].module === activeParentModuleSlice.module? "active-menu-text-color": "default-color"}
                                    >Subscription</span>
                                </NavLink>
                            </NavItem>
 
                            {/* PROFILE MODULE */}
                            <NavBarTab 
                                headingAvatar = {User}
                                tabHeading = {"Profile management"}
                                dropdownClasses= {classNames({ 
                                    "active show": PARENT_MODULE_ITEMS.secondMenuItems[0].module === activeParentModuleSlice.module,
                                    "menu__active": expandCollepsed(PARENT_MODULE_ITEMS.secondMenuItems[0].module === activeParentModuleSlice.module) 
                                })}
                                dropdownMenuClasses ={classNames(
                                     {
                                        "show": expandCollepsed(PARENT_MODULE_ITEMS.secondMenuItems[0].module === activeParentModuleSlice.module),
                                        "position-static": expandCollepsed(PARENT_MODULE_ITEMS.secondMenuItems[0].module === activeParentModuleSlice.module)
                                    })}
                                currentModule= "parentProfile"
                                setThisModuleActive ={(currentModule)=>{
                                    dispatch(setToggleForExpandAndColleps(!activeParentModuleSlice.toggleModul))
                                    if(activeParentModuleSlice.module === "parentProfile"){
                                        return;
                                    } 
                                    navigate('/viewProfile');
                                    dispatch(setParentModuleActiveDispatch(currentModule))
                                    dispatch(setToggleForExpandAndColleps(true))
                                }}
                                subMen = {SIDE_MENU_ITEMS.fistMenuItems} 
                            />

                            {/* LOG-OUT */}
                            <LogoutCOM onClick = {props.onLogout}/>
                        </Nav> 
                    </div>
                </div>
                <div className="drawerBackdrop" onClick={removeDrawer}></div>
            </React.Fragment>
    )
}

export default (props)=>{ 
    return (
        <Parent>
            <React.Fragment>
                <SidebarComponent {...props} />
            </React.Fragment>
        </Parent>
    )
}