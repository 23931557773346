import React, { useEffect } from "react";
import './index.css';
import { NavLink } from "react-router-dom";
import {useLocation, useNavigate} from "react-router-dom";
import Logo from './../../../ASSETS/Images/xr/logo.png';
import loginBackground from './../../../ASSETS/Images/xr/loginbg.jpg';
import verifyEmail from './../../../ASSETS/Images/xr/send.png';
import { toast } from 'react-toastify';
import {resendEmailOnConfirmUser} from './../../../UTILITYS/endPoints'
import { useDispatch } from 'react-redux'
import Container from './../index.container'

function ConfirmUser(props) {

    let { state } = useLocation();
    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(()=>{
      window.scrollTo(0, 0);
      if(state === undefined || state === null || state.email === undefined ){
        return navigate('/')
      }
    }, [])

    const applyResendEmailOnConfirmUser = async(e)=>{
      e!= undefined && e.preventDefault();
      try{
        let data = {
          email: state.email
        }
        let res= await resendEmailOnConfirmUser(data, dispatch);
        toast.dismiss();
        if(res!=undefined && res.result!= undefined && res.result.message) toast.error(res.result.message, { });
      }catch (err){
        if(err!=undefined && err.isError!= undefined && err.isError) toast.error(err.responseException.exceptionMessage, { });
        console.log('this is catch applyResetPassword', err)
      }

    }

    return (
      <React.Fragment>
        <Container>  
          <div className="text-center mb-3 mt-3">
            <img src={Logo} alt="Logo" className="mb-4 pb-2" />
            
            <div className="email-verification pb-3">
              <img src={verifyEmail} alt="Logo" className="mb-3 d-none" /> 
              <h4 className="mb-3">Verify your email address</h4>
              <p>Almost there! An email containing verification instructions was sent to <a href="mailto:xerxes@gmail.com">{state!=undefined && state.email!= undefined && state.email}</a></p>
            
              <div className="mt-4 pt-2">
                <p className="mb-0">Didn't receive the email? <a href="#" onClick={applyResendEmailOnConfirmUser}>Resend email</a></p>
                <hr />
                <p className="mb-0">Already have an account? <NavLink to="/login"><u>Login</u></NavLink></p>
              </div>
            </div>
          </div>
        </Container> 
      </React.Fragment>
    );
  }
  
  export default ConfirmUser;