import React, {useEffect, useState} from "react";
import { Button, Modal, ModalBody } from 'reactstrap'; 
import AddAssets from '../../../ASSETS/Images/xr/360-light.png';
import Close from '../../../ASSETS/Images/icons/cancel.png' 
import 'react-circular-progressbar/dist/styles.css';
import ThreeSixtyImageUploader from "../../DRAGE_FILE_FOLDER/ThreeSixtyImageUploader";
import { useSelector, useDispatch } from 'react-redux';
import { resetThreeSixtyVirtualTourDispatch } from "./../../../SCREENS/360virtualTour/Slices/threeSixtyVirtualImageOnUpload.slice";
import { toast } from "react-toastify";
import { setDoNotShowExpiredModal } from "../../../SCREENS/Profile/slice.reducer";
import { ExpiredModal } from "../../Modal/Expired";

export default (props)=>{
    
    const [modal, setModal] = useState(false); 
    const virtualTourImages = useSelector(state => state.virtualTourImages); 
    const userProfileDetails = useSelector(state => state.userProfileDetails)
    const dispatch = useDispatch();
 
    // console.log("process.env.REACT_APP_EXPERIENCE_UPLOAD_LIMIT",userProfileDetails.isPremium, !userProfileDetails.isExpired, virtualTourImages.totalLength, process.env.REACT_APP_EXPERIENCE_UPLOAD_LIMIT)    
    const toggle = () => {
        
        if(userProfileDetails.isExpired){
            toast.dismiss();
            dispatch(setDoNotShowExpiredModal(false));
            toast("Your subscription has been expired");
            return null;
        }
        
        // if(userProfileDetails.isPremium && !userProfileDetails.isExpired && virtualTourImages.totalLength >= process.env.REACT_APP_EXPERIENCE_UPLOAD_LIMIT){
        //     toast.dismiss();
        //     toast(`You can't upload more than ${process.env.REACT_APP_EXPERIENCE_UPLOAD_LIMIT} Experience`);
        //     return null;
        // }
        
        dispatch(resetThreeSixtyVirtualTourDispatch());
        if(modal && virtualTourImages.isFileUploadingState){
            props.onFileUpload(true);
            setModal(!modal);
            return;
        }
        setModal(!modal);
    }

    return (
        <React.Fragment>
            <ExpiredModal />
            {/* Add Asset button  */}
            <div className="addAssets">
                <button 
                    className="btn btn-primary"
                    onClick={toggle}
                ><img src={AddAssets} alt="add" className="me-2" />Add 360° Experiences</button>
            </div>
 
            <Modal isOpen={modal} centered className="drop-modal">
                <Button className="modalClose" onClick={toggle}>
                    <img src={Close} alt="Images" className="img-fluid" />
                </Button>
                <ModalBody>
                    <div className="Drop_Area"> 
                        <ThreeSixtyImageUploader 
                            preventToUpload = {()=>setModal(!modal)}
                            toggle = {toggle}
                            isThreeSixtyImage= {true}
                            tour ={props.tour}
                            {...props}
                        />
                    </div>
                    {/* <div className="inputUploaderLoader">
                        <div className="w-100 pe-3">
                            <div className="small mb-1"><label>Image.jpg</label> <span className="float-end">60%</span></div>
                            <div class="progress">
                                <div class="progress-bar" role="progressbar" style={{ width: '60%' }} aria-valuenow="60" aria-valuemin="0" aria-valuemax="60"></div>
                            </div>
                        </div>
                        <img src={Close} alt="Images" className="img-fluid cancel-upload cursor-pointer" />
                    </div> */}
                </ModalBody>
            </Modal>

        </React.Fragment>
    )
} 
