import React, { useEffect, useRef, useState, useContext } from "react";
import ReactDOM from 'react-dom';
import edit from './../../ASSETS/Images/icons/edit.png';
import AudioAsset from './../../ASSETS/Images/icons/AudioAssest2.jpg';
import Play from './../../ASSETS/Images/icons/play.svg';
// import Image from './../../ASSETS/Images/icons/Image.svg';
import Audio from './../../ASSETS/Images/icons/audio.svg';
// import Video from './../../ASSETS/Images/icons/Video.svg';
// import Location from './../../ASSETS/Images/icons/Location.svg';
// import Web from './../../ASSETS/Images/icons/web.svg';
import Image from './../../ASSETS/Images/icons/Image.png';
// import Audio from './../../ASSETS/Images/icons/audio.svg';
import Video from './../../ASSETS/Images/icons/Video_three_sixty.png';
import Location from './../../ASSETS/Images/icons/Lable.png';
import Web from './../../ASSETS/Images/icons/URL.png';
import HS from './../../ASSETS/Images/icons/location.png';
import CanelAddTagIcon from './../../ASSETS/Images/icons/cancel-add-tag-icon.png';
import AddTagIcon from './../../ASSETS/Images/icons/add-tag-icon.png';
import EditTagIcon from './../../ASSETS/Images/icons/edit-tag-icon.png';
import deleteTagIcon from './../../ASSETS/Images/icons/delete-tag-icon.png';
import { deviceType } from 'react-device-detect';
import deleteIcon from './../../ASSETS/Images/icons/delete.png';
import AddTag from './../../ASSETS/Images/icons/Cancel2x.png';
import Back from './../../ASSETS/Images/icons/Back.png';
import Collapse from './../../ASSETS/Images/icons/collapse.png';
import Expand from './../../ASSETS/Images/icons/Expand.png';
import CreateTokenContextProvider from "../../STORE/App-wide-state/Token/createTokenContextProvider.js";
import { useDispatch, useSelector } from 'react-redux'
import { getData } from "../../UTILITYS";
import { deleteVTtag, getAssetsForTagging, getSASurlToUploadVideosFiles, getVTtag, postVTtag, putDataTo, saveAssetToS_3 } from "../../UTILITYS/endPoints.js";
import { toast } from 'react-toastify';
import { Modal, ModalBody, Button, ModalFooter} from 'reactstrap'; 
import CancelIcon from './../../ASSETS/Images/icons/close.svg' 
import { useNavigate } from 'react-router-dom';
import validator from 'validator';
import { setParentModuleActiveDispatch } from "../SideBarMenu/activeParentModule.slice.js";
import MenuImg from './../../ASSETS/Images/xr/burger.png';
import Close from './../../ASSETS/Images/icons/cancel.png'
import MenuClose from './../../ASSETS/Images/xr/closeBurger.png';
import ReactPlayer from 'react-player'
import VideoPreview from "../Preview/VideoPreview.js";
import { THREE_SIXY_IMG_BASE_URL_TO_BE_SHOWN, DOMAIN_URL, VIEWER_THREE_SIXTY_WORLD_PAGE } from "../../APIs/index.js";
import Info from './../../ASSETS/Images/icons/Info.png';
import { setDoNotShowExpiredModal } from "../../SCREENS/Profile/slice.reducer.tsx";
import { ExpiredModal } from "../Modal/Expired.js";
import { fetchVirtualTourImagesDispatch, resetvirtualTourImages } from "../../SCREENS/360virtualTour/Slices/VirtualTourImages.slice.js";
// import { resetShareWithMeAndOthers } from "../../SCREENS/Home/slices/ShareWithMe.js";
import InfiniteScroll from "react-infinite-scroll-component";

function PenneliumReactOne(props) {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const panoramaRef = useRef(null);
  let clickStartPosition = { x: 0, y: 0 };
  let clickEndPosition = { x: 0, y: 0 };
  const [viewer, setviewer] = useState(null);
  const [isParonamaIMGloaded, setIsParonamaIMGloaded] = useState(0);
  const [currentVisibleHotspotID, setCurrentVisibleHotspotID] = useState("");  
  const [previousHSiD, setPreviousHSiD] = useState("");
  const tokenContext = useContext(CreateTokenContextProvider);
  const [isClickOnSavedTag, seIsClickOnSavedTag] = useState(false);
  const [isEnableNewTag, setIsEnableNewTag] = useState(false);
  const [isEnableDrawer, setIsEnableDrawer] = useState(false);
  const [isEnableActionDrawer, setIsEnableActionDrawer] = useState(false);
  const [isActEnableDrawer, setIsActEnableDrawer] = useState(false);
  const [isEnableDrawerItem, setIsEnableDrawerItem] = useState(true);
  const userProfileDetails = useSelector(state => state.userProfileDetails)
  const [fetchedHotSpot, setFetchedHotSpot] = useState([]);
  const [activeTagID, setActiveTagID] = useState("");
  const [HStobeDeleted, setHStobeDeleted] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  // const [virtualTourImages, setVirtualTourImages] = useState({});
  const virtualTourImages = useSelector(state => state.virtualTourImages);
  const [openList, setOpenList] = useState(false);
  const [imgToBeView, setImgToBeView] = useState({ fIleUrl: props.fIleUrl, id: props.id, name: props.name }); 
  const [addEditAssetsToViewIndrawer, setAddEditAssetsToViewIndrawer] = useState({});


  const [tageNameForAddEditTag, setTageNameForAddEditTag] = useState("");
  const [selectedOptionForAddEditTag, setSelectedOptionForAddEditTag] = useState("0");
  const [selectedImgPriviewForAddEditTag, setSelectedImgPriviewForAddEditTag] = useState("");
  const [selectedVideoPriviewUrlForAddEditTag, setSelectedVideoPriviewUrlForAddEditTag] = useState(null);
  const [selectedImgFileForAddEditTag, setSelectedImgFileForAddEditTag] = useState("");
  const [urlForAddEditTag, setUrlForAddEditTag] = useState("");
  const [isUrlValid, setIsUrlValid] = useState(false);
  
  // TAGGING
  const [selectedExperienceIdForHS, setSelectedExperienceIdForHS] = useState("");

  // TOUR INFO
  const [isTourInfoModalShow, setIsTourInfoModalShow] = useState(false);
  const [tourInfo, setTourInfo] = useState(null);

  const [loadVirtualImages, setLoadVirtualImages] = useState(1);
  
  useEffect(()=>{
    
    let virtualTour=null;
    async function getTourInfo(){
      virtualTour= await JSON.parse(localStorage.getItem("virtualTour"));
      console.log("tourInfotourInfotourInfo 1", virtualTour)
      setTourInfo(virtualTour);
    }
    getTourInfo()
    dispatch(setParentModuleActiveDispatch("parentVRexperience"))
    // dispatch(resetShareWithMeAndOthers("virtualTour"))
    return ()=>{
      localStorage.removeItem('isEditableTagData');
      localStorage.removeItem('isNewAddedTagData');
    }
  }, []);

  const deleteAssestToggle = () => {
    setDeleteModal(!deleteModal);
  }

  // GET HOT-SPOT AND MAP WITH `pannellum viewer`
  const fetchScenesArray= async()=>{

    let data= {
      id: imgToBeView.id
    }
    
    let dispatchObj= undefined;
    if(userProfileDetails.isUserFetch){
      dispatchObj=  dispatch;
    }

    let getScenesArrayPromiseJson= await getVTtag(data, dispatchObj, tokenContext.accessToken)
    let getScenesArray1= getScenesArrayPromiseJson.result;
      
    if(getScenesArray1.payload.length >0){
      getScenesArray1.payload.forEach(hotSpot =>{

        let createTooltipArgs= {
          pitch: hotSpot.pitchValue, yaw: hotSpot.pitchYaw, tagName: hotSpot.tagName, ...hotSpot, mainDBid: hotSpot.id, id: `edit-hotspot${hotSpot.uniqueTimestamp}`,
        }

        setFetchedHotSpot((prev)=>{
          return [...prev, createTooltipArgs]
        });
        

        let newHotspot = {
            id: `edit-hotspot${hotSpot.uniqueTimestamp}`,
            pitch: hotSpot.pitchValue,
            yaw: hotSpot.pitchYaw,
            cssClass: getIcon(createTooltipArgs.tagType),
            createTooltipFunc: showHotspot,
            // createTooltipArgs: "New Hotspot",
            createTooltipArgs,
            clickHandlerFunc: (e)=>{
              e.stopPropagation()
              setIsEnableNewTag(false);
              setActiveTagID(hotSpot.id);
              editTag(createTooltipArgs);
              viewer.lookAt(parseInt(hotSpot.pitchValue), parseInt(hotSpot.pitchYaw));
            },
            isAddInfoEnable: false,
            draggable: true,
            "dragHandlerFunc": dragHandlerFunc,
            "dragHandlerArgs": {id: 0}
          };
        // Add hotspot
        viewer.addHotSpot(newHotspot);
      });
    }
  }

  useEffect(() => {
    if(viewer!==null && userProfileDetails.isUserFetch){
      if(viewer.isLoaded() === undefined){
        let elem = document.getElementsByClassName('pnlm-load-box');
        let temp = "<div class='loader'><div class='lds-spinner'><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div>";
        elem[0].innerHTML = temp;
      }
    }
  })

  // useEffect(()=>{
  //   if(isParonamaIMGloaded){
  //     setTimeout(() => {
  //       let pnlmRenderContainer = document.querySelector('.pnlm-render-container');
  //       let addtags = document.querySelectorAll('.addtag');
        
  //       addtags.forEach((addtag) => {
  //         pnlmRenderContainer.appendChild(addtag);
  //       });
  //     }, 1000);
  //   }
  // }, [isParonamaIMGloaded]);

   // GET HOT-SPOT AND MAP WITH `pannellum viewer`
   useEffect(() => {
    if(viewer!==null){
      viewer.on('load', ()=>{
        fetchScenesArray();
        setIsParonamaIMGloaded(isParonamaIMGloaded+1);
        let elem = document.getElementsByClassName('pnlm-about-msg');
        if (elem.length > 0) {
          elem[0].remove();
        }
      })
    }
  }, [viewer])

  // POST HOT-SPOT TO BACK-END
  async function addNewHotSpot(createTooltipArgs) {
    // Create an object with the data to be sent in the request body

    let res={};
    let url="";
    let assetId= null;

    if(selectedOptionForAddEditTag == 3){
      const formData = new FormData(); 
      formData.append("files", selectedImgFileForAddEditTag);
      res=  await saveAssetToS_3(formData, dispatch, tokenContext.accessToken); 
      url="";
      assetId= res.result.payload[0].id;
    }

    if(selectedOptionForAddEditTag == 0){
      url="";
      assetId= null;
    }
    
    if(selectedOptionForAddEditTag == 5){
      url=selectedVideoPriviewUrlForAddEditTag;
      assetId= null;
    }else if(selectedOptionForAddEditTag == 4){
      url=urlForAddEditTag;
      assetId= null;
    }

    let addTagData= {
      tagName: createTooltipArgs.tagName,
      // virtualTourID: createTooltipArgs.virtualTourID,
      virtualTourImageID: imgToBeView.id,
      pitchValue: createTooltipArgs.pitch,
      pitchYaw: createTooltipArgs.yaw,
      // url: createTooltipArgs.urlVal,
      url: url,
      // assetId: res.result.payload[0].id,
      assetId,
      uniqueTimestamp: createTooltipArgs.id,
      // tagType: createTooltipArgs.tagType,
      tagType: selectedOptionForAddEditTag,
    }

    
    console.log("addEditAssetsToViewIndrawer 2", 
      addEditAssetsToViewIndrawer, 
      addTagData,
      res,
    )

    resetTag();
    setIsEnableDrawer(false);
    setIsEnableActionDrawer(false);
    setIsActEnableDrawer(false);

    const resSavedTag= await postVTtag(addTagData, dispatch, tokenContext.accessToken)

    return [resSavedTag, res];

  }

  // EDIT HOT-SPOT TO BACK-END
  async function editHotSpot(createTooltipArgs) {

    let res={};
    let url="";
    let assetId= createTooltipArgs.assetID ?createTooltipArgs.assetID: null;

    if(selectedOptionForAddEditTag == 3 && selectedImgFileForAddEditTag instanceof File){
      const formData = new FormData(); 
      formData.append("files", selectedImgFileForAddEditTag);
      res=  await saveAssetToS_3(formData, dispatch, tokenContext.accessToken); 
      url="";
      assetId= res.result.payload[0].id;
    }

    if(selectedOptionForAddEditTag == 0){
      url="";
      assetId= null;
    }
    
    if(selectedOptionForAddEditTag == 5){
      url=selectedVideoPriviewUrlForAddEditTag;
      assetId= null;
    }else if(selectedOptionForAddEditTag == 4){
      url=urlForAddEditTag;
      assetId= null;
    }

    let addTagData= {
      tagName: createTooltipArgs.tagName,
      // virtualTourID: createTooltipArgs.virtualTourID,
      virtualTourImageID: imgToBeView.id,
      pitchValue: createTooltipArgs.pitch,
      pitchYaw: createTooltipArgs.yaw,
      // url: createTooltipArgs.urlVal,
      url: url,
      // assetId: createTooltipArgs.assetId,
      assetId,
      uniqueTimestamp: createTooltipArgs.uniqueTimestamp,
      // tagType: createTooltipArgs.tagType,
      tagType: selectedOptionForAddEditTag,
    }

    resetTag();
    setIsEnableDrawer(false);
    setIsEnableActionDrawer(false);
    setIsActEnableDrawer(false);

    const resUpdatedTag= await postVTtag(addTagData, dispatch, tokenContext.accessToken);

    return [resUpdatedTag, res];

  }

  // TO ADD `panoramaRef` WITH `pannellum viewer`.
  useEffect(() => {

    if(viewer) viewer.destroy();

    setviewer(window.pannellum.viewer(panoramaRef.current, {
      draggable: true,
      "disableKeyboardCtrl": true,
      "type": "equirectangular",
      "autoLoad": true, 
      "panorama": imgToBeView.fIleUrl,
      "hotSpots": [],
      "showControls": false,
      "compass": true,
      "sceneFadeDuration": 3000,
      "orientationOnByDefault": deviceType === "mobile"? true: false,
    }));
    // Additional configurations and customization can be done here
    // return () => {
    //   viewer && viewer.destroy();
    // };
  }, [imgToBeView.fIleUrl]);

  // useEffect(()=>{
  //   (async function () {
  //     const getstoreVirtualTourImages =await JSON.parse(localStorage.getItem('virtualTourImages'));
  //     setVirtualTourImages(getstoreVirtualTourImages)
  //   })();
  // }, [])

  // HANDLER TO ADD TAG 
  function dragHandlerFunc(e, args, hs) {
    if (e.type == "mouseup") {
        // Here I can update the hotSpot in my database by using args.id and hs.yaw, hs.pitch for the new position
        console.log("mouseupmouseup >>", args.id, hs.yaw, hs.pitch);
    }
  }
  
  // HANDLER TO ADD TAG 
  async function addEditTagHandler(createTooltipArgs) {
    console.log("setFetchedHotSpotsetFetchedHotSpot 1", createTooltipArgs)
    
    seIsClickOnSavedTag(false);
    setIsEnableNewTag(false);
    setActiveTagID("");

    if(selectedOptionForAddEditTag == 6){
      applyAddHS(createTooltipArgs); 
      return;
    }
    if(createTooltipArgs.isEdite){
      editTagHandler(createTooltipArgs)
    }else{
      addTagHandler(createTooltipArgs);
    }
  }

  async function applyAddHS(createTooltipArgs) {
    
    // REMOVE ADD HOT-SPOT
    viewer.removeHotSpot(createTooltipArgs.isEdite ?createTooltipArgs.id: createTooltipArgs.currenHSiD);

    // REMOVE TEMPORARY EDIT HOT-SPOT IF CLICKED ON TAG AND USE RADIO BUTTON
    let isEditableTagData= await localStorage.getItem("isEditableTagData");
    const stringToObjIsEditableTagData = JSON.parse(isEditableTagData);
    if(stringToObjIsEditableTagData!== null && stringToObjIsEditableTagData.isEditableTagOpen){
      // REMOVE TEMPORARY EDIT HOT-SPOT
      viewer.removeHotSpot(stringToObjIsEditableTagData.previouseEditableTagID);
    }
    
    // will add hotspot, get new-id  
    let [savedTag]= await addNewExperienceHotSpot(createTooltipArgs);
    
    let updatedCreateTooltipArgs= {
      // ...savedTag.result,
      pitch: savedTag.result.payload.pitchValue, 
      yaw: savedTag.result.payload.pitchYaw, 
      tagName: savedTag.result.payload.tagName, 
      ...savedTag.result.payload, 
      mainDBid: savedTag.result.payload.id, 
      id: savedTag.result.payload.uniqueTimestamp,
    }
    
    // SHOW-HOTSPOT but using new-id
    addHotspotParmanent({...savedTag.result.payload});

    setFetchedHotSpot((prev)=>{
      return [ ...prev, updatedCreateTooltipArgs]
    })

    setFetchedHotSpot((prev)=>{
      return [ ...prev.filter(hs=>{
       if(hs.uniqueTimestamp != savedTag.result.payload.uniqueTimestamp){
         return true;
       }else{
         return false;
       }
      }), updatedCreateTooltipArgs]
     })
  }

  async function addNewExperienceHotSpot(createTooltipArgs) {

    let addTagData= {
      tagName: createTooltipArgs.tagName,
      virtualTourImageID: imgToBeView.id,
      url: null,
      assetId: parseInt(selectedExperienceIdForHS), // --> eid
      pitchValue:  createTooltipArgs.pitch,
      pitchyaw: createTooltipArgs.yaw,
      uniqueTimestamp: createTooltipArgs.isEdite ? createTooltipArgs.uniqueTimestamp: createTooltipArgs.id,
      tagType: selectedOptionForAddEditTag
    }

    console.log("addEditTagHandleraddEditTagHandler 1", selectedExperienceIdForHS, selectedOptionForAddEditTag, createTooltipArgs, addTagData);

    resetTag();
    setIsEnableDrawer(false);
    setIsEnableActionDrawer(false);
    setIsActEnableDrawer(false);

    const resSavedTag= await postVTtag(addTagData, dispatch, tokenContext.accessToken)

    return [resSavedTag];

  }

  async function  addTagHandler(createTooltipArgs) {
    const tagName = createTooltipArgs.tagName;
    // REMOVE ADD HOT-SPOT

    console.log("handlerToUploadAndEditTaghandlerToUploadAndEditTag", createTooltipArgs);

    viewer.removeHotSpot(createTooltipArgs.currenHSiD);
    
    // will add hotspot, get new-id  
    let [savedTag, upladedImgRes]= await addNewHotSpot(createTooltipArgs);
    // await putVideoOnAzure(createTooltipArgs);
    
    let updatedCreateTooltipArgs= {
      // ...savedTag.result,
      pitch: savedTag.result.payload.pitchValue, yaw: savedTag.result.payload.pitchYaw, tagName: savedTag.result.payload.tagName, ...savedTag.result.payload, mainDBid: savedTag.result.payload.id, id: savedTag.result.payload.uniqueTimestamp,
    }
    
    let filePath= {};
    if(savedTag.result.payload.tagType ===3 && upladedImgRes.result !== undefined){
      filePath.filePath= `${THREE_SIXY_IMG_BASE_URL_TO_BE_SHOWN}${upladedImgRes.result.payload[0].fIleUrl}`;
    }
    
    // SHOW-HOTSPOT but using new-id
    addHotspotParmanent({...savedTag.result.payload, ...filePath});
    // setFetchedHotSpot((prev)=>{
    //   return [ ...prev, createTooltipArgs]
    // });

    setFetchedHotSpot((prev)=>{
      return [ ...prev, {...updatedCreateTooltipArgs, ...filePath}]
    })
  }
  
  // HANDLER TO ADD TAG 
  async function editTagHandler(createTooltipArgs) {
    
    const tagName = createTooltipArgs.tagName;
    console.log(" ",tagName,  createTooltipArgs);
    
    // REMOVE TEMPORARY EDIT HOT-SPOT IF CLICKED ON TAG
    viewer.removeHotSpot(createTooltipArgs.id);
    
    // REMOVE TEMPORARY EDIT HOT-SPOT IF CLICKED ON TAG AND USE RADIO BUTTON
    let isEditableTagData= await localStorage.getItem("isEditableTagData");
    const stringToObjIsEditableTagData = JSON.parse(isEditableTagData);
    if(stringToObjIsEditableTagData!== null && stringToObjIsEditableTagData.isEditableTagOpen){
      // REMOVE TEMPORARY EDIT HOT-SPOT
      viewer.removeHotSpot(stringToObjIsEditableTagData.previouseEditableTagID);
    }

    // will edit hotspot, get new-id  
    let [updatedTag, upladedImgRes]= await editHotSpot(createTooltipArgs);

    let updatedCreateTooltipArgs= {
      pitch: updatedTag.result.payload.pitchValue, yaw: updatedTag.result.payload.pitchYaw, tagName: updatedTag.result.payload.tagName, ...updatedTag.result.payload, mainDBid: updatedTag.result.payload.id, id: updatedTag.result.payload.uniqueTimestamp,
    }

    let filePath= {};
    if(updatedTag.result.payload.tagType ===3 && upladedImgRes.result !== undefined){
      filePath.filePath= `${THREE_SIXY_IMG_BASE_URL_TO_BE_SHOWN}${upladedImgRes.result.payload[0].fIleUrl}`;
    }
    else{
      filePath.filePath= updatedTag.result.payload.filePath;
    }

      // SHOW-HOTSPOT but using new-id
    addHotspotParmanent({...updatedTag.result.payload, ...filePath});
    
    setFetchedHotSpot((prev)=>{
     return [ ...prev.filter(hs=>{
      if(hs.uniqueTimestamp != updatedTag.result.payload.uniqueTimestamp){
        return true;
      }else{
        return false;
      }
     }), {...updatedCreateTooltipArgs, ...filePath}]
    })
    // await putVideoOnAzure(createTooltipArgs);
  }

  const deleteTag=async (createTooltipArgs)=> {
    viewer.removeHotSpot(createTooltipArgs.uniqueTimestamp);
    viewer.removeHotSpot(createTooltipArgs.id);
    deleteVTtag({uniqueTimestamp: createTooltipArgs.uniqueTimestamp}, dispatch, tokenContext.accessToken);
    setFetchedHotSpot((prev)=>{
      return [ ...prev.filter(hs=>hs.uniqueTimestamp != createTooltipArgs.uniqueTimestamp)]
    })
    setHStobeDeleted({});
    deleteAssestToggle();

    let isNewAddedTagData= await localStorage.getItem("isNewAddedTagData");
    const stringToObjIsNewAddedTagData = JSON.parse(isNewAddedTagData);
    if(stringToObjIsNewAddedTagData!== null && stringToObjIsNewAddedTagData.newAddedID){
      viewer.removeHotSpot(stringToObjIsNewAddedTagData.newAddedID);
      setPreviousHSiD("");
      localStorage.setItem("isNewAddedTagData", JSON.stringify({
        newAddedID: ""
      }));
    }
    // edit-tag(already)(new adding): remove everthing with state, localStorage, tag of the tag(end)

    let isEditableTagData= await localStorage.getItem("isEditableTagData");
    const stringToObjIsEditableTagData = JSON.parse(isEditableTagData);
    if(stringToObjIsEditableTagData!== null && stringToObjIsEditableTagData.isEditableTagOpen){
      console.log("removeremoveremove 1", stringToObjIsEditableTagData);
      // REMOVE TEMPORARY EDIT HOT-SPOT

      if(stringToObjIsEditableTagData.pitchYaw.uniqueTimestamp == createTooltipArgs.uniqueTimestamp){
        viewer.removeHotSpot(stringToObjIsEditableTagData.previouseEditableTagID);
  
        localStorage.setItem("isEditableTagData", JSON.stringify({
          previouseEditableTagID: "",
          isEditableTagOpen: false,
          pitchYaw: null
        }));
      }
    }
  }
  
  function addShowHotspot(createTooltipArgs) {
    console.log("getActiveClassgetActiveClass 3", createTooltipArgs);
    var currenHSiD= Date.now();
    let newHotspot = {
      id: createTooltipArgs.uniqueTimestamp,
      pitch: createTooltipArgs.pitch,
      yaw: createTooltipArgs.yaw,
      cssClass: getIcon(createTooltipArgs.tagType),
      createTooltipFunc: showHotspot,
      // createTooltipArgs: "New Hotspot",
      createTooltipArgs: {
        pitch: createTooltipArgs.pitch, yaw: createTooltipArgs.yaw, tagName: createTooltipArgs.tagName, ...createTooltipArgs, id: createTooltipArgs.uniqueTimestamp,
      },
      clickHandlerFunc: (e)=>{
        e.stopPropagation();
        setIsEnableNewTag(false);
        setActiveTagID(createTooltipArgs.mainDBid);
        editTag({ pitch: createTooltipArgs.pitch, yaw: createTooltipArgs.yaw, tagName: createTooltipArgs.tagName, ...createTooltipArgs, id: createTooltipArgs.uniqueTimestamp });
        viewer.lookAt(parseInt(createTooltipArgs.pitch), parseInt(createTooltipArgs.yaw));
      },
      isAddInfoEnable: false,
      draggable: true,
      "dragHandlerFunc": dragHandlerFunc,
      "dragHandlerArgs": {id: 0}
    };
    // Add hotspot
    console.log("cancelTagHandler 2", createTooltipArgs, newHotspot);
    viewer.addHotSpot(newHotspot);
  }

  // HANDLER TO CANCEL TAG 
  async function cancelTagHandler(createTooltipArgs) {

    seIsClickOnSavedTag(false);
    setIsEnableNewTag(false);
    setActiveTagID("");
    if(createTooltipArgs.isEdite){

      // REMOVE TEMPORARY EDIT HOT-SPOT IF CLICKED ON TAG
      viewer.removeHotSpot(createTooltipArgs.id);
      
      // REMOVE TEMPORARY EDIT HOT-SPOT IF CLICKED ON TAG AND USE RADIO BUTTON
      let isEditableTagData= await localStorage.getItem("isEditableTagData");
      const stringToObjIsEditableTagData = JSON.parse(isEditableTagData);
      if(stringToObjIsEditableTagData!== null && stringToObjIsEditableTagData.isEditableTagOpen){
        // REMOVE TEMPORARY EDIT HOT-SPOT
        viewer.removeHotSpot(stringToObjIsEditableTagData.previouseEditableTagID);
      }

      // SHOW-HOTSPOT IN REPLACE OF TEMPORARY EDIT HOT-SPOT
      setTimeout(() => {
        addShowHotspot(createTooltipArgs);
      },0);

    }else{
      // REMOVE ADD HOT-SPOT
      viewer.removeHotSpot(createTooltipArgs.currenHSiD);
    }

    // RESET ALL LOCAL-STORAGE AFTER CANCELING ADDING OR UPDATING HOT-SPOT(start)
    setPreviousHSiD("");
    localStorage.setItem("isNewAddedTagData", JSON.stringify({
      newAddedID: ""
    }));

    localStorage.setItem("isEditableTagData", JSON.stringify({
      previouseEditableTagID: "",
      isEditableTagOpen: false,
      pitchYaw: null
    }));

    resetTag();
    setIsEnableDrawer(false);
    setIsEnableActionDrawer(false);
    setIsActEnableDrawer(false);
    // RESET ALL LOCAL-STORAGE AFTER CANCELING ADDING OR UPDATING HOT-SPOT(end)
  }

  const editTag= async(createTooltipArgs)=> {

    // REMOVE EDIT AND ADD HOT-SPOT IF EXISTS(start) 

    // hotSpot should be removed if same `isNewAddedTagData` or `isEditableTagData` added.
    // edit-tag(already)(new adding): remove everthing with state, localStorage, tag of the tag(start)
    let isNewAddedTagData= await localStorage.getItem("isNewAddedTagData");
    const stringToObjIsNewAddedTagData = JSON.parse(isNewAddedTagData);
    if(stringToObjIsNewAddedTagData!== null && stringToObjIsNewAddedTagData.newAddedID){
      viewer.removeHotSpot(stringToObjIsNewAddedTagData.newAddedID);
      setPreviousHSiD("");
      localStorage.setItem("isNewAddedTagData", JSON.stringify({
        newAddedID: ""
      }));
      // addShowHotspot(createTooltipArgs);
    }
    // edit-tag(already)(new adding): remove everthing with state, localStorage, tag of the tag(end)

    let isEditableTagData= await localStorage.getItem("isEditableTagData");
    const stringToObjIsEditableTagData = JSON.parse(isEditableTagData);
    if(stringToObjIsEditableTagData!== null && stringToObjIsEditableTagData.isEditableTagOpen){
      // REMOVE TEMPORARY EDIT HOT-SPOT
      viewer.removeHotSpot(stringToObjIsEditableTagData.previouseEditableTagID);
      // SHOW HOTSPOT IN REPLACE OF PREVIOUSE TEMPORARY EDIT HOT-SPOT
      addShowHotspot(stringToObjIsEditableTagData.pitchYaw);
    }
    // REMOVE EDIT AND ADD HOT-SPOT IF EXISTS(end)

    // console.log("getActiveClassgetActiveClass 2", createTooltipArgs);
    // REMOVE CURRENT SHOW-HOTSPOT AND ADD EDIT-HOTSPOT FOR TEMPORARY TIME(start)
    viewer.removeHotSpot(createTooltipArgs.id);

    // // UPDATE `fetchedHotSpot`, THE REASON IS THAT WE ARE CHANGING THE ID WHEN CALLING `addShowHotspot`.

    var currenHSiD= Date.now();
    
    updateFetchedHotSpot(createTooltipArgs.uniqueTimestamp);

    console.log("tagName, createTooltipArgs ",createTooltipArgs);
    
    handlerToUploadAndEditTag({
      pitch: createTooltipArgs.pitch, 
      yaw: createTooltipArgs.yaw, 
      tagName: createTooltipArgs.tagName,
      isEdite: true,
      ...createTooltipArgs,
      id: currenHSiD
    }); // same as --> addEditTagHotspot();
    
    var newHotspot = {
      id: currenHSiD,
      tagName: createTooltipArgs.tagName,
      pitch: createTooltipArgs.pitch,
      yaw: createTooltipArgs.yaw,
      cssClass: getIcon(createTooltipArgs.tagType),
      // createTooltipFunc: addEditTagHotspot,
      createTooltipFunc: showHotspot,
      createTooltipArgs: {
        pitch: createTooltipArgs.pitch, 
        yaw: createTooltipArgs.yaw, 
        tagName: createTooltipArgs.tagName,
        isEdite: true,
        ...createTooltipArgs,
        id: currenHSiD,
        addTooltipPermanent: true
      },
      isAddInfoEnable: true,
      draggable: true,
      "dragHandlerFunc": dragHandlerFunc,
      "dragHandlerArgs": {id: 0}
    };

    viewer.addHotSpot(newHotspot);
    // REMOVE CURRENT SHOW-HOTSPOT AND ADD EDIT-HOTSPOT FOR TEMPORARY TIME(end)
    
    // STORE THE EDIT HOTS-POT ID TO BE DELETED IF WE DO EDIT NEXT TIME
    let previouseEditableTagID= currenHSiD;
    localStorage.setItem("isEditableTagData", JSON.stringify({
      previouseEditableTagID: previouseEditableTagID,
      pitchYaw: createTooltipArgs,
      isEditableTagOpen: true
    }));
  }

  // HANDLER ADD HOTSPOT JUST AFTER ADDING
  function showHotspot(hotSpotDiv, createTooltipArgs) {

    // WORKIN WITH SPAN
    hotSpotDiv.addEventListener('mouseenter', (e) => {
      console.log("mouseentermouseenter", createTooltipArgs);
      seIsClickOnSavedTag(true);
    })
    
    hotSpotDiv.addEventListener('mouseleave', () => {
      seIsClickOnSavedTag(false);
    });
    
    const editRemoveButton= `
    <p>${createTooltipArgs.tagName}</p>
    `;
        
    hotSpotDiv.classList.add('custom-tooltip');

    console.log("show-tag-delete-section", createTooltipArgs)

    if(createTooltipArgs.addTooltipPermanent){
      hotSpotDiv.classList.add('permanent-tooltip-visible');
    }

    var span = document.createElement('span');
    span.setAttribute('id', 'show-tag-section'+createTooltipArgs.pitch);
    span.innerHTML = editRemoveButton;
    hotSpotDiv.appendChild(span);

    // document.getElementById(`show-tag-img-section${createTooltipArgs.pitch}`).addEventListener('click', (e) => {
    //   editTag(e, createTooltipArgs);
    // });
  
    // document.getElementById(`show-tag-delete-section${createTooltipArgs.pitch}`).addEventListener('click', () => {
    //   console.log("show-tag-delete-section", createTooltipArgs)
    //   deleteTag(createTooltipArgs) 
    // });

    // Add hover event listeners
    span.addEventListener('mouseenter', (e) => {
      setCurrentVisibleHotspotID(e.target.id);
      console.log("mouseentermouseenter", createTooltipArgs);
      // seIsClickOnSavedTag(true);
    });
    span.addEventListener('mouseleave', () => {
      setCurrentVisibleHotspotID("");
      // seIsClickOnSavedTag(false);
    });
    span.style.width = span.scrollWidth - 20 + 'px';
    span.style.marginLeft = -(span.scrollWidth - hotSpotDiv.offsetWidth) / 2 + 'px';
    span.style.marginTop = -span.scrollHeight - 12 + 'px';
  }
 
  // SHOW HOT-SPOT AFTER ADDING
  function addHotspotParmanent(createTooltipArgs, tagName) {

    // var currenHSiD= Date.now();
    var newHotspot = { 
      id: createTooltipArgs.uniqueTimestamp,
      pitch: createTooltipArgs.pitchValue, 
      yaw: createTooltipArgs.pitchYaw,
      cssClass: getIcon(createTooltipArgs.tagType),
      createTooltipFunc: showHotspot,
      // createTooltipArgs: "New Hotspot",
      createTooltipArgs: {
         pitch: createTooltipArgs.pitchValue, yaw: createTooltipArgs.pitchYaw, tagName: createTooltipArgs.tagName, ...createTooltipArgs, mainDBid: createTooltipArgs.id, id: createTooltipArgs.uniqueTimestamp,
      },
      clickHandlerFunc: (e)=>{

        e.stopPropagation()
        setIsEnableNewTag(false);
        setActiveTagID(createTooltipArgs.id)
        
        editTag({pitch: createTooltipArgs.pitchValue, yaw: createTooltipArgs.pitchYaw, tagName: createTooltipArgs.tagName, ...createTooltipArgs, mainDBid: createTooltipArgs.id, id: createTooltipArgs.uniqueTimestamp});
      },
      isAddInfoEnable: false,
      draggable: true,
      "dragHandlerFunc": dragHandlerFunc,
      "dragHandlerArgs": {id: 0}
    };
    
    // RESET ALL LOCAL-STORAGE AFTER ADDING OR UPDATING HOT-SPOT(start)
    if(createTooltipArgs.isAddOnClientSide){
      setPreviousHSiD("");
      localStorage.setItem("isNewAddedTagData", JSON.stringify({
        newAddedID: ""
      }));
    }
    localStorage.setItem("isEditableTagData", JSON.stringify({
      previouseEditableTagID: "",
      isEditableTagOpen: false,
      pitchYaw: null
    }));
    setPreviousHSiD("");
    localStorage.setItem("isNewAddedTagData", JSON.stringify({
      newAddedID: ""
    }));
    // RESET ALL LOCAL-STORAGE AFTER ADDING OR UPDATING HOT-SPOT(end)

    // Add hotspot
    viewer.addHotSpot(newHotspot);
  }

  // ADD TAG HOT-SPOT FORM
  function addEditTagHotspot(hotSpotDiv, createTooltipArgs) {

    let copyCreateTooltipArgs= {...createTooltipArgs}
    
    let isEdite= false;
    if(createTooltipArgs.isEdite){
      isEdite= true;
    } 
     
    let none= ``
   
    let image= `<label class="mb-2">Select asset</label><div class="image-container">
      <div id="image-result-container" class="scroll-style"></div>
    </div>`

    let video= `<label class="mb-2">Select asset</label><div class="video-container">
      <div id="video-result-container" class="scroll-style"></div>
    </div>`
    
    let audio= `<label class="mb-2">Select asset</label><div class="audio-container">
      <div id="audio-result-container" class="scroll-style"></div>
    </div>`
    
    let url= `<label for="url${createTooltipArgs.pitch}">Url</label><input 
      type="text" 
      class="form-control pannelium-tag-input"
      id="url${createTooltipArgs.pitch}" 
      placeholder="Url"
      value="${isEdite && createTooltipArgs.url? createTooltipArgs.url: ""}"
    >`

    const formTemplate = `
        <form>
            <div class="form-group">
                <label for="tagName">Tag Name</label>
                <input type="text" maxlength="30" value="${isEdite? createTooltipArgs.tagName: ""}" class="form-control pannelium-tag-input" id="tagName${createTooltipArgs.pitch}" placeholder="Tag Name">
            </div>

            <div class="rdo-btn form-group">
                <span>
                  <input
                    type="radio"
                    value="NONE"
                    name= "assest-name"
                    id="NONE${createTooltipArgs.pitch}"
                    ${getCheckedValue({...createTooltipArgs, whichTag: 0})}
                  />
                  <label for="NONE${createTooltipArgs.pitch}">None</label>
                </span>
                <span>
                  <input
                      type="radio"
                      value="IMAGE"
                      name= "assest-name"
                      id="IMAGE${createTooltipArgs.pitch}"
                      ${getCheckedValue({...createTooltipArgs, whichTag: 3})}
                  />
                  <label for="IMAGE${createTooltipArgs.pitch}">Image</label>
                </span>
                <span>
                    <input
                        type="radio"
                        value="VIDEO"
                        name= "assest-name"
                        id="VIDEO${createTooltipArgs.pitch}"
                        ${getCheckedValue({...createTooltipArgs, whichTag: 2})}
                    />
                    <label for="VIDEO${createTooltipArgs.pitch}">Video</label>
                </span>
                <span>
                  <input
                      type="radio"
                      value="AUDIO"
                      name= "assest-name"
                      id="AUDIO${createTooltipArgs.pitch}"
                      ${getCheckedValue({...createTooltipArgs, whichTag: 1})}
                  />
                  <label for="AUDIO${createTooltipArgs.pitch}">Audio</label>
                </span>
                <span>
                  <input
                      type="radio"
                      value="URL"
                      name= "assest-name"
                      id="URL${createTooltipArgs.pitch}"
                      ${getCheckedValue({...createTooltipArgs, whichTag: 4})}
                  />
                  <label for="URL${createTooltipArgs.pitch}">Url</label>
                </span>
            </div>

            <div class="form-group" id= "action-tag${createTooltipArgs.pitch}">
               ${none}
            </div>

            <button id="add-tag-submit${createTooltipArgs.pitch}" type="submit" class="btn btn-primary pannelium-tag-button">Save</button>
            <button id="add-tag-cancel${createTooltipArgs.pitch}" type="button" class="btn btn-secondary pannelium-tag-button">Cancel</button>
        </form>
    `;

    // <div class="myDiv" id="add-tag-section" style="visibility: visible;">
    const divTemplate = `
        <div class="myDiv" id="${isEdite? 'edit-tag-section': "add-tag-section"}" style="visibility: visible;">
        ${formTemplate}
        </div>
    `;
    
    hotSpotDiv.innerHTML = divTemplate;

    hotSpotDiv.addEventListener('mouseenter', (e) => {
      console.log("mouseentermouseenter 4", createTooltipArgs);
      seIsClickOnSavedTag(true);
    })

    hotSpotDiv.addEventListener('mouseleave', () => {
      seIsClickOnSavedTag(false);
    });

    let assetId= null;
    let urlVal= null;
    let tagName = document.getElementById(`tagName${createTooltipArgs.pitch}`);

    let appedValue ={ 
      tagType: 0,
      assetId: null
    }

    setTimeout(() => {
      if(createTooltipArgs.isEdite && createTooltipArgs.tagType=== 0){
        document.getElementById(`NONE${createTooltipArgs.pitch}`).click();
        
      }else if(createTooltipArgs.isEdite && createTooltipArgs.tagType=== 1){
        document.getElementById(`AUDIO${createTooltipArgs.pitch}`).click();
        
      }else if(createTooltipArgs.isEdite && createTooltipArgs.tagType=== 2){
        document.getElementById(`VIDEO${createTooltipArgs.pitch}`).click();
        
      }else if(createTooltipArgs.isEdite && createTooltipArgs.tagType=== 3){
        document.getElementById(`IMAGE${createTooltipArgs.pitch}`).click();
        
      }else if(createTooltipArgs.isEdite && createTooltipArgs.tagType=== 4){
        document.getElementById(`URL${createTooltipArgs.pitch}`).click();
        
      }
    }, 100);
    
    document.getElementById(`NONE${createTooltipArgs.pitch}`).addEventListener('click', (e) => {
        while (document.getElementById('action-tag' + createTooltipArgs.pitch).childNodes.length > 1) {
            document.getElementById('action-tag' + createTooltipArgs.pitch).removeChild(document.getElementById('action-tag' + createTooltipArgs.pitch).lastChild);
        }
        document.getElementById('action-tag' + createTooltipArgs.pitch).insertAdjacentHTML('beforeend', none);
        // appedValue ={ tagName, fileInput: null, urlVal: "" }

        appedValue.tagType= 0
        appedValue.assetId= null
    });
    
    document.getElementById(`IMAGE${createTooltipArgs.pitch}`).addEventListener('click', async(e) => {
        while (document.getElementById('action-tag' + createTooltipArgs.pitch).childNodes.length > 1) {
            document.getElementById('action-tag' + createTooltipArgs.pitch).removeChild(document.getElementById('action-tag' + createTooltipArgs.pitch).lastChild);
        }
        document.getElementById('action-tag' + createTooltipArgs.pitch).insertAdjacentHTML('beforeend', image);
        appedValue.tagType= 3
        let pageNum = 1;
        let isLoading = false;
        const container =  document.getElementById("image-result-container");
        const resultContainer = document.getElementById("image-result-container");

        let data= {
          id: props.id
        }
        let getScenesArrayPromiseJson= await getVTtag(data , dispatch, tokenContext.accessToken)
        
        let getSavedTags= getScenesArrayPromiseJson.result.payload;
        let isLoadMore= true;
          
        async function fetchData() {
            if (isLoading) return;
            isLoading = true;

            try{ 
              let getImgAssetsForTagging =await getAssetsForTagging(pageNum, 10, 3, tokenContext.accessToken);
              
              console.log("getImgAssetsForTagging.result.payload.pagedList", getImgAssetsForTagging.result.payload.pagedList,typeof pageNum, pageNum===1)
              if(getImgAssetsForTagging.result.payload.pagedList.length === 0 && pageNum===1){
                let noContentDiv = document.createElement('div');
                noContentDiv.className = 'no-cnt';
                noContentDiv.innerText = 'No Asset Added';
                resultContainer.appendChild(noContentDiv);
                return;
              } 
              
              if(getImgAssetsForTagging.result.payload.pagedList.length === 0)
                isLoadMore= false;
              
                let selectedTag = getImgAssetsForTagging.result.payload.pagedList.filter( (tag)=>createTooltipArgs.assetID !== tag.id );
              let allTag = getImgAssetsForTagging.result.payload.pagedList.find( (tag)=>createTooltipArgs.assetID === tag.id );

              let placedSelectedTagAtTop =[];
              if(allTag !== undefined){
                placedSelectedTagAtTop =[allTag, ...selectedTag]
              }else{
                placedSelectedTagAtTop =[...selectedTag]
              }
            
              placedSelectedTagAtTop.forEach((item) => {
                  // let img= `<img id="image${item.id}" src="${item.thumbnailUrl}" alt="image" class="assets_image"/>`;
                  let img= `<img src="${item.thumbnailUrl}" alt="image" class="assets_image"/>`;
                
                  const container = document.createElement('span');
                  container.setAttribute('id', `image${item.id}`);

                  getSavedTags.forEach(element => {
                    // console.log("item.nameitem.name", createTooltipArgs.assetID, item);
                    if( createTooltipArgs.assetID==item.id ){
                      container.classList.add('class_second');
                      appedValue.assetId= item.id
                    }
                  });
                  
                  container.innerHTML = img.trim();
                  resultContainer.appendChild(container);
              
                  container.addEventListener('click', (e) => {
                    // console.log("passpasspass 1", e.currentTarget);
                    
                    const parentElement = document.getElementById('image-result-container');
                    const childElements = parentElement.getElementsByClassName('assets_image');
                    for (let i = 0; i < childElements.length; i++) {
                      childElements[i].parentElement.classList.remove('class_second');
                    }
                    const imageElement = e.currentTarget;
                    imageElement.classList.add('class_second');
                    // imageElement.classList.add('class_second');
                    
                    if (imageElement.classList.contains('class_second')) {
                      assetId= imageElement.id.replace("image", "");
                      createTooltipArgs.assetID= item.id;
                      // appedValue.tagType= 3
                      appedValue.assetId= assetId;
                    } else {
                      assetId= null;
                    }
                  })
              });
              isLoading = false;
            }catch(err) {
              isLoading = false;
            }

        }
          
        container.onscroll = () => {
            if (isLoading) return;
          
            if (
                (Math.ceil(container.clientHeight 
                    + container.scrollTop) >=
                    container.scrollHeight) && isLoadMore
            ) {
                pageNum++;
                fetchData();
            }
        };
          
        fetchData();

    });

    document.getElementById(`VIDEO${createTooltipArgs.pitch}`).addEventListener('click', async(e) => {
        while (document.getElementById('action-tag' + createTooltipArgs.pitch).childNodes.length > 1) {
            document.getElementById('action-tag' + createTooltipArgs.pitch).removeChild(document.getElementById('action-tag' + createTooltipArgs.pitch).lastChild);
        }
        document.getElementById('action-tag' + createTooltipArgs.pitch).insertAdjacentHTML('beforeend', video);
        appedValue.tagType= 2
        let pageNum = 1;
        let isLoading = false;
        const container = document.getElementById("video-result-container");
        const resultContainer = document.getElementById("video-result-container");

        let data= {
          id: props.id
        }
        let getScenesArrayPromiseJson= await getVTtag(data , dispatch, tokenContext.accessToken)

        let getSavedTags= getScenesArrayPromiseJson.result.payload;
        let isLoadMore= true;
        
        async function fetchData() {
            if (isLoading) return;
            isLoading = true;
            
            try{
              let getVidAssetsForTagging =await getAssetsForTagging(pageNum, 10, 2, tokenContext.accessToken);

              if(getVidAssetsForTagging.result.payload.pagedList.length === 0 && pageNum===1){
                let noContentDiv = document.createElement('div');
                noContentDiv.className = 'no-cnt';
                noContentDiv.innerText = 'No Asset Added';
                resultContainer.appendChild(noContentDiv);
                return;
              }

              if(getVidAssetsForTagging.result.payload.pagedList.length === 0)
                isLoadMore= false;

              let selectedTag = getVidAssetsForTagging.result.payload.pagedList.filter( (tag)=>createTooltipArgs.assetID !== tag.id );
              let allTag = getVidAssetsForTagging.result.payload.pagedList.find( (tag)=>createTooltipArgs.assetID === tag.id );

              let placedSelectedTagAtTop =[];
              if(allTag !== undefined){
                placedSelectedTagAtTop =[allTag, ...selectedTag]
              }else{
                placedSelectedTagAtTop =[...selectedTag]
              }

              placedSelectedTagAtTop.forEach((item) => {
                  // let img= `<img id="video${item.id}" src="${item.thumbnailUrl}" alt="video" class="assets_image"/><label class="vid_icon"></label>`;
                  let img= `<img src="${item.thumbnailUrl}" alt="video" class="assets_image"/><label class="vid_icon"></label>`;
                  
                  const container = document.createElement('span');
                  container.setAttribute('id', `video${item.id}`);

                  getSavedTags.forEach(element => {
                    // console.log("item.nameitem.name", createTooltipArgs.assetID, item);
                    if( createTooltipArgs.assetID==item.id ){
                      container.classList.add('class_second');
                      appedValue.assetId= item.id
                    }
                  });

                  container.innerHTML = img.trim();
                  resultContainer.appendChild(container);

                  container.addEventListener('click', (e) => {

                    const parentElement = document.getElementById('video-result-container');
                    const childElements = parentElement.getElementsByClassName('assets_image');
                    for (let i = 0; i < childElements.length; i++) {
                      childElements[i].parentElement.classList.remove('class_second');
                    }
                    const imageElement = e.currentTarget;
                    imageElement.classList.add('class_second');

                    if (imageElement.classList.contains('class_second')) {
                      assetId= imageElement.id.replace("video", "");
                      createTooltipArgs.assetID= item.id;
                      // appedValue.tagType= 2
                      appedValue.assetId= assetId;
                    } else {
                      assetId= null;
                    }
                  })
              });
              isLoading = false;
            }catch(err) {
              isLoading = false;
            }
            
        }
          
        container.onscroll = () => {
            if (isLoading) return;
          
            if (
                (Math.ceil(container.clientHeight 
                    + container.scrollTop) >=
                    container.scrollHeight) && isLoadMore
            ) {
                pageNum++;
                fetchData();
            }
        };
          
        fetchData();

    });

    document.getElementById(`AUDIO${createTooltipArgs.pitch}`).addEventListener('click', async(e) => {
        while (document.getElementById('action-tag' + createTooltipArgs.pitch).childNodes.length > 1) {
            document.getElementById('action-tag' + createTooltipArgs.pitch).removeChild(document.getElementById('action-tag' + createTooltipArgs.pitch).lastChild);
        } 
        document.getElementById('action-tag' + createTooltipArgs.pitch).insertAdjacentHTML('beforeend', audio);
        appedValue.tagType= 1
        let pageNum = 1;
        let isLoading = false;
        const container = document.getElementById("audio-result-container");
        const resultContainer = document.getElementById("audio-result-container");

        let data= {
          id: props.id
        }
        let getScenesArrayPromiseJson= await getVTtag(data , dispatch, tokenContext.accessToken)

        let getSavedTags= getScenesArrayPromiseJson.result.payload;
        let isLoadMore= true;
        
        async function fetchData() {
            if (isLoading) return;
            isLoading = true;

            try{
              let getAudAssetsForTagging =await getAssetsForTagging(pageNum, 10, 1, tokenContext.accessToken);

              if(getAudAssetsForTagging.result.payload.pagedList.length === 0 && pageNum===1){
                let noContentDiv = document.createElement('div');
                noContentDiv.className = 'no-cnt';
                noContentDiv.innerText = 'No Asset Added';
                resultContainer.appendChild(noContentDiv);
                return;
              }

              if(getAudAssetsForTagging.result.payload.pagedList.length === 0)
                isLoadMore= false;

              let selectedTag = getAudAssetsForTagging.result.payload.pagedList.filter((tag)=>createTooltipArgs.assetID !== tag.id );
              let allTag = getAudAssetsForTagging.result.payload.pagedList.find((tag)=>createTooltipArgs.assetID === tag.id );

              let placedSelectedTagAtTop =[];
              if(allTag !== undefined){
                placedSelectedTagAtTop =[allTag, ...selectedTag]
              }else{
                placedSelectedTagAtTop =[...selectedTag]
              }

              placedSelectedTagAtTop.forEach((item) => {
                  // let img= `<img id="audio${item.id}" src="${AudioAsset}" alt="audio" class="assets_image"/>`;
                  // <label>Audo ${item.id}</label>
                  let img= `<img id="audio${item.id}" src="${AudioAsset}" alt="audio" class="assets_image"/><label>${item.name.length >25 ? item.name.substring(0, 25)+"...": item.name}</label>`;
                 
                  const container = document.createElement('span');
                  container.setAttribute('id', `audio${item.id}`);

                  getSavedTags.forEach(element => {
                    if( createTooltipArgs.assetID==item.id ){
                      container.classList.add('class_second');
                      appedValue.assetId= item.id
                    }
                  }); 
                 
                  container.innerHTML = img.trim();
                  resultContainer.appendChild(container);
                  
                  container.addEventListener('click', (e) => {
                    const parentElement = document.getElementById('audio-result-container');
                    const childElements = parentElement.getElementsByClassName('assets_image');
                    for (let i = 0; i < childElements.length; i++) {
                      childElements[i].parentElement.classList.remove('class_second');
                    }
                    const imageElement = e.currentTarget;
                    imageElement.classList.add('class_second');

                    if (imageElement.classList.contains('class_second')) {
                      assetId= imageElement.id.replace("audio", "");
                      createTooltipArgs.assetID= item.id;
                      console.log("item.nameitem.name", createTooltipArgs.assetID);
                      // appedValue.tagType= 1
                      appedValue.assetId= assetId;
                    } else {
                      assetId= null;
                    }
                  })
              });
              isLoading = false;
            }catch(err) {
              isLoading = false;
            }
        }
          
        container.onscroll = () => {
          console.log("isLoadingisLoading",Math.ceil(container.clientHeight 
            + container.scrollTop) ,
            container.scrollHeight)
            if (isLoading) return;
          
            if (
                (Math.ceil(container.clientHeight 
                    + container.scrollTop)+1 >=
                    container.scrollHeight) && isLoadMore
            ) {
              
                pageNum++;
                fetchData();
            }
        };
          
        fetchData();

    });

    document.getElementById(`URL${createTooltipArgs.pitch}`).addEventListener('click', (e) => {
        while (document.getElementById('action-tag' + createTooltipArgs.pitch).childNodes.length > 1) {
            document.getElementById('action-tag' + createTooltipArgs.pitch).removeChild(document.getElementById('action-tag' + createTooltipArgs.pitch).lastChild);
        } 
        document.getElementById('action-tag' + createTooltipArgs.pitch).insertAdjacentHTML('beforeend', url);

        urlVal = document.getElementById(`url${createTooltipArgs.pitch}`);

        appedValue.tagType= 4
        appedValue.assetId= null
        
    });
  
    document.getElementById(`add-tag-submit${createTooltipArgs.pitch}`).addEventListener('click', (e) => {
        e.preventDefault();

        let urlVal= ""
        if(document.getElementById(`url${createTooltipArgs.pitch}`)!== undefined &&document.getElementById(`url${createTooltipArgs.pitch}`)!== null ){
            urlVal= document.getElementById(`url${createTooltipArgs.pitch}`).value
        }

        if(checkValidation({isEdit: createTooltipArgs.isEdite, ...appedValue, urlVal, tagName: tagName.value})){
          console.log("passpasspass", appedValue);
          addEditTagHandler(e, {
            ...createTooltipArgs, 
            ...appedValue, 
            urlVal: urlVal, 
            tagName: tagName.value, 
          })
        }

    });

    document.getElementById(`add-tag-cancel${createTooltipArgs.pitch}`).addEventListener('click', (e) => {cancelTagHandler(e, copyCreateTooltipArgs)});
  
  }
 
  const handleMouseDown = (event) => {
    clickStartPosition.x = event.clientX;
    clickStartPosition.y = event.clientY;
  };
  
  const isClickedUnderSubmiteTagSection = (event) => {

    var clickedX = event.clientX; // X coordinate of the clicked area
    var clickedY = event.clientY; // Y coordinate of the clicked area
  
    var addTagSection = document.getElementById('add-tag-section');
    var editTagSection = document.getElementById('edit-tag-section');
    var showTagSection = document.getElementById(currentVisibleHotspotID);
  
    if(editTagSection !== null){
      var rect = editTagSection.getBoundingClientRect(); // Get the position and size of the element
      var elementX = rect.left; // X coordinate of the element
      var elementY = rect.top; // Y coordinate of the element
      var elementWidth = rect.width; // Width of the element
      var elementHeight = rect.height; // Height of the element
      // Check if the clicked area falls within the boundaries of the element
      if (clickedX >= elementX && clickedX <= elementX + elementWidth &&
          clickedY >= elementY && clickedY <= elementY + elementHeight) 
        return true;
    } 
  
    if(addTagSection !== null){
      var rect = addTagSection.getBoundingClientRect(); // Get the position and size of the element
      var elementX = rect.left; // X coordinate of the element
      var elementY = rect.top; // Y coordinate of the element
      var elementWidth = rect.width; // Width of the element
      var elementHeight = rect.height; // Height of the element
      // Check if the clicked area falls within the boundaries of the element
      if (clickedX >= elementX && clickedX <= elementX + elementWidth &&
          clickedY >= elementY && clickedY <= elementY + elementHeight) 
        return true;
    } 

    if(showTagSection){
      var rect = showTagSection.getBoundingClientRect(); // Get the position and size of the element
    
      var elementX = rect.left; // X coordinate of the element
      var elementY = rect.top; // Y coordinate of the element
      var elementWidth = rect.width; // Width of the element
      var elementHeight = rect.height; // Height of the element
    
      // Check if the clicked area falls within the boundaries of the element
      if (clickedX >= elementX && clickedX <= elementX + elementWidth &&
          clickedY >= elementY && clickedY <= elementY + elementHeight) 
        return true;  
    }
    
    return false;
  }
  
  const handleMouseUp = async(event) => {

    if(isClickOnSavedTag){
      return;
    }

    clickEndPosition.x = event.clientX;
    clickEndPosition.y = event.clientY;
    if(isClickedUnderSubmiteTagSection(event)){
      return;
    }

    // return;
    const distanceThreshold = 5; // Adjust the threshold as needed
    const deltaX = Math.abs(clickEndPosition.x - clickStartPosition.x);
    const deltaY = Math.abs(clickEndPosition.y - clickStartPosition.y);
    if (deltaX <= distanceThreshold && deltaY <= distanceThreshold) {
      handleMouseClick(event);
    }
  };

  const handleMouseClick = async(event) => {

    // Get the pitch and yaw values at the clicked coordinates

    let coords= "";
    let pitch= "";
    let yaw= "";

    if(event instanceof Object){
        coords = viewer.mouseEventToCoords(event);
        console.log(coords);
        // pitch = coords.pitch;
        pitch = coords[0];//viewer.getPitch();
        yaw =  coords[1];//viewer.getYaw();
    }else{
        pitch = viewer.getPitch();//viewer.getPitch();
        yaw =  viewer.getYaw();//viewer.getYaw();
    }
    var currenHSiD= Date.now();

    handlerToUploadAndEditTag({id: currenHSiD, pitch, yaw, currenHSiD, isAddOnClientSide: true }); // same as --> addEditTagHotspot();

    var newHotspot = {
        id: currenHSiD,
        pitch: pitch,
        yaw: yaw,
        cssClass: selectedExperienceIdForHS ? "add-hot-spot" :
        // "custom-hotspot"
          getIcon(selectedOptionForAddEditTag)
        ,
        // createTooltipFunc: addEditTagHotspot,
        createTooltipArgs: {
            id: currenHSiD, pitch, yaw, currenHSiD, isAddOnClientSide: true
        },
        isAddInfoEnable: true,
        draggable: true,
        "dragHandlerFunc": dragHandlerFunc,
        "dragHandlerArgs": {id: 0}
    };

    // REMOVE EDIT AND ADD HOT-SPOT(start)

    // click somewhere(new adding): update state, previouse-tag, localStorage with new one(start)
    if(previousHSiD){
      viewer.removeHotSpot(previousHSiD);
    }
    setPreviousHSiD(currenHSiD);
    localStorage.setItem("isNewAddedTagData", JSON.stringify({
      newAddedID: currenHSiD,
      pitchYaw: newHotspot,
    }));
    // click somewhere(new adding): update state, previouse-tag, localStorage with new one(end)

    // IF YOU CLICK ON EMPTY PITCH, THEN `isEditableTagData` HOT-SPOT SHOUD BE REMOVED IF EXIXST
    let string= await localStorage.getItem("isEditableTagData");
    const stringToObj = JSON.parse(string);
    if(stringToObj!==null && stringToObj.isEditableTagOpen){
      viewer.removeHotSpot(stringToObj.previouseEditableTagID);
      // Show hotSpot
      addShowHotspot(stringToObj.pitchYaw);
      localStorage.setItem("isEditableTagData", JSON.stringify({
        previouseEditableTagID: "",
        isEditableTagOpen: false,
        pitchYaw: null
      }));
    }

    // REMOVE EDIT AND ADD HOT-SPOT(end)

    // Add hotspot
    viewer.addHotSpot(newHotspot);

  };

  const getHotSpot = ()=>{
    return (
      fetchedHotSpot.map(hs=>{
        return  <div key={hs.uniqueTimestamp} class={getActiveClass(hs)} 
          onClick={async(e)=>{
            // viewer.lookAt(parseInt(hs.pitchValue), parseInt(hs.pitchYaw));
            // setActiveTagID(hs.mainDBid);
            e.stopPropagation();
            viewer.lookAt(parseInt(hs.pitchValue), parseInt(hs.pitchYaw));
            editTag({...hs, isEdite: true});
          }}
        >
          <img src={getRightDrawerIcon(hs.tagType)} class="tagImage" alt="back icon" />
          <p>{hs.tagName}</p>
          
          {/* EDIT TAG */} 
          <button class="btn btn-link" 
            style={{"position": "relative", "left": "-12px"}} type="button" onClick={(e)=>{
            e.stopPropagation();
            viewer.lookAt(parseInt(hs.pitchValue), parseInt(hs.pitchYaw));
            editTag({...hs, isEdite: true});
          }}>
            <img src={EditTagIcon} alt="delete tag" />
          </button>
          
          {/* DELETE TAG */}
          <button class="btn btn-link" type="button" onClick={(e)=>{
            console.log("virtualTourImagesvirtualTourImages 1::", hs)
            e.stopPropagation();

            if( userProfileDetails.isExpired ){
                dispatch(setDoNotShowExpiredModal(false));
                // setOpenUpgrade(true);
                toast("Your subscription has been expired");
                return;
            }

            deleteAssestToggle();
            setHStobeDeleted(hs);
          }}>
            <img src={deleteIcon} alt="delete tag" />
          </button>
        </div>
      })
    )
  }

  const getDeleteConfirmationPopup = ()=>{
    return  <Modal isOpen={deleteModal} centered className="modal-medium" toggle= {deleteAssestToggle} >
              <ModalBody>
                  <div className="text-center mt-3">
                      <img src={CancelIcon} alt="Images" className="img-fluid mb-4" />
                      <p>Are you sure? Do you want to delete this tag ?</p>
                  </div>  
              </ModalBody>
              <ModalFooter className="justify-content-center pt-0">
                  <Button className="me-2 me-md-3" color="outline-secondary" onClick={()=>{
                    setHStobeDeleted({});
                    deleteAssestToggle();
                  }}>Cancel</Button>
                  <Button className="ms-2 ms-md-3" color="primary" onClick={()=>{
                    
                      deleteTag(HStobeDeleted);
                      setTimeout(() => {
                        setActiveTagID("");
                        setIsEnableNewTag(false);
                        resetTag();
                        setIsEnableActionDrawer(false);
                        setIsEnableDrawer(true);
                        setIsActEnableDrawer(true);
                      }, 0);
                      
                  } }>Yes</Button>
              </ModalFooter>
      </Modal>
  }
  
  const getActiveClass = (hs)=>{
    if(hs.mainDBid === activeTagID){
      return "tagitem active"
    }else{
      return "tagitem"
    }
  }

  const updateFetchedHotSpot = (timeStamp)=>{
    setFetchedHotSpot((prev)=>{
      return prev.map(hs=>{
        if(hs.uniqueTimestamp == timeStamp){
          return{
            ...hs, id: hs.uniqueTimestamp
          }
        }else{
          return hs;
        }
      })
    })
  }

  const checkValidation = async()=>{
    
    toast.dismiss();
    
    if(tageNameForAddEditTag === ""){
      toast.error("Please Enter Tag Name", { });
      return false
    }
    
    if(addEditAssetsToViewIndrawer.isEdite){
      
      switch (parseInt(selectedOptionForAddEditTag)) {
        case 5:
          if(!isUrlValid){
            toast.error("Unsupported video platform", { });
            return false
          }
          if(validator.isEmpty(selectedVideoPriviewUrlForAddEditTag.trim()) || !validator.isURL(selectedVideoPriviewUrlForAddEditTag.trim())){
            toast.error("Please Enter Valid Vedio url", { });
            return false
          }
        break;
        case 3:
          if(!selectedImgFileForAddEditTag){
            toast.error("Please Select Valid Image", { });
            return false
          }
        break;
        case 4:
          // url validation
          let isValidURL= false;
          
          if(!validator.isEmpty(urlForAddEditTag.trim()) && validator.isURL(urlForAddEditTag.trim(), {protocols: ['http','https'], require_protocol: true})){
            isValidURL = true;
          }
          
          if( !isValidURL ){
            toast.error("Please Enter Valid Url", { });
            return false
          }
        break;
        case 6:
          // url validation 
          if( !selectedExperienceIdForHS ){
            toast.error("Please Select 360 Experience", { });
            return false
        }
        break;
      }
      return true;
    }else{
     
      let isNewAddedTagData= await localStorage.getItem("isNewAddedTagData");
      const stringToObjIsNewAddedTagData = JSON.parse(isNewAddedTagData);
      console.log("isNewAddedTagData :::", isNewAddedTagData)
      if(stringToObjIsNewAddedTagData === null || stringToObjIsNewAddedTagData.newAddedID == ""){
        toast.error("Please add Tag in Experience", { });
        return false;
      }

      switch (parseInt(selectedOptionForAddEditTag)) {
        case 5:
          if(!isUrlValid){
            toast.error("Unsupported video platform", { });
            return false
          }
          if(validator.isEmpty(selectedVideoPriviewUrlForAddEditTag.trim()) || !validator.isURL(selectedVideoPriviewUrlForAddEditTag.trim())){
            toast.error("Please Enter Valid Vedio url", { });
            return false
          }
          break;
        case 3:
          if(!selectedImgFileForAddEditTag){
            toast.error("Please Select Valid Image", { });
            return false
          }
          break;
        case 4:
          // url validation 

          let isValidURL= false;
          
          if(!validator.isEmpty(urlForAddEditTag.trim()) && validator.isURL(urlForAddEditTag.trim(), {protocols: ['http','https'], require_protocol: true})){
            isValidURL = true;
          }
          
          if(!isValidURL){
            toast.error("Please Enter Valid Url", { });
            return false
          }
          break;
        case 6:
          if( !selectedExperienceIdForHS ){
            toast.error("Please Select 360 Experience", { });
            return false
          }
          break;
      }
      return true;
    }
  }

  const getIcon = (tagType)=>{
    // console.log("handlerToUploadAndEditTaghandlerToUploadAndEditTag", typeof tagType, typeof 0, tagType);
    switch (parseInt(tagType)) {
      case 0:
        return "hotspot-location";
      case 1:
        return "hotspot-audio";
      case 2:
      case 5:
        return "hotspot-video";
      case 3:
        return "hotspot-image";
      case 4:
        return "hotspot-url";
      case 6:
        return "add-hot-spot";
    }
  }

  const getRightDrawerIcon = (tagType)=>{
    switch (tagType) {
      case 0:
        return Location;
      case 1:
        return Audio;
      case 2:
      case 5:
        return Video;
      case 3:
        return Image;
      case 4:
        return Web;
      case 6:
        return HS;
    }
  }

  const getCheckedValue = (createTooltipArgs)=>{
    
    if(createTooltipArgs.whichTag===0){
      return "checked"
    }else if(!createTooltipArgs.isEdite){
      return;
    }else if(createTooltipArgs.whichTag=== 2 && createTooltipArgs.isEdite && createTooltipArgs.tagType=== 2 ){
      return "checked"
    }else if(createTooltipArgs.whichTag=== 3 && createTooltipArgs.isEdite && createTooltipArgs.tagType=== 3 ){
      return "checked"
    }else if(createTooltipArgs.whichTag=== 4 && createTooltipArgs.isEdite && createTooltipArgs.tagType=== 4 ){
      return "checked"
    }
  }

  const getBrowseView =()=>{
    return <div className="form-group mb-3">
    <label className="form-label">Media attachment</label>
    <div className="browse-image">
      <input type= "file" onChange={handleImageUpload} />
      <div className="browse-content">
        {/* <img src={selectedImgPriviewForAddEditTag} style={{height: "200px", width: "200px"}} /> */}
        <a className="link-text"><u>Browse</u></a>
        <p className="mb-0 small mt-2">Or drag and drop files to upload</p>
      </div>
    </div>
  </div>
  }

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    setSelectedImgFileForAddEditTag(file);
    console.log("filefile", file)
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const base64String = e.target.result;
        setSelectedImgPriviewForAddEditTag(base64String);
      };
      reader.readAsDataURL(file);
    }
  };
  
  const checkUrlHandler = (bool)=>{
    console.log("checkUrlHandlercheckUrlHandler", bool)
    setIsUrlValid(bool);
  }

  const getSavedOrNewFileView = ()=>{

    if(selectedOptionForAddEditTag == 6){
      return <div style={{ maxHeight: "500px", overflow: "auto" }} id="scrollableDiv">
        <div>
        Select 360 Experience
      </div>
        {/* {getExperienceLIst()} */}
        {getExperiences()}
      </div>
    }
    
    if(addEditAssetsToViewIndrawer.isEdite){
      
      if(selectedOptionForAddEditTag == 0){
        return null;
      }
      else if(selectedOptionForAddEditTag == 5){
        return <>
                  {/* <input type="text" className="form-control" value={selectedVideoPriviewUrlForAddEditTag} onChange={e=>setUrlForAddEditTag(e.target.value)} /> */}

                  <input type="text" className="form-control" value={selectedVideoPriviewUrlForAddEditTag} onChange={e=>setSelectedVideoPriviewUrlForAddEditTag(e.target.value)} />
                  {selectedVideoPriviewUrlForAddEditTag && <div 
                    style={{
                      marginLeft: "12px",
                      marginTop: "19px"
                    }}><VideoPreview url={selectedVideoPriviewUrlForAddEditTag} onCheckUrl= {checkUrlHandler}/></div>}
                  {/* <ReactPlayer 
                    url={selectedVideoPriviewUrlForAddEditTag}
                    config={{
                        file: {
                            attributes: {
                              preload: "none",
                              // poster: props.video.thumbnailUrl,
                              onContextMenu: e => e.preventDefault(),
                            }
                        }
                    }}
                /> */}
              </>
      }
      else if(selectedOptionForAddEditTag == 3){
        return selectedImgPriviewForAddEditTag ?<div className="browse-image">
          <input type= "file" onChange={handleImageUpload} />
          <div className="browse-content">
            <img src={selectedImgPriviewForAddEditTag} style={{height: "230px", width: "230px"}} />
          </div>
        </div>: getBrowseView()
      }else if(selectedOptionForAddEditTag == 4){
        return  <input type="text" className="form-control" value={urlForAddEditTag} onChange={e=>setUrlForAddEditTag(e.target.value)} />
      }
   
    }else{
      if(selectedOptionForAddEditTag == 0){
        return null;

      }
      else if(selectedOptionForAddEditTag == 5){
        return <>
                {/* <input type="text" className="form-control" value={selectedVideoPriviewUrlForAddEditTag} onChange={e=>setUrlForAddEditTag(e.target.value)} />
                  <ReactPlayer  
                    url={selectedVideoPriviewUrlForAddEditTag}
                    config={{
                        file: {
                            attributes: {
                              preload: "none",
                              // poster: props.video.thumbnailUrl,
                              onContextMenu: e => e.preventDefault(),
                            }
                        }
                    }}
                /> */}
                 <input type="text" className="form-control" value={selectedVideoPriviewUrlForAddEditTag} onChange={e=>setSelectedVideoPriviewUrlForAddEditTag(e.target.value)} />
                  {selectedVideoPriviewUrlForAddEditTag && <div 
                    style={{
                      marginLeft: "12px",
                      marginTop: "19px"
                    }}><VideoPreview url={selectedVideoPriviewUrlForAddEditTag} onCheckUrl= {checkUrlHandler}/></div>}
              </>
      }
      else if(selectedOptionForAddEditTag == 3){
        return selectedImgPriviewForAddEditTag?<div className="browse-image">
        <input type= "file" onChange={handleImageUpload} />
        <div className="browse-content">
          <img src={selectedImgPriviewForAddEditTag} style={{height: "250px", width: "250px"}} />
        </div>
      </div>: getBrowseView()
      }else if(selectedOptionForAddEditTag == 4){
        return  <input type="text" className="form-control" value={urlForAddEditTag} onChange={e=>setUrlForAddEditTag(e.target.value)} />
      }
    }

  }

  const getExperienceLIst = ()=>{
    if(Object.keys(virtualTourImages).length !== 0){   

      return virtualTourImages.images.filter((img)=>img.id != imgToBeView.id).map((img)=>{
        return <div className="custom-radio">
        <input
          type="radio"
          value={img.id}
          id={img.id}
          name={"add-experience"}
          checked={img.id == selectedExperienceIdForHS}
          onChange={handleOptionChange}
        />
        <img src={img.thumbnailUrl} alt="img.name" style={{"height": "50px", "margin": "9px", "border-radius": "11px"}}/>
        <label>{ img.name !=undefined && img.name.length >15 ? img.name.substring(0, 15)+"...": img.name }</label>
      </div>
      })
    }
  }
  
  const handlerIsEnableDrawer = ()=>{  
    setIsEnableDrawer(!isEnableDrawer);
    setIsActEnableDrawer(!isActEnableDrawer);
  }
  
  const resetTag = async()=>{
    setAddEditAssetsToViewIndrawer({});
    setSelectedOptionForAddEditTag("0");
    setTageNameForAddEditTag("");
    setSelectedImgPriviewForAddEditTag("");
    setSelectedImgFileForAddEditTag(false);
    setSelectedVideoPriviewUrlForAddEditTag(null)
    setIsUrlValid(false);
    setUrlForAddEditTag("");
    setSelectedExperienceIdForHS("");
    
    if(previousHSiD){
      viewer.removeHotSpot(previousHSiD);
    }

    let isNewAddedTagData= await localStorage.getItem("isNewAddedTagData");
    const stringToObjIsNewAddedTagData = JSON.parse(isNewAddedTagData);
    if(stringToObjIsNewAddedTagData!== null && stringToObjIsNewAddedTagData.newAddedID){
      viewer.removeHotSpot(stringToObjIsNewAddedTagData.newAddedID);
      setPreviousHSiD("");
      localStorage.setItem("isNewAddedTagData", JSON.stringify({
        newAddedID: ""
      }));
      // addShowHotspot(createTooltipArgs);
    }
  }
  
  const handlerToUploadAndEditTag = (createTooltipArgs)=>{
    
    if(createTooltipArgs.isEdite){

      setSelectedOptionForAddEditTag(createTooltipArgs.tagType);
      setTageNameForAddEditTag(createTooltipArgs.tagName);

      if(createTooltipArgs.tagType == 6){
        setSelectedExperienceIdForHS(createTooltipArgs.assetID);
        setSelectedVideoPriviewUrlForAddEditTag("")
        setUrlForAddEditTag("");
        setSelectedImgPriviewForAddEditTag("")
      }
      if(createTooltipArgs.tagType == 4){
        setUrlForAddEditTag(createTooltipArgs.url);

        setSelectedImgPriviewForAddEditTag("")
        setSelectedVideoPriviewUrlForAddEditTag("")
      }else if(createTooltipArgs.tagType == 3){
        setSelectedImgPriviewForAddEditTag(createTooltipArgs.filePath);
        setSelectedImgFileForAddEditTag(true);

        setUrlForAddEditTag("");
        setSelectedVideoPriviewUrlForAddEditTag("")
      }else if(createTooltipArgs.tagType == 5){
        setSelectedVideoPriviewUrlForAddEditTag(createTooltipArgs.url)
        setIsUrlValid(true);
        setUrlForAddEditTag("");
        setSelectedImgPriviewForAddEditTag("")
      }else if(createTooltipArgs.tagType == 0){
        setSelectedVideoPriviewUrlForAddEditTag("")
        setUrlForAddEditTag("");
        setSelectedImgPriviewForAddEditTag("")
      }

    }else{
      if(selectedOptionForAddEditTag == 6){
        // setAddEditAssetsToViewIndrawer({})
        // setTageNameForAddEditTag("");
        // setSelectedImgPriviewForAddEditTag("")
        // setSelectedVideoPriviewUrlForAddEditTag(null)
        // setIsUrlValid(false);
        // setUrlForAddEditTag("");
      }else{
        // setAddEditAssetsToViewIndrawer({});
        // setSelectedOptionForAddEditTag("0");
        // setTageNameForAddEditTag("");
        // setSelectedImgPriviewForAddEditTag("");
        // setSelectedVideoPriviewUrlForAddEditTag(null)
        // setIsUrlValid(false);
        // setUrlForAddEditTag("");
        // setSelectedExperienceIdForHS("");
        // if(previousHSiD){
        //   viewer.removeHotSpot(previousHSiD);
        // }
      }
    }
  
    setAddEditAssetsToViewIndrawer(createTooltipArgs);
    setIsEnableDrawer(false);
    setIsEnableActionDrawer(true);
    setIsActEnableDrawer(true);

  }

  const handleOptionChange = async(event) => {

    switch(event.target.name){
      case "add-tag":
        setSelectedOptionForAddEditTag(event.target.value);
        // setSelectedExperienceIdForHS("")
        
        if(!addEditAssetsToViewIndrawer.isEdite){
          
          setIsEnableNewTag(true);
          setActiveTagID("");

          let isNewAddedTagData= await localStorage.getItem("isNewAddedTagData");
          const stringToObjIsNewAddedTagData = JSON.parse(isNewAddedTagData);
          if(stringToObjIsNewAddedTagData!== null && stringToObjIsNewAddedTagData.newAddedID){
            
            viewer.removeHotSpot(stringToObjIsNewAddedTagData.newAddedID);
            setPreviousHSiD("");
            localStorage.setItem("isNewAddedTagData", JSON.stringify({
              newAddedID: ""
            }));
            
            var currenHSiD= Date.now();
            var newHotspot = {
                id: currenHSiD,
                pitch: stringToObjIsNewAddedTagData.pitchYaw.pitch,
                yaw: stringToObjIsNewAddedTagData.pitchYaw.yaw,
                cssClass: getIcon(event.target.value),
                // createTooltipFunc: addEditTagHotspot,
                createTooltipArgs: {
                    id: currenHSiD, pitch: stringToObjIsNewAddedTagData.pitchYaw.pitch, yaw: stringToObjIsNewAddedTagData.pitchYaw.yaw, currenHSiD, isAddOnClientSide: true
                },
                isAddInfoEnable: true,
                draggable: true,
                "dragHandlerFunc": dragHandlerFunc,
                "dragHandlerArgs": {id: 0}
            };

            // REMOVE EDIT AND ADD HOT-SPOT(start)

            // click somewhere(new adding): update state, previouse-tag, localStorage with new one(start)
            if(previousHSiD){
              viewer.removeHotSpot(previousHSiD);
            }
            
            setPreviousHSiD(currenHSiD);
            localStorage.setItem("isNewAddedTagData", JSON.stringify({
              newAddedID: currenHSiD,
              pitchYaw: newHotspot,
            }));
            // click somewhere(new adding): update state, previouse-tag, localStorage with new one(end)

            // IF YOU CLICK ON EMPTY PITCH, THEN `isEditableTagData` HOT-SPOT SHOUD BE REMOVED IF EXIXST
            let string= await localStorage.getItem("isEditableTagData");
            const stringToObj = JSON.parse(string);
            if(stringToObj!==null && stringToObj.isEditableTagOpen){
              viewer.removeHotSpot(stringToObj.previouseEditableTagID);
              // Show hotSpot
              addShowHotspot(stringToObj.pitchYaw);
              localStorage.setItem("isEditableTagData", JSON.stringify({
                previouseEditableTagID: "",
                isEditableTagOpen: false,
                pitchYaw: null
              }));
            }

            // REMOVE EDIT AND ADD HOT-SPOT(end)

            // Add hotspot
            viewer.addHotSpot(newHotspot);

          }
          
          if(selectedOptionForAddEditTag == 4){
            setUrlForAddEditTag(urlForAddEditTag);
            setSelectedImgPriviewForAddEditTag("")
            setSelectedVideoPriviewUrlForAddEditTag("")
          }else if(selectedOptionForAddEditTag == 3){
            setSelectedImgPriviewForAddEditTag(selectedImgPriviewForAddEditTag)
            setUrlForAddEditTag("");
            setSelectedVideoPriviewUrlForAddEditTag("")
          }else if(selectedOptionForAddEditTag == 5){
            setSelectedVideoPriviewUrlForAddEditTag(selectedVideoPriviewUrlForAddEditTag)
            setIsUrlValid(true);
            setUrlForAddEditTag("");
            setSelectedImgPriviewForAddEditTag("")
          }else if(selectedOptionForAddEditTag == 0){
            setSelectedVideoPriviewUrlForAddEditTag("")
            setUrlForAddEditTag("");
            setSelectedImgPriviewForAddEditTag("")
          }
          else if(selectedOptionForAddEditTag == 6){
            setSelectedExperienceIdForHS("");
            setSelectedVideoPriviewUrlForAddEditTag("")
            setUrlForAddEditTag("");
            setSelectedImgPriviewForAddEditTag("")
          }
        
        }else{

          setIsEnableNewTag(false);
          setActiveTagID("");

          let isNewAddedTagData= await localStorage.getItem("isNewAddedTagData");
          const stringToObjIsNewAddedTagData = JSON.parse(isNewAddedTagData);
          if(stringToObjIsNewAddedTagData!== null && stringToObjIsNewAddedTagData.newAddedID){
            
            viewer.removeHotSpot(stringToObjIsNewAddedTagData.newAddedID);
            setPreviousHSiD("");
            localStorage.setItem("isNewAddedTagData", JSON.stringify({
              newAddedID: ""
            }));
          }

          let isEditableTagData= await localStorage.getItem("isEditableTagData");
          const stringToObjIsEditableTagData = JSON.parse(isEditableTagData);
          if(stringToObjIsEditableTagData!== null && stringToObjIsEditableTagData.isEditableTagOpen){
            // REMOVE TEMPORARY EDIT HOT-SPOT
            viewer.removeHotSpot(stringToObjIsEditableTagData.previouseEditableTagID);
          }

          var currenHSiD= Date.now();
          var newHotspot = {
            id: currenHSiD,
            tagName: stringToObjIsEditableTagData.pitchYaw.tagName,
            pitch: stringToObjIsEditableTagData.pitchYaw.pitch,
            yaw: stringToObjIsEditableTagData.pitchYaw.yaw,
            cssClass: getIcon(event.target.value),
            // createTooltipFunc: addEditTagHotspot,
            createTooltipFunc: showHotspot,
            createTooltipArgs: {
              pitch: stringToObjIsEditableTagData.pitchYaw.pitch, 
              yaw: stringToObjIsEditableTagData.pitchYaw.yaw, 
              tagName: stringToObjIsEditableTagData.pitchYaw.tagName,
              isEdite: true,
              ...stringToObjIsEditableTagData.pitchYaw,
              id: currenHSiD,
              addTooltipPermanent: true
            },
            isAddInfoEnable: true,
            draggable: true,
            "dragHandlerFunc": dragHandlerFunc,
            "dragHandlerArgs": {id: 0}
          };

          viewer.addHotSpot(newHotspot);
          // REMOVE CURRENT SHOW-HOTSPOT AND ADD EDIT-HOTSPOT FOR TEMPORARY TIME(end)
          
          // STORE THE EDIT HOTS-POT ID TO BE DELETED IF WE DO EDIT NEXT TIME
          let previouseEditableTagID= currenHSiD;
          localStorage.setItem("isEditableTagData", JSON.stringify({
            previouseEditableTagID: previouseEditableTagID,
            pitchYaw: stringToObjIsEditableTagData.pitchYaw,
            isEditableTagOpen: true
          }));
      
        }
      break;
      case "add-experience":
        setSelectedExperienceIdForHS(event.target.value);
        if(!addEditAssetsToViewIndrawer.isEdite){
          setIsEnableNewTag(true);
          setActiveTagID("");
        }
      break;
    }

  }
  
  const getRadioView = ()=>{
    return<div>
    <span className="custom-radio">
      <input
        type="radio"
        value="0"
        id="none"
        name="add-tag"
        checked={0 == selectedOptionForAddEditTag}
        onChange={handleOptionChange}
      />
      <label for="none">None</label>
    </span>

    <span className="custom-radio">
      <input
        type="radio"
        value="3"
        id="image"
        name="add-tag"
        checked={3 == selectedOptionForAddEditTag}
        onChange={handleOptionChange}
      />
     <label for="image">Image</label>
    </span>

    <span className="custom-radio">
      <input
        type="radio"
        value="5"
        id="video"
        name="add-tag"
        checked={5 == selectedOptionForAddEditTag}
        onChange={handleOptionChange}
      />
      <label for="video">Video</label>
    </span>

    <span className="custom-radio">
      <input
        type="radio"
        value="4"
        name="add-tag"
        id="url"
        checked={4 == selectedOptionForAddEditTag}
        onChange={handleOptionChange}
      />
      <label>Url</label>
    </span>

    {(virtualTourImages && virtualTourImages.images && virtualTourImages.totalLength > 1) && 
    <span className="custom-radio">
      <input
        type="radio"
        value="6"
        name="add-tag"
        id="addHP"
        checked={6 == selectedOptionForAddEditTag}
        onChange={handleOptionChange}
      />
      <label>Hotspot</label>
    </span>
    }
  </div>
  }

  const getUploadedActionView = ()=>{

    // let isEdite= false;
    // if(addEditAssetsToViewIndrawer.isEdite){
    //   isEdite= true;
    // } 

    return <React.Fragment>
      
      <div className="p-3">
      <div className="form-group mb-3">        
        <label className="form-label">Tag Name</label>

        {addEditAssetsToViewIndrawer && addEditAssetsToViewIndrawer.isEdite && <div 
          style={{
            "display": 'inline-block',
            "position": "relative",
            "left": "137px",
            "cursor": "pointer"
          }} 
          onClick={async()=> {
            console.log("virtualTourImagesvirtualTourImages 1::", addEditAssetsToViewIndrawer);

            if( userProfileDetails.isExpired ){
                dispatch(setDoNotShowExpiredModal(false));
                // setOpenUpgrade(true);
                toast("Your subscription has been expired");
                return;
            }

            deleteAssestToggle();
            setHStobeDeleted(addEditAssetsToViewIndrawer);
        }}>
        <img src={deleteTagIcon} alt="delete tag" style={{
          height: "15px"
        }}/>
        <span style={{
          padding: "3px",
          color: "red"
        }}>Delete</span>
        </div>}

        <input type="text" placeholder="Enter Tag Name" className="form-control" value={tageNameForAddEditTag} onChange={e=>setTageNameForAddEditTag(e.target.value)} />
      </div>
      
      <div className="form-group mb-3">
        <label className="form-label">Select Tag Type</label>
        {getRadioView()}
      </div>
      
      {/* {!isEdite && getBrowseView()} */}

      {getSavedOrNewFileView()}

      <div style={{margin: "27px"}}>
        <Button className="btn-long" color="primary" onClick={async()=> {
          if(await checkValidation(
            // {
            //   isEdit: addEditAssetsToViewIndrawer.isEdite,
            //   urlVal: urlForAddEditTag, 
            //   tagName: tageNameForAddEditTag
            // }
          )){

            if( userProfileDetails.isExpired ){
                dispatch(setDoNotShowExpiredModal(false));
                // setOpenUpgrade(true);
                toast("Your subscription has been expired");
                return;
            }

            addEditTagHandler({
              ...addEditAssetsToViewIndrawer, 
              // // ...appedValue, 
              urlVal: urlForAddEditTag, 
              tagName: tageNameForAddEditTag
            });
          }
          }}>Save</Button>
          <Button className="ms-5 ms-md-4 white-color" color="link" onClick={()=> {
            cancelTagHandler(addEditAssetsToViewIndrawer);
          }}>Cancel</Button>
          
          {/* {addEditAssetsToViewIndrawer && addEditAssetsToViewIndrawer.isEdite && <Button 
            className="btn-long" 
            color="primary" 
            style={{"position": "relative",
              "top": "12px",
              "left": "45px",
              "background": "white",
              "color": 'red',
              "border": "1px solid red"}}
            onClick={async()=> {
              console.log("virtualTourImagesvirtualTourImages 1::", addEditAssetsToViewIndrawer);
              deleteAssestToggle();
              setHStobeDeleted(addEditAssetsToViewIndrawer);
          }}>Delete</Button>} */}

        </div>
      </div>
    </React.Fragment>
  }

  // const getTourImgListView= ()=>{
  //   if(Object.keys(virtualTourImages).length !== 0){   
  //     return virtualTourImages.images.map((img)=>{
  //       return <div className={`tour-item ${imgToBeView.id === img.id && "active"}`} onClick={()=>{
  //        setOpenList(!openList);
  //        if(imgToBeView.id !== img.id)
  //         updateVierwer(img);
  //       }}> 
  //         {img.name}
  //       </div>
  //     })
  //   }
  // }

  const getTourImgs = ()=>{
    
      return  <InfiniteScroll
                className="row"
                dataLength={virtualTourImages.images}
                next={fetchMoreData}
                style={{
                  "overflow": "hidden",
                  "--bs-gutter-x": "0rem"
                }}
                hasMore={virtualTourImages.hasMoreVirtualTourImages}
                loader={virtualTourImages.totalLength > 25 ?<h4>Loading...</h4>: ""}
                endMessage={
                <p style={{ textAlign: "center" }}>
                    {/* <b>Yay! You have seen it all</b> */}
                </p>
                }
                scrollableTarget="scrollableDiv"
              >
              {getVirtualImag()}
      </InfiniteScroll>
   
 } 

 const getExperiences = ()=>{
    
  return  <InfiniteScroll
            className="row"
            dataLength={virtualTourImages.images}
            next={fetchMoreData}
            style={{
              "overflow": "hidden",
              "--bs-gutter-x": "0rem"
            }}
            hasMore={virtualTourImages.hasMoreVirtualTourImages}
            loader={virtualTourImages.totalLength > 25 ?<h4>Loading...</h4>: ""}
            endMessage={
            <p style={{ textAlign: "center" }}>
                {/* <b>Yay! You have seen it all</b> */}
            </p>
            }
            scrollableTarget="scrollableDiv"
          >
          {getExperienceLIst()}
  </InfiniteScroll>

}

  const fetchMoreData = () => {
    setLoadVirtualImages(loadVirtualImages+1)
  };
  
  useEffect(()=>{
    dispatch(resetvirtualTourImages());
    let virtualTour=null;
    async function getTourInfo(){
      virtualTour= await JSON.parse(localStorage.getItem("virtualTour"));
      dispatch(fetchVirtualTourImagesDispatch(loadVirtualImages, 25, virtualTour.id)); 
    }
    getTourInfo()
  },[]) 

  useEffect(()=>{
    if(tourInfo){
      dispatch(fetchVirtualTourImagesDispatch(loadVirtualImages, 25, tourInfo.id)); 
    }
  },[loadVirtualImages]) 

 const getVirtualImag = ()=>{
  if(Object.keys(virtualTourImages).length !== 0){
    return virtualTourImages.images.map((img)=>{
        return <div className={`tour-item ${imgToBeView.id === img.id && "active"}`} onClick={()=>{
          setOpenList(!openList);
          if(imgToBeView.id !== img.id)
          updateVierwer(img);
        }}> 
          {img.name}
        </div>
    })
  }
  }

  const updateVierwer= (img)=>{
    localStorage.removeItem('isEditableTagData');
    localStorage.removeItem('isNewAddedTagData');
    resetTag();
    seIsClickOnSavedTag(false);
    setIsEnableDrawer(false);
    setIsEnableNewTag(false);
    setIsEnableActionDrawer(false);
    setIsActEnableDrawer(false);
    setFetchedHotSpot([]);
    setImgToBeView(img);
  }

  const getMenu = ()=>{

    console.log("tourInfotourInfotourInfo", tourInfo)

    if(tourInfo){
      return <nav class="upper-menu">
      
        {tourInfo.tourLogo && <li style={{
          // "position": "relative",
          // "left": "-33px"
        }}>
          <img src={tourInfo.tourLogo} alt="menu icon"  />
        </li>}

        <li class="px-2 text-center"><div >{tourInfo.name}</div></li>

        <li onClick={()=>setIsTourInfoModalShow(!isTourInfoModalShow)}>
          <img src={Info} alt="menu icon" />
        </li>

     
      </nav>
    }

  }


  const getConfimViewToResumeAndCancel = ()=>{

    console.log("userProfileDetailsuserProfileDetails", userProfileDetails)

    if(tourInfo){
      return <Modal isOpen={isTourInfoModalShow} centered className="drop-modal" toggle={()=>{
          setIsTourInfoModalShow(!isTourInfoModalShow);
          }} >
          <Button className="modalClose" onClick={()=>{
              setIsTourInfoModalShow(!isTourInfoModalShow);
          }}>
              <img src={Close} alt="Images" className="img-fluid" />
          </Button>
          <ModalBody className="px-0">
              <div className="share_people text-center">
                  <div class="row">
                      <div className="col-12">
                        <h4 className="text-primary">{tourInfo.name}</h4>
                        
                        {tourInfo.tourDescription !== "" && <><hr/>
                        <div className="px-3">
                          
                          <h6>Tour Description </h6>
                          <div style={{"font-size": "13px"}}>{tourInfo.tourDescription}</div>
                        </div>
                        </>}

                        <hr/>

                        <div className="px-3" style={{"position": "relative", "top": "-9px"}}>
                          <div>{userProfileDetails.firstName} {userProfileDetails.lastName}</div>
                          <div style={{"font-size": "11px"}}>{userProfileDetails.email}</div>
                        </div>
                        {tourInfo.tourLogo && <img src={tourInfo.tourLogo} alt="Upload" style={{ "height": "50px", "width": "aut0","object-fit": "cover", borderRadius: "10px"}} />}      
                      </div>
                  </div>
              </div> 
          </ModalBody>
      </Modal>
    }
  }
  
  return (
    <React.Fragment>
      <ExpiredModal />
      {getConfimViewToResumeAndCancel()}
      {getDeleteConfirmationPopup()}
      <div className={`virtual-tour-wrap ${isActEnableDrawer && 'act'}`}>
    
        {isParonamaIMGloaded !=0  && <div className={`virtual-tour-drawer ${isEnableDrawer && 'actDrawer'}`}>
          <div className="collapseBtn">
              <button className="btn btn-link menu-btn" 
                onClick={()=>{
                  handlerIsEnableDrawer();
                }}
              > {!isEnableDrawer ?
                  <img src={Collapse} alt="collapse icon" />
                :
                  <img src={Expand} alt="collapse icon" />
                }
                </button>
          </div>
          <div class="tour-drawer-inner custom-scrollbar">
            <div class="accordion accordion-flush" id="accordionFlushExample">
              <div class="accordion-item">
                <h2 class="accordion-header" id="tags">
                  <button class={`accordion-button ${!isEnableDrawerItem && 'collapsed'}`} type="button" 
                  onClick={()=>{
                    setIsEnableDrawerItem(!isEnableDrawerItem);
                  }}
                  >
                    Tags
                  </button>
                </h2>
                <div id="flush-collapseOne" class={`accordion-collapse collapse ${isEnableDrawerItem && 'show'}`}>
                  <div class="accordion-body">
                    {fetchedHotSpot.length === 0 ?
                    <p class="no-cnt">No tags found</p> 
                    : 
                    <div class="taglist">
                      {getHotSpot()}
                    </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>}

        {isParonamaIMGloaded !=0 && <div className={`virtual-tour-drawer ${isEnableActionDrawer && 'actDrawer'}`}>
          <div class="tour-drawer-inner custom-scrollbar">
            <div class="accordion accordion-flush" id="accordionFlushExample">
              <div class="accordion-item">
                <div id="flush-collapseOne" class={`accordion-collapse collapse'}`}>
                  <div class="accordion-body">
                    {
                      getUploadedActionView()
                    }
                  </div>
                </div>
              </div>     
            </div>
          </div>
        </div>}
    
        <div className="panorama-wrap">
          <div className="panorama-wrap">
            {/* <button className="prev_btn btn btn-primary btn-sm">
              Preview
            </button> */}

            {isParonamaIMGloaded !=0 && <div className="backBtn">
                <button className="btn btn-round" onClick={async() =>{
                  let virtualTour= JSON.parse((await localStorage.getItem("virtualTour")));
                  navigate('/virtual-tour-images', {state: virtualTour})
                }}>
                  <img src={Back} alt="back icon" />
                </button>
            </div>}

            {!openList && <button className="btn btn-link menu-btn" style={{left: 0}}>
              <img src={MenuImg} alt="menu icon" onClick={() => setOpenList(!openList)} className="img-fluid" />
            </button>}

            {tourInfo && <button className="prev_btn btn btn-primary btn-sm" 
              onClick={() => {
                let publicLInk= DOMAIN_URL+VIEWER_THREE_SIXTY_WORLD_PAGE+`/${tourInfo.tourUrlToken}`;
                window.open(publicLInk, '_blank');
              }} 
            >
              Preview
            </button>}

            {getMenu()}
          
            <div
                className="panorama"
                ref={panoramaRef}
                style={{height: "calc(100vh - 0px)", width: "100%", minHeight: "230px"}}
                onMouseDown={(e)=>isEnableNewTag ? handleMouseDown(e): ()=>{}}
                onMouseUp={(e)=>isEnableNewTag ? handleMouseUp(e): ()=>{}}
                // onMouseDown={handleMouseDown}
                // onMouseUp={handleMouseUp}
            >
            </div>
          </div>

          <div className={`tour-menu-list ${openList && 'open'}` }>
            <button className="btn btn-link menu-btn" style={{left: 0}}>
                <img src={MenuClose} alt="menu icon" onClick={() => setOpenList(false)} className="img-fluid" />
              </button>
              {/* FOR PAGINATION  START*/}
                {/* {getViewrVirtourImagesList()}  */}
              {/* FOR PAGINATION  END*/}
              <div className="" style={{height: "100%",
                  display: "flex",
                  "align-items": "center"
              }}>
                <div className="tour-lists" id="scrollableDiv">
                  {/* {getTourImgListView()} */}
                  {getTourImgs()}
                </div>
              </div>
          </div>

         {isParonamaIMGloaded !=0 && <div className="addtag" 
          style={{zIndex: 11,
                  "position": "relative"
                }}>
            {!isEnableNewTag? 
              <button className={`${isEnableActionDrawer? "tagButtonInfo_": ""}`} style={{backgroundColor: "transparent", border: "none"}} onClick={()=>{
                  resetTag();
                  setIsEnableNewTag(true);
                  setIsEnableDrawer(false);
                  setIsEnableActionDrawer(false);
                  setIsActEnableDrawer(false);
                  setActiveTagID("");
              }}>
                <img src={AddTagIcon} style={{"height": "55px"}}/> 
              </button> 
              :
              <div>
                <span class={`tagButtonInfo ${isEnableActionDrawer? "tagButtonInfo_": ""}`}>Click on an object that you want to tag in the 360 Image.</span>
                <button className={`${isEnableActionDrawer? "tagButtonInfo_": ""}`} style={{backgroundColor: "transparent", border: "none"}} 
                onClick={async()=>{

                  setActiveTagID("");
                  setIsEnableNewTag(false);
                  resetTag();
                  setIsEnableDrawer(false);
                  setIsEnableActionDrawer(false);
                  setIsActEnableDrawer(false);

                  let isNewAddedTagData= await localStorage.getItem("isNewAddedTagData");
                  const stringToObjIsNewAddedTagData = JSON.parse(isNewAddedTagData);
                  if(stringToObjIsNewAddedTagData!== null && stringToObjIsNewAddedTagData.newAddedID){
                    viewer.removeHotSpot(stringToObjIsNewAddedTagData.newAddedID);
                    setPreviousHSiD("");
                    localStorage.setItem("isNewAddedTagData", JSON.stringify({
                      newAddedID: ""
                    }));
                    // addShowHotspot(createTooltipArgs);
                  }
                  let isEditableTagData= await localStorage.getItem("isEditableTagData");
                  const stringToObjIsEditableTagData = JSON.parse(isEditableTagData);
                  if(stringToObjIsEditableTagData!== null && stringToObjIsEditableTagData.isEditableTagOpen){
                    console.log("createTooltipArgscreateTooltipArgs 1", stringToObjIsEditableTagData);
                    // REMOVE TEMPORARY EDIT HOT-SPOT
                    viewer.removeHotSpot(stringToObjIsEditableTagData.previouseEditableTagID);
                    // SHOW HOTSPOT IN REPLACE OF PREVIOUSE TEMPORARY EDIT HOT-SPOT
                    addShowHotspot(stringToObjIsEditableTagData.pitchYaw);
                  }
                }}>
                
                <img src={CanelAddTagIcon} style={{"height": "55px"}}/> 
                {/* <img src={AddTag} className="cancel" alt="add icon" /> Cancel */}
              </button>
            </div>
            }
          </div>}
        </div>
      </div>
    </React.Fragment>
  );

}

export default PenneliumReactOne;