import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from 'react-router-dom'
import { getSubscriptionDispatch, resetSubscriptionDetails, setIsDetailsGetToFalse } from "./sbscription.slice";
import Info from "./info";
import { LoaderForIndividualPageDispatch } from "../../COMPONENTS/Loader/LoaderForIndividualPage.slice.reducer";
import { setParentModuleActiveDispatch } from "../../COMPONENTS/SideBarMenu/activeParentModule.slice";
import { setModuleActiveDispatch } from "../../COMPONENTS/SideBarMenu/activeModule.slice";
import Dashboard from "../Dashboard";
import { setDoNotShowExpiredModal } from "../Profile/slice.reducer";


export default ()=> {

    const dispatch = useDispatch();
    const subscription = useSelector(state => state.subscription);
    let {state}  = useLocation();
    
    // TO GET THE INFORMATION OF THE EXISTING USER
    useEffect(()=>{
        
        dispatch(setParentModuleActiveDispatch("parentSubscription"));
        dispatch(setModuleActiveDispatch(""));
        dispatch(setDoNotShowExpiredModal(true));

        if(!state){
            dispatch(resetSubscriptionDetails())
            dispatch(LoaderForIndividualPageDispatch(true));
            dispatch(getSubscriptionDispatch())
            dispatch(LoaderForIndividualPageDispatch(false));
        }
        
        return ()=>{
            dispatch(setIsDetailsGetToFalse())
        }

    }, []);

    if(!subscription.isDetailsGet){ 
        return <Dashboard /> 
    }

    return (
        <Dashboard><Info subscription= {subscription}/></Dashboard>
    )
};