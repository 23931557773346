// import querystring from 'querystring'
// import url from 'url'

import * as API from './../APIs'
import { loaderDispatch } from "../COMPONENTS/Loader/slice.reducer";
import {setSessionData} from './../SCREENS/Authentications/Login/slice.reducer'
import { resetApp } from './endPoints';
import ProfileImage from './../ASSETS/Images/defaultProfilePic.jpg';
import * as CONSTANT from './Constant';
import {CommanRoutesAPIsInterface} from "./Modals/baseApi.modal"
// const navigate = useNavigate();

export function postData<T>({url, headerConfig, dataObje= null}: CommanRoutesAPIsInterface, dispatch: any){

    return new Promise((resolve, reject)=>{

        let body: any= "";
        if(dataObje!==null && dataObje?.isFormData){
            console.log("deleteProfilePicdeleteProfilePic 4",)
            body= dataObje?.data;
        }else{
            console.log("deleteProfilePicdeleteProfilePic 5",)
            body= dataObje!==null? JSON.stringify(dataObje): "";
        }

        // console.log('catch err', url, headerConfig, dataObje) 
        console.log('this is dispatch', dispatch)
        dispatch!=undefined && dispatch(loaderDispatch(true))        
        fetch(url, {
            method: 'POST', // or 'PUT'
            headers: headerConfig !=undefined ? {...headerConfig}: {},
            signal: AbortSignal.timeout(60000),
            body: body,
        })
        .then(async(response) => {
            
            if(!response.ok && response.status === 401){
                console.log("this is a unautho 1", response);
                let res =await resetToken("POST" ,{url, headerConfig, dataObje}, dispatch);
                console.log("this is a unautho 4", res);
                resolve(res);
            }else{
                return response.json()
            }
            // return response.json()
        })
        .then((data) => {
            console.log("this is a postData 13", data)
            dispatch!=undefined && dispatch(loaderDispatch(false))
            if(data.isError !== undefined && data.isError ){
                // console.log("this is a postData catch", data)
                throw (data)
            }

            resolve(data)
        })
        .catch((error) => {
            loaderDispatch(false)
            dispatch!=undefined && dispatch(loaderDispatch(false))
            console.log("this is a postData catch", error)
            reject(error)
        });
        
    })
}

export function getData({url, headerConfig}: CommanRoutesAPIsInterface, dispatch: any){

    return new Promise((resolve, reject)=>{

        console.log('this is a getData then', url, headerConfig) 
        dispatch!=undefined && dispatch(loaderDispatch(true))        
        fetch(url, {
            method: 'GET', // or 'PUT'
            headers:  headerConfig !=undefined ? {...headerConfig}: {},
            signal: AbortSignal.timeout(60000),
        })
        .then( async(response) => {
            
            if(!response.ok && response.status === 401){
                console.log("this is a unautho 1444", response)
                let res =await resetToken("GET" ,{url, headerConfig}, dispatch)
                console.log("this is a unautho 4", res)
                resolve(res)
            }else{
                return response.json();
            }
            
        })
        .then((data) => {
            console.log("this is a getData then", data)
            dispatch!=undefined && dispatch(loaderDispatch(false))
            if(data.isError !== undefined && data.isError ){
                // console.log("this is a getData catch", data)
                throw (data)
            }

            resolve(data)
        })
        .catch((error) => {
            dispatch!=undefined && dispatch(loaderDispatch(false))
            console.log("this is a getData catch", error)
            reject(error)
        });
        
    })
}

export function postFormData({url, headerConfig, formData}: CommanRoutesAPIsInterface, dispatch: any){

    return new Promise((resolve, reject)=>{

        // console.log('catch err', url, headerConfig, formData) 
        // console.log('postFormDatapostFormData', url, headerConfig, formData)
        dispatch!=undefined && dispatch(loaderDispatch(true))        
        fetch(url, {
            method: 'POST', // or 'PUT'
            headers:  headerConfig !=undefined ? {...headerConfig}: {} ,
            body: formData!=undefined? (formData): "",
        })
        .then(async(response) => {
            // console.log("this is a postData then_1", response)

            if(!response.ok && response.status === 401){
                console.log("this is a unautho 1", response)
                let res =await resetToken("POST" ,{url, headerConfig, formData}, dispatch)
                console.log("this is a unautho 4", res)
                resolve(res)
            }else{
                return response.json()
            }
            // return response.json()
        })
        .then((data) => {
            console.log("this is a postData then", data)
            dispatch!=undefined && dispatch(loaderDispatch(false))
            if(data.isError !== undefined && data.isError ){
                // console.log("this is a postData catch", data)
                throw (data)
            }

            resolve(data)
        })
        .catch((error) => {
            loaderDispatch(false)
            dispatch!=undefined && dispatch(loaderDispatch(false))
            console.log("this is a postData catch", error)
            reject(error)
        });
        
    })
}

export async function resetToken(METHOD: any, urlDataConfig: any, dispatch: any){
    
    return new Promise( async(resolve, reject)=>{
        
        const localData = await JSON.parse(localStorage.getItem('data') as string).payload;
        
        let url= API.BASE_URL+API.REFRESH_TOKEN
        
        let dataObje = {
            "accessToken": localData.accessToken,
            "refreshToken": localData.refreshToken,
        }

        let headerConfig = {
            "Content-Type": "application/json"
        }

        fetch(url, {
            method: 'POST', // or 'PUT'
            headers: {...headerConfig},
            body:  JSON.stringify(dataObje)
        })
        .then(async(response) => {

            if(!response.ok && response.status === 400){
                resetApp(dispatch);
                return reject(response)
            }else{
                return response.json()
            }

        })
        .then(async (refreshTokenData) => {

            console.log("In reset 2", dispatch, refreshTokenData)
            
            dispatch!=undefined && dispatch(setSessionData(refreshTokenData.result))
            await localStorage.setItem("data", JSON.stringify({
            ...refreshTokenData.result
            }));

            console.log("this is a unautho 5", refreshTokenData)

            let res = {}
            if(METHOD === "GET"){
                res = await getApiAfterRefresh(urlDataConfig, refreshTokenData) as object
                console.log("this is a unautho 8", refreshTokenData)
            }else{
                res = await postApiAfterRefresh(urlDataConfig, refreshTokenData) as object
            }

            resolve(res)
        })
        .catch((error) => {
            reject(error)
        })
        .finally(() => {
            console.log('Experiment completed');
        });
        
    })
}

export function postApiAfterRefresh({url, headerConfig, dataObje}: CommanRoutesAPIsInterface, refreshTokenData: any){

    return new Promise((resolve, reject)=>{

        console.log("this is a unautho 11", url, headerConfig, refreshTokenData)
        
        let updateHeaderConfig = {
            ...headerConfig,
            "Content-Type": "application/json",
            "Authorization": "Bearer " +refreshTokenData.result.payload.accessToken
        }

        fetch(url, {
            method: 'POST', // or 'PUT'
            headers:  updateHeaderConfig !=undefined ? {...updateHeaderConfig}: {} ,
            body: dataObje!=undefined? JSON.stringify(dataObje): "",
        })
        .then( async(response) => {
            return response.json()
        })
        .then((data) => {
           
            console.log("this is a unautho 7", data)
          
            if(data.isError !== undefined && data.isError ){
                // console.log("this is a getData catch", data)
                throw (data)
            }
            resolve(data)
        })
        .catch((error) => {

            console.log("this is a getData catch", error)
            reject(error)
        });
        
    })
}

export function getApiAfterRefresh({url, headerConfig}: CommanRoutesAPIsInterface, refreshTokenData: any){

    return new Promise((resolve, reject)=>{

        console.log("this is a unautho 11", url, headerConfig, refreshTokenData)
        
        let updateHeaderConfig = {
            ...headerConfig,
            "Content-Type": "application/json",
            "Authorization": "Bearer " +refreshTokenData.result.payload.accessToken
        }

        fetch(url, {
            method: 'GET', // or 'PUT'
            headers:  updateHeaderConfig !=undefined ? {...updateHeaderConfig}: {} ,
        })
        .then( async(response) => {
            return response.json()
        })
        .then((data) => {
           
            console.log("this is a unautho 7", data)
          
            if(data.isError !== undefined && data.isError ){
                // console.log("this is a getData catch", data)
                throw (data)
            }
            resolve(data)
        })
        .catch((error) => {

            console.log("this is a getData catch", error)
            reject(error)
        });
        
    })
}

export function deleteAPI({url, headerConfig, dataObje}: CommanRoutesAPIsInterface, dispatch: any){

    return new Promise((resolve, reject)=>{

        // console.log('catch err', url, headerConfig, dataObje) 
        console.log('this is dispatch', dispatch)
        dispatch!=undefined && dispatch(loaderDispatch(true))        
        fetch(url, {
            method: 'DELETE', // or 'PUT'
            headers:  headerConfig !=undefined ? {...headerConfig}: {} ,
            body: dataObje!=undefined? JSON.stringify(dataObje): "",
        })
        .then(async(response) => {
            console.log("this is a postData 12", response)

            if(!response.ok && response.status === 401){
                console.log("this is a unautho 1", response)
                let res =await resetToken("POST" ,{url, headerConfig, dataObje}, dispatch)
                console.log("this is a unautho 4", res)
                resolve(res)
            }else{
                return response.json()
            }
            // return response.json()
        })
        .then((data) => {
            console.log("this is a postData 13", data)
            dispatch!=undefined && dispatch(loaderDispatch(false))
            if(data.isError !== undefined && data.isError ){
                // console.log("this is a postData catch", data)
                throw (data)
            }

            resolve(data)
        })
        .catch((error) => {
            loaderDispatch(false)
            dispatch!=undefined && dispatch(loaderDispatch(false))
            console.log("this is a postData catch", error)
            reject(error)
        });
        
    })
}

export function getImgURL(url: any){
    if(url !== undefined &&url !== null &&url !== "")
    return `${url}`
    return ProfileImage
}

// export function random (length) {
//     return Math.random().toString(16).substr(2, length);
// };


export const random = (length = 8) => {
    // Declare all characters
    let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';

    // Pick characers randomly
    let str = '';
    for (let i = 0; i < length; i++) {
        str += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    console.log('this is string for dom', str)
    return str;

};

// REDIRECTION AFTER UPLOADING THE FILE OR FILES
export function redirectAfterUpload(Image: stringNumber, Audio: stringNumber, Video: stringNumber) {
    console.log('redirectAfterUploadredirectAfterUpload 1', Image, Audio, Video)
    if (Image > Audio) {
      if (Image > Video) {
        return "assets-images"
      } else {
        if ((Image == Video) && Video > Audio) {
          return "assets-images"
        }
        return "assets-videos"
      }
    } else if (Audio > Video) {
      if ((Image == Audio) && (Video < Audio)) {
        return "assets-images"
      }
    //   console.log('redirectAfterUploadredirectAfterUpload 2', Image, Audio, Video)
      return "assets-audios"
    } else {
      if ((Image == Audio) && (Audio == Video)) {
        return "assets-images"
      } else if ((Image < Audio) && (Audio == Video)) {
        return "assets-audios"
      } else if ((Image < Audio) && (Audio < Video)) {
        return "assets-videos"
      }
      return "assets-videos"
    }
}

// GET MEDIA
export const getMedia = (ext: string) => {
    let extention= "";
    let media= "";
    let extentions= [];
    for (let key in CONSTANT.UPLOADED_FILES_EXT) {
        extentions = CONSTANT.UPLOADED_FILES_EXT[key];
        extention =extentions.find((ex: any) => ex.toLowerCase() === ext.toLowerCase());
        if(extention !=="" && extention !==undefined){
            console.log('file.type.splitfile.type.split', extention, key); 
            if(key === "images"){
                media = "image";
            }else if(key === "audios"){
                media = "audio";
            }else if(key === "videos"){
                media = "video";
            }
            break;
        }
    }
    return media;
}

export function putData<T>({url, headerConfig, body}: CommanRoutesAPIsInterface, dispatch: any){

    return new Promise((resolve, reject)=>{

        // console.log('catch err', url, headerConfig, dataObje) 
        console.log('createTooltipArgscreateTooltipArgs 5', url, headerConfig, body)
        dispatch!=undefined && dispatch(loaderDispatch(true))        
        fetch(url, {
            method: "PUT", // or 'PUT'
            headers:  headerConfig !=undefined ? {...headerConfig}: {},
            body: body
        })
        .then(async(response) => {
            console.log("this is a postData catch putData", response)
            return true
        })
        .then((data) => {
            dispatch!=undefined && dispatch(loaderDispatch(false))
            resolve(data)
        })
        .catch((error) => {
            dispatch!=undefined && dispatch(loaderDispatch(false))
            console.log("this is a postData catch putData", error)
            reject(error)
        });
        
    })
}