import { Route, Routes, Navigate } from "react-router-dom";
import Login from './../SCREENS/Authentications/Login'

// REGISTRATION RELETED ROUTE
import Registration from './../SCREENS/Authentications/Registration'
import ConfirmUser from './../SCREENS/Authentications/ConfirmUser'
import Congratulation from './../SCREENS/Congratulation/Congratulation'

// FORGET AND RESET RELETED ROUTE
import ChangePasswordSuccessView from './../SCREENS/Authentications/ForgetResetPassword/ChangePasswordSuccessView'
import ForgetPassword from './../SCREENS/Authentications/ForgetResetPassword/ForgetPassword'
import ResetPassword from './../SCREENS/Authentications/ForgetResetPassword/ResetPassword'
import SentEmailView from './../SCREENS/Authentications/ForgetResetPassword/SentEmailView'

// PROFILE RELETED ROUTE
import ViewProfile from "./../SCREENS/Profile/View.profile.tsx";
import EditProfile from "./../SCREENS/Profile/Edit.profile.tsx";
import ChangePasswordProfile from "./../SCREENS/Profile/ChangePassword.profile";

// SUBSCRIPTION
import Payment from "../SCREENS/Subscription";

// ANALYTICS
import Analytics from "../SCREENS/Analytics/landing.js";

import Home from "../SCREENS/Home/landing.js";

// VIRTUAL-TOUR
import VirtualHome from "../SCREENS/360virtualTour/VirtualHome.js";
import VirtualTourImages from "../SCREENS/360virtualTour/Virtual-tour-images-landing-page";
import ThreeSixtyWorld from "../SCREENS/360virtualTour/ThreeSixtyWorld";

// ===> VIEWER --> To target end users  
import ThreeSixtyViewer from "../SCREENS/viewer/index__.js";

const AllRoutes = () => ( 
    <Routes>
        {/* **  APP --> Those who will create 360VT and Images */}
        {/* AUTHENTICATION */}
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        
        <Route path="/registration" element={<Registration />} />
        <Route path="/confirmation" element={<ConfirmUser />} />
        <Route path="/congratulation" element={<Congratulation />} />

        <Route path="/forgetPassword" element={<ForgetPassword />} />
        <Route path="/sentEmailView" element={<SentEmailView />} />
        <Route path="/resetPassword" element={<ResetPassword />} />
        <Route path="/ChangePasswordSuccessView" element={<ChangePasswordSuccessView />} />

        {/* PROFILE AND CHANGE PASSWORD */}
        <Route path="/viewProfile" element={<ViewProfile />} />
        <Route path="/editProfile" element={<EditProfile />} />
        <Route path="/changePassword" element={<ChangePasswordProfile />} />

        {/* 360-VIRTUAL-TOUR */}
        <Route path="/virtual-tour" element={<VirtualHome />} />
        <Route path="/virtual-tour-images" element={<VirtualTourImages />} />
        <Route path="/virtual-tour-world" element={<ThreeSixtyWorld />} />

        {/* SUBSCRIPTION */}
        <Route path="/subscription" element={<Payment />} />
        
        {/* ANALYTICS */}
        <Route path="/analytics" element={<Analytics />} />

        {/* **  VIEWER --> To target end users   */}
        <Route path="/three-sixty-world/:virtualTourToken" element={<ThreeSixtyViewer />} />

        <Route path="/home" element={<Home />} />
       
        <Route path="*" element={<Navigate to="/ViewProfile"/>} />
    </Routes>
  );
  
export default AllRoutes;
