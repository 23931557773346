import { createSlice } from '@reduxjs/toolkit'
import { getData, postData } from '../../../UTILITYS/index.tsx';
import * as API from '../../../APIs'
import { toast } from 'react-toastify';

const initialState = {
    images: [],
    isImgResGet: false,
    hasMoreVirtualTourImages: true,
    totalLength: 0
}

export const virtualTourImages = createSlice({
  name: 'virtualTourImages',
  initialState: { ...initialState }, 
  reducers: {
  
    // REDUCERS FOR VIRTUAL TOUR IMAGES
    setImages: (state, action) => {
      console.log('this is res of i sssf ff', action)
      return {
        ...state,
          images: [...state.images, ...action.payload.pagedList]
        }
    },
    setResponseForVirtualImages: (state, action) => {
      return {
        ...state,    
        [action.payload.key]: action.payload.value 
        }
    },
    setHasMoreVirtualTourImages: (state, action)=>{
      return {
        ...state,
          hasMoreVirtualTourImages: action.payload
      }
    },
    isFileUploading: (state, action) => {
      return {
        ...state,
        isFileUploadingState: action.payload
      }
    },
    setImagesTotalLength: (state, action) => {
      return {
        ...state,
        totalLength: action.payload
      }
    },
    resetvirtualTourImages: (state, action)=>{
      return {...initialState}
    },
    setUpdateVirtualTourImage: (state, action) => {

      console.log("this is a udddd try", action)

      return {
        ...state,
        images: [...state.images.map(image =>{
          if(image.id === action.payload.id)
            return {
              ...image,
              name: action.payload.name
            }
            return image;
        })]
      }
    },
    setDeletedVirtualTourImage: (state, action) => {
      return {
        ...state,
        images: [...state.images.filter(image =>  image.id !== action.payload.id )]
      }
    }
  }
})

// Action creators are generated for each case reducer function
export const { 
  setImages,
  setResponseForVirtualImages,
  setHasMoreVirtualTourImages,
  isFileUploading, 
  setImagesTotalLength,
  resetvirtualTourImages,
  setUpdateVirtualTourImage,
  setDeletedVirtualTourImage
 } = virtualTourImages.actions;


export const isFileUploadingDispatch = (isUploading) => {
  return async(dispatch, getState) => {
    dispatch(isFileUploading(isUploading))
  }
}
 
const fetchVirtualTourImages =async (token, dispatch, pageIndex, pageSize, virtualtourid ) =>{

    try{

      let url= `${API.BASE_URL+API.GET_VIRTUAL_TOUR_IMAGES}?pageindex=${pageIndex}&pagesize=${pageSize}&virtualtourid=${virtualtourid}` 
     
      let headerConfig = {
        "Content-Type": "application/json",
        "Authorization": "Bearer " +token
      }

      const fetchVTiMGs =await getData({url, headerConfig}, dispatch)

      console.log("this is a fetchVTiMGs try", fetchVTiMGs)
    
    return fetchVTiMGs;
    
  }catch (err){

    console.log("this is a fetchVTiMGs catch")
    throw (err)
  }

}

export const fetchVirtualTourImagesDispatch = (paegIndex, pageSize, tourId) => {

  return async(dispatch, getState) => {
    
    if(getState().localSessionDetails.payload === "") return;

      try{ 

        let token = getState().localSessionDetails.payload.accessToken;

        const resIMGs =await fetchVirtualTourImages(token, dispatch, paegIndex, pageSize, tourId)
        
        if(resIMGs.result.payload.pagedList.length === 0){
          dispatch(setHasMoreVirtualTourImages(false)) 
        }
        
        dispatch(setImages(resIMGs.result.payload))
        dispatch(setImagesTotalLength(resIMGs.result.payload.totalRecords))

        dispatch(setResponseForVirtualImages({ key: "isImgResGet", value: true}))
       
      }catch (err){

        if(err!= undefined && err.isError){
           
        }
    }
  }

}

const updateVirtualTourImage =async (token, dispatch, data) =>{

  try{

    // let url= `${API.BASE_URL+API.GENERATE_PIN}`
    let url= `${API.BASE_URL+API.UPLOAD_360_IMAGES}`
   
    let headerConfig = {
      // "Content-Type": "application/json",
      "Authorization": "Bearer " +token
    }

    // let dataObje= {
    //   ...data
    // }

    const formData = new FormData(); 
    formData.append( "name", data.name );
    formData.append( "id", data.id );

    let dataObje= {
      data: formData,
      isFormData: true
    }

    const fetchUpdatedVirtualTourImage =await postData({url, headerConfig, dataObje}, dispatch)

    console.log("this is a fetchUpdatedVirtualTourImage try", fetchUpdatedVirtualTourImage)
  
  return fetchUpdatedVirtualTourImage;
  
  }catch (err){

    if(err!=undefined && err.isError!= undefined && err.isError) toast.error(err.responseException.exceptionMessage, { });
  }

}

export const updateVirtualTourImageDispatch = (data) => {

  return async(dispatch, getState) => {
    
    if(getState().localSessionDetails.payload === "") return;

    try{
        let token = getState().localSessionDetails.payload.accessToken;

        const resVirtualTourImage =await updateVirtualTourImage(token, dispatch, data)
        
        if(resVirtualTourImage.result != undefined && resVirtualTourImage.result.code === 200){

          console.log("setUpdateVirtualTourImagesetUpdateVirtualTourImage", resVirtualTourImage)
          dispatch(setUpdateVirtualTourImage(data))

          toast("360° Experiences updated Successfully");
        }
       
      }catch (err){

        if(err!= undefined && err.isError){
           
        }
    }
  }
}

const deleteVirtualTourImage =async (token, dispatch, data) =>{

  try{

    // let url= `${API.BASE_URL+API.GENERATE_PIN}`
    let url= `${API.BASE_URL+API.DELETE_VIRTUAL_TOUR_IMAGE}`
   
    let headerConfig = {
      "Content-Type": "application/json",
      "Authorization": "Bearer " +token
    }

    let dataObje= {
      ...data
    }

    const deletedVirtualImages =await postData({url, headerConfig, dataObje}, dispatch)

    console.log("this is a deletedVirtualImages try", deletedVirtualImages)
  
  return deletedVirtualImages;
  
  }catch (err){

    if(err!=undefined && err.isError!= undefined && err.isError) toast.error(err.responseException.exceptionMessage, { });
  }

}

export const deleteVirtualTourImageDispatch = (data) => {

  return async(dispatch, getState) => {
    
    if(getState().localSessionDetails.payload === "") return;

    try{
        let token = getState().localSessionDetails.payload.accessToken;

        const resVirtualTourImage =await deleteVirtualTourImage(token, dispatch, data)
        
        if(resVirtualTourImage.result != undefined && resVirtualTourImage.result.code === 200){
          dispatch(setDeletedVirtualTourImage(data))
          dispatch(setImagesTotalLength(getState().virtualTourImages.totalLength -1))
        }

        toast("Image deleted Successfully.");
       
      }catch (err){

        if(err!= undefined && err.isError){
           
        }
    }
  }
}

export default virtualTourImages.reducer 