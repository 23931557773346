import { createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify';
import { getSubscriptionDetails } from '../../UTILITYS/endPoints.js';

const initialState = {
  isDetailsGet: false,
  isPaymentProcecing: false,
}

export const subscription = createSlice({
  name: 'subscription',
  initialState: { ...initialState },
  reducers: {
    setSubscriptionDetails: (state, action) => {
      console.log('setSubscriptionDetails >>', action)
      return {...action.payload, isDetailsGet: true}
    },
    updateSubscriptionDetails: (state, action) => {
      console.log('setSubscriptionDetails updateSubscriptionDetails >>', {
        ...state,
        ...action.payload,
        isDetailsGet: true
      })
      return {
        ...state,
        ...action.payload,
        isDetailsGet: true
      }
    },
    resetSubscriptionDetails: (state, action)=>{
      return {...initialState}
    },
    setIsDetailsGetToFalse: (state, action)=>{
      return {...state, isDetailsGet: false}
    },
    setIsPaymentProcecing: (state, action)=>{
      console.log("subscription__ 2", action.payload)
      return {...state, isPaymentProcecing: action.payload}
    }
  }
})

// Action creators are generated for each case reducer function
export const { setSubscriptionDetails, resetSubscriptionDetails, updateSubscriptionDetails, setIsDetailsGetToFalse, setIsPaymentProcecing } = subscription.actions

export const subscriptionFetch =async (dispatch, token) =>{
  try{
    return await getSubscriptionDetails(dispatch, token);
  }catch (err){
    console.log("this is a loginFetch catch")
    throw (err)
  }
}

export const getSubscriptionDispatch = () => {

  return async(dispatch, getState) => {

    toast.dismiss();
    if(getState().localSessionDetails.payload === "") return;
    
    try{
        let token = getState().localSessionDetails.payload.accessToken;
        const resSubscription =await subscriptionFetch(dispatch, token);
        dispatch(setSubscriptionDetails(resSubscription.result.payload));
      }catch (err){

        if(err!=undefined && err.isError!= undefined && err.isError && err.responseException.exceptionMessage){
          toast.error(err.responseException.exceptionMessage, { });
        }
    }
  }
}

export const updateSubscriptionDetailsDispatch = (updatedData) => {

    return async(dispatch) => {
        dispatch(updateSubscriptionDetails(updatedData));
    }
}


export default subscription.reducer;