import { createSlice } from '@reduxjs/toolkit'
import { postData } from '../../../UTILITYS/index.tsx';
import { toast, ToastContainer } from 'react-toastify';
import * as API from './../../../APIs/index'
import {resetUserData} from './../../Profile/slice.reducer.tsx'
import {resetHamburg} from './../../../STORE/CommonSlices/hameBurgerOnOff'
import { resetModuleActive } from '../../../COMPONENTS/SideBarMenu/activeModule.slice';
import { resetParentModuleActive } from '../../../COMPONENTS/SideBarMenu/activeParentModule.slice';
import { redirect } from "react-router-dom";
import { LoaderForIndividualPageDispatch } from "../../../COMPONENTS/Loader/LoaderForIndividualPage.slice.reducer";
// import { resetSubscriptionDetails } from '../../Subscription/slice.reducer.js';

const initialState = {
  code: "",
  message: "",
  pagination: "",
  payload: "",
  sentDate: "",
  isLoginFaild: false,
}

export const sessionData = createSlice({
  name: 'sessionData',
  initialState: { ...initialState },
  reducers: {
    setSessionData: (state, action) => {
      console.log('this is our login submit data >>', action)
      return {...action.payload}
    },
    resetSessionData: (state, action)=>{
      return {...initialState}
    },
    setLoginFaild: (state, action)=>{
      state.isLoginFaild = action.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const { setSessionData, resetSessionData, setLoginFaild } = sessionData.actions

const loginFetch =async (data, dispatch) =>{
    
  console.log("this is a loginFetch try")

    try{
      let url= API.BASE_URL+API.LOGIN
      let dataObje= {
        email: data.email,
        password: data.password
      }
      let headerConfig = {
        "Content-Type": "application/json"
      }
      const loginRes =await postData({url, headerConfig, dataObje}, dispatch)

      console.log("this is a loginDispatch try", loginRes)
    
    return loginRes;
    
  }catch (err){

    console.log("this is a loginFetch catch")
    throw (err)
  }

}

const logoutFetch = () =>{
    // console.log(data.email)
    // try{
    //   postData(data)
    // }catch (err){
    //   throw (err)
    // }
}

export const loginDispatch = (data, navigate, email) => {

  return async(dispatch, getState) => {
    // const stateBefore = getState()
    try{
      
        const resloginFetch =await loginFetch(data, dispatch) 
        
        dispatch(setSessionData(resloginFetch.result))
        localStorage.setItem("data", JSON.stringify({
          ...resloginFetch.result
        }))
        dispatch(setLoginFaild(false))
      }catch (err){

        console.log("this is a loginDispatch catch")
        dispatch(setLoginFaild(true))
        if(err!=undefined && err.isError!= undefined && err.isError && err.responseException.exceptionMessage === "Please verify your email"){
          let registrationDATA = {
            email :email,
            isNotVerified: true
          }
          toast.error(err.responseException.exceptionMessage, { });
          navigate('/confirmation', { state: registrationDATA})
        }else if(err!=undefined && err.isError!= undefined && err.isError && err.responseException.exceptionMessage){
          toast.error(err.responseException.exceptionMessage, { });
        }else if(err!=undefined && err.isError!= undefined && err.isError){
          toast.error("Invalid Email or Password", { });
        }
        
    
        // err dispatch goes here
    }
  }
}

export const logoutDispatch = (navigate = null) => {
    
    return async(dispatch, getState) => {
        // const stateBefore = getState()
        try{
            await logoutFetch()
            localStorage.clear()
            dispatch(resetSessionData())
            dispatch(resetUserData())
            dispatch(resetHamburg())
            dispatch(resetModuleActive())
            dispatch(resetParentModuleActive())
            // dispatch(resetSelfAssestsOnHome())
            // dispatch(resetShareWithMeAndOthers())
            dispatch(LoaderForIndividualPageDispatch(false))
            // dispatch(resetSubscriptionDetails(false))
            navigate!== null && navigate("/login", { replace: true }); 
            navigate === null && redirect("/login");
        }catch (err){
          throw (err)
        }
    }
}

export default sessionData.reducer