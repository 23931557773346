import React, { useEffect, useState } from "react";
import { 
    WhatsappShareButton, WhatsappIcon, 
    FacebookShareButton, FacebookIcon,
    TwitterShareButton, TwitterIcon,
    EmailShareButton, EmailIcon
} from "react-share";
import {Button, Modal, ModalBody, ModalHeader} from 'reactstrap';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { LOCAL_DOMAIN_VIEER_BASE_URL, DOMAIN_URL, VIEWER_THREE_SIXTY_WORLD_PAGE } from "../../APIs";
import Close from './../../ASSETS/Images/icons/cancel.png'
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import { ExpiredModal } from "../Modal/Expired";
import { setDoNotShowExpiredModal } from "../../SCREENS/Profile/slice.reducer";

export default (props)=>{

    const dispatch = useDispatch();
    const [openSharModal, setOpenSharModal] = useState(false);
    const [sharableData, setSharableData] = useState({ title: "", url: "" });
    const [didCopied, setDidCopied] = useState(false);
    const userProfileDetails = useSelector(state=> state.userProfileDetails)
    
    useEffect(()=>{
        if(props.openSharModal){
            if(userProfileDetails.isExpired){
                toast.dismiss();
                toast("Your subscription has been Expired...");
                dispatch(setDoNotShowExpiredModal(false));
                toggleSharModal();
                return;
            }
            createTourLinkAndShare(true);
        }
        else
            setOpenSharModal(false);
    }, [props.openSharModal])

    const createTourLinkAndShare = ()=>{
        let publicLInk= "";
        // publicLInk =LOCAL_DOMAIN_VIEER_BASE_URL+VIEWER_THREE_SIXTY_WORLD_PAGE+`/${props.tour.tourUrlToken}`;
        publicLInk =DOMAIN_URL+VIEWER_THREE_SIXTY_WORLD_PAGE+`/${props.tour.tourUrlToken}`;
        setSharableData({
            title: props.tour.name,
            url: publicLInk
        });
        setOpenSharModal(true);
    }

    const toggleSharModal = ()=>{
        setOpenSharModal(false);
        setDidCopied(false);
        props.setOpenSharModal(false);
    }

    if(userProfileDetails.isExpired){
        return <ExpiredModal />;
    }
    
    return (
        <React.Fragment>
            <Modal isOpen={openSharModal} centered className="drop-modal" toggle={toggleSharModal} >
                <Button className="modalClose" onClick={()=>{
                    toggleSharModal()
                }}>
                    <img src={Close} alt="Images" className="img-fluid" />
                </Button>
                <ModalHeader className="mt-2 pb-1 px-0">Share link with friends</ModalHeader>
                <ModalBody className="pt-0">
                    <div className="share-field mx-2 mb-3">
                        <input value={sharableData.url} disabled className="form-control text-truncate" />
                        <CopyToClipboard text={sharableData.url} onCopy={() => {
                            if(!didCopied){
                                setDidCopied(true);
                                toast.dismiss();
                                toast("Copied Successfully", { })
                            }
                        }}>
                            <span >{didCopied? "Copied": "Copy"}</span>
                        </CopyToClipboard>
                    </div>
                    <div className="text-center py-3 share-btn">
                        <WhatsappShareButton 
                            title={sharableData.title}
                            url={sharableData.url}
                        >
                            <WhatsappIcon round></WhatsappIcon>
                        </WhatsappShareButton>
                        <FacebookShareButton 
                            quote={sharableData.title}
                            url={sharableData.url}
                        >
                            <FacebookIcon round></FacebookIcon>
                        </FacebookShareButton>
                        <TwitterShareButton 
                            title={sharableData.title}
                            url={sharableData.url}
                        >
                            <TwitterIcon round></TwitterIcon>
                        </TwitterShareButton>
                        <EmailShareButton 
                            subject={sharableData.title}
                            body={sharableData.url}
                        >
                            <EmailIcon round></EmailIcon>
                        </EmailShareButton>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    )
}