import React, { useState, useContext } from "react";
import { Button, Modal, ModalBody } from 'reactstrap'; 
import { cancelSubscription, checkPaymentStatus, getPortalLink, verifyCardPayment } from "../../UTILITYS/endPoints";
import { toast } from 'react-toastify';
import Close from '../../ASSETS/Images/icons/cancel.png'
import CreateTokenContextProvider from "../../STORE/App-wide-state/Token/createTokenContextProvider";
import { useDispatch } from "react-redux";
import { getSubscriptionStatusBC } from "../../UTILITYS/Helper";
import { getSubscriptionDispatch, resetSubscriptionDetails, setIsPaymentProcecing } from "./sbscription.slice";
import { fetchUserDetailsDispatch, resetUserData } from "../../SCREENS/Profile/slice.reducer";
import { razorpayConfig } from "../../UTILITYS/Constant";

export default (props)=> {  

    const dispatch = useDispatch();
    const subscription= props.subscription;
    const [modal, setModal] = useState(false);
    const tokenContext = useContext(CreateTokenContextProvider);
    const [stripePortalModal, setStripePortalModal] = useState(false);

    function loadScript(src) {
        return new Promise((resolve) => {
        const script = document.createElement('script')
        script.src = src
        script.onload = () => {
            resolve(true)
        }
        script.onerror = () => {
            resolve(false)
        }
        document.body.appendChild(script)
        })
    }

    async function displayRazorpay () {
        const res = await loadScript(razorpayConfig.v1_library)
        if (!res){
            alert('Razropay failed to load!!')
            return 
        }
        var options = {
            subscription_id: subscription.razorpaySubscriptionDetails.subscriptionId,
            handler: function (response){
                if(response !== undefined && response.razorpay_signature!== undefined){
                    dispatch(setIsPaymentProcecing(true))
                    verifyPayment(response);
                }
            },
            ...razorpayConfig.options
        };
        const paymentObject = new window.Razorpay(options); 
        paymentObject.open();
        paymentObject.on('payment.failed', function (response){
            console.log("handler 3", response);
            if(response != undefined && response.error!=undefined && response.error.description!= undefined && response.error.description !== "") toast.error(response.error.description, { });
        })
    }

    const verifyPayment =async (response)=>{
        const {razorpay_payment_id, razorpay_subscription_id, razorpay_signature} =response;
        let data ={
            razorpaysignature: razorpay_signature,
            razorpaypaymentid: razorpay_payment_id
        }
        try{
            const res = await verifyCardPayment(data, tokenContext.accessToken); 
            if(res !== undefined && res.result !== undefined && res.result.message !== undefined && res.result.message === "Success"){
                setTimeout(async() => {
                    const res = await checkPaymentStatus({razorpaypaymentid: razorpay_payment_id}, tokenContext.accessToken); 
                    if(res !== undefined && res.result !== undefined && res.result.message !== undefined && res.result.message === "Success" && res.result.payload === "captured"){
                        setTimeout(async() => {
                            toast("Subscription created successsfully");
                            dispatch(setIsPaymentProcecing(false))
                            dispatch(resetSubscriptionDetails())
                            dispatch(resetUserData())
                            dispatch(getSubscriptionDispatch())
                            dispatch(fetchUserDetailsDispatch()); 
                        }, 6000)
                    }
                }, 2500);
            }
        }catch (err){
            if(err!=undefined && err.isError!= undefined && err.isError) toast.error(err.responseException.exceptionMessage, { });
        }
    }

    const getStatusValue =(subscription)=>{
        if( subscription.razorpaySubscriptionDetails.status === "cancelled" )
            return "Canceled"
        if( subscription.subscriptionStatus === "Trial" )
            return "Trial"
        if( subscription.subscriptionStatus === "Active" )
            return "Active"
        if( subscription.subscriptionStatus === "Expired" )
            return "Expired"
    }

    const getStatusCode =(subscription)=>{

        if( subscription.razorpaySubscriptionDetails.status === "cancelled" )
            return "4"
        if( subscription.subscriptionStatus === "Trial" )
            return "1"
        if( subscription.subscriptionStatus === "Active" )
            return "3"
        if( subscription.subscriptionStatus === "Expired" )
            return "5"
    }

    const getSubsctriptionActionButtonBasedOnStatus = ()=>{

        const status = getStatusCode(subscription);
        if(status === "1" || status === "5"){
            return <Button className="btn btn-primary expired-background" 
                onClick={displayRazorpay}
            >
                Start Subscription
            </Button>
        }
        else if(status === "3"){
            return <Button className="btn btn-primary" 
                onClick={()=> setModal(!modal)} 
            >
                Cancel
            </Button>
        }
    }


    const togglePaushResumeSubscription = async()=>{
        const status = getStatusCode(subscription);
        toast.dismiss();
       if((status === "3")){
            try{
                let getRes = await cancelSubscription( tokenContext.accessToken, dispatch );
                toast(getRes.result.payload.subscriptionPlan, { });
                setModal(!modal);
            }catch (err){
                if(err!=undefined && err.isError!= undefined && err.isError) toast.error(err.responseException.exceptionMessage, { });
                console.log("resultresult 3", err);
            }
        }
    }

    const getConfimViewToResumeAndCancel = ()=>{
        const status = getStatusCode(subscription);
        return <Modal isOpen={modal} centered className="drop-modal" toggle={()=>{
            setModal(!modal);
            }} >
            <Button className="modalClose" onClick={()=>{
                setModal(!modal);
            }}>
                <img src={Close} alt="Images" className="img-fluid" />
            </Button>
            <ModalBody>
                <div className="share_people py-4 text-center">
                    <div class="row">
                        <div className="col-12">
                            <h5>Are you sure?</h5>
                           <p>Do you want to { status === "3" && "Cancel Subscription"}.</p>
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col-12 mt-4">
                            <button 
                                className="btn btn-primary"
                                onClick={togglePaushResumeSubscription}
                            >Yes</button>
                            <button 
                                className="btn btn-primary btn-cancel ms-4"
                                onClick={()=> setModal(!modal)}
                            >Cancel</button>
                        </div>
                    </div>
                </div> 
            </ModalBody>
        </Modal>
    }

    const redirectedToStripePortal = ()=>{
      
        return <Modal isOpen={stripePortalModal} centered className="drop-modal" toggle={()=>{
            setStripePortalModal(!stripePortalModal);
            }} >
            <Button className="modalClose" onClick={()=>{
                setStripePortalModal(!stripePortalModal);
            }}>
                <img src={Close} alt="Images" className="img-fluid" />
            </Button>
            <ModalBody>
                <div className="share_people py-4 text-center">
                    <div class="row">
                        <div className="col-12">
                           <p>You will now be redirected to Stripe Payments’ website, our billing processing partner.</p>
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col-12 mt-4">
                            <button 
                                className="btn btn-primary"
                                onClick={async ()=> {

                                    let data ={
                                        CustomerId: subscription.stripeSubscriptionDetails.customerId,
                                        stripestatus: subscription.stripeSubscriptionDetails.status
                                    }

                                    let getRes = await getPortalLink(data, tokenContext.accessToken, dispatch );
                                    console.log("getPortalLinkgetPortalLink", getRes)
                                    
                                    if(getRes && getRes.result && getRes.result.payload){
                                        window.open(getRes.result.payload,'_blank')
                                        setStripePortalModal(!stripePortalModal)
                                    }
                                }}
                            >Yes</button>
                            <button 
                                className="btn btn-primary btn-cancel ms-4"
                                onClick={()=> setStripePortalModal(!stripePortalModal)}
                            >Cancel</button>
                        </div>
                    </div>
                </div> 
            </ModalBody>
        </Modal>
    }

    const statusValue = getStatusValue(subscription);
    const status = getStatusCode(subscription);

    return <React.Fragment>
    {getConfimViewToResumeAndCancel()}
    {redirectedToStripePortal()}
    <div className="px-2 px-md-0">
        <div className="container-fluid">
            <div className="white-box mt-0 mt-md-2 mh-100-120">
                <h5 className=" mb-4"><strong>Subscription</strong></h5>
                <h6 className="text-medium">Current Plan</h6>
                <div 
                    class="card mb-4" 
                    style={{"background-color": "var(--card-some_how_light-background)"}}
                >
                    <div class="card-body">
                        <div className="d-block d-lg-flex justify-content-between">
                            <div>
                                <h6 class="card-title"><strong>Orbzify <span className="text-primary text-capitalize">{subscription.subscriptionStatus}</span></strong></h6>
                                
                                <p class="card-text mb-0">Monthly Subscription<span className="subscription-status" 
                                    style={getSubscriptionStatusBC(status)}
                                >
                                    {statusValue}
                                </span></p>
                                
                                <div dangerouslySetInnerHTML={{ __html: subscription.message }} style={{"margin-top": "18px"}}/>
                            </div>
                            <div className="text-lg-end mt-3 mt-lg-0">
                                {getSubsctriptionActionButtonBasedOnStatus()}
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</React.Fragment>
}