import {useState, useEffect} from "react";
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { setDoNotShowExpiredModal } from "../../SCREENS/Profile/slice.reducer";
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom'
import Expired from './../../ASSETS/Images/xr/expired.png';

export const ExpiredModal=()=>{
    
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const [isModalShowIfSubscriptionExpired, setIsModalShowIfSubscriptionExpired] = useState(false);
    const userProfileDetails = useSelector(state => state.userProfileDetails);

    console.log("userProfileDetails.isExpired", userProfileDetails)

    useEffect(()=>{
        let path = pathname.split("/")[1];
        if(path === "home" || path === "virtual-tour" || path === "virtual-tour-images" || path === "virtual-tour-world" || path === "viewProfile" || path === "editProfile" || path === "changePassword" ){
          console.log("alisalis",userProfileDetails.doNotShowExpiredModal)
            setIsModalShowIfSubscriptionExpired((userProfileDetails.subscriptionStatus === "Expired") && !userProfileDetails.doNotShowExpiredModal);
        }
      }, [userProfileDetails.subscriptionStatus, userProfileDetails.doNotShowExpiredModal]);
    
    return <Modal isOpen={isModalShowIfSubscriptionExpired} centered className="inner-modal" toggle={()=>{
        setIsModalShowIfSubscriptionExpired(false);
        dispatch(setDoNotShowExpiredModal(true));
    }} >
        <ModalHeader toggle={()=>{
          setIsModalShowIfSubscriptionExpired(false);
          dispatch(setDoNotShowExpiredModal(true));
        }}></ModalHeader>
        <ModalBody>
          <div className="m-4 mt-0" style={{textAlign: "center"}}>
          <img src={Expired} alt="Images" className="img-fluid" />
          <h5 className="text-danger mt-4 pt-1 mb-3">Your Premium Subscription has expired!</h5>
          <p className="mw-400">Renew your subscription to continue using the premium feature.</p>
            <button className="btn mt-2 btn-primary btn-long" onClick={()=>{
              dispatch(setDoNotShowExpiredModal(true));
              navigate('/subscription');
            }}>Click here to renew subscription</button>
        </div>
        </ModalBody>
    </Modal>
}

