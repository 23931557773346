import { PIE_CHART_BACKGROUNG_COLOR, PIE_CHART_BODER_COLOR } from "./Constant";

export const base64toBlob = (data) => {
  const base64WithoutPrefix = data.replace(/^data:image\/\w+;base64,/, "");
  const bytes = atob(base64WithoutPrefix);
  let length = bytes.length;
  let out = new Uint8Array(length);
  while (length--) {
    out[length] = bytes.charCodeAt(length);
  }
  return new Blob([out], { type: "image/jpeg" });
};

export const getLineAnimation=(length, totalDuration=550)=>{
  const delayBetweenPoints = totalDuration / length;
  const previousY = (ctx) => ctx.index === 0 ? ctx.chart.scales.y.getPixelForValue(100) : ctx.chart.getDatasetMeta(ctx.datasetIndex).data[ctx.index - 1].getProps(['y'], true).y;
  const animation = {
    x: {
      type: 'number',
      easing: 'linear',
      duration: delayBetweenPoints,
      from: NaN, // the point is initially skipped
      delay(ctx) {
        if (ctx.type !== 'data' || ctx.xStarted) {
          return 0;
        }
        ctx.xStarted = true;
        return ctx.index * delayBetweenPoints;
      }
    },
    y: {
      type: 'number',
      easing: 'linear',
      duration: delayBetweenPoints,
      from: previousY,
      delay(ctx) {
        if (ctx.type !== 'data' || ctx.yStarted) {
          return 0;
        }
        ctx.yStarted = true;
        return ctx.index * delayBetweenPoints;
      }
    }
  };

  return animation;
}

export const pieChartDatasetsCommanConfig =(data)=> {

  let newBackgroundColor= [...PIE_CHART_BACKGROUNG_COLOR];
  let newBorderColor= [...PIE_CHART_BODER_COLOR];
  if(data.length !== PIE_CHART_BACKGROUNG_COLOR.length && data.length < PIE_CHART_BACKGROUNG_COLOR.length){
      let getDiff= parseInt(PIE_CHART_BACKGROUNG_COLOR.length) - parseInt(data.length);
      newBackgroundColor= newBackgroundColor.slice(getDiff);
      newBorderColor= newBorderColor.slice(getDiff);
  }
  return{
      label: 'Views',
      backgroundColor: newBackgroundColor,
      borderColor: newBorderColor,
      borderWidth: 1,
  }
}


export const getPercentage =(data)=> {
  
  // Calculate the total sum of values
  const totalValue = data.reduce((sum, item) => sum + item.views, 0);
  
  // Calculate the percentage for each item
  return data.map(item => {
    let toFixed= ((item.views / totalValue) * 100).toFixed(1);
    if(parseInt(toFixed.toString().split(".")[1]) === 0){
      toFixed= Math.floor(toFixed);
    }
    return { percentage: toFixed,
      ...item
      }
    });
    
  }

export const getSubscriptionStatusBC =(status)=> {
  if( status === "1" || status === "3" ) return {"background-color": "#00D924"}
else if( status === "2" || status === "4" ) return {"background-color":"#EB9A00"}
  else return {"background-color":"#DB0000"}
}

export const getColor =(color, property)=> {
  return {[property]: color}
}

// export const getStatusClass =(userDetails)=>{    

//   if(userDetails.isPremium && !userDetails.isExpired){
//     return "user-premium"
//   }else if(userDetails.isExpired){
//     return "user-expired"
//   }else if(!userDetails.isPremium){
//     return "user-free"
//   }

// }
export const getStatusClass =(userDetails)=>{    

  if(userDetails.subscriptionStatus === "Active"){
    return "user-premium"
  }else if(userDetails.subscriptionStatus === "Expired"){
    return "user-expired"
  }else if(userDetails.subscriptionStatus === "Trial"){
    return "user-free"
  }

}