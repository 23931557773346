import React, {useState, useEffect} from "react";
import Parent from '.'
import './ForgetResetPasswordStyle.module.css'
import Logo from './../../../COMPONENTS/Logo'
import Container from './../index.container'
import CopyRight from "../../../COMPONENTS/CopyRight";
import verifiedEmail from './../../../ASSETS/Images/xr/changed.png';
import { NavLink } from "react-router-dom";
import {useLocation} from "react-router-dom";

// THIS SCREEN WILL SHOW IF PASSWORD CHANGED SUCCESSFULLY
export default (props) =>{
    let { state } = useLocation();
    const [iscreatepassword, setIscreatepassword] = useState(false);

    useEffect(()=>{
        if(state !== undefined && state !== null && state.iscreatepassword ){
            setIscreatepassword(true)
        }else{
            setIscreatepassword(false)
        }
      }, [])

    return (
        <Parent>
            <React.Fragment>
                <Container>      
                    <div className="text-center mb-4 mt-3">
                        <Logo logoClass="mb-1 pb-2" />
                        <div className="email-verification pb-3">
                            <img src={verifiedEmail} alt="Logo" className="mb-3 pb-1" /> 
                            <h5>{iscreatepassword ? "Password created" :"Password changed"}</h5>
                            <p>{iscreatepassword ? "You password has been successfully created" :"You password has been successfully changed."}</p>
                            <div className="mt-4 pt-2">
                                <NavLink className="btn btn-primary w-100" to="/login">Login</NavLink>
                            </div>
                        </div>
                    </div>
                    
                    {/* <CopyRight copyRightClassess = {["text-center", "copyright"]} /> */}
                </Container>
            </React.Fragment>
        </Parent>
    )
}