import React, {useEffect, useRef, useState} from "react";
import './index.css';
import { NavLink, useNavigate, useParams} from "react-router-dom";

// import Logo from './../../../ASSETS/Images/Recuerdo_1x.png';

import Logo from './../../../COMPONENTS/Logo'
import Eye from './../../../ASSETS/Images/eye.png';
import EyeOff from './../../../ASSETS/Images/eye-off.png';
// import loginBackground from './../../../ASSETS/Images/Background_image.jpg';
import validator from 'validator';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import * as APIs from './../../../UTILITYS/endPoints'
import { useSelector, useDispatch } from 'react-redux'
import Container from './../index.container'
import CopyRight from "../../../COMPONENTS/CopyRight";


function Registration() {

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isPasswordMaskedOPen, setIsPasswordMaskedOPen] = useState(true)
  const [isConPasswordMaskedOPen, setConIsPasswordMaskedOPen] = useState(true)
  const navigate = useNavigate()
  const [isChecked, setIsChecked] = useState(false)
  const dispatch = useDispatch()

  const [firstNameErr, setrFirstNameErr] = useState(false)
  const [lastNameErr, setLastNameErr] = useState(false)
  const [emailNameErr, setEmailNameErr] = useState(false)
  const [passwordNameErr, setPasswordNameErr] = useState(false)
  const [consfirmfirstNameErr, setConsfirmfirstNameErr] = useState(false)

  useEffect(()=>{
      window.scrollTo(0, 0);
  }, [])
  
  const registration = async (e)=>{
    
    e.preventDefault();
    toast.dismiss()
   
    if(validator.isEmpty(firstName.trim())){
      toast.error("Please Enter First Name", { });
      setrFirstNameErr(true)
      return;
    }else if(!validator.isLength(firstName.trim(), {min: 1, max: 25})){
      toast.error("First Name length should be minimum 3 char and maximum 25 char", { });
      setrFirstNameErr(true)
      return;
    }

    if(!validator.isEmail(email, {blacklisted_chars : "+-"})){
      if(email === "") toast.error("Please Enter Email", { });
      else toast.error("Please Enter valid Email", { });
      setEmailNameErr(true)
      return
    }
    
    if(!validator.isLength(password, {min: 8, max: 14})){
      if(password === "") {
        toast.error("Please Enter Password", { });
        setPasswordNameErr(true)
      }else {
        toast.error("Password length should be minimum 8 char and maximum 14 char", { });
        setPasswordNameErr(true)
      }
      return;
    }

    if(isChecked === false){
      toast.error("Please accept Terms and Conditions", { });
      return;
    }
    
    let LastName="";
    let FirstName=firstName.trim();
    
    if(firstName.trim().split(" ").length >1){
      let firstSpaceIndex = firstName.trim().indexOf(' ');
      FirstName = firstName.trim().split(" ")[0];
      LastName = firstName.substring(firstSpaceIndex + 1);
    }

    
    let registrationDATA = {
      firstName: FirstName,
      lastName: LastName,
      email :email,
      password: password,
      phoneNumber: "8305430349",
      roles: [
        "User"
      ],
    }

    try{
      await APIs.registrationPost(registrationDATA, dispatch);
      navigate('/confirmation', { state: registrationDATA})
    }catch (err){
      if(err!=undefined && err.isError!= undefined && err.isError) toast.error(err.responseException.exceptionMessage, { });
    }
  }

  const onInputChange = (e)=>{
    
    switch(e.target.name){
      case "firstName":
        setFirstName(e.target.value.replace(/[^A-Za-z\s]+/g, ''));
        setrFirstNameErr(false)
        break;
      case "email":
        setEmail(e.target.value);
        setEmailNameErr(false)
        break;
      case "password":
        setPassword(e.target.value);
        setPasswordNameErr(false)
        break;
    }
}
  
    return (
      <React.Fragment>
        <Container> 
          {/* Logo  */}
          <div className="text-center mb-3 mt-3">
             <Logo logoClass="mb-1 pb-2" />
            <h4>Signup</h4>
          </div>

          {/* signup form start  */}
            <form className="pb-3" onSubmit={registration}>
              <div className="mb-3">
                  <label className="form-label">Full Name</label>
                  <input value={firstName} onChange={onInputChange} type="text" className={classNames('form-control', { "form-error": firstNameErr })} name="firstName" placeholder="First Name" />
              </div>
              <div className="mb-3">
                <label className="form-label">Email</label>
                  <input value={email} onChange={onInputChange}  type="text" className={classNames('form-control', { "form-error": emailNameErr })} name="email" placeholder="Email" />
              </div>
              {/* <div className="mb-3">
                  <input ref={phoneNumber} type="text" className={classNames('form-control', { "form-error": true })} name="phoneNumber" placeholder="Phone Number" />
              </div> */}
              <div className="mb-3 ">
                <label className="form-label">Password</label>
                  <div className="password-field">
                    <input value={password} onChange={onInputChange}  type={isPasswordMaskedOPen ?'password': "Text"} name="password" className={classNames('form-control', { "form-error": passwordNameErr })} placeholder="Password" />
                    <span onClick={()=>setIsPasswordMaskedOPen(!isPasswordMaskedOPen)}>
                        <img src={isPasswordMaskedOPen ?Eye: EyeOff} alt="Eye" />
                        {/* <img src={EyeOff} className="d-none" alt="Eye" /> */}
                    </span>
                  </div>
              </div>
              <div className="form-check">
                <input 
                className="form-check-input" 
                type="checkbox" 
                id="check" 
                checked={isChecked}
                onChange={()=>setIsChecked(!isChecked)}
                />
                <label className="form-check-label" htmlFor="check">
                I accept <NavLink 
                  to="#" 
                  onClick={(e) => {
                    e.preventDefault();
                    window.open('https://www.orbzify.com/terms-of-use/', '_blank');
                  }} target="_blank"><u>Terms & Conditions</u></NavLink>
                </label>
              </div>
            
              <div className="mb-4 mt-4">
                  <button className="btn btn-primary w-100">Sign up</button>
              </div>
              <div className="text-center pt-2">
                Already have an account? <NavLink to="/login"><u>Login</u></NavLink>
              </div>
            </form>
          {/* signup form end  */}

          {/* copyright content  */}
          {/* <CopyRight copyRightClassess = {["text-center", "copyright"]} /> */}
        </Container>
      </React.Fragment>
    );
  }
  
  export default Registration;