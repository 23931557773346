import {useState, useEffect} from "react"; 
import { Modal, ModalBody, Button, ModalHeader} from 'reactstrap'; 
import FeaturesMobile from './../../ASSETS/Images/xr/featured-mobile.jpg';
import { useNavigate } from 'react-router-dom'

export default (props) =>{

    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate()

    const toggleModal = () => {
        setIsOpen(!isOpen);
        props.setOpenUpgrade(!isOpen)
    }

    const applyUpgradation =async()=>{
        toggleModal();
        navigate('/subscription')
    }

    useEffect(()=>{
        setIsOpen(props.isOpen) 
    }, [props.isOpen])

    return  <Modal isOpen={isOpen} centered className="modal-md features-modal" toggle= {toggleModal} >
        <ModalHeader className="p-0" toggle={toggleModal}></ModalHeader>
        <ModalBody className="p-0">
        <div className="d-flex flex-column align-items-center">
            <img src={FeaturesMobile} alt="Images" className="img-fluid w-100" />
            <div className="px-4 px-md-5 py-4 w-100">
                <h5 className="text-uppercase text-black">You discovered a <span className="text-primary">Premium</span> feature!!</h5>
                <div>
                    <p>Upgrade to orbzify Premium will provide you access to</p>
                    <ul className="ps-3 features">
                        <li><strong>Unlimited</strong>  360° Tours</li>
                        <li><strong>30</strong> - 360° Experience in a Tours</li>
                        <li><strong>Unlimited</strong> - Sharing your 360 Tours</li>
                    </ul>
                    <div className="text-center pt-3 pt-md-4 pb-0 pb-md-3">
                        <Button className="me-2 me-md-3 btn-long text-uppercase" color="primary" onClick={applyUpgradation}>Upgrade</Button>
                        {/* <Button className="ms-2 ms-md-3 long-btn" color="outline-secondary" onClick={toggleModal}>Cancel</Button> */}
                    </div>
                </div>
            </div>
        </div>  
    </ModalBody>
    </Modal>
    

}

