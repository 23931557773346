import React, { useState } from "react";
import BackgroundCOM from './../../COMPONENTS/BackgroundCOM'
import {RECUERO_INFO_TEXT} from './../../UTILITYS/Constant';

export default (props)=>{

  console.log('this is AuthenticationContainer index props',props)
  return (
    <React.Fragment>
      <div className="container-fluid login-container">
        <div className="row auth-background-image" style={{
              display: "flex",
              "align-items": "center",
              "justify-content": "center",
        }}>
            {/* <BackgroundCOM>
              <h4 className="text-light text-center d-none">{RECUERO_INFO_TEXT}</h4>
            </BackgroundCOM> */}
            <div className="col-md-5 col-xxl-4 login-form" style={{}}>
             {props.children}
            </div>  
        </div>
      </div>
    </React.Fragment>
  )
}