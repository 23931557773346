import { configureStore } from '@reduxjs/toolkit'
import localSessionDetails from './../SCREENS/Authentications/Login/slice.reducer'
import userProfileDetails from './../SCREENS/Profile/slice.reducer.tsx'
import loader from './../COMPONENTS/Loader/slice.reducer'
import LoaderForIndividualPage from './../COMPONENTS/Loader/LoaderForIndividualPage.slice.reducer'
import hamburgur from './CommonSlices/hameBurgerOnOff'
import activeModule from '../COMPONENTS/SideBarMenu/activeModule.slice'
import activeParentModule from '../COMPONENTS/SideBarMenu/activeParentModule.slice'
// import assestsData from '../SCREENS/Home/slice.reducer'
// import shareWithMeAndOthers from '../SCREENS/Home/slices/ShareWithMe'
// import sharedAssestWithMeOrBy from '../SCREENS/FamilyAndFriends/slice.reducer'
// import vrUsers from '../SCREENS/VR-experience/Slices/VRexperienceUser.slice'
// import vrRoom from '../SCREENS/VR-experience/Slices/Room.slice'
// import VRassestList from '../SCREENS/VR-experience/Slices/VRassestList.slice'
import currentUploadedAssestsCount from './../COMPONENTS/DRAGE_FILE_FOLDER/dragFileFolder.slice'
import virtualTours from '../SCREENS/360virtualTour/Slices/VirtualTour.slice'
import virtualTourImages from '../SCREENS/360virtualTour/Slices/VirtualTourImages.slice'
import threeSixtyVirtualImageOnUpload from '../SCREENS/360virtualTour/Slices/threeSixtyVirtualImageOnUpload.slice'
import dashBoardData from '../SCREENS/Home/home.slice.js'
// import subscription from '../SCREENS/Subscription/slice.reducer.js'
import subscription from './../SCREENS/Subscription/sbscription.slice.js'
    
import viewerVirtualTourImages from '../SCREENS/viewer/viewerVirtualTourImages.slice.js'

const store= configureStore({
    reducer: { 
        localSessionDetails, 
        userProfileDetails, 
        loader,
        LoaderForIndividualPage,
        hamburgur,
        activeModule,
        activeParentModule,
        // assestsData,
        // shareWithMeAndOthers,
        // sharedAssestWithMeOrBy,
        // vrUsers,
        // vrRoom,
        // VRassestList,
        currentUploadedAssestsCount,
        virtualTours,
        virtualTourImages,
        threeSixtyVirtualImageOnUpload,
        viewerVirtualTourImages,
        dashBoardData,
        // subscription,
        subscription,

    }
});

export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch